import React from "react"
import "./PhoneNumberInput.scss"
import TextInput from "./TextInput"

interface Props {
  forceMobile?: boolean
  placeholder?: string
  onSubmit: (phoneNumber: string) => void
}

export default function PhoneNumberInput(props: Props): JSX.Element {
  const { forceMobile, placeholder } = props
  const onSubmit = (text?: string) => props.onSubmit(text!)
  const validation = forceMobile ? [/^07\d{9}$/] : [/^07\d{9}$/, /^\d{11}$/]
  const validationExplainer = forceMobile
    ? [
        "This is not a valid phone number.",
        "Make sure it's 11 numbers with no spaces",
        "and it's a mobile phone (starts with 07)"
      ]
    : [
        "This is not a valid phone number.",
        "Make sure it's 11 numbers with no spaces",
        "(and starts with 07 if it's a mobile phone)"
      ]
  return (
    <TextInput
      forceValue
      borderless
      trimAllSpacesOnSubmit
      trimAllSpacesOnValidation
      placeholder={placeholder || "Type your phone number"}
      clearOnSubmit={false}
      autoCorrect={"none"}
      autoCapitalize={"none"}
      autoComplete={"phoneNumber"}
      validation={validation}
      validationExplainer={validationExplainer}
      onSubmit={onSubmit}
    />
  )
}
