import moment from "moment"
import {
  ALCOHOL_QUANTITIES as AQ,
  ALCOHOL_FREQUENCIES as AF,
  GENDER_INSIGHT,
  ETHNICITY_INSIGHT,
  ARMED_FORCES_INSIGHT,
  DISABILITY_INSIGHT,
  RELIGION_INSIGHT,
  InsightEmploymentStatus,
  InsightEmploymentBenefits,
  InsightEmploymentSupport,
  GenderBirthAssigned
} from "@limbic/types"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { ALCOHOL_FREQUENCIES, ALCOHOL_QUANTITIES } from "../../../models/Constants"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import invariant from "../../../utils/invariant"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import type {
  LANGUAGE_INSIGHT,
  CIVIL_STATUS_INSIGHT,
  LIMBIC_IMPACT_LEVEL,
  LTC_INSIGHT,
  ReferralPayloadInsight,
  SEXUALITY_INSIGHT
} from "@limbic/types"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import { IAPTIDs } from "../../../models/IIAPTService"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  languages,
  ltcs,
  maritalStatuses,
  religions,
  sameGenderAsBirth,
  sexualities,
  employmentSupportsType
} from "../../../config/referralForms/insight-form"
import { toLowerCaseProperties } from "../../../utils/object"
import dialoguesRegistry from "../../dialoguesRegistry"

interface State extends SelfReferralIAPTScriptState {
  asylumSeeker?: boolean
  childUnderEighteen?: boolean
  disabilities?: string[]
  hasLongCovid?: boolean
  isReferralForLongCovid?: boolean
  benefits?: InsightEmploymentBenefits
  employmentStatus?: InsightEmploymentStatus
  employmentSupport?: InsightEmploymentSupport
  employmentAdvisorAccepted?: boolean | undefined
  mainIssue?: string
  maritalStatus?: string
  moneyWorries?: boolean
  involvedInDisaster?: string
  primaryLanguage?: string
  requiresInterpreter?: boolean
  perinatal?: string[]
  numberOfPreviousIaptTreatments?: "Never" | "Once" | "Twice" | "Three or more times"
  receivedPreviousIaptTreatment?: boolean
  receivingStatutorySickPay?: boolean
  keyWorker?: boolean
  gpConsent1?: boolean
  gpConsent2?: boolean
  genderOther?: string
  sexualityOther?: string
}

export class SelfReferralInsightScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralInsightScript"

  /** Script Steps */

  @step
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askMainIssue }
  }

  @step
  askWannaDoSelfReferral(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "There are just a few more details I need from you",
        "This should take no more than 10 minutes",
        "The first section collects personal information, the second is around some of your symptoms"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoSelfReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Sure" }, { body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.startSelfReferralPart1
    }
  }

  @step.logState
  askPermissionToEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you allow us to contact you over email?",
      prompt: {
        id: this.getPromptId("askPermissionToEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPermissionToEmail"
      },
      nextStep: this.handlePermissionToEmail
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canSendEmail = d.response
  })
  handlePermissionToEmail(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  @step.logState
  askMainIssue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "Brilliant",
        `So ${name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you)`
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssue
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralInsightScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
  })
  @step.checkInputForCrisis({ getNextStep: (s: SelfReferralInsightScript) => s.askPhoneNumber })
  handleMainIssue(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPhoneNumber }
  }

  @step.logState
  askPhoneNumber(d: IStepData<State>): IStepResult {
    if (d.state.phoneNumber) {
      return {
        nextStep: this.askDoYouHaveAnEmail
      }
    }
    return {
      body: "And what's the best phone number to reach you on?",
      prompt: {
        id: this.getPromptId("askPhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step.logState
  askCanIContactYouOnPhoneNumber(d: IStepData<State>): IStepResult {
    const isMobilePhone = isValidMobilePhone(d.state.phoneNumber!)
    return {
      body: "And do I have permission to message you or leave a voicemail on that number?",
      prompt: {
        id: this.getPromptId("askCanIContactYouOnPhoneNumber"),
        trackResponse: true,
        type: "checkbox",
        options: [
          isMobilePhone && {
            body: "You may send me text messages",
            key: "canSendTextMessagesToPhoneNumber",
            initialValue: false
          },
          {
            body: "You may leave voicemail messages",
            key: "canLeaveVoicemailToPhoneNumber",
            initialValue: false
          }
        ].filter(Boolean) as any
      },
      nextStep: this.handleCanIContactYouOnPhoneNumber
    }
  }

  @step.logState
  @step.checkInputForCrisis({ getNextStep: (s: SelfReferralInsightScript) => s.askSexuality })
  askSexuality(d: IStepData<State>): IStepResult {
    const sexualities = this.getSexualities(d.state)
    if (!sexualities?.length) {
      this.logBreadcrumb("SEXUALITIES NOT FOUND", d.state, { sexualities })
      this.logMessage("SEXUALITIES NOT FOUND")
      return { nextStep: this.askLongTermMedicalCondition }
    }
    return {
      body: "How would you describe your sexuality?",
      prompt: {
        id: this.getPromptId("askSexuality"),
        type: "inlinePicker",
        choices: sexualities.map(g => ({ body: this.getChoiceBody(g), value: g })),
        textPrompt: {
          forceValue: false
        },
        isUndoAble: true,
        dataPointsName: "askSexuality"
      },
      nextStep: this.handleSexuality
    }
  }

  // We need to handle it here, because in the base script it checks the answer if it
  // matches the options, and in this case, we have a custom (free text) response
  @step.logStateAndResponse
  async handleSexuality(d: IStepData<State, string>): Promise<IStepResult> {
    const sexualities = this.getSexualities(d.state)
    const isListed = sexualities?.find(s => s === d.response)
    if (isListed) {
      d.state.sexualityOther = undefined // in case of an undo
      d.state.sexuality = d.response
    } else {
      d.state.sexuality = undefined // in case of an undo
      d.state.sexualityOther = d.response
    }
    this.setPeople({ sexuality: d.response })
    return { nextStep: this.askMaritalStatus }
  }

  @step.logState
  askPerinatalStatus(d: IStepData<State>): IStepResult {
    const perinatals = this.getPerinatals(d.state)
    if (!perinatals?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatals })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.askDisabilityStatus }
    }

    return {
      body: "Which of the following apply to you?",
      prompt: {
        id: this.getPromptId("askPerinatalStatus"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          {
            body: "None apply",
            value: "none",
            backgroundColor: "#8C96D4FF",
            selectIndividually: true
          },
          ...perinatals.map(g => ({ body: g, value: g }))
        ],
        dataPointsName: "askPerinatalStatus"
      },
      nextStep: this.handlePerinatalStatus
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string[] | "none">, script: SelfReferralInsightScript) => {
      if (d.response === "none") return
      d.state.perinatal = d.response
      script.referralStore.setCustomField<State>("perinatal", d.response)
      script.setPeople({ perinatal: d.response })
    }
  )
  handlePerinatalStatus(_d: IStepData<State, string[]>): IStepResult {
    return { nextStep: this.askChildUnderEighteen }
  }

  @step.logState
  askChildUnderEighteen(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have children under the age of 18 living in your household?",
      prompt: {
        id: this.getPromptId("askChildUnderEighteen"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleChildUnderEighteen
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.childUnderEighteen = d.response
    script.referralStore.setCustomField<State>("childUnderEighteen", d.response)
  })
  handleChildUnderEighteen(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askAsylumSeeker }
  }

  @step.logState
  askAsylumSeeker(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you an asylum seeker?",
      prompt: {
        id: this.getPromptId("askAsylumSeeker"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleAsylumSeeker
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.asylumSeeker = d.response
    script.referralStore.setCustomField<State>("asylumSeeker", d.response)
  })
  handleAsylumSeeker(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPrimaryLanguage }
  }

  @step.logState
  askPrimaryLanguage(d: IStepData<State>): IStepResult {
    const language = this.getLanguage(d.state)
    if (language) {
      return { nextStep: this.askRequireInterpreter }
    }
    const languages = this.getLanguages(d.state)
    if (!languages?.length) {
      this.logBreadcrumb("LANGUAGES NOT FOUND", d.state, { languages })
      this.logMessage("LANGUAGES NOT FOUND")
      return { nextStep: this.askDisabilityStatus }
    }

    return {
      body: "What is your primary spoken language?",
      prompt: {
        id: this.getPromptId("askPrimaryLanguage"),
        type: "inlinePicker",
        choices: languages.map(g => ({ body: g, value: g })),
        dataPointsName: "askPrimaryLanguage"
      },
      nextStep: this.handleLanguage
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralInsightScript) => {
    d.state.primaryLanguage = d.response
    script.referralStore.setCustomField<State>("primaryLanguage", d.response)
  })
  handleLanguage(d: IStepData<State, string>): IStepResult {
    if (d.state.primaryLanguage !== "English") {
      return { nextStep: this.askRequireInterpreter }
    }
    return { nextStep: this.askDisabilityStatus }
  }

  @step.logState
  askRequireInterpreter(d: IStepData<State>): IStepResult {
    if (d.state.spineInterpreterRequired !== null) {
      return { nextStep: this.askDisabilityStatus }
    }
    return {
      body: "Do you require an interpreter?",
      prompt: {
        id: this.getPromptId("askRequireInterpreter"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequireInterpreter
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.requiresInterpreter = d.response
    script.referralStore.setCustomField<State>("requiresInterpreter", d.response)
  })
  handleRequireInterpreter(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askDisabilityStatus }
  }

  @step.logState
  askMaritalStatus(d: IStepData<State>): IStepResult {
    const maritalStatuses = this.getMaritalStatuses(d.state)

    if (!maritalStatuses?.length) {
      this.logBreadcrumb("MARITAL STATUSES NOT FOUND", d.state, { maritalStatuses })
      this.logMessage("MARITAL STATUSES NOT FOUND")
      return { nextStep: this.askDisabilityStatus }
    }

    return {
      body: "What is your current relationship status?",
      prompt: {
        id: this.getPromptId("askMaritalStatus"),
        type: "inlinePicker",
        choices: maritalStatuses.map(g => ({ body: g, value: g })),
        dataPointsName: "askMaritalStatus"
      },
      nextStep: this.handleMaritalStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralInsightScript) => {
    d.state.maritalStatus = d.response
    script.referralStore.setCustomField<State>("maritalStatus", d.response)
  })
  handleMaritalStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askReligion }
  }

  @step.logState
  askHasLongCovid(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have a diagnosis of long covid or you feel you are suffering with symptoms of long covid?",
      prompt: {
        id: this.getPromptId("askHasLongCovid"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleLongCovid
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.hasLongCovid = d.response
    script.referralStore.setCustomField<State>("hasLongCovid", d.response)
  })
  handleLongCovid(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      return {
        nextStep: this.askIsReferralForLongCovid
      }
    }
    return { nextStep: this.askEmploymentStatus }
  }

  @step.logState
  askIsReferralForLongCovid(_d: IStepData<State>): IStepResult {
    return {
      body: "Is this referral in relation to your long covid?",
      prompt: {
        id: this.getPromptId("askIsReferralForLongCovid"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleIsReferralForLongCovid
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.isReferralForLongCovid = d.response
    script.referralStore.setCustomField<State>("isReferralForLongCovid", d.response)
  })
  handleIsReferralForLongCovid(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEmploymentStatus }
  }

  @step.logState
  askEmploymentStatus(d: IStepData<State>): IStepResult {
    const employmentStatuses = this.getEmploymentStatuses(d.state)
    if (!employmentStatuses?.length) {
      this.logBreadcrumb("EMPLOYMENT STATUSES NOT FOUND", d.state, { employmentStatuses })
      this.logMessage("EMPLOYMENT STATUSES NOT FOUND")
      return { nextStep: this.askBenefits }
    }

    return {
      body: "What is your current employment status?",
      prompt: {
        id: this.getPromptId("askEmploymentStatus"),
        type: "inlinePicker",
        choices: employmentStatuses.map(g => ({ body: g, value: g })),
        dataPointsName: "askEmploymentStatus"
      },
      nextStep: this.handleEmploymentStatus
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, InsightEmploymentStatus>, script: SelfReferralInsightScript) => {
      d.state.employmentStatus = d.response
      script.referralStore.setCustomField<State>("employmentStatus", d.response)
    }
  )
  handleEmploymentStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askBenefits }
  }

  @step.logState
  askBenefits(d: IStepData<State>): IStepResult {
    const benefits = this.getBenefits(d.state)
    if (!benefits?.length) {
      this.logBreadcrumb("BENEFITS NOT FOUND", d.state, { benefits })
      this.logMessage("BENEFITS NOT FOUND")
      return { nextStep: this.askEmploymentSupport }
    }

    return {
      body: "Are you receiving any of the following benefits?",
      prompt: {
        id: this.getPromptId("askBenefits"),
        type: "inlinePicker",
        choices: [
          { body: "None", value: "None", backgroundColor: "#EC9CC8", selectIndividually: true },
          ...benefits.filter(m => !["None", "none"].includes(m)).map(m => ({ body: m, value: m }))
        ],
        dataPointsName: "askBenefits"
      },
      nextStep: this.handleBenefits
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, InsightEmploymentBenefits>, script: SelfReferralInsightScript) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (d.response !== "None") {
        d.state.benefits = d.response
        script.referralStore.setCustomField<State>("benefits", d.response)
      }
    }
  )
  handleBenefits(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askReceivingStatutorySickPay }
  }

  @step.logState
  askReceivingStatutorySickPay(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently receiving statutory sick pay?",
      prompt: {
        id: this.getPromptId("askReceivingStatutorySickPay"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleStatutorySickPay
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.receivingStatutorySickPay = d.response
    script.referralStore.setCustomField<State>("receivingStatutorySickPay", d.response)
  })
  handleStatutorySickPay(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEmploymentSupport }
  }

  @step.logState
  askEmploymentSupport(d: IStepData<State>): IStepResult {
    // In case there's an Undo, we need to reset this 👇
    d.state.employmentAdvisorAccepted = undefined
    const employmentSupports = this.getEmploymentSupports(d.state)
    if (!employmentSupports?.length) {
      this.logBreadcrumb("EMPLOYMENT SUPPORTS NOT FOUND", d.state, { employmentSupports })
      this.logMessage("EMPLOYMENT SUPPORTS NOT FOUND")
      return { nextStep: this.askMoneyWorries }
    }

    return {
      body: "Would you like support with any employment related difficulties?",
      prompt: {
        id: this.getPromptId("askEmploymentSupport"),
        type: "inlinePicker",
        choices: employmentSupports.map(g => ({ body: g, value: g })),
        dataPointsName: "askEmploymentSupport"
      },
      nextStep: this.handleEmploymentSupport
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, employmentSupportsType>, script: SelfReferralInsightScript) => {
      d.state.employmentSupport = d.response
      script.referralStore.setCustomField<State>("employmentSupport", d.response)
    }
  )
  handleEmploymentSupport(d: IStepData<State, employmentSupportsType>): IStepResult {
    const iaptID = d.state.iapt?.id
    const askPracticalEmploymentIAPTIDs = [
      IAPTIDs.INSIGHT_NOTTS,
      IAPTIDs.INSIGHT_DERBY,
      IAPTIDs.INSIGHT_MEDWAY
    ]
    const askPracticalEmploymentQuestion = askPracticalEmploymentIAPTIDs.includes(iaptID as IAPTIDs)
    if (d.response === "No employment support needed" || !askPracticalEmploymentQuestion) {
      // If askPracticalEmploymentSupport is not asked the value should always be set to undefined
      d.state.employmentAdvisorAccepted = undefined
      return { nextStep: this.askMoneyWorries }
    }
    // 👇 should never be the case to need to check if we need to ask the practical employment question - just a fail safe
    return {
      nextStep: askPracticalEmploymentQuestion
        ? this.askPracticalEmploymentSupport
        : this.askMoneyWorries
    }
  }

  @step.logState
  askPracticalEmploymentSupport(d: IStepData<State>): IStepResult {
    return {
      body: "We have practical employment support available from our Employment Advisory Service. Would you like to be contacted by an Employment Advisor?",
      prompt: {
        id: this.getPromptId("askPracticalEmploymentSupport"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPracticalEmploymentSupport"
      },
      nextStep: this.handlePracticalEmploymentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.employmentAdvisorAccepted = d.response
    script.referralStore.setCustomField<State>("employmentAdvisorAccepted", d.response)
  })
  handlePracticalEmploymentSupport(d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askMoneyWorries }
  }

  @step.logState
  askMoneyWorries(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have money worries?",
      prompt: {
        id: this.getPromptId("askMoneyWorries"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleMoneyWorries
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.moneyWorries = d.response
    script.referralStore.setCustomField<State>("moneyWorries", d.response)
  })
  handleMoneyWorries(d: IStepData<State, string>): IStepResult {
    if (d.state?.iapt?.id === IAPTIDs.INSIGHT_WIRRAL) {
      return { nextStep: this.askInvolvedInDisaster }
    }
    return { nextStep: this.goToIAPTAccommodation }
  }

  @step.logState
  goToIAPTAccommodation(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const IAPTAccommodationDialogue = dialoguesRegistry.get(DialogueIDs.IAPTAccommodation)
    return {
      nextDialogue: new IAPTAccommodationDialogue({ ...d.state }),
      nextStep: this.askAlcohol
    }
  }

  @step.logState
  askInvolvedInDisaster(d: IStepData<State>): IStepResult {
    const disasters = this.getDisasters(d.state)
    if (!disasters?.length) {
      this.logBreadcrumb("DISASTERS NOT FOUND", d.state, { disasters })
      this.logMessage("DISASTERS NOT FOUND")
      return { nextStep: this.goToIAPTAccommodation }
    }

    return {
      body: "Have you been directly involved in any of these incidents?",
      prompt: {
        id: this.getPromptId("askInvolvedInDisaster"),
        type: "inlinePickerMultiSelect",
        choices: [
          { body: "None", value: "none", backgroundColor: "#EC9CC8", selectIndividually: true },
          ...disasters.filter(m => m !== "None").map(m => ({ body: m, value: m }))
        ]
      },
      nextStep: this.handleInvolvedInDisaster
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralInsightScript) => {
    d.state.involvedInDisaster = d.response
    script.referralStore.setCustomField<State>("involvedInDisaster", d.response)
  })
  handleInvolvedInDisaster(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToIAPTAccommodation }
  }

  @step.logState
  askReceivedPreviousIaptTreatment(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you previously received treatment from an IAPT service?",
      prompt: {
        id: this.getPromptId("askReceivedPreviousIaptTreatment"),
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askReceivedPreviousIaptTreatment"
      },
      nextStep: this.handleReceivedPreviousIaptTreatment
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.receivedPreviousIaptTreatment = d.response
    script.referralStore.setCustomField<State>("receivedPreviousIaptTreatment", d.response)
  })
  handleReceivedPreviousIaptTreatment(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      return { nextStep: this.askNumberOfPreviousIaptTreatments }
    }
    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askNumberOfPreviousIaptTreatments(d: IStepData<State>): IStepResult {
    const previousIaptTreatments = this.getPreviousIaptTreatments(d.state)
    if (!previousIaptTreatments?.length) {
      this.logBreadcrumb("PREVIOUS IAPT TREATMENTS NOT FOUND", d.state, { previousIaptTreatments })
      this.logMessage("PREVIOUS IAPT TREATMENTS NOT FOUND")
      return { nextStep: this.askAlcohol }
    }

    return {
      body: "How many times have you accessed an IAPT service in the past?",
      prompt: {
        id: this.getPromptId("askNumberOfPreviousIaptTreatments"),
        type: "inlinePicker",
        choices: previousIaptTreatments.filter(m => m !== "None").map(m => ({ body: m, value: m }))
      },
      nextStep: this.handleNumberOfPreviousIaptTreatments
    }
  }

  @step.logState
  @step.handleResponse(
    (
      d: IStepData<State, "Never" | "Once" | "Twice" | "Three or more times">,
      script: SelfReferralInsightScript
    ) => {
      d.state.numberOfPreviousIaptTreatments = d.response
      script.referralStore.setCustomField<State>("numberOfPreviousIaptTreatments", d.response)
    }
  )
  handleNumberOfPreviousIaptTreatments(d: IStepData<State, string>): IStepResult {
    this.updateReferralType(d.state)
    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askKeyWorker(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you work for the NHS or the care system?",
      prompt: {
        id: this.getPromptId("askKeyWorker"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleAskKeyWorker
    }
  }

  @step.logState
  askGender(d: IStepData<State>): IStepResult {
    const genders = this.getGenders(d.state)
    if (!genders?.length) {
      this.logBreadcrumb("GENDERS NOT FOUND", d.state, { genders })
      this.logMessage("GENDERS NOT FOUND")
      return { nextStep: this.askPerinatalStatus }
    }
    return {
      body: "Which gender do you identify as?",
      prompt: {
        id: this.getPromptId("askGender"),
        type: "inlinePicker",
        choices: genders.map(g => ({ body: g, value: g })),
        textPrompt: {
          forceValue: false
        },
        isUndoAble: true,
        dataPointsName: "askGender"
      },
      nextStep: this.handleGender
    }
  }

  // We need to handle it here, because in the base script it checks the answer if it
  // matches the options, and in this case, we have a custom (free text) response
  @step.logStateAndResponse
  async handleGender(d: IStepData<State, string>): Promise<IStepResult> {
    const genders = this.getGenders(d.state)
    const isListed = genders?.find(s => s === d.response)
    if (isListed) {
      d.state.genderOther = undefined // in case of an undo
      d.state.gender = d.response
    } else {
      d.state.gender = undefined // in case of an undo
      d.state.genderOther = d.response
    }
    this.setPeople({ gender: d.response })
    if (d.state.gender?.includes("Male")) {
      d.state.title = "Mr"
    } else if (d.state.gender?.includes("Female")) {
      d.state.title = "Ms"
    } else {
      d.state.title = "Mx"
    }
    return { nextStep: this.askSameGenderAsBirth }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.keyWorker = d.response
    script.referralStore.setCustomField<State>("keyWorker", d.response)
  })
  handleAskKeyWorker(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askGPConsent1 }
  }

  @step.logState
  askGPConsent1(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "As part of our process in Insight IAPT, we share assessment outcomes and treatment summaries with your GP",
        "Are you happy for us to share these summaries with your GP?"
      ],
      prompt: {
        id: this.getPromptId("askGPConsent1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleGPConsent1
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.gpConsent1 = d.response
    script.referralStore.setCustomField<State>("gpConsent1", d.response)
  })
  handleGPConsent1(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askGPConsent2 }
    }
    return {
      body: "You selected that you don’t want the clinical summaries shared with you GP, please be aware that in the event of an emergency or issues with risk or safeguarding we would contact your GP for their support",
      nextStep: this.askGPConsent2
    }
  }

  @step.logState
  askGPConsent2(_d: IStepData<State>): IStepResult {
    return {
      body: "Would you like to receive a copy of the summaries that are written after your assessment and after treatment?",
      prompt: {
        id: this.getPromptId("askGPConsent2"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleGPConsent2
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralInsightScript) => {
    d.state.gpConsent2 = d.response
    script.referralStore.setCustomField<State>("gpConsent2", d.response)
  })
  handleGPConsent2(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.doReferralSubmission }
  }

  async onRiskReferralFinished(_state: State): Promise<void> {
    return
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [`Good news, ${name}`, `Your referral to ${iaptName} is nearly ready to go ✉️`],
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [
          { body: "Great", value: false },
          { body: "Good to know", value: false }
        ]
      },
      nextStep: this.end
    }
  }

  @step.logState
  askDisability(d: IStepData<State>): IStepResult {
    const disabilities = this.getDisabilities(d.state)
    if (!disabilities?.length) {
      this.logBreadcrumb("DISABILITIES NOT FOUND", d.state, { disabilities })
      this.logMessage("DISABILITIES NOT FOUND")
      return { nextStep: this.askExArmedForces }
    }

    return {
      body: "Okay, please specify",
      prompt: {
        id: this.getPromptId("askDisability"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: disabilities.filter(m => m !== "None").map(m => ({ body: m, value: m }))
      },
      nextStep: this.handleDisabilities
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string[]>, script: SelfReferralInsightScript) => {
    d.state.disabilities = d.response
    script.referralStore.setCustomField<State>("disabilities", d.response)
  })
  handleDisabilities(d: IStepData<State, string[]>): IStepResult {
    this.setPeople({ disabilities: d.response })
    return { nextStep: this.askExArmedForces }
  }

  /** Generic Handlers */

  getPerinatals(state: State): string[] {
    const statuses = ["I am pregnant", "New parent"]
    // TODO: 👇 this needs to include all kent services when we figure them out
    const isKent = state.iapt?.id === "2917833"
    statuses.push(isKent ? "I have a child under 5" : "I have a child under 2")
    return statuses
  }

  getLanguages(state: State): string[] {
    return state.iapt?.referralForm?.languages ?? []
  }

  getMaritalStatuses(state: State): string[] {
    return state.iapt?.referralForm?.maritalStatuses ?? []
  }

  getEmploymentStatuses(state: State): InsightEmploymentStatus[] {
    return state.iapt?.referralForm?.employmentStatuses ?? ([] as any[])
  }

  getBenefits(state: State): InsightEmploymentBenefits[] {
    return state.iapt?.referralForm?.benefits ?? ([] as any[])
  }

  getEmploymentSupports(state: State): InsightEmploymentSupport[] {
    return state.iapt?.referralForm?.employmentSupports ?? ([] as any[])
  }

  getDisasters(state: State): string[] {
    return state.iapt?.referralForm?.disasters ?? []
  }

  getPreviousIaptTreatments(state: State): string[] {
    return state.iapt?.referralForm?.previousIaptTreatments ?? []
  }

  async onHandleEmail(state: State): Promise<IStepResult<State>> {
    if (state.email) {
      return { nextStep: this.askPermissionToEmail }
    }
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  async onHandleGenderSameAsBirth(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askPerinatalStatus }
  }

  async onHandlePerinatal(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askChildUnderEighteen }
  }

  async onHandleReligion(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askLongTermMedicalCondition }
  }

  async onHandleLongTermMedicalCondition(state: State): Promise<IStepResult<State>> {
    if (state.longTermMedicalCondition?.includes("No")) {
      return { nextStep: this.askHaveYouEverCaughtCovid }
    }
    return { nextStep: this.askDoesLTCAffectMood }
  }

  async onHandleDoesLTCAffectMood(state: State): Promise<IStepResult<State>> {
    if (state.ltcAffectsMood) return { nextStep: this.askHowMuchLTCAffectsMood }
    return { nextStep: this.askHaveYouEverCaughtCovid }
  }

  async onHandleHowMuchLTCAffectsMood(state: State): Promise<IStepResult<State>> {
    if (state.ltcMoodImpact === "little") return { nextStep: this.askHaveYouEverCaughtCovid }
    return { nextStep: this.askHowWellYouManageYourLTC }
  }

  async onHandleHowWellYouManageYourLTC(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askHaveYouEverCaughtCovid }
  }

  async onHandleHaveYouEverCaughtCovid(state: State): Promise<IStepResult<State>> {
    if (state.covidStatus === "NO") {
      return { nextStep: this.askEmploymentStatus }
    }
    return { nextStep: this.askWhenDidYouHaveCovid }
  }

  async onHandleWhenDidYouHaveCovid(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askHasLongCovid }
  }

  async onHandleMedicationWithinDoseRange(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askReceivedPreviousIaptTreatment }
  }

  async onHandleSubstancesInfo(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askReceivedPreviousIaptTreatment }
  }

  async onHandleSubstances(state: State): Promise<IStepResult<State>> {
    if (state.substances) {
      return { nextStep: this.askSubstancesOrigin }
    }
    return { nextStep: this.askReceivedPreviousIaptTreatment }
  }

  async onHandleCurrentSupport(state: State): Promise<IStepResult | void> {
    return { nextStep: this.askKeyWorker }
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadInsight> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      nameFirst: state.spineName?.firstName ?? this.getName(state),
      nameLast: state.spineName?.lastName ?? this.getLastName(state),
      nhsNumber: state.nhsNumber,
      whereHeardAboutUs: state.whereDidYouHearAboutService || undefined,
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      title: state.title,
      alcohol: state.alcohol,
      alcoholFrequency: this.getAlcoholFrequency(state),
      alcoholQuantity: this.getAlcoholQuantity(state),
      substances: state.substances,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      medication:
        state.substancesAreMedications && state.medicationInfo //
          ? [state.medicationInfo]
          : undefined,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      covidStatus: state.covidStatus,
      covidDate: state.covidDate,
      longCovid: !!this.referralStore.getCustomField<State>("hasLongCovid"),
      longCovidReferral: !!this.referralStore.getCustomField<State>("isReferralForLongCovid"),
      employmentStatus: state.employmentStatus,
      benefits: state.benefits,
      employmentSupport: state.employmentSupport,
      employmentAdvisorAccepted: state.employmentAdvisorAccepted,
      // prettier-ignore
      receivingStatutorySickPay: !!this.referralStore.getCustomField<State>("receivingStatutorySickPay"),
      moneyWorries: !!this.referralStore.getCustomField<State>("moneyWorries"),
      asylumSeeker: !!this.referralStore.getCustomField<State>("asylumSeeker"),
      disasterInvolvement: this.referralStore.getCustomField<State>(
        "involvedInDisaster"
      ) as ReferralPayloadInsight["disasterInvolvement"],
      language: this.getLanguage(state),
      interpreter:
        state.spineInterpreterRequired ??
        !!this.referralStore.getCustomField<State>("requiresInterpreter"),
      receivedPreviousIaptTreatment: !!this.referralStore.getCustomField<State>(
        "receivedPreviousIaptTreatment"
      ),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // prettier-ignore
      numberOfPreviousIaptTreatments: this.referralStore.getCustomField<State>("numberOfPreviousIaptTreatments"),
      civilStatus: this.getCivilStatus(state),
      ccgId: state.odsGP?.ccgs[0],
      surgeryId: state?.odsGP?.id,
      currentSupport: state.hasCurrentSupport,
      sexuality: this.getSexuality(state),
      sexualityOther: state.sexualityOther,
      gender: this.getGender(state),
      genderOther: state.genderOther,
      genderSameAsBirthAssigned: this.getGenderSameAsBirthAssigned(state),
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForced(state),
      disability: this.getDisability(state),
      religion: this.getReligion(state),
      descriptionMainDiff: this.referralStore.getCustomField<State>("mainIssue") as string,
      childrenUnder2: state.perinatal?.includes("I have a child under 2"),
      childrenUnder5: state.perinatal?.includes("I have a child under 5"),
      childrenUnder18: !!this.referralStore.getCustomField<State>("childUnderEighteen"),
      pregnant: !!state.perinatal?.includes("I am pregnant"),
      newParent: !!state.perinatal?.includes("New parent"),
      keyWorker: !!state.keyWorker,
      gpConsent1: !!state.gpConsent1,
      gpConsent2: !!state.gpConsent2,
      questionnaires: this.getQuestionnairesPayload(state)
    }
  }

  getReligion(state: State): RELIGION_INSIGHT {
    return religions[state.religion!] ?? "NOT_ANSWERED"
  }

  getDisability(state: State): DISABILITY_INSIGHT[] {
    if (state.disabilityStatus === false) return ["NONE"]
    if (!state.disabilityStatus || !state.disabilities?.length) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }
    return state.disabilities
      ? state.disabilities?.map(disability => disabilities[disability]).filter(Boolean)
      : ["NOT_ANSWERED"]
  }

  getArmedForced(state: State): ARMED_FORCES_INSIGHT {
    return exArmedForces[state.isExArmedForces!] ?? "NOT_ANSWERED"
  }

  getEthnicity(state: State): ETHNICITY_INSIGHT {
    return ethnicities[state.ethnicity!] ?? "NOT_ANSWERED"
  }

  getGender(state: State): GENDER_INSIGHT {
    return genders[state.gender!] ?? "NOT_LISTED"
  }

  getGenderSameAsBirthAssigned(state: State): GenderBirthAssigned {
    const genderSameAsBirthAssigned = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirthAssigned ?? "UNKNOWN"
  }

  getSexuality(state: State): SEXUALITY_INSIGHT | undefined {
    // NOT_LISTED when the response if a free text (custom input)
    return sexualities[state.sexuality!] || "NOT_LISTED"
  }

  getCivilStatus(state: State): CIVIL_STATUS_INSIGHT | undefined {
    return maritalStatuses[state.maritalStatus!]
  }

  getLanguage(state: State): LANGUAGE_INSIGHT | undefined {
    const spineLanguageKey = `${state.spineLanguage ?? ""}`.toLowerCase()
    const primaryLanguageKey = `${state.primaryLanguage ?? ""}`.toLowerCase()
    const languagesLowerCase = toLowerCaseProperties(languages)
    return languagesLowerCase[spineLanguageKey] ?? languagesLowerCase[primaryLanguageKey]
  }

  getLTC(state: State): LTC_INSIGHT[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getAlcoholFrequency(state: State): typeof AF[keyof typeof AF] | undefined {
    const map = {
      [ALCOHOL_FREQUENCIES.MONTHLY]: AF.MONTHLY,
      [ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4]: AF.MONTHLY_2_TO_4,
      [ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3]: AF.WEEKLY_2_TO_3,
      [ALCOHOL_FREQUENCIES.WEEKLY_4]: AF.WEEKLY_4
    }
    return map[state.alcoholFrequency!]
  }

  getAlcoholQuantity(state: State): typeof AQ[keyof typeof AQ] | undefined {
    const map = {
      [ALCOHOL_QUANTITIES._0_2]: AQ._0_2,
      [ALCOHOL_QUANTITIES._3_4]: AQ._3_4,
      [ALCOHOL_QUANTITIES._5_6]: AQ._5_6,
      [ALCOHOL_QUANTITIES._7_9]: AQ._7_9,
      [ALCOHOL_QUANTITIES._10_PLUS]: AQ._10_PLUS
    }
    return map[state.alcoholQuantity!]
  }
}

export default class SelfReferralInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralInsight
  readonly name: string = "SelfReferralInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralInsightDialogue.id, new SelfReferralInsightScript(), state, snapshot)
  }
}
