import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends BaseScriptState {
  assessmentPreference?: "telephone" | "digital"
}

export class AssessmentPitchVitalityScript extends BaseScript<State> {
  readonly name: string = "AssessmentPitchVitalityScript"

  /** Script Steps */
  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.sayIntro }
  }

  @step.logState
  sayIntro(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    return {
      body: [
        "I've been designed by therapists to give you a digital mental health assessment",
        "I'll ask you a few questions to measure symptoms of common mental illness",
        "I'll then share the results with you and help you find the best support options available"
      ],
      prompt: {
        id: this.getPromptId("sayIntro"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sounds good 👍", value: true },
          { body: "Okay", value: true },
          { body: "I'd rather do this on a phone call ", value: false }
        ]
      },
      nextStep: this.handleIntro
    }
  }

  @step.logState
  async handleIntro(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.assessmentPreference = d.response ? "digital" : "telephone"
    return {
      body: d.response ? "Brilliant!" : "No problem",
      nextStep: this.end
    }
  }
}

/** istanbul ignore next */
export default class AssessmentPitchVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchVitality
  readonly name: string = "AssessmentPitchVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchVitalityDialogue.id, new AssessmentPitchVitalityScript(), state, snapshot)
  }
}
