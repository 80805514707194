import React from "react"
import classNames from "classnames"
import { Typography } from "@material-ui/core"
import Linkify, { MatchInfo, Props as LinkifyProps } from "react-linkify"
import "./BotBubble.scss"
import { IBubbleProps } from "./IBubbleProps"

type Props = IBubbleProps

export default function BotBubble(props: Props): JSX.Element {
  const { isFirst, isLast, message, children } = props
  const text = typeof children === "string" ? children : message
  const contentCSS = classNames("lb-bot-bubble-content", {
    first: isFirst && !isLast,
    last: isLast
  })
  return (
    <div className="lb-bot-bubble-container">
      <div className={contentCSS}>
        {!!text && (
          <Typography className="lb-bot-bubble-text">
            <BoldDecorator>
              <URLDecorator>
                {/* This acts as a fallback in case a link is added but not in the proper format */}
                <Linkify componentDecorator={URLComponentDecorator}>{text}</Linkify>
              </URLDecorator>
            </BoldDecorator>
          </Typography>
        )}
        {children}
      </div>
    </div>
  )
}

const URLMatchDecorator = (text: string): MatchInfo[] => {
  // noinspection RegExpUnnecessaryNonCapturingGroup
  return [...text.matchAll(/(?:\[)(.+)?(?:])(?:\()(.+)?(?:\))/gi)] //
    .map(i => ({
      text: i[1] || i[2],
      url: i[2],
      index: i.index,
      lastIndex: Number(i.index) + i[0].length
    })) as MatchInfo[]
}

const URLComponentDecorator = (href, text, key) => (
  <a
    className="lb-bot-bubble-text-link"
    href={href}
    key={key}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
)

function URLDecorator(props: LinkifyProps) {
  const { children, ...restProps } = props
  return (
    <Linkify //
      matchDecorator={URLMatchDecorator}
      componentDecorator={URLComponentDecorator}
      {...restProps}
    >
      {children}
    </Linkify>
  )
}

const textToRegexableText = (text?: string) => text?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") ?? text

const BoldMatchDecorator = (text: string): MatchInfo[] | null => {
  try {
    const hasBold = text?.indexOf("<b>") > -1
    const boldTexts = [] as string[]
    let fullText = text
    if (hasBold) {
      const div = document.createElement("div")
      div.innerHTML = text
      const bTags = [...div.getElementsByTagName("b")]
      bTags.forEach(b => {
        const txt = b.textContent || b.innerText
        if (txt) {
          boldTexts.push(txt)
          div.replaceChild(document.createTextNode(txt), b)
          return
        }
        div.removeChild(b)
      })
      fullText = div.textContent || div.innerText
    }

    if (boldTexts.length) {
      return fullText
        .split(new RegExp(`(${boldTexts.map(textToRegexableText).join("|")})`)) //
        .map(t => {
          const index = text.indexOf(t)
          const isBold = boldTexts.includes(t)
          return {
            text: t,
            url: isBold ? t : "",
            index
          }
        }) as MatchInfo[]
    }
  } catch (e) {
    return [
      {
        text: text.replace(/<b>/g, "").replace(/<\/b>/g, ""),
        index: 0
      }
    ] as MatchInfo[]
  }
  return null
}

const BoldComponentDecorator = (match, text, key) => {
  if (match)
    return (
      <b className="limbic-bot-bubble-text-bold" key={key}>
        {match}
      </b>
    )
  return <React.Fragment key={key}>{text}</React.Fragment>
}

function BoldDecorator(props: LinkifyProps) {
  const { children, ...restProps } = props
  return (
    <Linkify //
      matchDecorator={BoldMatchDecorator}
      componentDecorator={BoldComponentDecorator}
      {...restProps}
    >
      {children}
    </Linkify>
  )
}
