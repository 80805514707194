import Persistable from "../../models/Persistable"
import validateClinicalPath from "../../utils/validateClinicalPath"
import getClinicalPath from "../../utils/getClinicalPath"
import { RiskLevel } from "../../models/Constants"
import type { ClinicalFlags, ProblemCategories } from "../../models/Constants"
import type {
  IClinicalPath,
  ITreatmentOption,
  IUserClinicalPathDescriptor
} from "../../models/IClinicalPath"

export default class ClinicalStore extends Persistable {
  readonly name: string = "ClinicalStore"
  defaultPaths: IClinicalPath[]
  customPaths: IClinicalPath[]
  complexComorbidPath?: IClinicalPath
  undeterminedPath?: IClinicalPath
  primaryProblems: ProblemCategories[]
  secondaryProblems: ProblemCategories[]
  flags: ClinicalFlags[]

  riskLevel: RiskLevel
  riskLevelReason?: string
  isCrisis: boolean
  triggerWords?: string[]
  triggerWordsPhrase?: string
  assessmentFinished: boolean
  clinicalPath?: IClinicalPath
  treatmentOptions: ITreatmentOption[]

  constructor() {
    super()
    this.defaultPaths = this.hydrate("defaultPaths") ?? []
    this.customPaths = this.hydrate("customPaths") ?? []
    this.complexComorbidPath = this.hydrate("complexComorbidPath")
    this.undeterminedPath = this.hydrate("undeterminedPath")
    this.primaryProblems = this.hydrate("primaryProblems") ?? []
    this.secondaryProblems = this.hydrate("secondaryProblems") ?? []
    this.flags = this.hydrate("flags") ?? []
    this.riskLevel = this.hydrate("riskLevel") ?? RiskLevel.Low
    this.riskLevelReason = this.hydrate("riskLevelReason")
    this.isCrisis = this.hydrate("isCrisis") ?? false
    this.triggerWords = this.hydrate("triggerWords")
    this.triggerWordsPhrase = this.hydrate("triggerWordsPhrase")
    this.assessmentFinished = this.hydrate("assessmentFinished") ?? false
    this.clinicalPath = this.hydrate("clinicalPath")
    this.treatmentOptions = this.hydrate("treatmentOptions") ?? []
  }

  setDefaultPaths(paths: IClinicalPath[]): void {
    this.defaultPaths = paths
    this.persist("defaultPaths", paths)
  }

  setCustomPaths(paths: IClinicalPath[]): void {
    this.customPaths = paths
    this.persist("customPaths", paths)
  }

  setComplexComorbidPath(path: IClinicalPath): void {
    this.complexComorbidPath = path
    this.persist("complexComorbidPath", path)
  }

  setUndeterminedPath(path: IClinicalPath): void {
    this.undeterminedPath = path
    this.persist("undeterminedPath", path)
  }

  setPrimaries(problems: ProblemCategories[]): void {
    this.primaryProblems = [...new Set(problems)] // ensure no duplicates
    this.persist("primaryProblems", this.primaryProblems)
  }

  setSecondaries(problems: ProblemCategories[]): void {
    this.secondaryProblems = [...new Set(problems)] // ensure no duplicates
    this.persist("secondaryProblems", this.secondaryProblems)
  }

  setFlags(flags: ClinicalFlags[]): void {
    this.flags = [...new Set(flags)] // ensure no duplicates
    this.persist("flags", this.flags)
  }

  setRiskLevel(riskLevel: RiskLevel): void {
    this.riskLevel = riskLevel
    this.persist("riskLevel", riskLevel)
  }

  setRiskLevelReason(riskLevelReason?: string): void {
    this.riskLevelReason = riskLevelReason
    this.persist("riskLevelReason", riskLevelReason)
  }

  setIsCrisis(isCrisis: boolean): void {
    this.isCrisis = isCrisis
    this.persist("isCrisis", isCrisis)
  }

  setTriggerWords(triggerWords?: string[]): void {
    this.triggerWords = triggerWords
    this.persist("triggerWords", triggerWords)
  }

  setTriggerWordsPhrase(triggerWordsPhrase?: string): void {
    this.triggerWordsPhrase = triggerWordsPhrase
    this.persist("triggerWordsPhrase", triggerWordsPhrase)
  }

  setAssessmentFinished(assessmentFinished: boolean): void {
    this.assessmentFinished = assessmentFinished
    this.persist("assessmentFinished", assessmentFinished)
  }

  setClinicalPath(path: IClinicalPath | undefined): void {
    this.clinicalPath = path
    this.persist("clinicalPath", path)
  }

  setTreatmentOptions(treatments: ITreatmentOption[]): void {
    const newTreatments = [...treatments].sort((a, b) => a.order - b.order)
    this.treatmentOptions = newTreatments
    this.persist("treatmentOptions", newTreatments)
  }

  declineTreatment(treatment: ITreatmentOption): void {
    const newList = this.treatmentOptions.map(t => {
      if (t.name !== treatment.name) return t
      return { ...t, declined: true, accepted: false }
    })
    this.setTreatmentOptions(newList)
  }

  acceptTreatment(treatment: ITreatmentOption): void {
    const newList = this.treatmentOptions.map(t => {
      if (t.name !== treatment.name) return { ...t, accepted: false }
      return { ...t, declined: false, accepted: true }
    })
    this.setTreatmentOptions(newList)
  }

  addPrimaryProblem(problem: ProblemCategories): void {
    this.setPrimaries([...this.primaryProblems, problem])
  }

  addSecondaryProblem(problem: ProblemCategories): void {
    this.setSecondaries([...this.secondaryProblems, problem])
  }

  addFlag(flag: ClinicalFlags): void {
    this.setFlags([...this.flags, flag])
  }

  generateClinicalPath(): void {
    const input = this.pathDescriptor
    let path = getClinicalPath(this.defaultPaths, this.customPaths, input)
    if (!path && input.primaryProblems.length > 1) {
      path = this.complexComorbidPath
    }
    if (!path) path = this.undeterminedPath
    this.setClinicalPath(path)
    this.setTreatmentOptions(path?.treatments || [])
  }

  getIsValidPath(): boolean {
    return validateClinicalPath(this.pathDescriptor)
  }

  getAcceptedTreatment(): ITreatmentOption | undefined {
    return this.treatmentOptions.find(t => t.accepted)
  }

  getDeclinedTreatments(): ITreatmentOption[] {
    return this.treatmentOptions.filter(t => t.declined)
  }

  /** Getters / Setters */

  get isHighRisk(): boolean {
    return this.riskLevel === RiskLevel.High
  }

  get isModerateRisk(): boolean {
    return this.riskLevel === RiskLevel.Moderate
  }

  get isRisk(): boolean {
    return this.isHighRisk || this.isModerateRisk
  }

  get pathDescriptor(): IUserClinicalPathDescriptor {
    return {
      primaryProblems: this.primaryProblems,
      secondaryProblems: this.secondaryProblems,
      flags: this.flags
    }
  }
}
