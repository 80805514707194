import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PermissionsScript } from "./PermissionsScript"
import type { PermissionsScriptState } from "./PermissionsScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = PermissionsScriptState
export type PermissionsCCGScriptState = State

export class PermissionsCCGScript extends PermissionsScript {
  readonly name: string = "PermissionsCCGScript"

  /** Script Steps */

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "I also know mental health can be a sensitive subject",
        `So ${name}, I just want to reassure you that all your information is secured to the highest standards`
      ],
      nextStep: this.showTOSLinks
    }
  }

  @step.logState
  handleIUnderstand(_d: IStepData<State>): IStepResult {
    return {
      body: "Great! I think we're ready to get started",
      nextStep: this.end
    }
  }
}

export default class PermissionsCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsCCG
  readonly name: string = "PermissionsCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsCCGDialogue.id, new PermissionsCCGScript(), state, snapshot)
  }
}
