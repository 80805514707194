import AssessmentScript, { AssessmentScriptState } from "./AssessmentScript"

type State = AssessmentScriptState
export type AssessmentIAPTScriptState = State

export default abstract class AssessmentIAPTScript extends AssessmentScript {
  /** Generic Handlers */

  handlePHQ9Responses(state: State): void {
    this.sendPHQ9(state)
  }

  handleGAD7Responses(state: State): void {
    this.sendGAD7(state)
  }

  handlePhobiaScaleResponses(state: State): void {
    this.sendPhobiaScale(state)
  }

  handleIAPTEmploymentStatusResponses(state: State): void {
    this.sendIAPTEmploymentStatus(state)
  }

  handleWSASResponses(state: State): void {
    this.sendWSAS(state)
  }

  handleIAPTMedicationResponses(state: State): void {
    this.sendIAPTMedication(state)
  }
}
