import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { DialogueIDs } from "../../DialogueIDs"

type State = EligibilityCheckWithPDSScriptState

export type EligibilityCheckVHGScriptState = State

export class EligibilityCheckVHGScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckVHGScript"

  /** Script Steps */

  // The following is extended because:
  // 1 - There's a conditional transition to the old way of making a referral
  // if the the user tried 2 times (askSelectGPFromUserPostcode)
  // 2 - The new transition to askSelectGPFromUserPostcode does not seem nice without
  // adding an additional copy: "Now, let me see if I can find your GP in an other way"
  @step.logState
  sayICouldntFindYouInPDS(d: IStepData<State>): IStepResult {
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers ?? ""
    if (d.state.spineSearchCount === 2) d.state.allowSkipPDS = true

    const body =
      d.state.spineSearchCount! >= 3
        ? [
            "It seems like you are having trouble entering details that match with the NHS database",
            `If you'd rather call into the service and talk to a human, you can do so here:\n${organisationPhoneNumbers}`,
            "Now, let me see if I can find your GP in an other way"
          ]
        : [
            "Hmm, it looks like I wasn't able to find you in the NHS Database...",
            "Most of the time, this is because you're registered with your GP under your Christian name or old address still"
          ]
    return {
      body,
      nextStep:
        d.state.spineSearchCount! >= 3 ? this.askSelectGPFromUserPostcode : this.askConfirmDetails
    }
  }

  /** Generic Handlers */
}

export default class EligibilityCheckVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckVHG
  readonly name: string = "EligibilityCheckVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckVHGDialogue.id, new EligibilityCheckVHGScript(), state, snapshot)
  }
}
