import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import AssessmentIAPTScript from "./AssessmentIAPTScript"
import type { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentIAPTScriptState

export class AssessmentInsightScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentInsightScript"

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

export default class AssessmentInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentInsight
  readonly name: string = "AssessmentInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentInsightDialogue.id, new AssessmentInsightScript(), state, snapshot)
  }
}
