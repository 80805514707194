import BaseScript, { BaseScriptState } from "../BaseScript"
import Dialogue, { IDialogueSnapshot } from "../../backend/chatbot/Dialogue"
import { step } from "../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../DialogueIDs"
import type { IStepData, IStepResult } from "../../backend/chatbot/models/IStep"

type State = BaseScriptState

export class MainFlowScript extends BaseScript<State> {
  readonly name: string = "MainFlowScript"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult<State> {
    this.rootStore.discussionStore.setStarted(true)
    return { nextStep: this.goToCurrentStep }
  }

  @step.logState
  goToCurrentStep(d: IStepData<State>): IStepResult {
    const currentStep = this.rootStore.discussionStore.currentStep
    const DialogueClass = this.rootStore.discussionStore.getDialogueClass(currentStep)
    if (!DialogueClass) return { nextStep: this.goToNextStep }
    return {
      nextDialogue: new DialogueClass({ ...d.state }),
      nextStep: this.handleCurrentStep
    }
  }

  @step.logState
  handleCurrentStep(d: IStepData<State, undefined, BaseScriptState>): IStepResult {
    this.updateState(d.state, d.previousDialogue?.state)
    const undoBlocked = this.rootStore.applicationStore.undoBlocked
    const undoBlockIsTemp = this.rootStore.applicationStore.undoBlockIsTemp
    if (undoBlocked && undoBlockIsTemp) this.enableUndo(d.state)
    return { nextStep: this.goToNextStep }
  }

  @step.logState
  goToNextStep(_d: IStepData<State>): IStepResult {
    const stepsOrder = this.rootStore.discussionStore.stepsOrder
    const currentStepIndex = this.rootStore.discussionStore.currentStepIndex
    if (currentStepIndex >= stepsOrder.length - 1) {
      return { nextStep: this.end }
    }
    this.rootStore.discussionStore.setCurrentStepIndex(currentStepIndex + 1)
    return { nextStep: this.goToCurrentStep }
  }
}

export default class MainFlowDialogue extends Dialogue<State> {
  static id = DialogueIDs.MainFlow
  readonly name: string = "MainFlowDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(MainFlowDialogue.id, new MainFlowScript(), state, snapshot)
  }
}
