import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameIAPTScript } from "./GetNameIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { GetNameScriptState } from "./GetNameScript"
import type { VitalityTitles } from "../../../models/Constants"

interface State extends GetNameScriptState {
  preferredTitle?: VitalityTitles
}

export type GetNameVitalityScriptState = State

export class GetNameVitalityScript extends GetNameIAPTScript {
  readonly name: string = "GetNameVitalityScript"

  /** Script Steps */

  @step
  step1(d: IStepData<State>): IStepResult {
    const hasName = d.state.name && d.state.username
    return {
      nextStep: hasName ? this.askHowToBeAddressed : this.askFullName
    }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Nice to meet you ${name}`,
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: "Nice to meet you too" }]
      },
      nextStep: this.askHowToBeAddressed
    }
  }

  @step
  askHowToBeAddressed(_d: IStepData<State>): IStepResult {
    const hasTitle = !!this.referralStore.getCustomField("title")
    if (hasTitle) {
      return { nextStep: this.end }
    }
    const titles = this.getTitles()
    return {
      body: "How would you like to be addressed?",
      prompt: {
        id: this.getPromptId("askHowToBeAddressed"),
        trackResponse: true,
        type: "inlinePicker",
        choices: titles.map(g => ({ body: g, value: g })),
        dataPointsName: "askHowToBeAddressed"
      },
      nextStep: this.handleHowToBeAddressed
    }
  }

  @step.logStateAndResponse
  async handleHowToBeAddressed(d: IStepData<State, VitalityTitles>): Promise<IStepResult> {
    d.state.preferredTitle = d.response
    this.referralStore.setCustomField<State>("preferredTitle", d.response)
    this.setPeople({ preferredTitle: d.response })
    this.track(d.response)
    return { nextStep: this.end }
  }

  /** Generic Handlers */
  getTitles(): VitalityTitles[] {
    return ["Mr", "Ms", "Mrs", "Miss", "Mx"]
  }
}

export default class GetNameVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameVitality
  readonly name: string = "GetNameVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameVitalityDialogue.id, new GetNameVitalityScript(), state, snapshot)
  }
}
