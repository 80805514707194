import type { IStep, IStepData, StepDecorator, StepDescriptor } from "../../models/IStep"
import type Script from "../../Script"
import type { ScriptState } from "../../Script"
import CrisisDetector from "../../../../models/CrisisDetector"
import { Category } from "@limbic/crisis-detection"

interface CrisisDetectionProps<State extends ScriptState, S extends Script<State> = any> {
  disableDetectionIfWrong?: boolean
  getNextStep?: (script: S) => IStep<State>
  getInput?: (d: IStepData<State, any>) => string | undefined
  getShouldCheckForCrisis?: (d: IStepData<State, any>) => boolean
  ignoredCategories?: Category[]
}

function decorate<State extends ScriptState, S extends Script<State> = any>(
  step: IStep<State>,
  props: CrisisDetectionProps<State, S>
): IStep<State> {
  const disableDetectionIfWrong = props?.disableDetectionIfWrong ?? true
  const getNextStep = props?.getNextStep
  const getInput = props?.getInput
  const getShouldCheckForCrisis = props?.getShouldCheckForCrisis
  return async function (this: S, d: IStepData<State>) {
    let nextStep = d?.previousStepName ? this[d?.previousStepName] : this[step.stepName!]
    if (getNextStep) nextStep = getNextStep(this)
    if (!nextStep) nextStep = step
    if (d) {
      const shouldCheckForCrisis = getShouldCheckForCrisis?.(d) ?? true
      if (shouldCheckForCrisis) {
        const input = getInput ? getInput(d) : d?.response
        const triggerWords = await CrisisDetector.getInstance().detectCrisis(
          input,
          props.ignoredCategories
        )
        if (triggerWords?.length) {
          await this.onHandleTriggerWords?.(d.state, triggerWords, input)
          const CrisisDialogue = this.getCrisisDialogue?.(d.state)
          const nextDialogue = CrisisDialogue
            ? new CrisisDialogue({ ...(d.state || {}), disableDetectionIfWrong })
            : undefined
          return { nextDialogue, nextStep }
        }
      }
    }
    return step.call(this, d)
  }
}

// Dont get intimidated by this weird situation here.
// This is just function overloading where you explicitly
// describe the various signatures this function can be
// called with
// prettier-ignore
export function checkInputForCrisis<State extends ScriptState, S extends Script<State> = any>(t: S, key: string, desc: StepDescriptor)
export function checkInputForCrisis<State extends ScriptState, S extends Script<State> = any>(
  props: CrisisDetectionProps<State, S>
)
export function checkInputForCrisis<State extends ScriptState, S extends Script<State> = any>():
  | StepDecorator<State, S>
  | StepDescriptor {
  // eslint-disable-next-line prefer-rest-params
  const props: CrisisDetectionProps<State, S> = arguments.length === 1 ? arguments[0] : undefined
  function decorator(target: S, key: keyof S, desc: StepDescriptor): StepDescriptor {
    const org = desc.value
    desc.value = decorate(org!, props)
    return desc
  }
  if (arguments.length === 1) {
    return decorator
  }
  // eslint-disable-next-line prefer-rest-params
  return decorator(arguments[0], arguments[1], arguments[2])
}

checkInputForCrisis.decorate = decorate
