import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = BaseScriptState
export type AssessmentPitchCCGScriptState = State

export class AssessmentPitchCCGScript extends BaseScript<State> {
  readonly name: string = "AssessmentPitchCCGScript"

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return {
      body: "I've been designed by therapists to give you a free digital mental health check up",
      prompt: {
        id: this.getPromptId("step1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: false },
          { body: "Tell me more", value: true }
        ]
      },
      nextStep: this.handleStep1
    }
  }

  @step.logState
  handleStep1(d: IStepData<State, boolean>): IStepResult {
    const body = d.response ? "Certainly!" : undefined
    return { body, nextStep: this.sayPitch }
  }

  @step.logState
  sayPitch(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental illness",
        "I'll then share the results with you and help you find the best support options available",
        "Sound okay?"
      ],
      prompt: {
        id: this.getPromptId("sayPitch"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "Help me decide", value: false }
        ]
      },
      nextStep: this.handlePitch
    }
  }

  @step.logState
  handlePitch(d: IStepData<State, boolean>): IStepResult {
    if (d.response) return { nextStep: this.sayGladToHearIt }
    return { nextStep: this.explainMore }
  }

  @step.logState
  explainMore(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "Of course. Mental wellbeing is a complicated topic. There are no quick fixes",
        "But trust me when I say that this is the first step towards a solution",
        "You're at the beginning of an evidence-based pathway that has been shown to help people with a range of common mental health issues",
        `I think this is worth doing now that you're here, ${name}`
      ],
      prompt: {
        id: this.getPromptId("explainMore"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "Go on then" }]
      },
      nextStep: this.handleExplainMore
    }
  }

  @step.logState
  handleExplainMore(_d: IStepData<State>): IStepResult {
    return {
      body: "👍",
      nextStep: this.end
    }
  }

  @step.logState
  sayGladToHearIt(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Glad to hear it, ${name}`,
      nextStep: this.end
    }
  }
}

export default class AssessmentPitchCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchCCG
  readonly name: string = "AssessmentPitchCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchCCGDialogue.id, new AssessmentPitchCCGScript(), state, snapshot)
  }
}
