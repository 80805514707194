import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

const iaptGPMapVHGKentMedway: IAPTGPMap = {
  G82044: IAPTIDs.VHG_KM,
  G82021: IAPTIDs.VHG_KM,
  G82097: IAPTIDs.VHG_KM,
  G82698: IAPTIDs.VHG_KM,
  G82073: IAPTIDs.VHG_KM,
  G82026: IAPTIDs.VHG_KM,
  G82693: IAPTIDs.VHG_KM,
  G82088: IAPTIDs.VHG_KM,
  G82122: IAPTIDs.VHG_KM,
  G82014: IAPTIDs.VHG_KM,
  G82067: IAPTIDs.VHG_KM,
  G82808: IAPTIDs.VHG_KM,
  G82203: IAPTIDs.VHG_KM,
  G82185: IAPTIDs.VHG_KM,
  G82780: IAPTIDs.VHG_KM,
  G82218: IAPTIDs.VHG_KM,
  G82212: IAPTIDs.VHG_KM,
  G82809: IAPTIDs.VHG_KM,
  G82687: IAPTIDs.VHG_KM,
  G82032: IAPTIDs.VHG_KM,
  G82143: IAPTIDs.VHG_KM,
  G82028: IAPTIDs.VHG_KM,
  G82062: IAPTIDs.VHG_KM,
  G82799: IAPTIDs.VHG_KM,
  G82775: IAPTIDs.VHG_KM,
  G82206: IAPTIDs.VHG_KM,
  G82048: IAPTIDs.VHG_KM,
  G82634: IAPTIDs.VHG_KM,
  G82690: IAPTIDs.VHG_KM,
  G82639: IAPTIDs.VHG_KM,
  Y00449: IAPTIDs.VHG_KM,
  G82006: IAPTIDs.VHG_KM,
  G82137: IAPTIDs.VHG_KM,
  G82737: IAPTIDs.VHG_KM,
  G82140: IAPTIDs.VHG_KM,
  G82035: IAPTIDs.VHG_KM,
  G82706: IAPTIDs.VHG_KM,
  G82768: IAPTIDs.VHG_KM,
  G82225: IAPTIDs.VHG_KM,
  G82702: IAPTIDs.VHG_KM,
  G82757: IAPTIDs.VHG_KM,
  G82744: IAPTIDs.VHG_KM,
  G82092: IAPTIDs.VHG_KM,
  G82056: IAPTIDs.VHG_KM,
  G82022: IAPTIDs.VHG_KM,
  G82099: IAPTIDs.VHG_KM,
  Y02506: IAPTIDs.VHG_KM,
  G82710: IAPTIDs.VHG_KM,
  G83009: IAPTIDs.VHG_KM,
  G82164: IAPTIDs.VHG_KM,
  G82647: IAPTIDs.VHG_KM,
  G82135: IAPTIDs.VHG_KM,
  G82722: IAPTIDs.VHG_KM,
  G82023: IAPTIDs.VHG_KM,
  G83010: IAPTIDs.VHG_KM,
  G82017: IAPTIDs.VHG_KM,
  G82118: IAPTIDs.VHG_KM,
  G82041: IAPTIDs.VHG_KM,
  G82013: IAPTIDs.VHG_KM,
  G82741: IAPTIDs.VHG_KM,
  G82129: IAPTIDs.VHG_KM,
  G82682: IAPTIDs.VHG_KM,
  G82653: IAPTIDs.VHG_KM,
  G83048: IAPTIDs.VHG_KM,
  G82231: IAPTIDs.VHG_KM,
  G82042: IAPTIDs.VHG_KM,
  G82024: IAPTIDs.VHG_KM,
  G83628: IAPTIDs.VHG_KM,
  G82120: IAPTIDs.VHG_KM,
  G82197: IAPTIDs.VHG_KM,
  G82671: IAPTIDs.VHG_KM,
  G82037: IAPTIDs.VHG_KM,
  G82605: IAPTIDs.VHG_KM,
  G82093: IAPTIDs.VHG_KM,
  G82074: IAPTIDs.VHG_KM,
  G82736: IAPTIDs.VHG_KM,
  G82039: IAPTIDs.VHG_KM,
  G82080: IAPTIDs.VHG_KM,
  G82221: IAPTIDs.VHG_KM,
  G82234: IAPTIDs.VHG_KM,
  G82082: IAPTIDs.VHG_KM,
  G82069: IAPTIDs.VHG_KM,
  G82743: IAPTIDs.VHG_KM,
  G82686: IAPTIDs.VHG_KM,
  G82219: IAPTIDs.VHG_KM,
  G82027: IAPTIDs.VHG_KM,
  G82201: IAPTIDs.VHG_KM,
  G82161: IAPTIDs.VHG_KM,
  G82051: IAPTIDs.VHG_KM,
  G82046: IAPTIDs.VHG_KM,
  G82802: IAPTIDs.VHG_KM,
  G82057: IAPTIDs.VHG_KM,
  G82090: IAPTIDs.VHG_KM,
  G82059: IAPTIDs.VHG_KM,
  G82656: IAPTIDs.VHG_KM,
  G82156: IAPTIDs.VHG_KM,
  G82106: IAPTIDs.VHG_KM,
  G82107: IAPTIDs.VHG_KM,
  G82119: IAPTIDs.VHG_KM,
  G82214: IAPTIDs.VHG_KM,
  G82641: IAPTIDs.VHG_KM,
  G82631: IAPTIDs.VHG_KM,
  G82108: IAPTIDs.VHG_KM,
  G84023: IAPTIDs.VHG_KM,
  G82648: IAPTIDs.VHG_KM,
  G82154: IAPTIDs.VHG_KM,
  G82100: IAPTIDs.VHG_KM,
  G82087: IAPTIDs.VHG_KM,
  G82888: IAPTIDs.VHG_KM,
  G82679: IAPTIDs.VHG_KM,
  G82123: IAPTIDs.VHG_KM,
  A89042: IAPTIDs.VHG_KM,
  Y06420: IAPTIDs.VHG_KM,
  G83621: IAPTIDs.VHG_KM,
  G82719: IAPTIDs.VHG_KM,
  G82600: IAPTIDs.VHG_KM,
  G82233: IAPTIDs.VHG_KM,
  G82083: IAPTIDs.VHG_KM,
  G82085: IAPTIDs.VHG_KM,
  Y02222: IAPTIDs.VHG_KM,
  Y02826: IAPTIDs.VHG_KM,
  G83052: IAPTIDs.VHG_KM,
  G82635: IAPTIDs.VHG_KM,
  G82183: IAPTIDs.VHG_KM,
  G82732: IAPTIDs.VHG_KM,
  G82604: IAPTIDs.VHG_KM,
  G82058: IAPTIDs.VHG_KM,
  G82110: IAPTIDs.VHG_KM,
  G82098: IAPTIDs.VHG_KM,
  G82226: IAPTIDs.VHG_KM,
  G82089: IAPTIDs.VHG_KM,
  G82697: IAPTIDs.VHG_KM,
  G82071: IAPTIDs.VHG_KM,
  G82224: IAPTIDs.VHG_KM,
  G81008: IAPTIDs.VHG_KM,
  G82176: IAPTIDs.VHG_KM,
  G82112: IAPTIDs.VHG_KM,
  H84625: IAPTIDs.VHG_KM,
  G82019: IAPTIDs.VHG_KM,
  G84034: IAPTIDs.VHG_KM,
  G82200: IAPTIDs.VHG_KM,
  G82016: IAPTIDs.VHG_KM,
  G82077: IAPTIDs.VHG_KM,
  G82031: IAPTIDs.VHG_KM,
  G82708: IAPTIDs.VHG_KM,
  G83066: IAPTIDs.VHG_KM,
  G85124: IAPTIDs.VHG_KM,
  Y02811: IAPTIDs.VHG_KM,
  G82103: IAPTIDs.VHG_KM,
  G82229: IAPTIDs.VHG_KM,
  G82012: IAPTIDs.VHG_KM,
  G82015: IAPTIDs.VHG_KM,
  G82667: IAPTIDs.VHG_KM,
  G82125: IAPTIDs.VHG_KM,
  G82010: IAPTIDs.VHG_KM,
  G82235: IAPTIDs.VHG_KM,
  G85065: IAPTIDs.VHG_KM,
  L81037: IAPTIDs.VHG_KM,
  G83053: IAPTIDs.VHG_KM,
  G82650: IAPTIDs.VHG_KM,
  G82124: IAPTIDs.VHG_KM,
  D83611: IAPTIDs.VHG_KM,
  G82060: IAPTIDs.VHG_KM,
  G83012: IAPTIDs.VHG_KM,
  G82753: IAPTIDs.VHG_KM,
  G82134: IAPTIDs.VHG_KM,
  G82155: IAPTIDs.VHG_KM,
  G84621: IAPTIDs.VHG_KM,
  G82139: IAPTIDs.VHG_KM,
  G82113: IAPTIDs.VHG_KM,
  G84032: IAPTIDs.VHG_KM,
  G82094: IAPTIDs.VHG_KM,
  G82152: IAPTIDs.VHG_KM,
  G82791: IAPTIDs.VHG_KM,
  A99916: IAPTIDs.VHG_KM,
  E85124: IAPTIDs.VHG_KM,
  G82721: IAPTIDs.VHG_KM,
  G82215: IAPTIDs.VHG_KM,
  G82050: IAPTIDs.VHG_KM,
  C81005: IAPTIDs.VHG_KM,
  Y03640: IAPTIDs.VHG_KM,
  G82025: IAPTIDs.VHG_KM,
  G82095: IAPTIDs.VHG_KM,
  G82076: IAPTIDs.VHG_KM,
  G82128: IAPTIDs.VHG_KM,
  G82763: IAPTIDs.VHG_KM,
  G82691: IAPTIDs.VHG_KM,
  G83047: IAPTIDs.VHG_KM,
  G82727: IAPTIDs.VHG_KM,
  G82681: IAPTIDs.VHG_KM,
  G83046: IAPTIDs.VHG_KM
}

export const iaptGPMapVHG: IAPTGPMap = {
  ...iaptGPMapVHGKentMedway
}
