import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IntroductionScript, IntroductionScriptState } from "./IntroductionScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = IntroductionScriptState
export type IntroductionIAPTScriptState = State

export class IntroductionIAPTScript extends IntroductionScript {
  readonly name: string = "IntroductionIAPTScript"

  /** Script Steps */

  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "Hi there!",
        "I’m Limbic",
        `I'm a friendly robot assistant to help you access psychological support from ${organisationName}`
      ],
      nextStep: directReferral ? this.end : this.askCanIHelp
    }
  }

  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: "Can I help?",
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: `Yes, I'd like to refer myself to ${organisationName}`, value: true },
          { body: "No, I'm just browsing", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }
}

export default class IntroductionIAPTDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionIAPT
  readonly name: string = "IntroductionIAPTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionIAPTDialogue.id, new IntroductionIAPTScript(), state, snapshot)
  }
}
