import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { joinWithAnd } from "../../../utils/array"
import { TrackingEvents } from "../../../models/Constants"
import { DialogueIDs } from "../../DialogueIDs"
import { SuitableServicesSignpostScript } from "./SuitableServicesSignpostScript"
import type { SuitableServicesSignpostScriptState } from "./SuitableServicesSignpostScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { capitaliseFirst } from "../../../utils/string"
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber"
import { getIAPTServicesByCCG, getIAPTServicesBYODSCCG } from "../../../backend/api/nhs"

type State = SuitableServicesSignpostScriptState
export type SuitableServicesSignpostIAPTScriptState = State

export class SuitableServicesSignpostIAPTScript extends SuitableServicesSignpostScript {
  readonly name: string = "SuitableServicesSignpostIAPTScript"

  /** Script Steps */

  @step.logState
  sayWeDontSupportYourArea(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `So ${name}, with the NHS, you can get mental health support from services in the same area as your registered GP`,
        `I've just done a search, and it doesn't look like ${organisationName} have any services in the same area as your GP`
      ],
      prompt: {
        id: this.getPromptId("sayWeDontSupportYourArea"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "I understand" }, { body: "Fair enough" }]
      },
      nextStep: this.handleWeDontSupportYourArea
    }
  }

  @step
  handleWeDontSupportYourArea(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Sorry about that ${name}`,
      nextStep: this.checkIAPTServices
    }
  }

  @step
  async checkIAPTServices(d: IStepData<State>): Promise<IStepResult> {
    await this.fetchIAPTSuggestions(d.state)
    if (d.state.iaptSuggestions?.length) {
      return { nextStep: this.sayIAPTSuggestions }
    }
    this.logBreadcrumb("checkIAPTServices without iapt suggestions", d.state)
    this.logMessage("checkIAPTServices without iapt suggestions")
    if (d.state.odsGP || d.state.gp) {
      return { nextStep: this.signPostToTheUsersGP }
    }
    this.track(TrackingEvents.SIGNPOST_WITHOUT_GP_INFO)
    const organisationGenericPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    return {
      body: `Your GP is the best person to speak to about this. If you're unsure of your GP's contact information, you can call our team on ${organisationGenericPhoneNumber} and someone will be able to discuss your options`,
      nextStep: this.promptThanksOK
    }
  }

  @step
  sayIAPTSuggestions(d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.SIGNPOST_IAPTS)
    const services = joinWithAnd(d.state.iaptSuggestions?.map(i => i.formattedName || i.name))
    const serviceContacts = d.state.iaptSuggestions
      ?.map(i => `${i.formattedName || i.name}: ${i.phoneNumber}`)
      .join("\n")
    return {
      body: [
        "Don't worry though - I've found some other services, which might be right for you",
        services,
        `Please give them a call on any of the following numbers:\n${serviceContacts}`,
        "If you call one of these services, a friendly human should be able to book you in for an assessment with an NHS clinician"
      ],
      prompt: {
        id: this.getPromptId("sayIAPTSuggestions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: "okay" },
          { body: "Thanks", value: "thanks" }
        ]
      },
      nextStep: this.sayOrganisationPhoneNumbers
    }
  }

  @step.logState
  sayOrganisationPhoneNumbers(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers
    return {
      body: [
        `If you still think that ${organisationName} is right for you, please call one of our services:`,
        organisationPhoneNumbers,
        "Our staff are always happy to talk options through with you"
      ],
      nextStep: this.promptThanksOK
    }
  }

  @step
  signPostToTheUsersGP(d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.SIGNPOST_GP)
    const gpName = d.state.odsGP?.name ?? d.state.gp?.formattedName
    const gpPhoneNumber = d.state.odsGP?.phone?.[0] ?? d.state.gp?.phoneNumber
    return {
      body: [
        "To find where you can access mental health support, you'll need to ask your GP",
        `You can contact ${capitaliseFirst(gpName, true)} on ${formatPhoneNumber(gpPhoneNumber)}`
      ],
      nextStep: this.promptThanksOK
    }
  }

  /** Generic Handlers */

  async fetchIAPTSuggestions(state: State): Promise<void> {
    try {
      this.logBreadcrumb("fetchIAPTSuggestions", state)
      const eligibleIAPTIds = this.rootStore.configStore.eligibleIAPTIds
      const lat = state.userPostcode?.latitude
      const lon = state.userPostcode?.longitude
      if (!state.iaptSuggestions?.length && state.gp?.ccg.id) {
        const ccg = state.gp.ccg.id
        const { data } = await getIAPTServicesByCCG(ccg, lat, lon)
        const suggestions = data?.filter(s => !eligibleIAPTIds?.includes(s.id))
        if (suggestions) return this.setIAPTSuggestions(state, suggestions)
      }
      if (!state.iaptSuggestions?.length && state.odsGP?.ccgs.length) {
        const ccg = state.odsGP.ccgs[0]
        const { data } = await getIAPTServicesBYODSCCG(ccg, lat, lon)
        const suggestions = data?.filter(s => !eligibleIAPTIds?.includes(s.id))
        if (suggestions) return this.setIAPTSuggestions(state, suggestions)
      }
      if (!state.iaptSuggestions?.length && state.userPostcode?.ccg) {
        const ccg = state.userPostcode?.ccg
        const { data } = await getIAPTServicesBYODSCCG(ccg, lat, lon)
        const suggestions = data?.filter(s => !eligibleIAPTIds?.includes(s.id))
        if (suggestions) return this.setIAPTSuggestions(state, suggestions)
      }
    } catch (e) {
      this.logException(e, "fetchIAPTSuggestions")
    }
  }
}

/* istanbul ignore next */
export default class SuitableServicesSignpostIAPTDialogue extends Dialogue<State> {
  static id = DialogueIDs.SuitableServicesSignpostIAPT
  readonly name: string = "SuitableServicesSignpostIAPTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SuitableServicesSignpostIAPTDialogue.id,
      new SuitableServicesSignpostIAPTScript(),
      state,
      snapshot
    )
  }
}
