import {
  ARMED_FORCES_MAYDEN_LINCS,
  CIVIL_STATUS_MAYDEN_LINCS,
  DISABILITY_MAYDEN_LINCS,
  ETHNICITY_MAYDEN_LINCS,
  GENDER_MAYDEN_LINCS,
  LANGUAGE_MAYDEN_LINCS,
  LTC_MAYDEN_LINCS,
  PERINATAL_MAYDEN_LINCS,
  RELIGION_MAYDEN_LINCS,
  SEXUALITY_MAYDEN_LINCS,
  GenderBirthAssigned
} from "@limbic/types"

export const disabilities: Record<string, DISABILITY_MAYDEN_LINCS> = {
  "No disability": "NONE",
  "Behavioural & emotional": "BEHAVIOUR",
  Hearing: "HEARING",
  "Manual Dexterity": "MANUAL",
  "Memory or ability to concentrate, learn or understand (Learning Disability)": "LEARNING",
  "Mobility & Gross motor": "MOTOR",
  "Perception of Physical Danger": "PERCEPTION",
  "Personal, Self Care & Continence": "SELF_CARE",
  "Progessive Conditions & Physical Health": "PROGRESSIVE",
  Sight: "SIGHT",
  Speech: "SPEECH",
  Other: "NOT_LISTED"
}

export const genders: Record<string, GENDER_MAYDEN_LINCS> = {
  "Male (including trans man)": "MALE",
  "Female (including trans woman)": "FEMALE",
  "Non-binary": "NON_BINARY",
  "Other (not Listed)": "NOT_LISTED",
  "Not known": "UNKNOWN",
  "Not stated": "NOT_ANSWERED"
}

export const perinatalStatuses: Record<string, PERINATAL_MAYDEN_LINCS> = {
  "None apply": "NONE",
  Pregnant: "PREGNANT",
  "Child under 2": "CHILD_UNDER_2",
  "New parent": "NEW_PARENT"
}

export const ethnicities: Record<string, ETHNICITY_MAYDEN_LINCS> = {
  "White - British": "WHITE_BRITISH",
  "White - Irish": "WHITE_IRISH",
  "Mixed - White and Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "Mixed - White and Asian": "MIXED_WHITE_ASIAN",
  "Mixed - Any other mixed background": "MIXED_OTHER",
  "Asian or Asian British - Indian": "ASIAN_BRITISH_INDIAN",
  "Asian or Asian British - Pakistani": "ASIAN_BRITISH_PAKISTANI",
  "Asian or Asian British - Bangladeshi": "ASIAN_BRITISH_BANGLADESHI",
  "Asian or Asian British - Any Other Asian Background": "ASIAN_BRITISH_OTHER",
  "Black or Black British - African": "BLACK_BRITISH_AFRICAN",
  "Black or Black British - Any Other Black Background": "BLACK_BRITISH_OTHER",
  "Other Ethnic Groups - Chinese": "CHINESE",
  "Not Stated": "NOT_ANSWERED",
  "Not Known": "UNKNOWN"
}

export const exArmedForces: Record<string, ARMED_FORCES_MAYDEN_LINCS> = {
  "Yes - ex-services": "YES_EX",
  "Yes - currently serving (including reservists)": "YES_CURRENT",
  No: "NO",
  "Dependant of a ex-serving member": "DEPENDENT",
  "Not Stated": "NOT_ANSWERED",
  Unknown: "UNKNOWN"
}

export const sexualities: Record<string, SEXUALITY_MAYDEN_LINCS> = {
  Heterosexual: "HETEROSEXUAL",
  Gay: "HOMOSEXUAL_MALE",
  Lesbian: "HOMOSEXUAL_FEMALE",
  Bisexual: "BISEXUAL",
  Other: "NOT_LISTED",
  "Not known": "UNKNOWN",
  "Not stated": "NOT_ANSWERED"
}

export const maritalStatuses: Record<string, CIVIL_STATUS_MAYDEN_LINCS> = {
  Single: "SINGLE",
  Married: "MARRIED",
  Divorced: "DIVORCED",
  Widowed: "WIDOWED",
  "Co-habiting": "COHABITING",
  Separated: "SEPARATED",
  "Long term": "LONG_TERM",
  "Civil partnership": "CIVIL_PARTNERSHIP",
  "Not disclosed": "NOT_ANSWERED"
}

export const ltcs: Record<string, LTC_MAYDEN_LINCS> = {
  Asthma: "ASTHMA",
  Cancer: "CANCER",
  "Chronic Pain": "CHRONIC_PAIN",
  Dementia: "DEMENTIA",
  Diabetes: "DIABETES",
  Epilepsy: "EPILEPSY",
  "Heart Disease": "HEART_DISEASE",
  "Medically unexplained symptoms": "MEDICALLY_UNEXPLAINED"
}

export const religions: Record<string, RELIGION_MAYDEN_LINCS> = {
  "Baha'i": "BAHAI",
  Buddhist: "BUDDHIST",
  Christian: "CHRISTIAN",
  "Decline to disclose": "DECLINE_TO_RESPOND",
  Hindu: "HINDU",
  Jain: "JAIN",
  Jewish: "JEWISH",
  Muslim: "MUSLIM",
  "No religious group": "NONE",
  "Not stated": "NOT_ANSWERED",
  Pagan: "PAGAN",
  "Parsi / Zoroastrian": "ZOROASTRIAN",
  Sikh: "SIKH"
}

export const languages: Record<string, LANGUAGE_MAYDEN_LINCS> = {
  English: "ENGLISH",
  "Akan (Ashanti)": "AKAN",
  Albanian: "ALBANIAN",
  Amharic: "AMHARIC",
  Arabic: "ARABIC",
  "British Sign Language": "BRITISH_SIGN_LANGUAGE",
  Dutch: "DUTCH",
  "Farsi (Persian)": "PERSIAN_FARSI",
  Finnish: "FINNISH",
  Flemish: "FLEMISH",
  French: "FRENCH",
  Gaelic: "GAELIC",
  German: "GERMAN",
  Greek: "GREEK",
  Gujerati: "GUJARATI",
  Hausa: "HAUSA",
  Hebrew: "HEBREW",
  Hindi: "HINDI",
  "Igbo (Ibo)": "IGBO",
  Italian: "ITALIAN",
  Japanese: "JAPANESE",
  Korean: "KOREAN",
  Kurdish: "KURDISH",
  Lingala: "LINGALA",
  "Makaton (sign language)": "MAKATON_SIGN_LANGUAGE",
  Malayalam: "MALAYALAM",
  Norwegian: "NORWEGIAN",
  "Pashto (Pashtoo)": "PASHTO",
  Polish: "POLISH",
  Portuguese: "PORTUGUESE",
  Punjabi: "PUNJABI",
  Russian: "RUSSIAN",
  "Serbian/Croatian": "SERBIAN",
  Sinhala: "SINHALA",
  Somali: "SOMALI",
  Spanish: "SPANISH",
  Swahili: "SWAHILI",
  Swedish: "SWEDISH",
  "Tagalog (Filipino)": "TAGALOG_FILIPINO",
  Tamil: "TAMIL",
  Thai: "THAI",
  Tigrinya: "TIGRINYA",
  Turkish: "TURKISH",
  Urdu: "URDU",
  Vietnamese: "VIETNAMESE",
  Welsh: "WELSH",
  Yoruba: "YORUBA",
  Other: "NOT_LISTED"
}

export const genderSameAsBirth: Record<string, GenderBirthAssigned> = {
  Yes: "YES",
  No: "NO",
  "I don't know": "UNKNOWN",
  "I don't want to say": "NOT_ANSWERED"
}

const form = {
  form_url: "https://gateway.mayden.co.uk/referral-v2/c2ce2fcb-3586-4e64-8405-deac6ce440b1",
  filled_by: "API",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  sameGenderAsBirth: Object.keys(genderSameAsBirth),
  ethnicities: Object.keys(ethnicities),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs),
  languages: Object.keys(languages),
  maritalStatuses: Object.keys(maritalStatuses),
  religions: Object.keys(religions),
  employmentStatuses: [
    "Employed full time",
    "Employed part time",
    "Employed but signed off from work",
    "Unemployed",
    "Full time student",
    "Retired",
    "Full time homemaker or carer",
    "Long term sick or disabled",
    "Unpaid voluntary work",
    "Not receiving benefits"
  ],
  employmentSupports: [
    "Yes - Returning to work",
    "Yes - Staying in work",
    "Yes - Career support",
    "Yes - Finding employment",
    "No employment support needed"
  ],
  previousIaptTreatments: ["Never", "Once", "Twice", "Three or more times"],
  benefits: ["JSA", "ESA", "PIP", "Incapacity Benefit", "Universal Credit", "None"],
  disasters: ["None", "Manchester Bombings", "Hillsborough", "New Ferry Blast"]
}

export default form
