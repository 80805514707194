import { action, observable } from "mobx"
import Persistable from "../../models/Persistable"
import { LoggableType } from "../../models/Logger/LoggableType"

export default class ApplicationStore extends Persistable {
  readonly name: string = "ApplicationStore"

  @observable username?: string
  @observable preferredName?: string
  @observable totalProgressBars: number
  @observable currentProgressBar: number
  @observable currentProgress: number
  @observable undoBlocked: boolean
  @observable undoBlockIsTemp: boolean

  constructor() {
    super()
    this.totalProgressBars = this.hydrate("totalProgressBars") ?? 0
    this.currentProgressBar = this.hydrate("currentProgressBar") ?? 0
    this.currentProgress = this.hydrate("currentProgress") ?? 0
    this.username = this.hydrate("username")
    this.preferredName = this.hydrate("preferredName")
    this.undoBlocked = this.hydrate("undoBlocked") ?? false
    this.undoBlockIsTemp = this.hydrate("undoBlockIsTemp") ?? false
  }

  /** Actions */

  @action
  setUsername(username: string | undefined): void {
    this.username = username
    this.persist("username", username)
  }

  @action
  setPreferredName(preferredName: string | undefined): void {
    this.preferredName = preferredName
    this.persist("preferredName", preferredName)
  }

  @action
  setTotalProgressBars(totalProgressBars: number): void {
    this.totalProgressBars = totalProgressBars
    this.persist("totalProgressBars", totalProgressBars)
  }

  @action
  setCurrentProgressBar(currentProgressBar: number): void {
    this.currentProgressBar = currentProgressBar
    this.persist("currentProgressBar", currentProgressBar)
  }

  @action
  setCurrentProgress(currentProgress: number): void {
    this.currentProgress = currentProgress
    this.persist("currentProgress", currentProgress)
  }

  @action
  blockUndo(temp = false): void {
    this.undoBlocked = true
    this.persist("undoBlocked", true)
    this.setUndoBlockIsTemp(temp)
  }

  @action
  enableUndo(): void {
    this.undoBlocked = false
    this.persist("undoBlocked", false)
    this.setUndoBlockIsTemp(false)
  }

  @action
  setUndoBlockIsTemp(undoBlockIsTemp: boolean): void {
    this.undoBlockIsTemp = undoBlockIsTemp
    this.persist("undoBlockIsTemp", undoBlockIsTemp)
  }
}

ApplicationStore.setLogType(LoggableType.UI)
