import moment from "moment"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import dialoguesRegistry from "../../dialoguesRegistry"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import isValidPhoneNumber, {
  isValidLandlineNumber,
  isValidMobilePhone
} from "../../../utils/isvalidPhoneNumber"
import { ALCOHOL_FREQUENCIES, ALCOHOL_QUANTITIES, TrackingEvents } from "../../../models/Constants"
import {
  ALCOHOL_QUANTITIES as AQ,
  ALCOHOL_FREQUENCIES as AF,
  ReferralPayloadMaydenNotts,
  LANGUAGE_MAYDEN_NOTTS,
  SEXUALITY_MAYDEN_NOTTS,
  PERINATAL_MAYDEN_NOTTS,
  GENDER_MAYDEN_NOTTS,
  ETHNICITY_MAYDEN_NOTTS,
  ARMED_FORCES_MAYDEN_NOTTS,
  RELIGION_MAYDEN_NOTTS,
  LTC_MAYDEN_NOTTS,
  DISABILITY_MAYDEN_NOTTS,
  GenderBirthAssigned
} from "@limbic/types"
import type { LIMBIC_IMPACT_LEVEL } from "@limbic/types"
import invariant from "../../../utils/invariant"
import {
  perinatalStatuses,
  genders,
  sexualities,
  religions,
  ltcs,
  disabilities,
  exArmedForces,
  ethnicities,
  languages,
  sameGenderAsBirth
} from "../../../config/referralForms/nottinghamshire"

interface State extends SelfReferralIAPTScriptState {
  mainIssue?: string
  disabilities?: string[]
  impactOfProblems?: string
  howLongDidYouHaveTheseDifficulties?: string
  whatChangesWouldLikeToMake?: string
  therapyPreference?: string[]
  difficultiesAttendingWeeklyTheraySessions?: boolean
  appointmentPreference?: "Video" | "Telephone"
  nextOfKinContactDetails?: string
  nhsWorker?: boolean
  preferredLanguage?: string
  requiresInterpreter?: boolean
  currentSupportDetails?: string
  hasHistoryOfMentalHealthIssues?: boolean
  typeOfHelpInThePast?: string
  hasSocialOrDomesticProblems?: boolean
  socialOrDomesticProblemsDetails?: string
  hasEmploymentDifficulties?: boolean
  howDidYouHearAboutTheService?: string
  currentlyTakingMedication?: boolean
  detailsCurrentMedication?: string
  interpreterLanguage?: string
}

export class SelfReferralNottinghamshireScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralNottinghamshireScript"

  /** Script Steps */
  @step.logState
  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askMainIssue }
  }

  @step.logState
  askPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: ["Brilliant", "So firstly, what's the best mobile phone number to reach you on?"],
      prompt: {
        id: this.getPromptId("askPhoneNumber"),
        type: "phoneNumber",
        forceMobile: true
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNottinghamshireScript) => s.returnToAskPhoneNumber
  })
  async handlePhoneNumber(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      this.track(TrackingEvents.INVALID_PHONE_NUMBER)
      return {
        body: "Sorry this is not a valid phone number. Let's try again",
        nextStep: this.sayPleaseTypePhoneNumber
      }
    }
    d.state.phoneNumber = d.response
    const result = await this.onHandlePhoneNumber?.(d.state)
    if (result) return result

    return {
      body: "Thanks",
      nextStep: this.askCanIContactYouOnPhoneNumber
    }
  }

  @step.logState
  askMainIssue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, what's the main issue that has brought you here today?`,
        "(Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life)"
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleMainIssue
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNottinghamshireScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNottinghamshireScript) => s.askWhatImpactDoProblemsHave
  })
  handleMainIssue(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askWhatImpactDoProblemsHave }
  }

  @step.logState
  askWhatImpactDoProblemsHave(_d: IStepData<State>): IStepResult {
    return {
      body: "And what impact do these difficulties have on your life?",
      prompt: {
        id: this.getPromptId("askWhatImpactDoProblemsHave"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleWhatImpactDoProblemsHave
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNottinghamshireScript) => {
    d.state.impactOfProblems = d.response
    script.referralStore.setCustomField<State>("impactOfProblems", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNottinghamshireScript) => s.askHowLongDidYouHaveTheseDifficulties
  })
  handleWhatImpactDoProblemsHave(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askHowLongDidYouHaveTheseDifficulties }
  }

  @step.logState
  askHowLongDidYouHaveTheseDifficulties(_d: IStepData<State>): IStepResult {
    return {
      body: "How long have you had these difficulties? How do you feel your problems have come about?",
      prompt: {
        id: this.getPromptId("askHowLongDidYouHaveTheseDifficulties"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleHowLongDidYouHaveTheseDifficulties
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNottinghamshireScript) => {
    d.state.howLongDidYouHaveTheseDifficulties = d.response
    script.referralStore.setCustomField<State>("howLongDidYouHaveTheseDifficulties", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNottinghamshireScript) => s.askWhatChangesWouldYouLikeToMake
  })
  handleHowLongDidYouHaveTheseDifficulties(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askWhatChangesWouldYouLikeToMake }
  }

  @step.logState
  askWhatChangesWouldYouLikeToMake(_d: IStepData<State>): IStepResult {
    return {
      body: "What changes would you like to make through therapy? Are there any reasons for doing this now?",
      prompt: {
        id: this.getPromptId("askWhatChangesWouldYouLikeToMake"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleWhatChangesWouldYouLikeToMake
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNottinghamshireScript) => {
    d.state.whatChangesWouldLikeToMake = d.response
    script.referralStore.setCustomField<State>("whatChangesWouldLikeToMake", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNottinghamshireScript) => s.askTherapyPreference
  })
  handleWhatChangesWouldYouLikeToMake(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askTherapyPreference }
  }

  @step.logState
  askTherapyPreference(_d: IStepData<State>): IStepResult {
    return {
      body: "We offer digital platforms, group therapy and 1-1 therapy. Do you have a preference?",
      prompt: {
        id: this.getPromptId("askTherapyPreference"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          {
            body: "No preference",
            value: "noPreference",
            backgroundColor: "#8C96D4FF",
            selectIndividually: true
          },
          { body: "Digital platforms", value: "digitalPlatforms" },
          { body: "Group therapy", value: "groupTherapy" },
          { body: "1-1 therapy", value: "oneOneTherapy" }
        ]
      },
      nextStep: this.handleTherapyPreference
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string[]>, script: SelfReferralNottinghamshireScript) => {
      d.state.therapyPreference = d.response
      script.referralStore.setCustomField<State>("therapyPreference", d.response)
    }
  )
  handleTherapyPreference(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askDifficultiesWeeklyTherapySessions }
  }

  @step.logState
  askDifficultiesWeeklyTherapySessions(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you foresee any difficulties in attending weekly therapy sessions?",
      prompt: {
        id: this.getPromptId("askDifficultiesWeeklyTherapySessions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleDifficultiesWeeklyTherapySessions
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralNottinghamshireScript) => {
      d.state.difficultiesAttendingWeeklyTheraySessions = d.response
      script.referralStore.setCustomField<State>(
        "difficultiesAttendingWeeklyTheraySessions",
        d.response
      )
    }
  )
  handleDifficultiesWeeklyTherapySessions(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askAppointmentPreference }
  }

  @step.logState
  askAppointmentPreference(_d: IStepData<State>): IStepResult {
    return {
      body: "Please state your appointment preference",
      prompt: {
        id: this.getPromptId("askAppointmentPreference"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Video", value: "Video" },
          { body: "Telephone", value: "Telephone" }
        ]
      },
      nextStep: this.handleAppointmentPreference
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, "Video" | "Telephone">, script: SelfReferralNottinghamshireScript) => {
      d.state.appointmentPreference = d.response
      script.referralStore.setCustomField<State>("appointmentPreference", d.response)
    }
  )
  handleAppointmentPreference(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askForNextOfKinContactDetails }
  }

  @step.logState
  askForNextOfKinContactDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please can you provide us with your next of kin/emergency contact details",
      prompt: {
        id: this.getPromptId("askForNextOfKinContactDetails"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleForNextOfKinContactDetails
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNottinghamshireScript) => {
    d.state.nextOfKinContactDetails = d.response
    script.referralStore.setCustomField<State>("nextOfKinContactDetails", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNottinghamshireScript) => s.askForNextOfKinContactDetails
  })
  handleForNextOfKinContactDetails(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askNHSWorker }
  }

  @step.logState
  askNHSWorker(d: IStepData<State>): IStepResult {
    // TODO: Show name with title or without title?
    const name = this.getName(d.state)
    return {
      body: [`Thanks, ${name}`, "Are you a frontline NHS or social care worker?"],
      prompt: {
        id: this.getPromptId("askNHSWorker"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleNHSWorker
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralNottinghamshireScript) => {
      d.state.nhsWorker = d.response
      script.referralStore.setCustomField<State>("nhsWorker", d.response)
    }
  )
  handleNHSWorker(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askGender }
  }

  @step.logState
  askPreferredSpokenLanguage(d: IStepData<State>): IStepResult {
    const languages = this.getLanguages(d.state)
    if (!languages?.length) {
      this.logBreadcrumb("LANGUAGES NOT FOUND", d.state, { languages })
      this.logMessage("LANGUAGES NOT FOUND")
      return { nextStep: this.askRequiresAnInterpreter }
    }

    return {
      body: "What is your preferred spoken language?",
      prompt: {
        id: this.getPromptId("askPreferredSpokenLanguage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: languages.map(g => ({ body: g, value: g }))
      },
      nextStep: this.handlePreferredSpokenLanguage
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNottinghamshireScript) => {
    d.state.preferredLanguage = d.response
    script.referralStore.setCustomField<State>("preferredLanguage", d.response)
  })
  handlePreferredSpokenLanguage(d: IStepData<State, string>): IStepResult {
    if (d.response.match(/english/i)) {
      return {
        nextStep: this.askExArmedForces
      }
    }
    return { nextStep: this.askRequiresAnInterpreter }
  }

  @step.logState
  askRequiresAnInterpreter(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you require an interpreter?",
      prompt: {
        id: this.getPromptId("askRequiresAnInterpreter"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequiresInterpreter
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralNottinghamshireScript) => {
      d.state.requiresInterpreter = d.response
      script.referralStore.setCustomField<State>("requiresInterpreter", d.response)
    }
  )
  handleRequiresInterpreter(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      return { nextStep: this.askInterpreterLanguage }
    }
    return { nextStep: this.askExArmedForces }
  }

  @step.logState
  askInterpreterLanguage(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const primaryLanguage = d.state.preferredLanguage
    const languages = this.getLanguages(d.state)
    if (!languages?.length) {
      this.logBreadcrumb("LANGUAGES NOT FOUND", d.state, { languages })
      this.logMessage("LANGUAGES NOT FOUND")
      return { nextStep: this.askExArmedForces }
    }

    return {
      body: [
        `So ${name}, you stated your main spoken language is ${primaryLanguage}.`,
        "Is this the language you need an interpreter for?",
        "(If not, just type the language you'd like an interpreter for)"
      ],
      prompt: {
        id: this.getPromptId("askInterpreterLanguage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: `Yes, I need an interpreter for ${primaryLanguage}`,
            value: primaryLanguage
          },
          ...languages
            .filter(language => language !== primaryLanguage)
            .map(g => ({ body: g, value: g }))
        ],
        dataPointsName: "askInterpreterLanguage"
      },
      nextStep: this.handleInterpreterLanguage
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNottinghamshireScript) => {
    d.state.interpreterLanguage = d.response
    script.referralStore.setCustomField<State>("interpreterLanguage", d.state.interpreterLanguage)
  })
  handleInterpreterLanguage(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askExArmedForces }
  }

  @step.logState
  askDisability(d: IStepData<State>): IStepResult {
    const disabilities = this.getDisabilities(d.state)
    if (!disabilities?.length) {
      this.logBreadcrumb("DISABILITIES NOT FOUND", d.state, { disabilities })
      this.logMessage("DISABILITIES NOT FOUND")
      return { nextStep: this.askAlcohol }
    }

    return {
      body: "Do you have a disability?",
      prompt: {
        id: this.getPromptId("askDisability"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          {
            body: "No disability",
            value: "No disability",
            backgroundColor: "#8C96D4FF",
            selectIndividually: true
          },
          ...disabilities
            .map(g => ({ body: g, value: g }))
            .filter(item => item.body !== "No disability")
        ],
        dataPointsName: "askDisability"
      },
      nextStep: this.handleDisabilities
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string[]>, script: SelfReferralNottinghamshireScript) => {
      d.state.disabilities = d.response
      script.referralStore.setCustomField<State>("disabilities", d.response)
    }
  )
  handleDisabilities(d: IStepData<State, string[]>): IStepResult {
    this.setPeople({ disabilities: d.response })
    return { nextStep: this.askAlcohol }
  }

  @step.logState
  askCurrentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently (or within the last 6 months) receiving any other form of mental health support?",
      prompt: {
        id: this.getPromptId("askCurrentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCurrentSupport"
      },
      nextStep: this.handleCurrentSupport
    }
  }

  @step.logState
  askCurrentSupportDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please give details",
      prompt: {
        id: this.getPromptId("askCurrentSupportDetails"),
        type: "text",
        forceValue: true,
        dataPointsName: "askCurrentSupportDetails"
      },
      nextStep: this.handleCurrentSupportDetails
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNottinghamshireScript) => {
    d.state.currentSupportDetails = d.response
    script.referralStore.setCustomField<State>("currentSupportDetails", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNottinghamshireScript) => s.askCurrentSupportDetails
  })
  handleCurrentSupportDetails(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askHistoryOfMentalHealthProblems }
  }

  @step.logState
  askHistoryOfMentalHealthProblems(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have a history of mental health problems?",
      prompt: {
        id: this.getPromptId("askHistoryOfMentalHealthProblems"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askHistoryOfMentalHealthProblems"
      },
      nextStep: this.handleHistoryOfMentalHealthProblems
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralNottinghamshireScript) => {
      d.state.hasHistoryOfMentalHealthIssues = d.response
      script.referralStore.setCustomField<State>("hasHistoryOfMentalHealthIssues", d.response)
    }
  )
  handleHistoryOfMentalHealthProblems(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      return { nextStep: this.askWhatHelpInThePast }
    }
    return { nextStep: this.askAnySocialOrDomesticProblems }
  }

  @step.logState
  askWhatHelpInThePast(_d: IStepData<State>): IStepResult {
    return {
      body: "Please tell us what help/contact you have had with services in the past",
      prompt: {
        id: this.getPromptId("askWhatHelpInThePast"),
        type: "text",
        forceValue: true,
        dataPointsName: "askWhatHelpInThePast"
      },
      nextStep: this.handleWhatHelpInThePast
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNottinghamshireScript) => {
    d.state.typeOfHelpInThePast = d.response
    script.referralStore.setCustomField<State>("typeOfHelpInThePast", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNottinghamshireScript) => s.askWhatHelpInThePast
  })
  handleWhatHelpInThePast(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askAnySocialOrDomesticProblems }
  }

  @step.logState
  askAnySocialOrDomesticProblems(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have any social and/or domestic problems? (E.g. housing, relationships, employment)",
      prompt: {
        id: this.getPromptId("askAnySocialOrDomesticProblems"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAnySocialOrDomesticProblems"
      },
      nextStep: this.handleAnySocialOrDomesticProblems
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralNottinghamshireScript) => {
      d.state.hasSocialOrDomesticProblems = d.response
      script.referralStore.setCustomField<State>("hasSocialOrDomesticProblems", d.response)
    }
  )
  handleAnySocialOrDomesticProblems(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      return { nextStep: this.askSocialOrDomesticProblemsDetails }
    }
    return { nextStep: this.askDoYouHaveEmploymentDifficulties }
  }

  @step.logState
  askSocialOrDomesticProblemsDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please give details",
      prompt: {
        id: this.getPromptId("askSocialOrDomesticProblemsDetails"),
        type: "text",
        forceValue: true,
        dataPointsName: "askSocialOrDomesticProblemsDetails"
      },
      nextStep: this.handleSocialOrDomesticProblemsDetails
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNottinghamshireScript) => {
    d.state.socialOrDomesticProblemsDetails = d.response
    script.referralStore.setCustomField<State>("socialOrDomesticProblemsDetails", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNottinghamshireScript) => s.askSocialOrDomesticProblemsDetails
  })
  handleSocialOrDomesticProblemsDetails(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askDoYouHaveEmploymentDifficulties }
  }

  @step.logState
  askDoYouHaveEmploymentDifficulties(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have employment difficulties?",
      prompt: {
        id: this.getPromptId("askDoYouHaveEmploymentDifficulties"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askDoYouHaveEmploymentDifficulties"
      },
      nextStep: this.handleDoYouHaveEmploymentDifficulties
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralNottinghamshireScript) => {
      d.state.hasEmploymentDifficulties = d.response
      script.referralStore.setCustomField<State>("hasEmploymentDifficulties", d.response)
    }
  )
  handleDoYouHaveEmploymentDifficulties(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToIAPTAccommodation }
  }

  @step.logState
  goToIAPTAccommodation(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const IAPTAccommodationDialogue = dialoguesRegistry.get(DialogueIDs.IAPTAccommodation)
    return {
      nextDialogue: new IAPTAccommodationDialogue({ ...d.state }),
      nextStep: this.doReferralSubmission
    }
  }

  @step.logState
  askNHSNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "What is your NHS number?",
      prompt: {
        id: this.getPromptId("askNHSNumber"),
        trackResponse: false,
        type: "inlinePicker",
        choices: [{ body: "I don't know", value: null }],
        textPrompt: {
          trimAllSpacesOnSubmit: true,
          trimAllSpacesOnValidation: true,
          validation: [/^\d{10}$/],
          validationExplainer: [
            "This is not a valid NHS number",
            "It needs to be a number with 10 digits"
          ],
          forceValue: true
        },
        dataPointsName: "askNHSNumber"
      },
      nextStep: this.handleNHSNumber
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    // This 👇 is important to make sure we don't add empty strings
    d.state.nhsNumber = d.response ? d.response : undefined
  })
  handleNHSNumber(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.finishSelfReferral }
  }

  askCurrentlyTakingAnyMedication(_d: IStepData<State>): IStepResult {
    return {
      body: "And are you currently taking any medication prescribed by a doctor?",
      prompt: {
        id: this.getPromptId("askCurrentlyTakingAnyMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCurrentlyTakingAnyMedication"
      },
      nextStep: this.handleCurrentlyTakingAnyMedication
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.currentlyTakingMedication = d.response
  })
  handleCurrentlyTakingAnyMedication(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      return { nextStep: this.askDetailsAboutCurrentMedication }
    }
    return { nextStep: this.askCurrentSupport }
  }

  askDetailsAboutCurrentMedication(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you give me details about the medication?",
      prompt: {
        id: this.getPromptId("askDetailsAboutCurrentMedication"),
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleDetailsAboutCurrentMedication
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.detailsCurrentMedication = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNottinghamshireScript) => s.askCurrentSupport
  })
  handleDetailsAboutCurrentMedication(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askPermissionToEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Do we have permission to send you an email to that address?",
      prompt: {
        id: this.getPromptId("askPermissionToEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPermissionToEmail"
      },
      nextStep: this.handlePermissionToEmail
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canSendEmail = d.response
  })
  handlePermissionToEmail(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.askEthnicity }
    }
    return {
      body: "Are you or your partner currently pregnant or have a child under the age of 2?",
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: g, value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.perinatalStatus = d.response
  })
  async handlePerinatal(d: IStepData<State, string>): Promise<IStepResult> {
    this.setPeople({ perinatalStatus: d.response })
    return { nextStep: this.askEthnicity }
  }

  /* Generic Handlers */
  getLanguages(state: State): string[] {
    return state.iapt?.referralForm?.languages ?? []
  }

  getGenderSameAsBirthValues(state: State): string[] {
    return state.iapt?.referralForm?.sameGenderAsBirth ?? []
  }

  async onHandleGender(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askSameGenderAsBirth
    }
  }

  async onHandleSexuality(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askPerinatal
    }
  }

  async onHandleEthnicity(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askReligion
    }
  }

  async onHandleEmail(state: State): Promise<IStepResult<State>> {
    if (state.email) {
      return { nextStep: this.askPermissionToEmail }
    }
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  async onHandleReligion(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askPreferredSpokenLanguage
    }
  }

  async onHandleExArmedForces(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askLongTermMedicalCondition
    }
  }

  async onHandleLongTermMedicalCondition(state: State): Promise<IStepResult<State>> {
    if (state.longTermMedicalCondition?.includes("No")) {
      return { nextStep: this.askHaveYouEverCaughtCovid }
    }
    return { nextStep: this.askDoesLTCAffectMood }
  }

  async onHandleDoesLTCAffectMood(state: State): Promise<IStepResult<State>> {
    if (state.ltcAffectsMood) return { nextStep: this.askHowMuchLTCAffectsMood }
    return { nextStep: this.askHaveYouEverCaughtCovid }
  }

  async onHandleHowMuchLTCAffectsMood(state: State): Promise<IStepResult<State>> {
    if (state.ltcMoodImpact === "little") return { nextStep: this.askHaveYouEverCaughtCovid }
    return { nextStep: this.askHowWellYouManageYourLTC }
  }

  async onHandleHowWellYouManageYourLTC(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askHaveYouEverCaughtCovid }
  }

  async onHandleHaveYouEverCaughtCovid?(state: State): Promise<IStepResult<State>> {
    if (state.covidStatus === "NO") {
      return { nextStep: this.askDisability }
    }
    return { nextStep: this.askWhenDidYouHaveCovid }
  }

  async onHandleWhenDidYouHaveCovid?(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askDisability }
  }

  async onHandleCurrentSupport(state: State): Promise<IStepResult<State>> {
    if (state.hasCurrentSupport) {
      return { nextStep: this.askCurrentSupportDetails }
    }
    return { nextStep: this.askHistoryOfMentalHealthProblems }
  }

  async onHandleMedicationWithinDoseRange(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askCurrentlyTakingAnyMedication }
  }

  async onHandlePermissionToSendMailToAddress(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askNHSNumber }
  }

  async onHandleSubstances(state: State): Promise<IStepResult<State>> {
    if (state.substances) {
      return { nextStep: this.askSubstancesOrigin }
    }
    return { nextStep: this.askCurrentlyTakingAnyMedication }
  }

  async onHandleSubstancesInfo(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askCurrentlyTakingAnyMedication }
  }

  async onHandleMedicationInfo(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askCurrentlyTakingAnyMedication }
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadMaydenNotts> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      nameFirst: this.getName(state),
      nameLast: this.getLastName(state),
      title: state.nameTitle,
      whereHeardDetails: state.whereDidYouHearAboutService,
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || state.address,
        county: state.county || state.address || "n/a",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      nhsNumber: state.nhsNumber,
      gpPractice: state.gp?.name,
      gpName: state.gp?.name,
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      alcohol: state.alcohol,
      alcoholFrequency: this.getAlcoholFrequency(state),
      alcoholQuantity: this.getAlcoholQuantity(state),
      substances: state.substances,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      medication:
        state.substancesAreMedications && state.medicationInfo //
          ? [state.medicationInfo]
          : undefined,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      medicationPrescribed: state.currentlyTakingMedication,
      medicationPrescribedDetails: state.detailsCurrentMedication,
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      covidStatus: state.covidStatus,
      covidDate: state.covidDate,
      // NOTE: It seems we are not asking this question for Notts
      // civilStatus: this.getCivilStatus(state),
      disability: this.getDisability(state),
      language: this.getLanguage(state, "preferred"),
      employmentDifficulties: state.hasEmploymentDifficulties,
      interpreter: state.requiresInterpreter,
      interpreterLanguage: this.getLanguage(state, "interpreter"),
      sexuality: this.getSexuality(state),
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirthAssigned(state),
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForced(state),
      religion: this.getReligion(state),
      problemByPatient: state.mainIssue,
      perinatal: this.getPerinatal(state),
      problemLifeImpact: state.impactOfProblems,
      problemDurationAndSource: state.howLongDidYouHaveTheseDifficulties,
      treatmentExpectation: state.whatChangesWouldLikeToMake,
      difficultiesAttendingTreatment: state.difficultiesAttendingWeeklyTheraySessions,
      treatmentPreference: state.appointmentPreference,
      emergencyContact: state.nextOfKinContactDetails,
      keyWorker: state.nhsWorker,
      pastMHProblems: state.hasHistoryOfMentalHealthIssues,
      pastMHProblemsDetails: state.typeOfHelpInThePast,
      domesticProblems: state.hasSocialOrDomesticProblems,
      domesticProblemsDetails: state.socialOrDomesticProblemsDetails,
      pastTreatment: state.hasCurrentSupport,
      pastTreatmentDetails: state.currentSupportDetails,
      therapyPreference: state.therapyPreference,
      questionnaires: this.getQuestionnairesPayload(state)
    }
  }

  getAlcoholFrequency(state: State): typeof AF[keyof typeof AF] | undefined {
    const map = {
      [ALCOHOL_FREQUENCIES.MONTHLY]: AF.MONTHLY,
      [ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4]: AF.MONTHLY_2_TO_4,
      [ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3]: AF.WEEKLY_2_TO_3,
      [ALCOHOL_FREQUENCIES.WEEKLY_4]: AF.WEEKLY_4
    }
    return map[state.alcoholFrequency!]
  }

  getAlcoholQuantity(state: State): typeof AQ[keyof typeof AQ] | undefined {
    const map = {
      [ALCOHOL_QUANTITIES._0_2]: AQ._0_2,
      [ALCOHOL_QUANTITIES._3_4]: AQ._3_4,
      [ALCOHOL_QUANTITIES._5_6]: AQ._5_6,
      [ALCOHOL_QUANTITIES._7_9]: AQ._7_9,
      [ALCOHOL_QUANTITIES._10_PLUS]: AQ._10_PLUS
    }
    return map[state.alcoholQuantity!]
  }

  getLanguage(state: State, type: "preferred" | "interpreter"): LANGUAGE_MAYDEN_NOTTS | undefined {
    return languages[state[`${type}Language`]!]
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_NOTTS {
    return ethnicities[state.ethnicity!] ?? "NOT_ANSWERED"
  }

  getArmedForced(state: State): ARMED_FORCES_MAYDEN_NOTTS {
    return exArmedForces[state.isExArmedForces!] ?? "NOT_ANSWERED"
  }

  getReligion(state: State): RELIGION_MAYDEN_NOTTS {
    return religions[state.religion!] ?? "NOT_ANSWERED"
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_NOTTS | undefined {
    // NOT_LISTED when the response if a free text (custom input)
    return sexualities[state.sexuality!] || "NOT_LISTED"
  }

  getGender(state: State): GENDER_MAYDEN_NOTTS {
    return genders[state.gender!] ?? "NOT_LISTED"
  }

  getGenderSameAsBirthAssigned(state: State): GenderBirthAssigned {
    const genderSameAsBirthAssigned = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirthAssigned ?? "UNKNOWN"
  }

  getPerinatal(state: State): PERINATAL_MAYDEN_NOTTS {
    return perinatalStatuses[state.perinatalStatus!] ?? "NOT_ANSWERED"
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getLTC(state: State): LTC_MAYDEN_NOTTS[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getDisability(state: State): DISABILITY_MAYDEN_NOTTS[] {
    if (state.disabilityStatus === false) return ["NONE"]
    if (!state.disabilityStatus || !state.disabilities?.length) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }
    return state.disabilities
      ? state.disabilities?.map(disability => disabilities[disability]).filter(Boolean)
      : ["NOT_ANSWERED"]
  }
}

export default class SelfReferralNottinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralNottinghamshire
  readonly name: string = "SelfReferralNottinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralNottinghamshireDialogue.id,
      new SelfReferralNottinghamshireScript(),
      state,
      snapshot
    )
  }
}
