import createSurveyDialogue from "../createSurveyDialogue"
import ISurvey, { SurveyType } from "../../models/ISurvey"
import { DialogueIDs } from "../DialogueIDs"

const IAPTPhobiaScale: ISurvey = {
  startingQuestion:
    "Okay {name}, please slide the circle below to rate how much you would avoid each of the following situations or objects…",
  questions: [
    {
      id: "1",
      questionName: "phobialScaleSocialSituations",
      question:
        "1. Social situations\n(due to fear of being embarrassed or making a fool of yourself etc.)",
      type: "slider",
      progress: 0.33,
      min: 0,
      max: 8,
      labels: {
        0: "Would not avoid",
        2: "Slightly avoid",
        4: "Definitely avoid",
        6: "Markedly avoid",
        8: "Always avoid"
      }
    },
    {
      id: "2",
      questionName: "phobialScaleCertainSituationsPanicAttack",
      question:
        "2. Certain situations because you are afraid you might have a panic attack or other distressing symptoms\n" +
        "(e.g. loss of bladder control, vomiting, dizziness etc.)",
      type: "slider",
      progress: 0.66,
      min: 0,
      max: 8,
      labels: {
        0: "Would not avoid",
        2: "Slightly avoid",
        4: "Definitely avoid",
        6: "Markedly avoid",
        8: "Always avoid"
      }
    },
    {
      id: "3",
      questionName: "phobialScaleCertainSituationsParticularObjects",
      question:
        "3. Certain situations because you are afraid of particular objects or activities\n" +
        "(e.g. animals, heights, seeing blood, being in confined spaces, driving or flying etc.)",
      type: "slider",
      progress: 1,
      min: 0,
      max: 8,
      labels: {
        0: "Would not avoid",
        2: "Slightly avoid",
        4: "Definitely avoid",
        6: "Markedly avoid",
        8: "Always avoid"
      }
    }
  ]
}

const id = DialogueIDs.IAPTPhobiaScale
export default createSurveyDialogue(id, IAPTPhobiaScale, SurveyType.IAPTPhobiaScale)
