import { IGPServiceConfig } from "../models/IAppLaunchConfig"
import { IAPTIDs } from "../models/IIAPTService"

export const gpServices: Record<string, IGPServiceConfig> = {
  DevonRoadSurgery: {
    id: "13362",
    pimsCode: "35981",
    nacsCode: "G82088",
    name: "Devon Road Surgery",
    formattedName: "Devon Road Surgery",
    postcode: {
      postcode: "DA4 9AB",
      ccg: "NHS Kent and Medway",
      ccgId: "91Q",
      latitude: 51.403711,
      longitude: 0.243172
    },
    ccg: {
      id: "10880436",
      name: "NHS Kent And Medway CCG",
      code: "91Q"
    },
    eligibleIAPT: IAPTIDs.VHG_KM
  }
}
