import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScript, GetNameScriptState } from "./GetNameScript"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = GetNameScriptState
export type GetNameIAPTScriptState = State

export class GetNameIAPTScript extends GetNameScript {
  readonly name: string = "GetNameIAPTScript"

  /** Script Steps */

  @step
  step1(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const body = directReferral
      ? "I'm now going to create a patient record for you"
      : "Ok, I'm just going to create a patient record for you"
    const prompt = directReferral
      ? ({
          type: "inlinePicker",
          choices: [{ body: "Okay" }]
        } as IInlinePickerSingleSelectPrompt)
      : undefined
    return { body, prompt, nextStep: this.askFullName }
  }
}

export default class GetNameIAPTDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameIAPT
  readonly name: string = "GetNameIAPTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameIAPTDialogue.id, new GetNameIAPTScript(), state, snapshot)
  }
}
