import { DiscussionSteps } from "../../models/Constants"
import { DialogueIDs } from "../../conversation/DialogueIDs"

// 👇 this just means partially partial - aka we want some steps to always exist
interface IDialoguesMap extends Partial<Record<DiscussionSteps, DialogueIDs>> {
  [DiscussionSteps.SelfReferral]: DialogueIDs
  [DiscussionSteps.Assessment]: DialogueIDs
  [DiscussionSteps.RiskPathway]: DialogueIDs
  [DiscussionSteps.Crisis]: DialogueIDs
  [DiscussionSteps.Goodbye]: DialogueIDs
}

const defaultMap: Partial<IDialoguesMap> = {
  [DiscussionSteps.DateOfBirth]: DialogueIDs.DateOfBirth,
  [DiscussionSteps.EmailAddress]: DialogueIDs.EmailAddress,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionIAPT,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameIAPT,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckIAPT,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsIAPT,
  [DiscussionSteps.SelfReferralPitch]: DialogueIDs.SelfReferralPitchIAPT,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchIAPT,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsIAPT,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostIAPT,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpost
}

export const dialoguesMap_Demo: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckDemo,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDemo,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentDemo,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayDemo,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeDemo,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisDemo
}

export const dialoguesMap_DemoPrivateProviders: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionDemoPrivateProviders,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameDemoPrivateProviders,
  [DiscussionSteps.PeaceOfMind]: DialogueIDs.PeaceOfMindDemoPrivateProviders,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsDemoPrivateProviders,
  [DiscussionSteps.SelfReferralPitch]: DialogueIDs.SelfReferralPitchDemoPrivateProviders,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDemoPrivateProviders,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchDemoPrivateProviders,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentDemoPrivateProviders,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsDemoPrivateProviders,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayDemoPrivateProviders,
  [DiscussionSteps.WellbeingHub]: DialogueIDs.WellbeingDemoPrivateProviders,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeDemoPrivateProviders,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisDemoPrivateProviders
}

export const dialogueMap_HEALIX: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionHealix,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameHealix,
  [DiscussionSteps.PeaceOfMind]: DialogueIDs.PeaceOfMindHealix,
  [DiscussionSteps.SelfReferralPitch]: DialogueIDs.SelfReferralPitchHealix,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralHealix,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchHealix,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentHealix,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsHealix,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayHealix,
  [DiscussionSteps.WellbeingHub]: DialogueIDs.WellbeingHealix,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeHealix,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisHealix
}

export const dialogueMap_VITALITY: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionVitality,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameVitality,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckVitality,
  [DiscussionSteps.PeaceOfMind]: DialogueIDs.PeaceOfMindVitality,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsVitality,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchVitality,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralVitality,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentVitality,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9Vitality,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayVitality,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsVitality,
  [DiscussionSteps.BookAppointment]: DialogueIDs.BookAppointmentVitality,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeVitality,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisVitality
}

export const dialoguesMap_KM_CCG: IDialoguesMap = {
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionCCG,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameCCG,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckCCG,
  [DiscussionSteps.PeaceOfMind]: DialogueIDs.PeaceOfMindCCG,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsCCG,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchCCG,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentCCG,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayCCG,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsCCG,
  [DiscussionSteps.BookMentalHealthProfessional]: DialogueIDs.BookMentalHealthProfessional,
  [DiscussionSteps.WellbeingHub]: DialogueIDs.WellBeingCCG,
  [DiscussionSteps.SelfReferralPitch]: DialogueIDs.SelfReferralPitchCCG,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralCCG,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeCCG,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisCCG
}

export const dialoguesMap_TRENT_PTS: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameTrentPTS,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckTrentPTS,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralTrentPTS,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchTrentPTS,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentTrentPTS,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9TrentPTS,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayTrentPTS,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsTrentPTS,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeTrentPTS,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisTrentPTS
}

export const dialoguesMap_TRENT_PTSDemo: IDialoguesMap = {
  ...dialoguesMap_TRENT_PTS,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckTrentPTSDemo
}

export const dialoguesMap_NOTTINGHAMSHIRE: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameNottinghamshire,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckNottinghamshire,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralNottinghamshire,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchNottinghamshire,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentNottinghamshire,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9Nottinghamshire,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayNottinghamshire,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeNottinghamshire,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisNottinghamshire
}

export const dialoguesMap_INSIGHT: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameInsight,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckInsight,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsInsight,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralInsight,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchInsight,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentInsight,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsInsight,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayInsight,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeInsight,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisInsight
}

export const dialoguesMap_LINCOLNSHIRE: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameLincolnshire,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckLincolnshire,
  [DiscussionSteps.UnderageSignpost]: DialogueIDs.UnderageSignpostLincolnshire,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralLincolnshire,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchLincolnshire,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentLincolnshire,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayLincolnshire,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisLincolnshire,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeLincolnshire
}

export const dialoguesMap_SABP: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.Permissions]: DialogueIDs.PermissionsSABP,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameSABP,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckSABP,
  [DiscussionSteps.SuitableServicesSignpost]: DialogueIDs.SuitableServicesSignpostSABP,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralSABP,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchSABP,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentSABP,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9SABP,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsSABP,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwaySABP,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeSABP,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisSABP
}

export const dialoguesMap_VHG: IDialoguesMap = {
  ...defaultMap,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameVHG,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckVHG,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralVHG,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchVHG,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentVHG,
  [DiscussionSteps.PHQ9]: DialogueIDs.PHQ9VHG,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsVHG,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayVHG,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeVHG,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisVHG
}

export const dialoguesMapDRS: IDialoguesMap = {
  ...dialoguesMap_VHG,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionGP,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckGP,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchGP
}

export const dialoguesMapINSIGHT_WAITLIST: Partial<Record<DiscussionSteps, DialogueIDs>> = {
  ...dialoguesMap_INSIGHT,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionInsightWaitlist,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameInsightWaitlist,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralInsightWaitlist
}

export const dialoguesMapINSIGHT_FOLLOWUP: Partial<Record<DiscussionSteps, DialogueIDs>> = {
  ...dialoguesMap_INSIGHT,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionInsightFollowUp,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameInsightFollowUp,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralInsightWaitlist,
  [DiscussionSteps.AssessmentPitch]: DialogueIDs.AssessmentPitchInsightFollowUp,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayInsightFollowUp,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeInsightFollowUp
}

// this is on purpose like this because it's got a very simple flow
// that branches off in the self referral pitch
export const dialoguesMap_Demo_Conference: any = {
  ...dialoguesMap_Demo,
  [DiscussionSteps.Intro]: DialogueIDs.IntroductionDemoConference,
  [DiscussionSteps.GetName]: DialogueIDs.GetNameDemoConference,
  [DiscussionSteps.SelfReferralPitch]: DialogueIDs.SelfReferralPitchDemoConference,
  [DiscussionSteps.Eligibility]: DialogueIDs.EligibilityCheckDemoConference,
  [DiscussionSteps.SelfReferral]: DialogueIDs.SelfReferralDemoConference,
  [DiscussionSteps.Assessment]: DialogueIDs.AssessmentDemoConference,
  [DiscussionSteps.RiskPathway]: DialogueIDs.RiskPathwayDemo,
  [DiscussionSteps.TreatmentOptions]: DialogueIDs.TreatmentOptionsIAPT,
  [DiscussionSteps.Crisis]: DialogueIDs.CrisisDemo,
  [DiscussionSteps.Goodbye]: DialogueIDs.GoodbyeDemo
}
