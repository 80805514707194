import IAppLaunchConfig, { IAppLaunchConfigOptions } from "../models/IAppLaunchConfig"
import { gpServices } from "./gpServices"
import { clinicalPaths, complexComorbidPath, undeterminedPath } from "./clinical/clinicalPaths"
import {
  stepsOrder,
  stepsOrder_KM_CCG,
  stepsOrder_HEALIX,
  stepsOrder_DEMO_PRIVATE_PROVIDERS,
  stepsOrder_Insight_Waitlist,
  stepsOrder_DEMO_CONFERENCE,
  stepsOrder_VITALITY
} from "./discussion/stepsOrder"
import {
  dialogueMap_HEALIX,
  dialoguesMap_Demo,
  dialoguesMap_DemoPrivateProviders,
  dialoguesMap_INSIGHT,
  dialoguesMap_LINCOLNSHIRE,
  dialoguesMap_KM_CCG,
  dialoguesMap_NOTTINGHAMSHIRE,
  dialoguesMap_SABP,
  dialoguesMap_TRENT_PTS,
  dialoguesMap_VHG,
  dialoguesMapDRS,
  dialoguesMapINSIGHT_WAITLIST,
  dialoguesMapINSIGHT_FOLLOWUP,
  dialoguesMap_TRENT_PTSDemo,
  dialoguesMap_Demo_Conference,
  dialogueMap_VITALITY
} from "./discussion/dialoguesMap"
import { ccgServices } from "./ccgServices"
import { mergeOverrides } from "./clinical/mergeOverrides"
import {
  clinicalPathsTrent,
  clinicalPathsInsight,
  clinicalPathsLincolnshire,
  clinicalPathsNottinghamshire
} from "./clinical/overrides"
import { clinicalPathsVitaminds } from "./clinical/overrides/vitaminds"
import { clinicalPathsSABP } from "./clinical/overrides/sabp"
import { CCGCode } from "../models/ICCG"
import { IAPTIDs } from "../models/IIAPTService"
import { iaptGPMapInsight, iaptGPMapInsightDEMO } from "./iaptMaps/insight"
import { iaptGPMapLincolnshire } from "./iaptMaps/lincolnshire"
import { iaptGPMapVHG } from "./iaptMaps/vhg"
import { iaptGPMapSABP } from "./iaptMaps/sabp"
import {
  insightCustomClinicalPaths,
  lincolnshireCustomClinicalPaths,
  trentCustomClinicalPaths
} from "./clinical/overrides/custom"

const demoPhoneNumbers =
  "Demo Clinic London: +44 1234 567890\n" +
  "Demo Clinic Kent: +44 1234 567890\n" +
  "Demo Clinic Calderdale: +44 1234 567890"

const vhgPhoneNumbers =
  "NHS Mental Health (Vita Minds)\n\n" +
  "NHS Calderdale: +44 3330 153494\n" +
  "NHS Basildon and Brentwood: +44 1268 977171\n" +
  "NHS Bristol, North Somerset and South Gloucestershire: +44 3332 001893\n" +
  "NHS Derby and Derbyshire: +44 3330 153496\n" +
  "NHS Kent and Medway: +44 3330 153495\n" +
  "NHS Leicester, Leicestershire & Rutland: +44 3300 945595\n" +
  "NHS West Essex – Harlow, Uttlesford & Epping Forest: +44 2220 152966"

const vhgTerms =
  "VitaMinds [Terms of Service](https://www.vitahealthgroup.co.uk/terms-and-conditions/)\n" +
  "VitaMinds [Data Protection Policy Notice](https://www.vitahealthgroup.co.uk/data-protection-policy/)\n" +
  "VitaMinds [Website Privacy Notice](https://www.vitahealthgroup.co.uk/our-policies-and-procedures/privacy-notice/)"

const insightPhoneNumbers =
  "NHS Mental Health (Insight IAPT Talking Therapies)\n\n" +
  "Insight IAPT Talking Therapies (Kent): +44 3005 555555\n" +
  "Peterborough Talking Therapies: +44 3005 550888\n" +
  "Nottinghamshire Talking Therapies: +44 3005 555582\n" +
  "Insight IAPT Talking Therapies (Bassetlaw): +44 3003 000033\n" +
  "Derbyshire Talking Therapies: +44 3005 555582\n" +
  "Medway Talking Therapies: +44 3000 293000\n" +
  "Talking Together Wirral: +44 1516 491859"

const insightCrisisPhoneNumbers =
  "Local NHS Crisis Contacts\n\n" +
  "Bassetlaw: +44 8081 963779\n" +
  "Derby and Derbyshire: +44 8000 280077\n" +
  "Kend and Medway (Adults): +44 8007 839111\n" +
  "Kend and Medway (Under 18s): +44 8000 113474\n" +
  "Nottingham and Nottinghamshire: +44 8081 963779\n" +
  "Peterborough: 111 & press option 2 for the first response service\n" +
  "Wirral: +44 8001 456485"

const insightTerms = "Insight IAPT [Privacy Policy](https://www.insightiapt.org/privacy-policy/)"

const lincolnshirePhoneNumbers =
  "NHS Mental Health (Lincolnshire)\n\n" +
  "Steps2Change (Boston): +44 0303 123 4000\n" +
  "Steps2Change (Gainsborough): +44 0303 123 4000\n" +
  "Steps2Change (Grantham): +44 0303 123 4000\n" +
  "Steps2Change (Lincoln): +44 0303 123 4000\n" +
  "Steps2Change (Louth): +44 0303 123 4000\n" +
  "Steps2Change (Skegness): +44 0303 123 4000\n" +
  "Steps2Change (Sleaford): +44 0303 123 4000\n" +
  "Steps2Change (Spalding): +44 0303 123 4000\n" +
  "Steps2Change (Stamford): +44 0303 123 4000"

const lincolnshireCrisisPhoneNumbers =
  "Local NHS Crisis Contacts\n\n" + "Lincolnshire Mental Health Helpline: 0800 001 4331" // eslint-disable-line

const lincolnshireTerms =
  "Steps2Change [Terms of Service](https://www.lpft.nhs.uk/terms-use)\n" +
  "Steps2Change [Website Privacy Notice](https://www.lpft.nhs.uk/privacy-policy)"

const kentMedwayPhoneNumbers = ""
const kentMedwayTerms = ""
const kentMedwayWellbeingHubEMails =
  process.env.REACT_APP_BACKEND_ENV === "production" && process.env.REACT_APP_WELLBEINGHUB_EMAILS
    ? process.env.REACT_APP_WELLBEINGHUB_EMAILS?.split(" ")
    : ["john+wellbeinghub@limbic.ai", "ross+wellbeinghub@limbic.ai"]

const nottinghamshirePhoneNumbers = "Let's Talk Wellbeing\n\nNottinghamshire: +44 3003 002200"
const nottinghamshireTerms =
  "Let's Talk Wellbeing [Privacy Policy](https://www.nottinghamshirehealthcare.nhs.uk/privacy-policy)"

const trentPhoneNumbers =
  "NHS Mental Health (Trent PTS)\n\n" +
  "Trent PTS - Ropewalk: +44 1158 965950\n" +
  "Trent PTS - Woodlands Lodge: +44 1332 265659\n" +
  "Trent PTS - Saltergate: +44 1246 385752"
const trentTerms = "Trent PTS [Privacy Policy](https://www.trentpts.co.uk/privacy-statement)"

const sabpPhoneNumbers = ""
const sabpPhoneTerms =
  "Mind Matters [Terms of Service](https://www.sabp.nhs.uk/mind-matters/mind-matters-terms-and-conditions)"

export const apiKeysMap: Record<IAppLaunchConfig["API_KEY"], IAppLaunchConfigOptions> = {
  DEMO: {
    title: "Limbic Self Referral Assistant",
    serviceName: "Demo Service",
    organisationName: "Demo Organisation",
    organisationTerms: "",
    organisationPhoneNumbers: demoPhoneNumbers,
    organisationGenericPhoneNumber: "0123 4567 890",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [IAPTIDs.DEMO],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_Demo,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-keeping-safe-logo.png",
    keepingSafeTitle: "Demo"
  },
  DEMO_PRIVATE_PROVIDERS: {
    backendInstanceID: "DEMO_MAIN",
    title: "Limbic Self Referral Assistant",
    serviceName: "",
    organisationName: "Private Therapy Provider",
    organisationTerms: "",
    organisationPhoneNumbers: "0123 4567 890",
    organisationGenericPhoneNumber: "0123 4567 890",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_DEMO_PRIVATE_PROVIDERS,
    dialoguesMap: dialoguesMap_DemoPrivateProviders,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-keeping-safe-logo.png",
    keepingSafeTitle: "Demo"
  },
  VITAMINDS: {
    title: "VitaMinds Referral Assistant",
    serviceName: "VitaMinds",
    organisationName: "VitaMinds",
    organisationTerms: vhgTerms,
    organisationPhoneNumbers: vhgPhoneNumbers,
    organisationGenericPhoneNumber: "0333 0153 494",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-logo.png?1",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [
      IAPTIDs.VHG_DERBY,
      IAPTIDs.VHG_KM,
      IAPTIDs.VHG_BB,
      IAPTIDs.VHG_CALDERDALE,
      IAPTIDs.VHG_BNSSG,
      IAPTIDs.VHG_LLR,
      IAPTIDs.VHG_WEST_ESSEX
    ],
    iaptCCGMap: {
      [CCGCode.DERBY_DERBYSHIRE]: IAPTIDs.VHG_DERBY,
      [CCGCode.BASILDON_BRENTWOOD]: IAPTIDs.VHG_BB,
      [CCGCode.CALDERDALE]: IAPTIDs.VHG_CALDERDALE,
      [CCGCode.BNSSG]: IAPTIDs.VHG_BNSSG,
      [CCGCode.LEICESTER_CITY]: IAPTIDs.VHG_LLR,
      [CCGCode.E_LEICESTERSHIRE_RUTLAND]: IAPTIDs.VHG_LLR,
      [CCGCode.W_LEICESTERSHIRE]: IAPTIDs.VHG_LLR,
      [CCGCode.W_ESSEX]: IAPTIDs.VHG_WEST_ESSEX
    },
    iaptGPMap: iaptGPMapVHG,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_VHG,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsVitaminds),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsVitaminds)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsVitaminds)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-keeping-safe-logo.png",
    keepingSafeTitle: "Vita Health",
    freetextLimit: 500
  },
  VITAMINDS_FULLSCREEN: {
    title: "VitaMinds Referral Assistant",
    serviceName: "VitaMinds",
    organisationName: "VitaMinds",
    organisationTerms: vhgTerms,
    organisationPhoneNumbers: vhgPhoneNumbers,
    organisationGenericPhoneNumber: "0333 0153 494",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-logo.png?1",
    userMessageBackground: "#00A7CF",
    fullscreen: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/nhs_logos.png"],
    faqLink: "https://limbic.ai/faq",
    directReferral: true,
    eligibleIAPTIds: [
      IAPTIDs.VHG_DERBY,
      IAPTIDs.VHG_KM,
      IAPTIDs.VHG_BB,
      IAPTIDs.VHG_CALDERDALE,
      IAPTIDs.VHG_BNSSG,
      IAPTIDs.VHG_LLR,
      IAPTIDs.VHG_WEST_ESSEX
    ],
    iaptCCGMap: {
      [CCGCode.DERBY_DERBYSHIRE]: IAPTIDs.VHG_DERBY,
      [CCGCode.BASILDON_BRENTWOOD]: IAPTIDs.VHG_BB,
      [CCGCode.CALDERDALE]: IAPTIDs.VHG_CALDERDALE,
      [CCGCode.BNSSG]: IAPTIDs.VHG_BNSSG,
      [CCGCode.LEICESTER_CITY]: IAPTIDs.VHG_LLR,
      [CCGCode.E_LEICESTERSHIRE_RUTLAND]: IAPTIDs.VHG_LLR,
      [CCGCode.W_LEICESTERSHIRE]: IAPTIDs.VHG_LLR,
      [CCGCode.W_ESSEX]: IAPTIDs.VHG_WEST_ESSEX
    },
    iaptGPMap: iaptGPMapVHG,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_VHG,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsVitaminds),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsVitaminds)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsVitaminds)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-keeping-safe-logo.png",
    keepingSafeTitle: "Vita Health",
    freetextLimit: 500
  },
  DEVON_ROAD_SURGERY: {
    title: "VitaMinds Referral Assistant",
    serviceName: "Devon Road Surgery",
    organisationName: "VitaMinds",
    organisationTerms: vhgTerms,
    organisationPhoneNumbers: vhgPhoneNumbers,
    organisationGenericPhoneNumber: "0333 0153 494",
    gpService: gpServices.DevonRoadSurgery,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-logo.png?1",
    userMessageBackground: "#4C4475",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [
      IAPTIDs.VHG_DERBY,
      IAPTIDs.VHG_KM,
      IAPTIDs.VHG_BB,
      IAPTIDs.VHG_CALDERDALE,
      IAPTIDs.VHG_BNSSG,
      IAPTIDs.VHG_LLR
    ],
    iaptCCGMap: {
      [CCGCode.DERBY_DERBYSHIRE]: IAPTIDs.VHG_DERBY,
      [CCGCode.BASILDON_BRENTWOOD]: IAPTIDs.VHG_BB,
      [CCGCode.CALDERDALE]: IAPTIDs.VHG_CALDERDALE,
      [CCGCode.BNSSG]: IAPTIDs.VHG_BNSSG,
      [CCGCode.LEICESTER_CITY]: IAPTIDs.VHG_LLR,
      [CCGCode.E_LEICESTERSHIRE_RUTLAND]: IAPTIDs.VHG_LLR,
      [CCGCode.W_LEICESTERSHIRE]: IAPTIDs.VHG_LLR
    },
    iaptGPMap: iaptGPMapVHG,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMapDRS,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath,
    undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-keeping-safe-logo.png",
    keepingSafeTitle: "Vita Health",
    freetextLimit: 500
  },
  INSIGHT_TALKING_THERAPIES: {
    title: "Insight Referral Assistant",
    serviceName: "Insight IAPT",
    organisationName: "Insight IAPT",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/insight-logo.png",
    userMessageBackground: "#dc186d",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.INSIGHT_BASSETLAW,
      IAPTIDs.INSIGHT_NOTTS,
      IAPTIDs.INSIGHT_MEDWAY,
      IAPTIDs.INSIGHT_PETTERSBOROUGH,
      IAPTIDs.INSIGHT_WIRRAL,
      IAPTIDs.INSIGHT_KENT,
      IAPTIDs.INSIGHT_DERBY
    ],
    iaptCCGMap: {
      [CCGCode.BASSETLAW]: IAPTIDs.INSIGHT_BASSETLAW,
      [CCGCode.NOTTINGHAM_NOTTINGHAMSHIRE]: IAPTIDs.INSIGHT_NOTTS,
      [CCGCode.CAMBRIDGESHIRE_PETERBOROUGH]: IAPTIDs.INSIGHT_PETTERSBOROUGH,
      [CCGCode.WIRRAL]: IAPTIDs.INSIGHT_WIRRAL,
      [CCGCode.DERBY_DERBYSHIRE]: IAPTIDs.INSIGHT_DERBY
    },
    iaptGPMap: iaptGPMapInsight,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_INSIGHT,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/logo-keeping-safe.png",
    keepingSafeTitle: "Keeping yourself safe - Insight IAPT"
  },
  INSIGHT_TALKING_THERAPIES_FULLSCREEN: {
    title: "Insight Referral Assistant",
    serviceName: "Insight IAPT",
    organisationName: "Insight IAPT",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/insight-logo.png",
    userMessageBackground: "#dc186d",
    fullscreen: true,
    formLink: "https://www.insightiapt.org/self-refer/",
    directReferral: true,
    iaptIcons: [],
    eligibleIAPTIds: [
      IAPTIDs.INSIGHT_BASSETLAW,
      IAPTIDs.INSIGHT_NOTTS,
      IAPTIDs.INSIGHT_MEDWAY,
      IAPTIDs.INSIGHT_PETTERSBOROUGH,
      IAPTIDs.INSIGHT_WIRRAL,
      IAPTIDs.INSIGHT_KENT,
      IAPTIDs.INSIGHT_DERBY
    ],
    iaptCCGMap: {
      [CCGCode.BASSETLAW]: IAPTIDs.INSIGHT_BASSETLAW,
      [CCGCode.NOTTINGHAM_NOTTINGHAMSHIRE]: IAPTIDs.INSIGHT_NOTTS,
      [CCGCode.CAMBRIDGESHIRE_PETERBOROUGH]: IAPTIDs.INSIGHT_PETTERSBOROUGH,
      [CCGCode.WIRRAL]: IAPTIDs.INSIGHT_WIRRAL,
      [CCGCode.DERBY_DERBYSHIRE]: IAPTIDs.INSIGHT_DERBY
    },
    iaptGPMap: iaptGPMapInsight,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_INSIGHT,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/logo-keeping-safe.png",
    keepingSafeTitle: "Keeping yourself safe - Insight IAPT"
  },
  INSIGHT_WAITLIST: {
    title: "Insight Referral Assistant",
    serviceName: "Insight IAPT",
    backendInstanceID: "INSIGHT_WAITLIST",
    organisationName: "Insight IAPT",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/insight-logo.png",
    userMessageBackground: "#dc186d",
    fullscreen: false,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_Insight_Waitlist,
    dialoguesMap: dialoguesMapINSIGHT_WAITLIST,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/logo-keeping-safe.png",
    keepingSafeTitle: "Keeping yourself safe - Insight IAPT"
  },
  INSIGHT_FOLLOWUP: {
    title: "Insight Referral Assistant",
    serviceName: "Insight IAPT",
    backendInstanceID: "INSIGHT_FOLLOWUP",
    organisationName: "Insight IAPT",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/insight-logo.png",
    userMessageBackground: "#dc186d",
    fullscreen: false,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_Insight_Waitlist,
    dialoguesMap: dialoguesMapINSIGHT_FOLLOWUP,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/logo-keeping-safe.png",
    keepingSafeTitle: "Keeping yourself safe - Insight IAPT"
  },
  INSIGHT_TALKING_THERAPIES_DEMO: {
    title: "Insight Referral Assistant",
    serviceName: "Insight IAPT",
    organisationName: "Insight IAPT",
    backendInstanceID: "INSIGHT_E2E_DEMO",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/insight-logo.png",
    userMessageBackground: "#dc186d",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.INSIGHT_BASSETLAW_DEMO,
      IAPTIDs.INSIGHT_NOTTS_DEMO,
      IAPTIDs.INSIGHT_MEDWAY_DEMO,
      IAPTIDs.INSIGHT_PETTERSBOROUGH_DEMO,
      IAPTIDs.INSIGHT_WIRRAL_DEMO,
      IAPTIDs.INSIGHT_KENT_DEMO,
      IAPTIDs.INSIGHT_DERBY_DEMO
    ],
    iaptCCGMap: {
      [CCGCode.BASSETLAW]: IAPTIDs.INSIGHT_BASSETLAW_DEMO,
      [CCGCode.NOTTINGHAM_NOTTINGHAMSHIRE]: IAPTIDs.INSIGHT_NOTTS_DEMO,
      [CCGCode.CAMBRIDGESHIRE_PETERBOROUGH]: IAPTIDs.INSIGHT_PETTERSBOROUGH_DEMO,
      [CCGCode.WIRRAL]: IAPTIDs.INSIGHT_WIRRAL_DEMO,
      [CCGCode.DERBY_DERBYSHIRE]: IAPTIDs.INSIGHT_DERBY_DEMO
    },
    iaptGPMap: iaptGPMapInsightDEMO,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_INSIGHT,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/logo-keeping-safe.png",
    keepingSafeTitle: "Keeping yourself safe - Insight IAPT"
  },
  LINCOLNSHIRE: {
    backendInstanceID: "LINCS_MAIN",
    title: "Steps2Change Referral Assistant",
    serviceName: "Steps2Change",
    organisationName: "Steps2Change",
    organisationTerms: lincolnshireTerms,
    organisationPhoneNumbers: lincolnshirePhoneNumbers,
    crisisPhoneNumbers: lincolnshireCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0303 123 4000",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#01AEF0",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
      IAPTIDs.STEPS2CHANGE_LOUTH,
      IAPTIDs.STEPS2CHANGE_LINCOLN,
      IAPTIDs.STEPS2CHANGE_SKEGNESS,
      IAPTIDs.STEPS2CHANGE_SLEAFORD,
      IAPTIDs.STEPS2CHANGE_BOSTON,
      IAPTIDs.STEPS2CHANGE_GRANTHAM,
      IAPTIDs.STEPS2CHANGE_SPALDING,
      IAPTIDs.STEPS2CHANGE_STAMFORD
    ],
    iaptGPMap: iaptGPMapLincolnshire,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_LINCOLNSHIRE,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsLincolnshire),
    customClinicalPaths: lincolnshireCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsLincolnshire)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsLincolnshire)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/lincolnshire/logo.png",
    keepingSafeTitle: "Lincolnshire IAPT",
    freetextLimit: 500
  },
  LINCOLNSHIRE_FULLSCREEN: {
    backendInstanceID: "LINCS_MAIN",
    title: "Steps2Change Referral Assistant",
    serviceName: "Steps2Change",
    organisationName: "Steps2Change",
    organisationTerms: lincolnshireTerms,
    organisationPhoneNumbers: lincolnshirePhoneNumbers,
    crisisPhoneNumbers: lincolnshireCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0303 123 4000",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#01AEF0",
    fullscreen: true,
    formLink: "https://gateway.mayden.co.uk/referral-v2/c2ce2fcb-3586-4e64-8405-deac6ce440b1",
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/lincolnshire/logo.png"],
    eligibleIAPTIds: [
      IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
      IAPTIDs.STEPS2CHANGE_LOUTH,
      IAPTIDs.STEPS2CHANGE_LINCOLN,
      IAPTIDs.STEPS2CHANGE_SKEGNESS,
      IAPTIDs.STEPS2CHANGE_SLEAFORD,
      IAPTIDs.STEPS2CHANGE_BOSTON,
      IAPTIDs.STEPS2CHANGE_GRANTHAM,
      IAPTIDs.STEPS2CHANGE_SPALDING,
      IAPTIDs.STEPS2CHANGE_STAMFORD
    ],
    iaptGPMap: iaptGPMapLincolnshire,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_LINCOLNSHIRE,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsLincolnshire),
    customClinicalPaths: lincolnshireCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsLincolnshire)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsLincolnshire)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/lincolnshire/logo.png",
    keepingSafeTitle: "Lincolnshire IAPT",
    freetextLimit: 500
  },
  KENT_MEDWAY_CCG: {
    title: "Limbic Wellbeing Assistant",
    serviceName: "NHS talk therapy support",
    organisationName: "Kent & Medway Staff Wellbeing Hub",
    organisationTerms: kentMedwayTerms,
    organisationPhoneNumbers: kentMedwayPhoneNumbers,
    organisationGenericPhoneNumber: "1634 917110",
    ccgService: ccgServices.KentMedway,
    skipOnlineReferral: true,
    wellbeingHubEmails: kentMedwayWellbeingHubEMails,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.DOVER_KM,
      IAPTIDs.FAVERSHAM_KM,
      IAPTIDs.INSIGHT_KENT,
      IAPTIDs.WITH_YOU_KM
    ],
    discussionStepsOrder: stepsOrder_KM_CCG,
    dialoguesMap: dialoguesMap_KM_CCG,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath,
    undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/kmpt/kmpt-keeping-safe-logo.jpg",
    keepingSafeTitle: "Kent & Medway Staff Wellbeing Hub"
  },
  KENT_MEDWAY_CCG_FULLSCREEN: {
    title: "Limbic Wellbeing Assistant",
    serviceName: "NHS talk therapy support",
    organisationName: "Kent & Medway Staff Wellbeing Hub",
    organisationTerms: kentMedwayTerms,
    organisationPhoneNumbers: kentMedwayPhoneNumbers,
    organisationGenericPhoneNumber: "1634 917110",
    ccgService: ccgServices.KentMedway,
    wellbeingHubEmails: kentMedwayWellbeingHubEMails,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: true,
    directReferral: true,
    iaptIcons: [],
    eligibleIAPTIds: [
      IAPTIDs.DOVER_KM,
      IAPTIDs.FAVERSHAM_KM,
      IAPTIDs.INSIGHT_KENT,
      IAPTIDs.WITH_YOU_KM
    ],
    discussionStepsOrder: stepsOrder_KM_CCG,
    dialoguesMap: dialoguesMap_KM_CCG,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath,
    undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/kmpt/kmpt-keeping-safe-logo.jpg",
    keepingSafeTitle: "Kent & Medway Staff Wellbeing Hub"
  },
  NOTTINGHAMSHIRE: {
    title: "Limbic Referral Assistant",
    serviceName: "Let's Talk Wellbeing",
    organisationName: "Let's Talk Wellbeing",
    organisationTerms: nottinghamshireTerms,
    organisationPhoneNumbers: nottinghamshirePhoneNumbers,
    organisationGenericPhoneNumber: "01332 265659",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.NOTTINGHAMSHIRE_NOTTS, IAPTIDs.NOTTINGHAMSHIRE_NOTTS_CC],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_NOTTINGHAMSHIRE,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsNottinghamshire),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsNottinghamshire)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsNottinghamshire)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/nottinghamshire/notts-logo.png",
    keepingSafeTitle: "Keeping Yourself Safe: Let's Talk - Wellbeing",
    freetextLimit: 500
  },
  NOTTINGHAMSHIRE_FULLSCREEN: {
    title: "Limbic Referral Assistant",
    serviceName: "Let's Talk Wellbeing",
    organisationName: "Let's Talk Wellbeing",
    organisationTerms: nottinghamshireTerms,
    organisationPhoneNumbers: nottinghamshirePhoneNumbers,
    organisationGenericPhoneNumber: "01332 265659",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: true,
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/nottinghamshire/notts-logo.png"],
    eligibleIAPTIds: [IAPTIDs.NOTTINGHAMSHIRE_NOTTS, IAPTIDs.NOTTINGHAMSHIRE_NOTTS_CC],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_NOTTINGHAMSHIRE,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsNottinghamshire),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsNottinghamshire)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsNottinghamshire)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/nottinghamshire/notts-logo.png",
    keepingSafeTitle: "Keeping Yourself Safe: Let's Talk - Wellbeing",
    freetextLimit: 500
  },
  TRENT_PTS: {
    title: "Limbic Referral Assistant",
    serviceName: "Trent PTS",
    organisationName: "Trent PTS",
    organisationTerms: trentTerms,
    organisationPhoneNumbers: trentPhoneNumbers,
    organisationGenericPhoneNumber: "01332 265659",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.TRENT_NOTTS,
      IAPTIDs.TRENT_DERBY_WOODLANDS,
      IAPTIDs.TRENT_DERBY_SALTERGATE
    ],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_TRENT_PTS,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsTrent),
    customClinicalPaths: trentCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsTrent)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsTrent)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent-logo.png",
    keepingSafeTitle: "Trent PTS"
  },
  TRENT_PTS_FULLSCREEN: {
    title: "Limbic Referral Assistant",
    serviceName: "Trent PTS",
    organisationName: "Trent PTS",
    organisationTerms: trentTerms,
    organisationPhoneNumbers: trentPhoneNumbers,
    organisationGenericPhoneNumber: "01332 265659",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent.png",
    userMessageBackground: "#00A7CF",
    fullscreen: true,
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/nhs_logos-trent.png"],
    eligibleIAPTIds: [
      IAPTIDs.TRENT_NOTTS,
      IAPTIDs.TRENT_DERBY_WOODLANDS,
      IAPTIDs.TRENT_DERBY_SALTERGATE
    ],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_TRENT_PTS,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsTrent),
    customClinicalPaths: trentCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsTrent)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsTrent)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent-logo.png",
    keepingSafeTitle: "Trent PTS"
  },
  TRENT_PTS_DEMO: {
    title: "Limbic Referral Assistant",
    serviceName: "Trent PTS",
    organisationName: "Trent PTS",
    backendInstanceID: "TRENT_E2E_DEMO",
    organisationTerms: trentTerms,
    organisationPhoneNumbers: trentPhoneNumbers,
    organisationGenericPhoneNumber: "01332 265659",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.TRENT_NOTTS_DEMO,
      IAPTIDs.TRENT_DERBY_WOODLANDS_DEMO,
      IAPTIDs.TRENT_DERBY_SALTERGATE_DEMO
    ],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_TRENT_PTSDemo,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsTrent),
    customClinicalPaths: trentCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsTrent)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsTrent)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent-logo.png",
    keepingSafeTitle: "Trent PTS"
  },
  SABP: {
    title: "Limbic Referral Assistant",
    serviceName: "Mind Matters Surrey NHS",
    organisationName: "Mind Matters Surrey NHS",
    organisationTerms: sabpPhoneTerms,
    organisationPhoneNumbers: sabpPhoneNumbers,
    organisationGenericPhoneNumber: "0300 330 5450",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#6A6983",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.MIND_MATTERS_SAB],
    iaptGPMap: iaptGPMapSABP,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_SABP,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsSABP),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsSABP)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsSABP)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/sabp/mind-matters-logo.png",
    keepingSafeTitle: "Mind Matters Surrey NHS Stay safe message",
    freetextLimit: 500
  },
  SABP_FULLSCREEN: {
    title: "Limbic Referral Assistant",
    serviceName: "Mind Matters",
    organisationName: "Mind Matters",
    organisationTerms: sabpPhoneTerms,
    organisationPhoneNumbers: sabpPhoneNumbers,
    organisationGenericPhoneNumber: "0300 330 5450",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#6A6983",
    fullscreen: true,
    formLink: "https://gateway.mayden.co.uk/referral-v2/cb5a6c97-0eea-48eb-9d31-aa0621081ada",
    directReferral: true,
    iaptIcons: [],
    eligibleIAPTIds: [IAPTIDs.MIND_MATTERS_SAB],
    iaptGPMap: iaptGPMapSABP,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_SABP,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsSABP),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsSABP)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsSABP)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/sabp/mind-matters-logo.png",
    keepingSafeTitle: "Mind Matters Surrey",
    freetextLimit: 500
  },
  HEALIX: {
    backendInstanceID: "HEALIX_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "Mind Right service",
    organisationName: undefined,
    organisationTerms: "",
    organisationPhoneNumbers: "0330 094 8733",
    organisationGenericPhoneNumber: "0330 094 8733",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_HEALIX,
    dialoguesMap: dialogueMap_HEALIX,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-keeping-safe-logo.png",
    keepingSafeTitle: "Healix"
  },
  CONFERENCE_DEMO: {
    title: "Limbic Self Referral Assistant",
    serviceName: "Demo Service",
    organisationName: "Demo Organisation",
    organisationTerms: "",
    organisationPhoneNumbers: demoPhoneNumbers,
    organisationGenericPhoneNumber: "0123 4567 890",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [IAPTIDs.DEMO],
    discussionStepsOrder: stepsOrder_DEMO_CONFERENCE,
    dialoguesMap: dialoguesMap_Demo_Conference,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo-small.png",
    keepingSafeTitle: "Demo"
  },
  VITALITY: {
    backendInstanceID: "VITALITY_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "IPRS Health service",
    organisationName: "IPRS Health",
    organisationTerms: "",
    organisationPhoneNumbers: "0800 254 5244",
    organisationGenericPhoneNumber: "0800 254 5244",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/logo-lb.png",
    userMessageBackground: "#6a6983",
    fullscreen: true,
    iaptIcons: [
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/keeping-safe-logo.png",
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/limbic-logo.png"
    ],
    enableParams: true,
    directReferral: true,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_VITALITY,
    dialoguesMap: dialogueMap_VITALITY,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/logo-leaflet.png",
    keepingSafeTitle: "IPRS Health",
    enableSOS: true
  }
}
