import moment from "moment"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import invariant from "../../../utils/invariant"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"

type State = BaseScriptState
export type DateOfBirthScriptState = State

export class DateOfBirthScript extends BaseScript<State> {
  readonly name: string = "DateOfBirthScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.askBirthday }
  }

  @step.logState
  askBirthday(_d: IStepData<State>): IStepResult {
    return {
      body: "What's your date of birth?",
      nextStep: this.showPromptForBirthday
    }
  }

  @step.logState
  showPromptForBirthday(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForBirthday"),
        trackResponse: true,
        type: "date"
      },
      nextStep: this.handleBirthday
    }
  }

  @step
  handleBirthday(d: IStepData<State, number>): IStepResult {
    try {
      const date = moment(d.response)
      invariant(date, "I'm sorry that's not a valid date. Please enter your date of birth")
      invariant(
        date.isValid(),
        "I'm sorry that's not a valid date. Please enter your date of birth"
      )
      invariant(
        date.isBefore(moment()),
        "Hmm… I don’t think humans can time-travel. Can you try and edit your date of birth?"
      )
      invariant(
        date.isAfter(moment("1899-12-31")),
        "Hmm… I don’t think humans live that long. Can you try and edit your date of birth?"
      )
      d.state.birthday = date.toDate().getTime()
      this.setPeople({ age: moment().diff(date, "years") })
    } catch (e) {
      this.logException(e, "handleBirthday")
      return {
        body: e.message,
        nextStep: this.showPromptForBirthday
      }
    }

    return {
      body: "Thanks for sharing",
      nextStep: this.end
    }
  }
}

export default class DateOfBirthDialogue extends AdHocDialogue<State, DateOfBirthScript> {
  static id = DialogueIDs.DateOfBirth
  readonly name: string = "DateOfBirthDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(DateOfBirthDialogue.id, new DateOfBirthScript(), state, snapshot)
  }
}
