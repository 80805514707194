export enum DialogueIDs {
  MainFlow = "mainFlow",
  DateOfBirth = "dateOfBirthDialogue",
  EmailAddress = "emailAddressDialogue",
  IntroductionIAPT = "introductionIAPT",
  IntroductionGP = "introductionGP",
  IntroductionCCG = "introductionCCG",
  IntroductionHealix = "introductionHealix",
  IntroductionDemoPrivateProviders = "introductionDemoPrivateProviders",
  IntroductionInsightWaitlist = "introductionInsightWaitlist",
  IntroductionInsightFollowUp = "introductionInsightFollowUp",
  IntroductionDemoConference = "introductionDemoConference",
  IntroductionVitality = "introductionVitality",
  GetNameIAPT = "getNameIAPT",
  GetNameCCG = "getNameCCG",
  GetNameInsight = "getNameInsight",
  GetNameLincolnshire = "getNameLincolGetNameLincolnshire",
  GetNameHealix = "getNameHealix",
  GetNameNottinghamshire = "getNameNottinghamshire",
  GetNameTrentPTS = "getNameTrentPTS",
  GetNameVitality = "getNameVitality",
  GetNameDemoPrivateProviders = "getNameDemoPrivateProviders",
  GetNameInsightWaitlist = "getNameInsightWaitlist",
  GetNameInsightFollowUp = "getNameInsightFollowUp",
  GetNameDemoConference = "getNameDemoConference",
  GetNameSABP = "getNameSABP",
  GetNameVHG = "getNameVHG",
  EligibilityCheckDemo = "eligibilityCheckDemo",
  EligibilityCheckIAPT = "eligibilityCheckIAPT",
  EligibilityCheckWithPDSSpine = "eligibilityCheckWithPDSSpine",
  EligibilityCheckGP = "eligibilityCheckGP",
  EligibilityCheckCCG = "eligibilityCheckCCG",
  EligibilityCheckTrentPTS = "eligibilityCheckTrentPTS",
  EligibilityCheckTrentPTSDemo = "eligibilityCheckTrentPTSDemo",
  EligibilityCheckInsight = "eligibilityCheckInsight",
  EligibilityCheckLincolnshire = "eligibilityCheckLincolnshire",
  EligibilityCheckNottinghamshire = "eligibilityCheckNottinghamshire",
  EligibilityCheckSABP = "eligibilityCheckSABP",
  EligibilityCheckVHG = "eligibilityCheckVHG",
  EligibilityCheckDemoConference = "eligibilityCheckDemoConference",
  EligibilityCheckVitality = "eligibilityCheckVitality",
  SuitableServicesSignpostIAPT = "suitableServicesSignpostIAPT",
  SuitableServicesSignpostCCG = "suitableServicesSignpostCCG",
  SuitableServicesSignpostSABP = "suitableServicesSignpostSABP",
  UnderageSignpost = "underAgeSignpost",
  UnderageSignpostLincolnshire = "underAgeSignpostLincolnshire",
  PeaceOfMindIAPT = "peaceOfMindIAPT",
  PeaceOfMindGP = "peaceOfMindGP",
  PeaceOfMindCCG = "peaceOfMindCCG",
  PeaceOfMindHealix = "peaceOfMindHealix",
  PeaceOfMindVitality = "peaceOfMindVitality",
  PeaceOfMindDemoPrivateProviders = "peaceOfMindDemoPrivateProviders",
  PermissionsIAPT = "permissionsIAPT",
  PermissionsCCG = "permissionsCCG",
  PermissionsSABP = "permissionsSABP",
  PermissionsInsight = "permissionsInsight",
  PermissionsDemoPrivateProviders = "permissionsDemoPrivateProviders",
  PermissionsVitality = "permissionsVitality",
  AssessmentPitchIAPT = "assessmentPitchIAPT",
  AssessmentPitchGP = "assessmentPitchGP",
  AssessmentPitchCCG = "assessmentPitchCCG",
  AssessmentPitchTrentPTS = "assessmentPitchTrentPTS",
  AssessmentPitchHealix = "assessmentPitchHealix",
  AssessmentPitchInsight = "assessmentPitchInsight",
  AssessmentPitchInsightFollowUp = "assessmentPitchInsightFollowUp",
  AssessmentPitchLincolnshire = "assessmentPitchLincolnshire",
  AssessmentPitchNottinghamshire = "assessmentPitchNottinghamshire",
  AssessmentPitchSABP = "assessmentPitchSABP",
  AssessmentPitchVHG = "assessmentPitchVHG",
  AssessmentPitchDemoPrivateProviders = "assessmentPitchDemoPrivateProviders",
  AssessmentPitchVitality = "assessmentPitchVitality",
  AssessmentDemo = "assessmentDemo",
  AssessmentCCG = "assessmentCCG",
  AssessmentSABP = "assessmentSABP",
  AssessmentHealix = "assessmentHealix",
  AssessmentInsight = "assessmentInsight",
  AssessmentNottinghamshire = "assessmentNottinghamshire",
  AssessmentVHG = "assessmentVHG",
  AssessmentTrentPTS = "assessmentTrentPTS",
  AssessmentLincolnshire = "assessmentLincolnshire",
  AssessmentDemoPrivateProviders = "assessmentDemoPrivateProviders",
  AssessmentDemoConference = "assessmentDemoConference",
  AssessmentVitality = "assessmentVitality",
  TreatmentOptionsIAPT = "treatmentOptionsIAPT",
  TreatmentOptionsTrentPTS = "treatmentOptionsTrentPTS",
  TreatmentOptionsSABP = "treatmentOptionsSABP",
  TreatmentOptionsVHG = "treatmentOptionsVHG",
  TreatmentOptionsCCG = "treatmentOptionsCCG",
  TreatmentOptionsHealix = "treatmentOptionsHealix",
  TreatmentOptionsInsight = "treatmentOptionsInsight",
  TreatmentOptionsDemoPrivateProviders = "treatmentOptionsDemoPrivateProviders",
  TreatmentOptionsVitality = "treatmentOptionsVitality",
  BookMentalHealthProfessional = "bookMentalHealthProfessional",
  WellBeingCCG = "wellbeingCCG",
  WellbeingHealix = "wellbeingHealix",
  WellbeingDemoPrivateProviders = "wellbeingDemoPrivateProviders",
  SelfReferralDemo = "selfReferralDemo",
  SelfReferralPitchIAPT = "selfReferralPitchIAPT",
  SelfReferralPitchCCG = "selfReferralPitchCCG",
  SelfReferralPitchHealix = "selfReferralPitchHealix",
  SelfReferralPitchDemoPrivateProviders = "selfReferralPitchDemoPrivateProviders",
  SelfReferralPitchDemoConference = "selfReferralPitchDemoConference",
  SelfReferralVHG = "selfReferralVHG",
  SelfReferralCCG = "selfReferralCCG",
  SelfReferralHealix = "selfReferralHealix",
  SelfReferralTrentPTS = "selfReferralTrentPTS",
  SelfReferralInsight = "selfReferralInsight",
  SelfReferralSABP = "selfReferralSABP",
  SelfReferralLincolnshire = "selfReferralLincolnshire",
  SelfReferralNottinghamshire = "selfReferralNottingSelfReferralNottinghamshire",
  SelfReferralDemoPrivateProviders = "selfReferralDemoPrivateProviders",
  SelfReferralInsightWaitlist = "selfReferralInsightWaitlist",
  SelfReferralDemoConference = "selfReferralDemoConference",
  SelfReferralVitality = "selfReferralVitality",
  PHQ9 = "survey:PHQ9",
  PHQ9SABP = "survey:PHQ9_SABP",
  PHQ9Nottinghamshire = "survey:PHQ9_Nottinghamshire",
  PHQ9VHG = "survey:PHQ9_VHG",
  PHQ9TrentPTS = "survey:PHQ9_Trent_PTS",
  PHQ9Vitality = "survey:PHQ9_Vitality",
  RiskPathwayDemo = "riskPathwayDemo",
  RiskPathwayCCG = "riskPathwayCCG",
  RiskPathwayTrentPTS = "riskPathwayTrentPTS",
  RiskPathwayHealix = "riskPathwayHealix",
  RiskPathwayInsight = "riskPathwayInsight",
  RiskPathwayInsightFollowUp = "riskPathwayInsightFollowUp",
  RiskPathwayNottinghamshire = "riskPathwayNottinghamshire",
  RiskPathwaySABP = "riskPathwaySABP",
  RiskPathwayVHG = "riskPathwayVHG",
  RiskPathwayLincolnshire = "riskPathwayLincolnshire",
  RiskPathwayDemoPrivateProviders = "riskPathwayDemoPrivateProviders",
  RiskPathwayVitality = "riskPathwayVitality",
  GAD7 = "survey:GAD7",
  IAPTAccommodation = "survey:IAPTAccommodation",
  IAPTPhobiaScale = "survey:IAPTPhobiaScale",
  IAPTEmploymentStatus = "survey:IAPTEmploymentStatus",
  IAPTWorkAndSocialAdjustment = "survey:IAPTWorkAndSocialAdjustment",
  IAPTMedication = "survey:iaptMedication",
  Goodbye = "goodbye",
  GoodbyeDemo = "goodbyeDemo",
  GoodbyeCCG = "goodbyeCCG",
  GoodbyeTrentPTS = "goodbyeTrentPTS",
  GoodbyeSABP = "goodbyeSABP",
  GoodbyeHealix = "goodbyeHealix",
  GoodbyeInsight = "goodbyeInsight",
  GoodbyeInsightFollowUp = "goodbyeInsightFollowUp",
  GoodbyeNottinghamshire = "goodbyeNottinghashire",
  GoodbyeVHG = "goodbyeVHG",
  GoodbyeLincolnshire = "goodbyeLincolnshire",
  GoodbyeDemoPrivateProviders = "goodbyeDemoPrivateProviders",
  GoodbyeVitality = "goodbyeVitality",
  CrisisDemo = "crisisDemo",
  Crisis = "crisis",
  CrisisCCG = "crisisCCG",
  CrisisTrentPTS = "crisisTrentPTS",
  CrisisSABP = "crisisSABP",
  CrisisHealix = "crisisHealix",
  CrisisInsight = "crisisInsight",
  CrisisVHG = "crisisVHG",
  CrisisLincolnshire = "crisisLincolnshire",
  CrisisNottinghamshire = "crisisNottinghamshire",
  CrisisDemoPrivateProviders = "crisisDemoPrivateProviders",
  CrisisVitality = "crisisVitality",
  BookAppointmentVitality = "bookAppointmentVitality"
}
