import React from "react"
import { Typography } from "@material-ui/core"
import { Autorenew as RedoIcon } from "@material-ui/icons"
import Linkify from "react-linkify"
import "./UserBubble.scss"
import { IBubbleProps } from "./IBubbleProps"
import { observer } from "mobx-react"
import { useChatStore, useConfigStore } from "../../contexts/RootStoreContext"

interface Props extends IBubbleProps {
  children?: React.ReactChild
  isUndoAble?: boolean
  onUndoPress?: () => void
}

function UserBubble(props: Props): JSX.Element {
  const chat = useChatStore()
  const config = useConfigStore()
  const { isUndoAble, onUndoPress, message, children } = props
  const text = typeof children === "string" ? children : message
  const backgroundColor = config.userMessageBackground

  return (
    <div className="lb-user-bubble-container">
      {isUndoAble && (
        <button
          aria-label="Undo Last Step"
          aria-describedby="Undo Last Step"
          onClick={chat.isTyping ? undefined : onUndoPress}
          className="lb-user-bubble-undo-container"
        >
          <span className="lb-user-bubble-undo-text">Undo</span>
          <RedoIcon className="lb-user-bubble-undo-button" style={{ fill: backgroundColor }} />
        </button>
      )}
      {/* Not sure why they removed this - I had to add backgroundColor back */}
      {/* <div className="lb-user-bubble-content" style={{ backgroundColor }}> */}
      <div className="lb-user-bubble-content" style={{ backgroundColor }}>
        <Typography className="lb-user-bubble-text">
          <Linkify componentDecorator={componentDecorator}>{text}</Linkify>
        </Typography>
      </div>
    </div>
  )
}

const componentDecorator = (href, text, key) => (
  <a
    className="lb-user-bubble-text-link"
    href={href}
    key={key}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
)

export default observer(UserBubble)
