import React from "react"
import classnames from "classnames"
import { Close as CloseIcon } from "@material-ui/icons"
import { Typography } from "@material-ui/core"
import "./Launcher.scss"
import { LauncherContextProvider, useLauncherContext } from "../../contexts/LauncherContext"
import { useConfigStore } from "../../contexts/RootStoreContext"

interface Props {
  children?: React.ReactNode
}

export default function Launcher(props: Props): JSX.Element {
  return (
    <LauncherContextProvider>
      <LauncherView {...props} />
    </LauncherContextProvider>
  )
}

function LauncherView(props: Props): JSX.Element {
  const config = useConfigStore()
  const { isOpen, newMessagesCount, onToggle } = useLauncherContext()
  const launcherClasses = classnames("lb-launcher", { opened: isOpen, hidden: config.fullscreen })

  return (
    <>
      <button
        autoFocus
        aria-label="Limbic Self Referral"
        name="Limbic Self Referral"
        className={launcherClasses}
        onClick={() => onToggle()}
      >
        <MessageCount count={newMessagesCount} isOpen={isOpen} />
        <CloseIcon className={"lb-open-icon"} />
        <img
          className={"lb-closed-icon"}
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png"
          alt="Limbic Self Referral"
        />
      </button>
      {props.children}
    </>
  )
}

interface MessageCountProps {
  count: number
  isOpen: boolean
  style?: any
}

function MessageCount(props: MessageCountProps) {
  if (props.count === 0 || props.isOpen) {
    return null
  }
  return (
    <div className={"lb-new-messages-count"}>
      <Typography aria-label={"New messages count"}>{props.count}</Typography>
    </div>
  )
}
