import { IDataPoints } from "@limbic/types"
import Persistable from "../../models/Persistable"
import invariant from "../../utils/invariant"
import postDataPoints from "../../backend/api/dataPoints"

export default class DataPointsStore extends Persistable {
  readonly name: string = "DataPointsStore"
  dataPoints: IDataPoints[]

  getSignupCode?(): string | undefined
  getPatientID?(): string | undefined

  constructor() {
    super()
    this.dataPoints = this.hydrate("dataPoints") ?? []
  }

  addDataPoints(dataPoints: IDataPoints): void {
    const index = this.dataPoints.findIndex(dataPoint => dataPoint.q === dataPoints.q)
    if (index !== -1) {
      const updatedDataPoints = [...this.dataPoints]
      updatedDataPoints[index] = dataPoints
      this.dataPoints = updatedDataPoints
      this.persist("dataPoints", updatedDataPoints)
    } else {
      const updatedDataPoints = [...this.dataPoints]
      updatedDataPoints.push(dataPoints)
      this.dataPoints = updatedDataPoints
      this.persist("dataPoints", updatedDataPoints)
    }
  }

  getDataPoint(q: string): IDataPoints | unknown {
    return this.dataPoints.find(dataPoint => dataPoint.q === q)
  }

  getDataPoints(): IDataPoints[] {
    return this.dataPoints
  }

  clearDataPoints(): void {
    this.dataPoints = []
    this.persist("dataPoints", [])
  }

  async sendDataPoints(age: number): Promise<void> {
    try {
      const signupCode = this.getSignupCode?.()
      const patientID = this.getPatientID?.()

      const result = await postDataPoints(
        signupCode ? { signupCode } : { patientID },
        age,
        this.getDataPoints() || []
      )
      invariant(result, "Data points were not saved succesfully")
      if (result) {
        this.clearDataPoints()
      }
    } catch (e) {
      this.logException(e, "postDataPoints")
    }
  }
}
