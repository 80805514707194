import moment from "moment"
import { ALCOHOL_QUANTITIES as AQ, ALCOHOL_FREQUENCIES as AF } from "@limbic/types"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { ALCOHOL_FREQUENCIES, ALCOHOL_QUANTITIES } from "../../../models/Constants"
import invariant from "../../../utils/invariant"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type {
  LIMBIC_IMPACT_LEVEL,
  ReferralPayloadMock,
  GENDER_MOCK,
  DISABILITY_MOCK,
  PERINATAL_MOCK,
  ETHNICITY_MOCK,
  ARMED_FORCES_MOCK,
  SEXUALITY_MOCK,
  LTC_MOCK
} from "@limbic/types"
import sendEmail from "../../../backend/api/sendEmail"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  ltcs,
  perinatalStatuses,
  sexualities
} from "../../../config/referralForms/demo"

type State = SelfReferralIAPTScriptState

export class SelfReferralDemoScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralScript"

  /** Script Steps */

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [`Good news, ${name}`, `Your referral to ${iaptName} is nearly ready to go ✉️`],
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [
          { body: "Great", value: false },
          { body: "Good to know", value: false }
        ]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async getReferralPayload(state: State): Promise<ReferralPayloadMock> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      gender: this.getGender(state),
      disability: this.getDisability(state),
      perinatal: this.getPerinatal(state),
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForce(state),
      sexuality: this.getSexuality(state),
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      consentDataShare: true,
      consentDataStore: true,
      alcohol: state.alcohol,
      alcoholFrequency: this.getAlcoholFrequency(state),
      alcoholQuantity: this.getAlcoholQuantity(state),
      substances: state.substances,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      medication:
        state.substancesAreMedications && state.medicationInfo //
          ? [state.medicationInfo]
          : undefined,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords
    }
  }

  getGender(state: State): GENDER_MOCK {
    const gender = genders[state.gender!]
    return gender ?? "UNKNOWN"
  }

  getDisability(state: State): DISABILITY_MOCK {
    const disability = disabilities[state.disability!]
    return disability ?? "NONE"
  }

  getPerinatal(state: State): PERINATAL_MOCK {
    const perinatal = perinatalStatuses[state.perinatalStatus!]
    return perinatal ?? "NONE"
  }

  getEthnicity(state: State): ETHNICITY_MOCK {
    const ethnicity = ethnicities[state.ethnicity!]
    return ethnicity ?? "UNKNOWN"
  }

  getArmedForce(state: State): ARMED_FORCES_MOCK {
    const armedForces = exArmedForces[state.isExArmedForces!]
    return armedForces ?? "NO"
  }

  getSexuality(state: State): SEXUALITY_MOCK {
    const sexuality = sexualities[state.sexuality!]
    return sexuality ?? "UNKNOWN"
  }

  getLTC(state: State): LTC_MOCK[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getAlcoholFrequency(state: State): typeof AF[keyof typeof AF] | undefined {
    const map = {
      [ALCOHOL_FREQUENCIES.MONTHLY]: AF.MONTHLY,
      [ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4]: AF.MONTHLY_2_TO_4,
      [ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3]: AF.WEEKLY_2_TO_3,
      [ALCOHOL_FREQUENCIES.WEEKLY_4]: AF.WEEKLY_4
    }
    return map[state.alcoholFrequency!]
  }

  getAlcoholQuantity(state: State): typeof AQ[keyof typeof AQ] | undefined {
    const map = {
      [ALCOHOL_QUANTITIES._0_2]: AQ._0_2,
      [ALCOHOL_QUANTITIES._3_4]: AQ._3_4,
      [ALCOHOL_QUANTITIES._5_6]: AQ._5_6,
      [ALCOHOL_QUANTITIES._7_9]: AQ._7_9,
      [ALCOHOL_QUANTITIES._10_PLUS]: AQ._10_PLUS
    }
    return map[state.alcoholQuantity!]
  }

  async submitEmail(state: State, emails: string[]): Promise<void> {
    const subject = "Limbic | New User Referral"
    const organisationName = this.rootStore.configStore.organisationName
    try {
      const text = this.createBookingEmail(
        state,
        this.clinicalStore.isRisk,
        "submitted a new referral"
      )
      const to = emails.filter(Boolean) as string[]
      await sendEmail({ to, subject, text }, organisationName || "")
    } catch (e) {
      this.logException(e, "submitEmail")
    }
  }
}

export default class SelfReferralDemoDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralDemo
  readonly name = "SelfReferralDemoDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralDemoDialogue.id, new SelfReferralDemoScript(), state, snapshot)
  }
}
