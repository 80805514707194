import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  TreatmentOptionsIAPTScript,
  TreatmentOptionsIAPTScriptState
} from "./TreatmentOptionsIAPTDialogue"

type State = TreatmentOptionsIAPTScriptState

export class TreatmentOptionsVHGScript extends TreatmentOptionsIAPTScript {
  readonly name: string = "TreatmentOptionsVHGScript"

  /** Generic Handlers */

  getCustomReferralType(state: State): string | undefined {
    if (this.clinicalStore.assessmentFinished) return "Limbic Assessment"
    return "Self Referral"
  }
}

export default class TreatmentOptionsVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsVHG
  readonly name: string = "TreatmentOptionsVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(TreatmentOptionsVHGDialogue.id, new TreatmentOptionsVHGScript(), state, snapshot)
  }
}
