import { IUserClinicalPathDescriptor } from "../models/IClinicalPath"
import { getIntersection } from "./array"
import {
  ClinicalFlags,
  clinicalFlagsMap,
  disordersMap,
  ProblemCategories
} from "../models/Constants"
import Logger from "./Logger"

export default function validateClinicalPath(input: IUserClinicalPathDescriptor): boolean {
  try {
    const { flags, primaryProblems: primaries, secondaryProblems: secondaries } = input
    const primariesSet = new Set<ProblemCategories>(primaries)
    const secondariesSet = new Set<ProblemCategories>(secondaries)
    const flagsSet = new Set<ClinicalFlags>(flags)
    const all = [...primaries, ...secondaries]

    // You can't have an empty primary while the secondary has stuff.
    // You're either below caseness so both are empty or you have at
    // least one primary problem
    if (!primariesSet.size && secondariesSet.size) return false

    // You can't have both lists with more than one item
    if (primariesSet.size > 1 && secondariesSet.size > 1) return false

    // If you have more than 1 secondary then you must have exactly 1 primary
    if (secondariesSet.size > 1 && primariesSet.size !== 1) return false

    // Duplicates
    if (primariesSet.size !== primaries.length) return false
    if (secondariesSet.size !== secondaries.length) return false
    if (flagsSet.size !== flags.length) return false

    // Intersecting lists
    if (primaries.find(i => secondariesSet.has(i))) return false
    if (secondaries.find(i => primariesSet.has(i))) return false

    // Can't have any of the
    const anxietyDisorders = Object.values(disordersMap.anxiety).flat()
    const anxietyExtraDisorders = disordersMap.anxietyExtra
    const anxietyItems = all.filter(i => anxietyDisorders.includes(i))
    const anxietyExtraItems = all.filter(i => anxietyExtraDisorders.includes(i))
    if (anxietyItems.length && anxietyExtraItems.length) return false

    if (getIntersection(disordersMap.depression, all).length > 1) return false
    if (getIntersection(disordersMap.anxiety.socialPhobia, all).length > 1) return false
    if (getIntersection(disordersMap.anxiety.phobia, all).length > 1) return false
    if (getIntersection(disordersMap.anxiety.panic, all).length > 1) return false
    if (getIntersection(disordersMap.anxiety.worry, all).length > 1) return false
    if (getIntersection(disordersMap.anxietyExtra, all).length > 1) return false
    if (getIntersection(clinicalFlagsMap.wsas, flags).length > 1) return false

    // Depression as primary problem can only coexist with an Anxiety NOS secondary
    const isPrimaryDepression = !!primaries.find(i => disordersMap.depression.includes(i))
    if (isPrimaryDepression) {
      const nonDepressionPrimaries = primaries.filter(i => !disordersMap.depression.includes(i))
      if (nonDepressionPrimaries.length) return false
      const nonDepressionSecondaries = secondaries.filter(i => !disordersMap.depression.includes(i))
      if (nonDepressionSecondaries.length > 1) return false
      const nonDepressionSecondaryItem = nonDepressionSecondaries[0]
      if (
        nonDepressionSecondaryItem != null &&
        nonDepressionSecondaryItem !== ProblemCategories.AnxietyNOS
      )
        return false
    }
  } catch (e) {
    Logger.getInstance().exception(e, "validateClinicalPath")
    return false
  }

  return true
}
