import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import { SuitableServicesSignpostScript } from "./SuitableServicesSignpostScript"
import type { SuitableServicesSignpostScriptState } from "./SuitableServicesSignpostScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = SuitableServicesSignpostScriptState
export type SuitableServicesSignpostSABPScriptState = State

export class SuitableServicesSignpostSABPScript extends SuitableServicesSignpostScript {
  readonly name: string = "SuitableServicesSignpostSABPScript"

  /** Script Steps */

  @step.logState
  sayWeDontSupportYourArea(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `So ${name}, with the NHS, you can get mental health support from services in the same area as your registered GP`,
        `I've just done a search, and it doesn't look like ${organisationName} have any services in the same area as your GP`
      ],
      prompt: {
        id: this.getPromptId("sayWeDontSupportYourArea"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.giveDetails
    }
  }

  @step.logState
  giveDetails(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const url = "https://www.nhs.uk/service-search/find-a-psychological-therapies-service"
    return {
      body: [
        `Sorry about that ${name}`,
        `Details of other local providers can be found [here](${url})`,
        "Alternatively if you'd prefer to speak to a member of our team, you can contact us via telephone, text, or email:\n" +
          "Tel: 0300 330 5450\n" +
          "SMS: 07786 202 565\n" +
          "Email: mindmatters.surrey@sabp.nhs.uk"
      ],
      nextStep: this.promptThanksOK
    }
  }
}

/* istanbul ignore next */
export default class SuitableServicesSignpostSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.SuitableServicesSignpostSABP
  readonly name: string = "SuitableServicesSignpostSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SuitableServicesSignpostSABPDialogue.id,
      new SuitableServicesSignpostSABPScript(),
      state,
      snapshot
    )
  }
}
