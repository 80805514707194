import isOnline from "is-online"
import { backendEnv } from "../../config/config"
import invariant from "../../utils/invariant"
import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import delay from "../../utils/delay"
import type { IGPServiceODS } from "../../models/IGPService"

const TOTAL_RETRIES = 2

const backendEndpoints = {
  staging: "https://access-v2-staging.limbic.ai",
  development: "https://access-v2-staging.limbic.ai",
  production: "https://access-v2.limbic.ai"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const baseURL = backendEndpoints[backendEnv] || backendEndpoints.production
const FIND_GP_PATH = `${baseURL}/v1/ods/gp`
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export enum ODSFindRequestStatus {
  NOT_FOUND = "NOT_FOUND",
  NO_INTERNET = "NO_INTERNET",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED"
}

export async function getODSGPDetails(
  id: string,
  retry = 0
): Promise<[IGPServiceODS | undefined, ODSFindRequestStatus]> {
  if (!id) return [undefined, ODSFindRequestStatus.FAILED]

  let result: Response | undefined = undefined
  try {
    if (!(await isOnline())) return [undefined, ODSFindRequestStatus.NO_INTERNET]

    result = await fetch(`${FIND_GP_PATH}/${id}`, { headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      if (error === "GP not found") return [undefined, ODSFindRequestStatus.NOT_FOUND]
      throw new NetworkError(`${result.status}`, error)
    }
    if (!success && validations) throw new Error(validations?.join(", "))
    return [data, ODSFindRequestStatus.SUCCESS]
  } catch (e) {
    Logger.getInstance().exception(e, "createReferral fetch failed")
    if (retry < TOTAL_RETRIES) {
      const message = `updateReferral body for retry ${retry}`
      Logger.getInstance().breadcrumb({ message, data: { id } })
      Logger.getInstance().message("updateReferral retry")
      await delay(1)
      return await getODSGPDetails(id, retry + 1)
    }

    if (result?.status === 500) return [undefined, ODSFindRequestStatus.INTERNAL_ERROR]
    return [undefined, ODSFindRequestStatus.FAILED]
  }
}
