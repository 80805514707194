/* eslint-disable */
import "./polyfills"
import React from "react"
import ReactDOM from "react-dom"
import "./app/styles/styles.scss"
import * as serviceWorker from "./serviceWorker"
import { version } from "../package.json"
import Logger from "./utils/Logger"
import Loggable from "./models/Loggable"
import Tracker from "./utils/Tracker"
import Trackable from "./models/Trackable"
import Persistable from "./models/Persistable"
import RootStore from "./app/stores/rootStore"
import { RootStoreProvider } from "./app/contexts/RootStoreContext"
import BaseScript from "./conversation/BaseScript"
import App from "./app/App"
import CrisisDetector from "./models/CrisisDetector"
import { getLanguage } from "./utils/getLanguage"
import invariant from "./utils/invariant"
import { apiKeysMap } from "./config/apiKeysMap"
import customFields from "./config/customFields"
import validateCustomFields from "./utils/validateCustomFields"
import type IAppLaunchConfig from "./models/IAppLaunchConfig"

interface WindowWithLimbicNameSpace extends Window {
  LimbicChatbot: {
    onStart?(initFn: typeof init): void
    init?: (setupConfig: IAppLaunchConfig) => void
    initialized: boolean
  }
}

declare var window: WindowWithLimbicNameSpace

if (!window.LimbicChatbot) {
  window.LimbicChatbot = {
    init,
    initialized: false
  }
}

const mixPanelAPIKey = process.env.REACT_APP_MIXPANEL_API_KEY ?? ""
const dist = process.env.REACT_APP_DIST
const dsn = "https://81e0fe6d52154e3ebeadfb175cb0d5e8@o138988.ingest.sentry.io/5392826"
const allowUrls = undefined

function init(setupConfig: IAppLaunchConfig) {
  console.log("==> Initialising Limbic Web Bot...")
  if (!window.LimbicChatbot.initialized) {
    window.LimbicChatbot.initialized = true
    const key = setupConfig.API_KEY
    invariant(key, "no API key found")
    invariant(apiKeysMap[key], "no configuration found for this API key")
    const config = { ...apiKeysMap[key], ...(setupConfig.overrides || {}) }

    try {
      const language = getLanguage()
      const fullScreen = !!config.fullscreen
      Logger.setup(version, dsn, dist, allowUrls)
      const loggerSingleton = Logger.getInstance()
      Loggable.setLogger(loggerSingleton)
      // ⚠️ WARNING: 👇 Tracker is a Loggable so it needs to be setup after Logger/Loggable setup 👆
      if (mixPanelAPIKey) {
        Tracker.setAPIKey(mixPanelAPIKey)
      }
      Tracker.setup({ BOT_API_KEY: key })
      const trackerSingleton = Tracker.getInstance()
      trackerSingleton.register({ build: version })
      trackerSingleton.setPeople({ lastBuildUsed: version, language, ...config, fullScreen })
      trackerSingleton.register(config)
      Trackable.setTracker(trackerSingleton)
    } catch (e) {
      console.log(`init: ${e.message}`)
      Logger.getInstance().exception(e, "init -> Trackers setup")
    }

    try {
      Persistable.setStorage(sessionStorage)
      CrisisDetector.setStorage(localStorage)
      RootStore.getInstance()
      RootStore.ConfigStore.setConfig(key, config)
      RootStore.DiscussionStore.setDiscussionStepsOrder(config.discussionStepsOrder)
      RootStore.DiscussionStore.setDialoguesMap(config.dialoguesMap)
      RootStore.ClinicalStore.setDefaultPaths(config.defaultClinicalPaths)
      RootStore.ClinicalStore.setCustomPaths(config.customClinicalPaths)
      RootStore.ClinicalStore.setComplexComorbidPath(config.complexComorbidPath)
      RootStore.ClinicalStore.setUndeterminedPath(config.undeterminedPath)
      RootStore.ReferralStore.setSkipOnlineReferral(config.skipOnlineReferral ?? false)
      if (config.backendInstanceID) RootStore.ReferralStore.setInstanceID(config.backendInstanceID)

      const allFields = customFields[key]?.allFields ?? []
      const requiredFields = customFields[key]?.requiredFields ?? []
      validateCustomFields(allFields, requiredFields, setupConfig.customFields)
      if (setupConfig.customFields) {
        Object.entries(setupConfig.customFields) //
          .forEach(([key, value]) => RootStore.ReferralStore.setCustomField(key, value))
      }
    } catch (e) {
      Logger.getInstance().exception(e, "init -> RootStore setup")
    } finally {
      BaseScript.setRootStore(RootStore.getInstance())
    }

    void CrisisDetector.getInstance().updateCrisisDetection()

    const el = getMountingPoint()
    ReactDOM.render(
      <React.StrictMode>
        <RootStoreProvider>
          <App />
        </RootStoreProvider>
      </React.StrictMode>,
      el
    )
  }
}

function getMountingPoint(): Element {
  let el = document.querySelector("#limbic-chat")
  if (!el) {
    const newEl = document.createElement("div")
    newEl.setAttribute("id", "limbic-chat")
    document.body.appendChild(newEl)
    return newEl
  }
  return el
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

window.LimbicChatbot.onStart && window.LimbicChatbot.onStart(init)
