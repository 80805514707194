import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { DiscussionSteps } from "../../../models/Constants"
import sendEmail from "../../../backend/api/sendEmail"

type State = BaseScriptState
export type SelfReferralPitchDemoConferenceScriptState = State

export class SelfReferralPitchDemoConferenceScript extends BaseScript<State> {
  readonly name: string = "SelfReferralPitchDemoConferenceScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.goToEmailAddressDialogue }
  }

  @step.logState
  goToEmailAddressDialogue(d: IStepData<State>): IStepResult {
    const EmailAddressDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.EmailAddress)
    const nextDialogue = EmailAddressDialogue ? new EmailAddressDialogue({ ...d.state }) : undefined
    return {
      nextDialogue,
      nextStep: this.handleEmailAddressDialogue
    }
  }

  @step.logStateAndResponse
  async handleEmailAddressDialogue(d: IStepData<State, string>): Promise<IStepResult> {
    if (d.state.email) {
      await this.sendEmailAddressLead(d.state)
    }
    return { nextStep: this.askIntent }
  }

  @step.logState
  askIntent(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "(I can either show you how I check patient eligibility for our services, or I can skip ahead to mental health screening)",
        "(What are you more interested in seeing?)"
      ],
      prompt: {
        id: this.getPromptId("start"),
        type: "inlinePicker",
        choices: [
          { body: "Service eligibility 👉", value: "eligibility" },
          { body: "Mental health screening 👉️", value: "assessment" }
        ]
      },
      nextStep: this.handleIntent
    }
  }

  @step.logState
  handleIntent(d: IStepData<State, "eligibility" | "assessment">): IStepResult {
    if (d.response === "eligibility") {
      const EligibilityDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.Eligibility)
      const nextDialogue = EligibilityDialogue ? new EligibilityDialogue({ ...d.state }) : undefined
      return {
        body: "Okay, I'm on it",
        nextDialogue,
        nextStep: this.returnToAskIntent
      }
    }

    const AssessmentDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.Assessment)
    const nextDialogue = AssessmentDialogue ? new AssessmentDialogue({ ...d.state }) : undefined
    return {
      body: "Alright, let's see what we can do",
      nextDialogue,
      nextStep: this.returnToAskIntent
    }
  }

  @step.logState
  returnToAskIntent(_d: IStepData<State>): IStepResult {
    return {
      body: ["So...", "Let's start over"],
      nextStep: this.askIntent
    }
  }

  /** Generic Handlers */

  async sendEmailAddressLead(state: State): Promise<void> {
    try {
      const org = this.rootStore.configStore.organisationName
      const to = ["bas@limbic.ai"]
      const subject = "Conference | New email address"
      const text = `
      <html lang='en'>
      <head>
      <title>Conference | New email address</title>
      ${this.getEmailHTMLStyle()}
      </head>
      <body>
        <h1><b>Received new lead from the conference</h1><br/>
        <b>Name:</b> ${state.username}<br/> 
        <b>Email:</b> ${state.email}<br/> 
      </body>
    </html>
    `
        .replace(/undefined/gi, "-")
        .replace(/true/gi, "Yes")
        .replace(/false/gi, "No")
      await sendEmail({ to, bcc: ["john@limbic.ai"], subject, text }, org)
    } catch (e) {
      this.logException(e, "sendEmailAddressLead failed")
    }
  }
}

export default class SelfReferralPitchDemoConferenceDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralPitchDemoConference
  readonly name: string = "SelfReferralPitchDemoConferenceDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralPitchDemoConferenceDialogue.id,
      new SelfReferralPitchDemoConferenceScript(),
      state,
      snapshot
    )
  }
}
