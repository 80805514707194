import type { IClinicalPath } from "../../models/IClinicalPath"
import { ClinicalFlags, ClinicalGroups, ProblemCategories } from "../../models/Constants"

export const complexComorbidPath: IClinicalPath = {
  id: "complex_comorbid",
  enableSecondary: false,
  clinicalGroup: ClinicalGroups.ComplexComorbid,
  suggestTreatments: false,
  defaultReferralType: "Limbic Full Assessment - Assessment call required",
  treatments: [],
  declinedTreatmentsReferralType:
    "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
}

export const undeterminedPath: IClinicalPath = {
  id: "undetermined",
  enableSecondary: false,
  clinicalGroup: ClinicalGroups.Undetermined,
  suggestTreatments: false,
  defaultReferralType: "Limbic Full Assessment - Assessment call required",
  treatments: [],
  declinedTreatmentsReferralType:
    "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
}

export const clinicalPaths: IClinicalPath[] = [
  {
    id: "1",
    matcher: {
      primaryProblems: [],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.BelowCaseness,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required - Below Caseness",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "2",
    matcher: {
      primaryProblems: [],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.BelowCaseness_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required - Below Caseness",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "3",
    matcher: {
      primaryProblems: [],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.BelowCaseness_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required - Below Caseness",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "4",
    matcher: {
      primaryProblems: [ProblemCategories.M_Depression],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.M_Depression,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "5",
    matcher: {
      primaryProblems: [ProblemCategories.M_Depression],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.M_Depression_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "6",
    matcher: {
      primaryProblems: [ProblemCategories.M_Depression],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.M_Depression_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "7",
    matcher: {
      primaryProblems: [ProblemCategories.MS_Depression],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.MS_Depression,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "8",
    matcher: {
      primaryProblems: [ProblemCategories.MS_Depression],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.MS_Depression_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "9",
    matcher: {
      primaryProblems: [ProblemCategories.MS_Depression],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.MS_Depression_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "10",
    matcher: {
      primaryProblems: [ProblemCategories.S_Depression],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.S_Depression,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "11",
    matcher: {
      primaryProblems: [ProblemCategories.S_Depression],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.S_Depression_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "12",
    matcher: {
      primaryProblems: [ProblemCategories.S_Depression],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.S_Depression_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "13",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.SocialPhobia,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "14",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.SocialPhobia_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "15",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.SocialPhobia_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "16",
    matcher: {
      primaryProblems: [ProblemCategories.SpecificPhobia],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.SpecificPhobia,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "17",
    matcher: {
      primaryProblems: [ProblemCategories.SpecificPhobia],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.SpecificPhobia_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "18",
    matcher: {
      primaryProblems: [ProblemCategories.SpecificPhobia],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.SpecificPhobia_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "19",
    matcher: {
      primaryProblems: [ProblemCategories.SpecificPhobiaNBI],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.SpecificPhobiaNBI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "20",
    matcher: {
      primaryProblems: [ProblemCategories.SpecificPhobiaNBI],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.SpecificPhobiaNBI_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "21",
    matcher: {
      primaryProblems: [ProblemCategories.SpecificPhobiaNBI],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.SpecificPhobiaNBI_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "22",
    matcher: {
      primaryProblems: [ProblemCategories.Panic],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.Panic,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "23",
    matcher: {
      primaryProblems: [ProblemCategories.Panic],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.Panic_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "24",
    matcher: {
      primaryProblems: [ProblemCategories.Panic],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.Panic_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "25",
    matcher: {
      primaryProblems: [ProblemCategories.M_GADWorry],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.M_GADWorry,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "26",
    matcher: {
      primaryProblems: [ProblemCategories.M_GADWorry],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.M_GADWorry_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "27",
    matcher: {
      primaryProblems: [ProblemCategories.M_GADWorry],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.M_GADWorry_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "28",
    matcher: {
      primaryProblems: [ProblemCategories.S_GADWorry],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.S_GADWorry,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "29",
    matcher: {
      primaryProblems: [ProblemCategories.S_GADWorry],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.S_GADWorry_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "30",
    matcher: {
      primaryProblems: [ProblemCategories.S_GADWorry],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.S_GADWorry_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "31",
    matcher: {
      primaryProblems: [ProblemCategories.HealthAnxiety],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.HealthAnxiety,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "32",
    matcher: {
      primaryProblems: [ProblemCategories.HealthAnxiety],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.HealthAnxiety_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "33",
    matcher: {
      primaryProblems: [ProblemCategories.HealthAnxiety],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.HealthAnxiety_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "34",
    matcher: {
      primaryProblems: [ProblemCategories.OCD],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.OCD,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "35",
    matcher: {
      primaryProblems: [ProblemCategories.OCD],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.OCD_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "36",
    matcher: {
      primaryProblems: [ProblemCategories.OCD],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.OCD_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "37",
    matcher: {
      primaryProblems: [ProblemCategories.PTSD],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.PTSD,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "38",
    matcher: {
      primaryProblems: [ProblemCategories.PTSD],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.PTSD_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "39",
    matcher: {
      primaryProblems: [ProblemCategories.PTSD],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.PTSD_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "40",
    matcher: {
      primaryProblems: [ProblemCategories.AnxietyNOS],
      flags: []
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.AnxietyNOS,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "41",
    matcher: {
      primaryProblems: [ProblemCategories.AnxietyNOS],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.AnxietyNOS_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "42",
    matcher: {
      primaryProblems: [ProblemCategories.AnxietyNOS],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: false,
    clinicalGroup: ClinicalGroups.AnxietyNOS_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Limbic Full Assessment - Assessment call required",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  }
]
