import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { ProblemCategories } from "../../../models/Constants"
import isValidPhoneNumber from "../../../utils/isvalidPhoneNumber"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import {
  SelfReferralCCGScript,
  SelfReferralCCGScriptState
} from "../selfReferral/SelfReferralCCGDialogue"
import { TrackingEvents } from "../../../models/Constants"
import sendEmail from "../../../backend/api/sendEmail"

// prettier-ignore
const anxietyString = "An NHS self-help guide for anxiety [here](https://web.ntw.nhs.uk/selfhelp/leaflets/Anxiety.pdf)"
// prettier-ignore
const depressionString = "A video on how to break the cycle of low mood [here](https://www.youtube.com/watch?v=wXCeYgHLJdo)"
// prettier-ignore
const sleepString = "A video with tips for better sleep [here](https://www.youtube.com/watch?v=nysjq8VIwI8)"
// prettier-ignore
const ptsdString = "A website with information about trauma and how it affects mental health [here](https://www.mind.org.uk/information-support/types-of-mental-health-problems/trauma/effects-of-trauma/)"

interface State extends SelfReferralCCGScriptState {
  bookMentalHealthProfessional?: boolean
  bookMentalHealthProfessionalFailed?: boolean
  userAborted?: boolean
  isBelowCaseness?: boolean
}

export class WellbeingCCGScript extends SelfReferralCCGScript {
  readonly name: string = "WellbeingCCGScript"

  /** Script Steps */

  @step.logState
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, I think you would benefit from some online materials about wellbeing`,
        "I've selected some specific resources based on the answers to your questions"
      ],
      nextStep: this.checkProblems
    }
  }

  @step.logState
  checkProblems(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayResources }
  }

  @step.logState
  sayResources(d: IStepData<State>): IStepResult {
    const body: string[] = []
    const sleep = this.getSleepProblems(d.state)
    const depression = this.getDepressionProblems(d.state)
    const ptsd = this.getTraumaProblems()
    const anxiety = this.getAnxietyProblems(d.state)
    const belowCaseness = !depression && !anxiety

    ;(anxiety || belowCaseness) && body.push(anxietyString)
    ;(depression || belowCaseness) && body.push(depressionString)
    sleep && body.push(sleepString)
    ptsd && body.push(ptsdString)
    return {
      body,
      prompt: {
        id: this.getPromptId("sayResources"),
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: false },
          { body: "Thanks!", value: true }
        ]
      },
      nextStep: this.handleResources
    }
  }

  @step.logState
  handleResources(d: IStepData<State, boolean>): IStepResult {
    const body = d.response ? "You're welcome ☺️" : ""
    return {
      body,
      nextStep: this.sayWellbeingStaffWillContact
    }
  }

  @step.logState
  sayWellbeingStaffWillContact(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    if (d.state.bookMentalHealthProfessionalFailed || d.state.referralSubmissionFailed) {
      return {
        nextStep: this.goToGoodbye
      }
    }
    if (d.state.userAborted || d.state.isBelowCaseness) {
      if (d.state.isBelowCaseness) {
        void this.informService(d.state)
        return {
          body: `In the meantime, the friendly staff at ${organisationName} will take a look at the details you provided`,
          nextStep: d.state.phoneNumber ? this.theyllCallYou : this.askPhoneNumber
        }
      }
      if (d.state.userAborted) {
        return {
          nextStep: this.goToGoodbye
        }
      }
    }
    return {
      body: `In the meantime, the friendly staff at ${organisationName} will take a look at the details you provided`,
      nextStep: d.state.phoneNumber ? this.theyllCallYou : this.askPhoneNumber
    }
  }

  @step
  async theyllCallYou(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.phoneNumber) {
      return { nextStep: this.askPhoneNumber }
    }
    return {
      body: [
        `Someone will contact you on ${d.state.phoneNumber} for a quick check-in over the next few days`,
        "This will be a confidential chat to see how you're getting on, and assess whether you might benefit from further support"
      ],
      prompt: {
        id: this.getPromptId("theyllCallYou"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Okay", value: true },
          { body: "I understand", value: true },
          { body: "Change number", value: false }
        ]
      },
      nextStep: this.handleTheyllCallYou
    }
  }

  @step.logStateAndResponse
  handleTheyllCallYou(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      const proceedWithGoodbye =
        d.state.bookMentalHealthProfessional || d.state.isBelowCaseness || d.state.userAborted
      return {
        nextStep: proceedWithGoodbye ? this.goToGoodbye : this.doReferralSubmission
      }
    }
    return { nextStep: this.askPhoneNumber }
  }

  @step.logState
  askPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "What's the best phone number to call you on?",
      prompt: {
        id: this.getPromptId("askPhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step.logState
  async handlePhoneNumber(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      return {
        body: "Sorry this is not a valid phone number. Let's try again",
        nextStep: this.askPhoneNumber
      }
    }
    d.state.phoneNumber = d.response
    return {
      body: [
        "Thanks",
        `Someone will contact you on ${d.state.phoneNumber} for a quick check-in over the next few days`
      ],
      nextStep: this.doReferralSubmission
    }
  }

  async informService(state: State): Promise<boolean> {
    try {
      const type = "is below caseness"
      const text = this.createBookingEmail(state, this.clinicalStore.isRisk, type)
      const wellbeingHubEmails = this.rootStore.configStore.wellbeingHubEmails ?? []
      const to = wellbeingHubEmails.filter(Boolean) as string[]
      const subject = "User is Below Caseness"
      const organisationName = this.rootStore.configStore.organisationName
      await sendEmail({ to, subject: `Limbic | ${subject}`, text }, organisationName)
      this.track(TrackingEvents.BELOW_CASENESS_EMAIL)
    } catch (e) {
      this.logException(e, "notifyBelowCaseness")
      return false
    }
    return true
  }

  /** Generic Handlers */

  getSleepProblems(state: State): boolean {
    const phq9Responses = state.phq9Responses
    const q3Points = phq9Responses?.[2]?.points ?? 0
    return q3Points > 0
  }

  getDepressionProblems(state: State): boolean {
    const phq9Score = this.getPHQ9Total(state) ?? 0
    return phq9Score >= 10
  }

  getTraumaProblems(): boolean {
    const primaries = this.rootStore.clinicalStore.primaryProblems
    const secondaries = this.rootStore.clinicalStore.secondaryProblems
    return [...primaries, secondaries].includes(ProblemCategories.PTSD)
  }

  getAnxietyProblems(state: State): boolean {
    const gad7Score = this.getGAD7Total(state) ?? 0
    return gad7Score >= 8
  }

  getReferralSubmittedIndicatorHTML(): undefined {
    // we never send the referral to the service
    // so on reason to add the indicator
    return undefined
  }
}

export default class WellbeingCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.WellBeingCCG
  readonly name: string = "WellbeingCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(WellbeingCCGDialogue.id, new WellbeingCCGScript(), state, snapshot)
  }
}
