import { LoggableType } from "./Logger/LoggableType"
import { Severity } from "./Logger/Severity"
import invariant from "../utils/invariant"
import type ILogger from "./Logger/ILogger"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
global.__LB_LOGS__ = true

export default abstract class Loggable {
  abstract readonly name: string
  static _logger: ILogger
  static _logType: LoggableType
  _logger?: ILogger

  /** Static Methods */

  static setLogger(logger: ILogger): void {
    invariant(logger, `Loggable needs a static _logger property. [${logger}]`)
    this._logger = logger
  }

  static setLogType(logType: LoggableType): void {
    invariant(logType, `Loggable needs a static _logType property. [${logType}]`)
    this._logType = logType
  }

  /** Static Getters / Setters */

  static get logger(): ILogger {
    invariant(this._logger, `Loggable needs a static _logger property. [${this._logger}]`)
    return this._logger
  }

  static get logType(): LoggableType {
    invariant(this._logType, `Loggable needs a static _logType property. [${this._logType}]`)
    return this._logType
  }

  setLogger(logger: ILogger): void {
    invariant(logger, `Loggable needs a static _logger property. [${logger}]`)
    invariant(
      process.env.NODE_ENV === "test",
      "non-static setLogger of Loggable is only for testing"
    )
    this._logger = logger
  }

  /** Methods */

  logMessage(message: string): void {
    try {
      const string = `[${this.name}] - ${message}`
      this.logger.message(string)
      // eslint-disable-next-line no-empty
    } catch {}
  }

  logBreadcrumb(text: string, data?: Record<string, any>): void {
    try {
      const message = `${this.name} -> ${text}`
      const level = Severity.Info
      const type = this.logType
      this.logger.breadcrumb({ message, data, level, type })
      // eslint-disable-next-line no-empty
    } catch (e) {
      console.log(`${this.name} - logBreadcrumb: ${e.message}`)
    }
  }

  logException(e: Error, stepName: string): void {
    try {
      const message = `${this.name} -> ${stepName} error`
      this.logger.exception(e, message)
      // eslint-disable-next-line no-empty
    } catch {}
  }

  /** Getters / Setters */

  get logger(): ILogger {
    if (this._logger) return this._logger
    return (this.constructor as any).logger
  }

  protected get logType(): LoggableType {
    return (this.constructor as any).logType
  }
}

Loggable.setLogType(LoggableType.Logic)
