import { ACCESS_INSTANCES } from "@limbic/types"
import { CCGCode } from "./ICCG"
import type { IGPService } from "./IGPService"
import type { IReferralForm } from "./IReferralForm"

export interface IIAPTService {
  id: string
  name: string
  formattedName: string
  postcode: string
  emails?: string[]
  backendInstanceID?: keyof typeof ACCESS_INSTANCES
  clinicName?: string
  phoneNumber?: string
  ageThreshold?: number
  ageThresholdForTreatments?: number
  riskEmails?: string[]
  riskEmailsCC?: string[]
  riskEmailsBCC?: string[]
  eligibleGPs?: IGPService[]
  referralForm?: IReferralForm
}

export enum IAPTIDs {
  "DEMO" = "demo",
  "VHG_DERBY" = "10921210",
  "VHG_BB" = "10565427",
  "VHG_CALDERDALE" = "10927593",
  "VHG_BNSSG" = "10802686",
  "VHG_KM" = "10921209",
  "VHG_LLR" = "10974279",
  "VHG_WEST_ESSEX" = "10802687",
  "INSIGHT_KENT" = "2917833",
  "INSIGHT_PETTERSBOROUGH" = "76186",
  "INSIGHT_NOTTS" = "76190",
  "INSIGHT_BASSETLAW" = "3775518",
  "INSIGHT_DERBY" = "979390",
  "INSIGHT_WIRRAL" = "10290673",
  "INSIGHT_MEDWAY" = "7173844",
  "INSIGHT_KENT_DEMO" = "2917833_DEMO",
  "INSIGHT_PETTERSBOROUGH_DEMO" = "76186_DEMO",
  "INSIGHT_NOTTS_DEMO" = "76190_DEMO",
  "INSIGHT_BASSETLAW_DEMO" = "3775518_DEMO",
  "INSIGHT_DERBY_DEMO" = "979390_DEMO",
  "INSIGHT_WIRRAL_DEMO" = "10290673_DEMO",
  "INSIGHT_MEDWAY_DEMO" = "7173844_DEMO",
  "TRENT_NOTTS" = "8836752",
  "TRENT_DERBY_WOODLANDS" = "750232",
  "TRENT_DERBY_SALTERGATE" = "8836753",
  "TRENT_NOTTS_DEMO" = "8836752_DEMO",
  "TRENT_DERBY_WOODLANDS_DEMO" = "750232_DEMO",
  "TRENT_DERBY_SALTERGATE_DEMO" = "8836753_DEMO",
  "DOVER_KM" = "2917766",
  "FAVERSHAM_KM" = "462412",
  "WITH_YOU_KM" = "3775462",
  "MIND_MATTERS_SAB" = "43379",
  "STEPS2CHANGE_STAMFORD" = "3138268",
  "STEPS2CHANGE_SPALDING" = "3664784",
  "STEPS2CHANGE_GRANTHAM" = "41430",
  "STEPS2CHANGE_BOSTON" = "7353720",
  "STEPS2CHANGE_SLEAFORD" = "4210193",
  "STEPS2CHANGE_SKEGNESS" = "6995542",
  "STEPS2CHANGE_LINCOLN" = "41434",
  "STEPS2CHANGE_LOUTH" = "3138278",
  "STEPS2CHANGE_GAINSBOROUGH" = "71684",
  "NOTTINGHAMSHIRE_NOTTS_CC" = "72602",
  "NOTTINGHAMSHIRE_NOTTS" = "1708746"
}

export type IAPTCCGMap = Partial<Record<CCGCode, IAPTIDs>>
export type IAPTGPMap = Record<string, IAPTIDs>
