import isOnline from "is-online"
import { backendEnv } from "../../config/config"
import invariant from "../../utils/invariant"
import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import delay from "../../utils/delay"
import type { IResponsePDSPatient } from "@limbic/types"
import type { IGPServiceODS } from "../../models/IGPService"

const TOTAL_RETRIES = 2

const backendEndpoints = {
  staging: "https://access-v2-staging.limbic.ai",
  development: "https://access-v2-staging.limbic.ai",
  production: "https://access-v2.limbic.ai"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const baseURL = backendEndpoints[backendEnv] || backendEndpoints.production
const FIND_PATH = `${baseURL}/v1/pds/patient/find`
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export interface IPDSFindRequestPayload {
  dob: string // 'YYYY-MM-DD'
  postcode?: string
  nameFirst?: string
  nameLast?: string
}

export enum PDSFindRequestStatus {
  NOT_FOUND = "NOT_FOUND",
  MULTIPLE_RECORDS = "MULTIPLE_RECORDS",
  NO_INTERNET = "NO_INTERNET",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED"
}

export interface IResponsePDSPatientResponse extends Omit<IResponsePDSPatient, "gp"> {
  gp?: IGPServiceODS[]
}

export async function pdsFind(
  payload: IPDSFindRequestPayload,
  retry = 0
): Promise<[IResponsePDSPatientResponse | undefined, PDSFindRequestStatus]> {
  let body = ""
  let result: Response | undefined = undefined
  try {
    if (!(await isOnline())) return [undefined, PDSFindRequestStatus.NO_INTERNET]

    body = JSON.stringify(payload)
    result = await fetch(FIND_PATH, { body, headers, method: "POST" })
    if (result.status === 204) return [undefined, PDSFindRequestStatus.NOT_FOUND]
    if (result.status === 409) return [undefined, PDSFindRequestStatus.MULTIPLE_RECORDS]

    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) throw new NetworkError(`${result.status}`, error)
    if (!success && validations) throw new Error(validations?.join(", "))
    return [data, PDSFindRequestStatus.SUCCESS]
  } catch (e) {
    Logger.getInstance().exception(e, "pdsFind fetch failed")
    if (retry < TOTAL_RETRIES) {
      const message = `pdsFind body for retry ${retry}`
      Logger.getInstance().breadcrumb({ message, data: { body } })
      Logger.getInstance().message("pdsFind retry")
      await delay(1)
      return await pdsFind(payload, retry + 1)
    }

    if (result?.status === 500) return [undefined, PDSFindRequestStatus.INTERNAL_ERROR]
    return [undefined, PDSFindRequestStatus.FAILED]
  }
}
