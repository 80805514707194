import { step } from "../../../backend/chatbot/decorators/step"
import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { DiscussionSteps } from "../../../models/Constants"
import {
  EligibilityCheckDemoScript,
  EligibilityCheckDemoScriptState
} from "./EligibilityCheckDemoDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IGPService } from "../../../models/IGPService"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"

const gpServices: IGPService[] = [
  {
    id: "0",
    pimsCode: "",
    nacsCode: "",
    name: "Demo Eligible GP",
    formattedName: "Demo Eligible GP",
    postcode: "S43 4PL",
    phoneNumber: "+44 1234 567890",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "1",
    pimsCode: "",
    nacsCode: "",
    name: "Demo Out of Area GP",
    formattedName: "Demo Out of Area GP",
    postcode: "S43 4PL",
    phoneNumber: "+44 1234 567890",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  }
]

type State = EligibilityCheckDemoScriptState

export class EligibilityCheckDemoConferenceScript extends EligibilityCheckDemoScript {
  readonly name: string = "EligibilityCheckDemoConferenceScript"

  /** Script Steps */

  @step
  sayINeedToAskSomeDetails(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `I'll need a few details to start referring you to ${organisationName}`,
        "I know that mental health can be a sensitive subject",
        "I just want to reassure you that everything you tell me will be kept confidential "
      ],
      prompt: {
        id: this.getPromptId("startEligibilityCheck"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }]
      },
      nextStep: this.goToDateOfBirthDialogue
    }
  }

  @step.logState
  goToDateOfBirthDialogue(d: IStepData<State>): IStepResult {
    const DateOfBirthDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.DateOfBirth)
    const nextDialogue = DateOfBirthDialogue ? new DateOfBirthDialogue({ ...d.state }) : undefined
    return {
      nextDialogue,
      nextStep: this.handleDateOfBirthDialogue
    }
  }

  @step.logState
  handleDateOfBirthDialogue(_d: IStepData<State>): IStepResult {
    return { nextStep: this.checkAgeThresholds }
  }

  @step.logState
  saySelectYourGP(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, in order to find the right health service for you, I first need to locate your registered GP`,
        "Please select your GP from the list"
      ],
      prompt: {
        id: this.getPromptId("saySelectYourGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          ...gpServices.map(gp => ({ body: gp.formattedName, value: gp })),
          { body: "I don't know", value: null, backgroundColor: "#da4b4b" }
        ]
      },
      nextStep: this.handleSelectYourGP
    }
  }

  sayYoureEligible(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [
        "Good news!",
        `I’m really pleased to tell you that you’re eligible for support from ${iaptName}`,
        "I can help you get set up right now"
      ],
      prompt: {
        id: this.getPromptId("sayYoureEligible"),
        type: "inlinePicker",
        choices: [{ body: "Let's do this 👉" }]
      },
      nextStep: this.goToSelfReferralDialogue
    }
  }

  @step.logState
  goToSelfReferralDialogue(d: IStepData<State>): IStepResult {
    const SelfReferralDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.SelfReferral)
    const nextDialogue = SelfReferralDialogue ? new SelfReferralDialogue({ ...d.state }) : undefined
    return {
      nextDialogue,
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getNeedsToSelfReferManually(state: State): boolean {
    return false
  }
}

export default class EligibilityCheckDemoConferenceDialogue extends AdHocDialogue<
  State,
  EligibilityCheckDemoConferenceScript
> {
  static id = DialogueIDs.EligibilityCheckDemoConference
  readonly name: string = "EligibilityCheckDemoConferenceDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckDemoConferenceDialogue.id,
      new EligibilityCheckDemoConferenceScript(),
      state,
      snapshot
    )
  }
}
