import Loggable from "../../models/Loggable"
import type {
  IAppLaunchConfigOptions,
  ICCGServiceCOnfig,
  IGPServiceConfig
} from "../../models/IAppLaunchConfig"
import type { IAPTCCGMap } from "../../models/IIAPTService"
import { IAPTGPMap, IIAPTService } from "../../models/IIAPTService"
import { ACCESS_INSTANCES } from "@limbic/types"

export default class ConfigStore extends Loggable {
  readonly name: string = "ConfigStore"
  directReferral?: boolean
  // TODO: this 👇 needs to be optional
  eligibleIAPTIds!: IIAPTService["id"][]
  iaptCCGMap?: IAPTCCGMap
  iaptGPMap?: IAPTGPMap

  key!: string
  backendInstanceID?: keyof typeof ACCESS_INSTANCES
  logo!: string
  title!: string
  serviceName?: string
  organisationName?: string | undefined
  organisationTerms?: string
  organisationPhoneNumbers?: string
  organisationGenericPhoneNumber?: string
  crisisPhoneNumbers?: string
  gpService?: IGPServiceConfig
  ccgService?: ICCGServiceCOnfig
  wellbeingHubEmails?: string[]
  userMessageBackground?: string
  fullscreen?: boolean
  iaptIcons?: string[]
  enableParams?: boolean
  faqLink!: string
  formLink?: string
  keepingSafeLogo!: string
  keepingSafeTitle!: string
  freetextLimit?: number
  enableSOS?: boolean

  constructor() {
    super()
    this.reset()
  }

  reset(): void {
    this.key = ""
    this.directReferral = false
    this.backendInstanceID = undefined
    this.logo = "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png"
    this.title = "Limbic Assistant"
    this.serviceName = undefined
    this.organisationName = undefined
    this.organisationTerms = undefined
    this.organisationPhoneNumbers = undefined
    this.organisationGenericPhoneNumber = undefined
    this.gpService = undefined
    this.ccgService = undefined
    this.wellbeingHubEmails = undefined
    this.userMessageBackground = "#6A6983"
    this.fullscreen = false
    this.iaptIcons = []
    this.formLink = undefined
    this.enableParams = false
    this.faqLink = "https://limbic.ai/faq"
    this.keepingSafeLogo = ""
    this.keepingSafeTitle = ""
    this.eligibleIAPTIds = []
    this.freetextLimit = 0
    this.enableSOS = false
  }

  setConfig(key: string, config: IAppLaunchConfigOptions): void {
    this.overrideConfigByKey("key", key)
      .overrideConfigByKey("directReferral", config.directReferral)
      .overrideConfigByKey("logo", config.logo)
      .overrideConfigByKey("title", config.title)
      .overrideConfigByKey("backendInstanceID", config.backendInstanceID)
      .overrideConfigByKey("serviceName", config.serviceName)
      .overrideConfigByKey("organisationName", config.organisationName)
      .overrideConfigByKey("organisationTerms", config.organisationTerms)
      .overrideConfigByKey("organisationPhoneNumbers", config.organisationPhoneNumbers)
      .overrideConfigByKey("crisisPhoneNumbers", config.crisisPhoneNumbers)
      .overrideConfigByKey("organisationGenericPhoneNumber", config.organisationGenericPhoneNumber)
      .overrideConfigByKey("gpService", config.gpService)
      .overrideConfigByKey("wellbeingHubEmails", config.wellbeingHubEmails)
      .overrideConfigByKey("ccgService", config.ccgService)
      .overrideConfigByKey("userMessageBackground", config.userMessageBackground)
      .overrideConfigByKey("fullscreen", config.fullscreen ?? false)
      .overrideConfigByKey("iaptIcons", config.iaptIcons ?? [])
      .overrideConfigByKey("formLink", config.formLink ?? "")
      .overrideConfigByKey("enableParams", config.enableParams ?? false)
      .overrideConfigByKey("faqLink", config.faqLink ?? "")
      .overrideConfigByKey("keepingSafeLogo", config.keepingSafeLogo ?? "")
      .overrideConfigByKey("keepingSafeTitle", config.keepingSafeTitle ?? "")
      .overrideConfigByKey("eligibleIAPTIds", config.eligibleIAPTIds)
      .overrideConfigByKey("iaptCCGMap", config.iaptCCGMap ?? {})
      .overrideConfigByKey("iaptGPMap", config.iaptGPMap ?? {})
      .overrideConfigByKey("freetextLimit", config.freetextLimit)
      .overrideConfigByKey("enableSOS", config.enableSOS)
  }

  overrideConfigByKey<Key extends keyof this>(key: Key, value?: this[Key]): ConfigStore {
    this[key] = value ?? this[key]
    return this
  }
}
