import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"

type State = CrisisScriptState

export class CrisisDemoScript extends CrisisScript {
  readonly name: string = "CrisisDemoScript"
}

export default class CrisisDemoDialogue extends AdHocDialogue<State, CrisisDemoScript> {
  static id = DialogueIDs.CrisisDemo
  readonly name: string = "CrisisDemoDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisDemoDialogue.id, new CrisisDemoScript(), state, snapshot)
  }
}
