import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import isEmail from "validator/lib/isEmail"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"

type State = BaseScriptState
export type EmailAddressDialogueScriptState = State

export class EmailAddressDialogueScript extends BaseScript<State> {
  readonly name: string = "EmailAddressDialogueScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.askEmail }
  }

  @step.logState
  askEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type your email address",
      prompt: { id: this.getPromptId("askEmail"), type: "email" },
      nextStep: this.handleEmail
    }
  }

  @step.logStateAndResponse
  async handleEmail(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isEmail(d.response)
    if (!isValid) {
      return {
        body: "Sorry this is not a valid email address. Let's try again",
        nextStep: this.askEmail
      }
    }
    d.state.email = d.response
    return { nextStep: this.end }
  }
}

export default class EmailAddressDialogue extends AdHocDialogue<State, EmailAddressDialogueScript> {
  static id = DialogueIDs.EmailAddress
  readonly name: string = "EmailAddressDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EmailAddressDialogue.id, new EmailAddressDialogueScript(), state, snapshot)
  }
}
