import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import getQRCodeURL from "../../../utils/getQRCodeURL"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"
import type { GoodbyeScriptState } from "./GoodbyeScript"

interface WindowWithLimbicNameSpace extends Window {
  DEEP_LINK: string
}

declare let window: WindowWithLimbicNameSpace

window.DEEP_LINK = String(process.env.REACT_APP_DEEP_LINK ?? "enabled")

const deepLinkEnabled = window.DEEP_LINK !== "disabled"

type State = GoodbyeScriptState

export class GoodbyeHealixScript extends GoodbyeScript {
  readonly name: string = "GoodbyeHealixScript"

  /** Script Steps */

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    // skip the dynamic link - we show
    // it in the custom recap message
    const result = await super.sayGoodBye(d)
    // TODO: when you're creating the diagram for this
    //       make sure to add the path about favourite
    //       quotes so that Bas can compare and select
    return { ...result, nextStep: this.end }
  }

  /** Generic Handlers */

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const hasDynamicLink = deepLinkEnabled && state.dynamicLink
    if (state.referralSubmitted && hasDynamicLink) {
      const qrCodeURL = getQRCodeURL(state.dynamicLink!)
      return [
        "I'm looking forward to going on this journey with you",
        "We can continue our conversations in my mobile app",
        `If you're already on your mobile device, just click [here](${state.dynamicLink}) to download it`,
        "If you're on a desktop computer, you can scan the QR code below",
        {
          type: "imageAttachment",
          image: qrCodeURL
        }
      ]
    }
  }

  async onHandleRecapMessage(
    state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    return {
      body,
      prompt: {
        id: this.getPromptId("onHandleRecapMessage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.askFeedback
    }
  }

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    // If referral failed we cannot say any of the following messages
    if (state.referralSubmitted) {
      return isCrisis
        ? `Thank you for sharing this information with me ${name}`
        : `Well, it's been a pleasure getting to know you ${name}`
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      return `You will be contacted within 5 working days to discuss next steps with you`
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    // If referral failed they cannot contact the user (no phone number)
    if (state.referralSubmitted && this.clinicalStore.isHighRisk) {
      return "Because you identified as being in crisis, someone from our duty of care team will call you within 5 working days. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger."
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    // If referral failed they cannot contact the user (no phone number)
    if (state.referralSubmitted && this.clinicalStore.isModerateRisk) {
      return "Because you identified as being in crisis, someone from our duty of care team will call you within 5 working days. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger."
    }
  }
}

export default class GoodbyeHealixDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeHealix
  readonly name: string = "GoodbyeHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeHealixDialogue.id, new GoodbyeHealixScript(), state, snapshot)
  }
}
