import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"

type State = GoodbyeScriptState

export class GoodbyeDemoScript extends GoodbyeScript {
  readonly name: string = "GoodbyeDemoScript"
}

export default class GoodbyeDemoDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeDemo
  readonly name: string = "GoodbyeDemoDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeDemoDialogue.id, new GoodbyeDemoScript(), state, snapshot)
  }
}
