import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import delay from "../../utils/delay"
import isOnline from "is-online"
import invariant from "../../utils/invariant"
import { backendEnv } from "../../config/config"
import IAppointment, { AppointmentTimeslot, IAppointmentStatus } from "../../models/IAppointment"
import moment from "moment"

const TOTAL_RETRIES = 3

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/v1/insight/appointments",
  development: "https://access-v2-staging.limbic.ai/v1/insight/appointments",
  production: "https://access-v2.limbic.ai/v1/insight/appointments"
}

const demoEndpoints = {
  staging: "https://access-v2-staging.limbic.ai/v1/insight/demo/appointments",
  development: "https://access-v2-staging.limbic.ai/v1/insight/demo/appointments",
  production: "https://access-v2.limbic.ai/v1/insight/demo/appointments"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const BASE_URL = endpoints[backendEnv] || endpoints.production
const DEMO_BASE_URL = demoEndpoints[backendEnv] || demoEndpoints.production
const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export async function getInsightAppointments(
  isDemo: boolean,
  id: string,
  retry = 0
): Promise<[IAppointment[] | undefined, IAppointmentStatus]> {
  let body = ""
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) {
      return [undefined, IAppointmentStatus.NoInternetConnection]
    }
    body = JSON.stringify({ _id: id })
    const url = isDemo ? DEMO_BASE_URL : BASE_URL
    const result = await fetch(`${url}/available`, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    const parsedData = parseAppointments(data)
    return [parsedData, IAppointmentStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "getAppointments fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`getAppointments body for retry ${retry}`, body)
      Logger.getInstance().message("getAppointments retry")
      await delay(1)
      return await getInsightAppointments(isDemo, id, retry + 1)
    }
    return [undefined, IAppointmentStatus.RequestFailed]
  }
}

export async function reserveAppointment(
  isDemo: boolean,
  id: string,
  appointmentID: string,
  retry = 0
): Promise<[Response | undefined, IAppointmentStatus]> {
  let body = ""
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) {
      return [undefined, IAppointmentStatus.NoInternetConnection]
    }
    body = JSON.stringify({ _id: id, appointmentID })
    const url = isDemo ? DEMO_BASE_URL : BASE_URL
    const result = await fetch(`${url}/reserve`, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return [data, IAppointmentStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "reserveAppointment fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`reserveAppointment body for retry ${retry}`, body)
      Logger.getInstance().message("reserveAppointment retry")
      await delay(1)
      return await reserveAppointment(isDemo, id, appointmentID, retry + 1)
    }
    return [undefined, IAppointmentStatus.RequestFailed]
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}

function parseAppointments(data: any): IAppointment[] {
  const map: Record<string, AppointmentTimeslot[]> = {}

  data.sort((a: AppointmentTimeslot, b: AppointmentTimeslot) => {
    const c: number = new Date(a.timestamp).getTime()
    const d: number = new Date(b.timestamp).getTime()
    return c - d
  })

  data.forEach(timeslot => {
    const date = moment.utc(timeslot.timestamp).format("DD MM YYYY")

    if (map[date]) {
      const formattedTimeslot = {
        ...timeslot,
        time: moment(timeslot.timestamp).format("HH:mm")
      }
      map[date] = [...map[date], formattedTimeslot]
    } else {
      const formattedTimeslot = {
        ...timeslot,
        time: moment(timeslot.timestamp).format("HH:mm")
      }
      map[date] = [formattedTimeslot]
    }
  })
  return Object.keys(map).map(date => ({ date, timeslots: map[date] }))
}
