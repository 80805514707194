import self from "./createReferral"
import { backendEnv } from "../../config/config"
import invariant from "../../utils/invariant"
import { ReferralPayload } from "@limbic/types/dist/referral"
import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import delay from "../../utils/delay"

interface Response {
  _id: string
  signupCode: string
}

const TOTAL_RETRIES = 2

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/referral/create",
  development: "https://access-v2-staging.limbic.ai/referral/create",
  production: "https://access-v2.limbic.ai/referral/create"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const CREATE_REFERRAL_URL = endpoints[backendEnv] || endpoints.production
const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export default async function createReferral(
  payload: ReferralPayload,
  retry = 0
): Promise<Response | undefined> {
  let body = ""
  try {
    body = JSON.stringify(payload)
    const result = await fetch(CREATE_REFERRAL_URL, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "createReferral fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`createReferral body for retry ${retry}`, body)
      Logger.getInstance().message("createReferral retry")
      await delay(1)
      return await self(payload, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
