import SelfReferralIAPTScript, { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = SelfReferralIAPTScriptState

export class SelfReferralDemoConferenceScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralDemoConferenceScript"

  /** Script Steps */

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    d.state.hideEarlierYouSaid = true
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [`Good news, ${name}`, `Your referral to ${iaptName} is ready to go ✉️`],
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [
          { body: "Great", value: false },
          { body: "Good to know", value: false }
        ]
      },
      nextStep: this.end
    }
  }

  @step.logState
  askPhoneNumber(d: IStepData<State>): IStepResult {
    if (d.state.phoneNumber) return { nextStep: this.askCanIContactYouOnPhoneNumber }
    return super.askPhoneNumber(d)
  }

  @step.logState
  askDoYouHaveAnEmail(d: IStepData<State>): IStepResult {
    if (d.state.email) return { nextStep: this.checkPostCodeFromAddressLookup }
    return super.askDoYouHaveAnEmail(d)
  }

  /** Generic Handlers */

  @step.logState
  async doReferralSubmission(d: IStepData<State>): Promise<IStepResult> {
    d.state.referralSubmitted = true
    d.state.referralSubmissionFailed = false
    this.setPeople({ referralSubmitted: true })
    return { nextStep: this.sayReferralSucceeded }
  }

  // we won't do any referrals
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async getReferralPayload(state: State): Promise<any> {}
}

export default class SelfReferralDemoConferenceDialogue extends AdHocDialogue<
  State,
  SelfReferralDemoConferenceScript
> {
  static id = DialogueIDs.SelfReferralDemoConference
  readonly name = "SelfReferralDemoConferenceDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralDemoConferenceDialogue.id,
      new SelfReferralDemoConferenceScript(),
      state,
      snapshot
    )
  }
}
