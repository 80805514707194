import { backendEnv } from "../../config/config"
import invariant from "../../utils/invariant"
import { IReferralDoc } from "@limbic/types"
import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import delay from "../../utils/delay"

const TOTAL_RETRIES = 2

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/referral/show",
  development: "https://access-v2-staging.limbic.ai/referral/show",
  production: "https://access-v2.limbic.ai/referral/show"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const GET_REFERRAL_URL = endpoints[backendEnv] || endpoints.production
const method = "GET"
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export default async function getReferral(
  _id: string,
  retry = 0
): Promise<IReferralDoc | undefined> {
  invariant(_id, "Cannot get referral without an id")
  try {
    const path = `${GET_REFERRAL_URL}/${_id}`
    const result = await fetch(path, { method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "createReferral fetch failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("updateReferral retry")
      await delay(1)
      return await getReferral(_id, retry + 1)
    }
    throw e
  }
}
