import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import getNextWorkingDay from "../../../utils/getNextWorkingDay"
import moment from "moment"

interface State extends GoodbyeScriptState {
  paymentURL?: string
}

export type GoodbyeVitalityScriptState = State

export class GoodbyeVitalityScript extends GoodbyeScript {
  readonly name: string = "GoodbyeVitalityScript"

  /** Script Steps */

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    // skip the dynamic link - we show
    // it in the custom recap message and
    // skip favorite quotes - go to showPaymentLink
    const result = await super.sayGoodBye(d)
    return { ...result, nextStep: this.showPaymentLink }
  }

  @step.logState
  showPaymentLink(d: IStepData<State, true | undefined>): IStepResult {
    const name = this.getName(d.state)
    const great = d.response ? ["Great"] : []
    const hasPaymentURL = !!d.state.paymentURL
    const link = d.state.paymentURL
    const paymentURL = hasPaymentURL
      ? [
          "As part of our Vitality Health policy, you may be liable for certain charges from this point onwards",
          "I'm now going to hand over to IPRS Health to tell you more",
          `Please click on the following link: [Policy charges explained](${link})`
        ]
      : []
    return {
      body: [...great, ...paymentURL, `Goodbye ${name} 👋`],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    if (state.referralSubmitted) {
      return undefined
    }

    if (!state.referralSubmitted) {
      const name = this.getName(state)
      return [`I'm sorry I wasn't able to help you today ${name}`]
    }
  }

  async onHandleRecapMessage(
    state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    return {
      body,
      prompt: {
        id: this.getPromptId("onHandleRecapMessage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: state.referralSubmitted ? this.askFeedback : this.sayGoodBye
    }
  }

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    // If referral failed we cannot say any of the following messages
    if (state.referralSubmitted) {
      return isCrisis
        ? `Thank you for sharing this information with me ${name}`
        : `Well, it's been a pleasure getting to know you ${name}`
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const appointmentSet = this.referralStore.getCustomField("appointmentSet")
      const serviceName = this.rootStore.configStore.serviceName
      const organisationName = this.rootStore.configStore.organisationName
      if (appointmentSet) {
        const timestamps = this.referralStore.getCustomField("appointmentTimestamps")
        const [timeStart] = timestamps!.split("&&")
        const start = moment(timeStart).format("DD/MM/YYYY H:mm")
        // TODO: May need to add time of appointment in both messages 👇
        return state.isIdleSubmitted //
          ? `It looks like there hasn't been any activity for some time so I've shared your responses with the ${serviceName} team\n\n${organisationName} customer service team will be in contact with you within 1 working day to progress your referral\n\nIf you want to speak to them sooner they can be contacted Monday-Friday 9am-5pm on 0800 2545244`
          : `I've referred you to ${serviceName} and your appointment is booked for ${start}`
      }
      if (this.clinicalStore.isCrisis) {
        return undefined
      }
      return state.isIdleSubmitted
        ? `It looks like there hasn't been any activity for some time so I've shared your responses with the ${serviceName} team\n\n${organisationName} customer service team will be in contact with you within 1 working day to progress your referral\n\nIf you want to speak to them sooner they can be contacted Monday-Friday 9am-5pm on 0800 2545244`
        : `I've referred you to ${serviceName}. Someone from our admin team will be in touch the next working day to book you in\n\nIf you want to speak to them sooner they can be contacted Monday-Friday 9am-5pm on 0800 2545244`
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    // If referral failed they cannot contact the user (no phone number)
    if (state.referralSubmitted && this.clinicalStore.isHighRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const nextWorkingDay = await getNextWorkingDay()
      return `Because you identified as being in crisis, someone from the ${organisationName} duty clinical team will call you ${nextWorkingDay}. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger`
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    // If referral failed they cannot contact the user (no phone number)
    if (state.referralSubmitted && this.clinicalStore.isModerateRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const nextWorkingDay = await getNextWorkingDay()
      return `Because you identified as being in crisis, someone from the ${organisationName} duty clinical team will call you ${nextWorkingDay}. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger`
    }
  }
}

export default class GoodbyeVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeVitality
  readonly name: string = "GoodbyeVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeVitalityDialogue.id, new GoodbyeVitalityScript(), state, snapshot)
  }
}
