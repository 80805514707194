const customFields: Record<string, Record<"allFields" | "requiredFields", string[]>> = {
  HEALIX: {
    allFields: [
      "firstName",
      "middleNames",
      "lastName",
      "address",
      "dateOfBirth",
      "companyName",
      "memberID",
      "coverageLevel",
      "coverEndDate",
      "insurerID",
      "availableCover"
    ],
    requiredFields: ["coverEndDate", "insurerID", "availableCover"]
  },
  VITALITY: {
    // TODO: Review all fields
    allFields: [
      "firstName",
      "middleNames",
      "lastName",
      "emailAddress",
      "telephoneNumber",
      "postCode",
      "address",
      "dateOfBirth", // NOTE: they do not provide DOB, but age
      "entityNumber",
      "claimNumber"
    ],
    // TODO: Confirm required fields
    requiredFields: ["entityNumber"]
  }
}

export default customFields
