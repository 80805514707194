import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PeaceOfMindIAPTScript } from "./PeaceOfMindIAPTDialogue"
import type { PeaceOfMindIAPTScriptState } from "./PeaceOfMindIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = PeaceOfMindIAPTScriptState

export class PeaceOfMindDemoPrivateProvidersScript extends PeaceOfMindIAPTScript {
  readonly name: string = "PeaceOfMindDemoPrivateProvidersScript"

  /** Script Steps */

  @step.logState
  sayItsConfidential(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Fantastic! You're in the right place 👌",
        "Now, before we continue, I want to be very clear about something:",
        "<b>This conversation is completely confidential and separate from your place of work</b>",
        "The information you share will only be used to help find the best support for you - that's it"
      ],
      nextStep: this.promptUserToAgree
    }
  }
}

export default class PeaceOfMindDemoPrivateProvidersDialogue extends Dialogue<State> {
  static id = DialogueIDs.PeaceOfMindDemoPrivateProviders
  readonly name: string = "PeaceOfMindDemoPrivateProvidersDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      PeaceOfMindDemoPrivateProvidersDialogue.id,
      new PeaceOfMindDemoPrivateProvidersScript(),
      state,
      snapshot
    )
  }
}
