import { IClinicalPath, IClinicalPathOverride } from "../../models/IClinicalPath"

export function mergeOverrides(
  paths: IClinicalPath[],
  overrides: Record<string, IClinicalPathOverride>
): IClinicalPath[] {
  if (!paths.length) return paths
  return paths.map(path => {
    const override = overrides[path.id] || {}
    return { ...path, ...override }
  })
}
