import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import IDynamicLink from "../../models/IDynamicLink"

const URL = "https://limbic-firebase-dynamic-links.herokuapp.com/create"
const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")

export async function createDynamicLink(data: IDynamicLink): Promise<string | undefined> {
  try {
    const payload = { data, name: data.signupCode, suffix: data.signupCode }
    const body = JSON.stringify(payload)
    const result = await fetch(URL, { method, headers, body })
    const res = await result.json()
    if (!res?.success) {
      throw new NetworkError(undefined, res?.error)
    }
    return res?.data?.link
  } catch (e) {
    Logger.getInstance().exception(e, `post ${URL} failed`)
  }
}
