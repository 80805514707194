import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import delay from "../../utils/delay"
import isOnline from "is-online"
import invariant from "../../utils/invariant"
import { backendEnv } from "../../config/config"
import { SubmitReferralStatus } from "../../models/SubmitReferral"
import Base64 from "../../utils/base64"

const TOTAL_RETRIES = 3

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/v1/vitality/referral/submit",
  development: "https://access-v2-staging.limbic.ai/v1/vitality/referral/submit",
  production: "https://access-v2.limbic.ai/v1/vitality/referral/submit"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const BASE_URL = endpoints[backendEnv] || endpoints.production
const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")

export async function submitVitalityReferral(
  patientId: string,
  retry = 0
): Promise<[boolean, string, SubmitReferralStatus]> {
  const xApiKey = `${Date.now().toString(16)}.${Base64.btoa(API_KEY)}.${Base64.btoa(patientId)}`
  headers.set("x-api-key", xApiKey)
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) {
      return [false, "", SubmitReferralStatus.NoInternetConnection]
    }
    const result = await fetch(BASE_URL, { method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }

    const isSubmitSuccessful = data && data?.status === "SUCCESS"
    return [isSubmitSuccessful, data.serviceMeta.paymentURL, SubmitReferralStatus.Success]
  } catch (e) {
    Logger.getInstance().breadcrumb({
      message: "submitVitalityReferral fetch failed",
      data: { id: patientId }
    })
    Logger.getInstance().exception(e, "submitVitalityReferral fetch failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("submitVitalityReferral retry")
      await delay(1)
      return await submitVitalityReferral(patientId, retry + 1)
    }
    return [false, "", SubmitReferralStatus.RequestFailed]
  }
}
