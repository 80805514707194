import React from "react"
import { useObserver } from "mobx-react"
import RootStore from "../stores/rootStore"
import type ApplicationStore from "../stores/applicationStore"
import type ConfigStore from "../stores/configStore"
import type BotStore from "../stores/botStore"
import type ChatStore from "../stores/chatStore"
import type ReferralStore from "../stores/referralStore"

const ctx = React.createContext<RootStore | null>(null)

interface Props {
  rootStore?: RootStore
  children: React.ReactChild
}

export function RootStoreProvider(props: Props): JSX.Element {
  const { rootStore, children } = props
  return <ctx.Provider value={rootStore ?? RootStore.getInstance()}>{children}</ctx.Provider>
}

export function useRootStore(): RootStore {
  const store = React.useContext(ctx)
  if (!store) {
    throw new Error("useRootStore must be used within a RootStoreProvider")
  }
  return useObserver(() => store)
}

export function useApplicationStore(): ApplicationStore {
  const store = useRootStore()
  return store.applicationStore
}

export function useConfigStore(): ConfigStore {
  const store = useRootStore()
  return store.configStore
}

export function useBotStore(): BotStore {
  const store = useRootStore()
  return store.botStore
}

export function useChatStore(): ChatStore {
  const store = useRootStore()
  return store.chatStore
}

export function useReferralStore(): ReferralStore {
  const store = useRootStore()
  return store.referralStore
}
