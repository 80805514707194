import moment from "moment"
import {
  EligibilityCheckIAPTScript,
  EligibilityCheckIAPTState
} from "./EligibilityCheckIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import {
  IResponsePDSPatientResponse,
  pdsFind,
  PDSFindRequestStatus
} from "../../../backend/api/pds"
import invariant from "../../../utils/invariant"
import { getPostCodeDetails } from "../../../backend/api/postcodes"
import { PostcodeStatus } from "../../../models/IPostcode"
import { TrackingEvents } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type IName from "../../../models/IName"
import type ISelectable from "../../../models/ISelectable"

export interface EligibilityCheckWithPDSScriptState extends EligibilityCheckIAPTState {
  spineSearchCount?: number
  explanationCount?: number
  allowSkipPDS?: boolean
}

export abstract class EligibilityCheckWithPDSScript<
  State extends EligibilityCheckWithPDSScriptState = EligibilityCheckWithPDSScriptState
> extends EligibilityCheckIAPTScript<State> {
  readonly name: string = "EligibilityCheckWithPDSScriptState"
  readonly FAILED_ATTEMPTS_THRESHOLD: number = 0

  /** Optional Abstract Overrides */

  onFailedSpineSearchCountReached?(state: State): Promise<IStepResult | undefined>
  getFailedSpineSearchCountThreshold?(state: State): Promise<number>

  /** Scripts Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  sayIntroToSpineSearch(_d: IStepData<State>): IStepResult {
    return {
      body: "Alright, I'm just going to search you in the NHS database with the details you've given me",
      nextStep: this.fetchSpineData
    }
  }

  @step.logState
  @step.startTyping
  @step.delay(1)
  async fetchSpineData(d: IStepData<State>): Promise<IStepResult> {
    d.state.spineSearchCount ??= 0
    d.state.spineSearchCount++
    d.state.retryPostcodeTimes ??= 0
    d.state.retryPostcode = undefined
    d.state.allowSkipPDS = false
    const { userPostcode, birthday } = d.state
    const { firstName, middleNames } = d.state.name!
    const dob = moment(birthday).format("YYYY-MM-DD")
    const pdsPayload = {
      dob,
      postcode: userPostcode?.postcode,
      nameFirst: `${firstName}${middleNames ? ` ${middleNames}` : ""}`,
      nameLast: this.getLastName(d.state)
    }
    const [data, status] = await pdsFind(pdsPayload)
    this.track(TrackingEvents.SPINE_SEARCH_RESULT, { result: status, hasGP: !!data?.gp?.length })

    if (status === PDSFindRequestStatus.SUCCESS) {
      if (d.state.spineSearchCount === 1) this.track(TrackingEvents.SPINE_SUCCESS_ON_1ST_TRY)
      this.setPeople({ spineSearchSuccessFull: true, spineSearchCount: d.state.spineSearchCount })
      const { name, nhsNumber, gp, gender, language, interpreterRequired } = data ?? {}
      if (name?.length) {
        const spineName = {} as any
        const first = name[0]?.first
        const last = name[0]?.last
        if (first?.length) {
          const [firstName, ...middleNames] = first
          spineName.firstName = firstName
          spineName.middleNames = middleNames.join(" ")
        }
        if (last) spineName.lastName = last
        if (spineName.firstName && spineName.lastName) d.state.spineName = spineName
      }
      if (nhsNumber) d.state.nhsNumber = nhsNumber
      if (gp?.length) this.setODSGP(d.state, gp[0])
      if (gender) d.state.spineGender = gender
      if (language) d.state.spineLanguage = language
      if (interpreterRequired != null) d.state.spineInterpreterRequired = interpreterRequired
      const { postcode } = this.extractPDSAddress(data?.address?.[0])
      if (postcode && d.state.userPostcode?.postcode) d.state.userPostcode.postcode = postcode
      if (d.state.odsGP) return { body: "Found you!", nextStep: this.selectIAPTServiceByODSGP }
      else return { nextStep: this.sayICouldntFindYourGP }
    }

    if (status === PDSFindRequestStatus.NO_INTERNET) {
      // this attempt doesn't need to count since they
      // didn't even make it due to lack of internet,
      // so we revert the count bump
      d.state.spineSearchCount = Math.max(0, d.state.spineSearchCount - 1)
      return { nextStep: this.askRetryInternetConnectionForFetchSpineData }
    }

    // prettier-ignore
    if (this.FAILED_ATTEMPTS_THRESHOLD && d.state.spineSearchCount >= this.FAILED_ATTEMPTS_THRESHOLD) {
      const result = await this.onFailedSpineSearchCountReached?.(d.state)
      if (result) return result
    }

    switch (status) {
      case PDSFindRequestStatus.NOT_FOUND:
        return { nextStep: this.sayICouldntFindYouInPDS }
      case PDSFindRequestStatus.MULTIPLE_RECORDS:
        d.state.allowSkipPDS = true
        return { nextStep: this.sayICouldntFindYouInPDS }
      case PDSFindRequestStatus.INTERNAL_ERROR:
      default:
        //prettier-ignore
        this.setPeople({ spineSearchSuccessFull: false, spineSearchCount: d.state.spineSearchCount })
        return { nextStep: this.sayICouldntFindYouInPDSAndGoManual }
    }
  }

  @step.logState
  askRetryInternetConnectionForFetchSpineData(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryInternetConnectionForFetchSpineData"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.fetchSpineData
    }
  }

  @step.logState
  sayICouldntFindYourGP(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Found you!",
        "Hmm, however it looks like I wasn't able to find your GP",
        "Let me see if I can find your GP in an other way"
      ],
      nextStep: this.askSelectGPFromUserPostcode
    }
  }

  @step.logState
  sayICouldntFindYouInPDS(d: IStepData<State>): IStepResult {
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers?.length
      ? this.rootStore.configStore.organisationPhoneNumbers
      : this.rootStore.configStore.organisationGenericPhoneNumber
    const body =
      d.state.spineSearchCount! >= 3
        ? [
            "It seems like you are having trouble entering details that match with the NHS database",
            `If you'd rather call into the service and talk to a human, you can do so here:\n${organisationPhoneNumbers}`
          ]
        : [
            "Hmm, it looks like I wasn't able to find you in the NHS Database...",
            "Most of the time, this is because you're registered with your GP under your Christian name or old address still"
          ]
    return {
      body,
      nextStep: this.askConfirmDetails
    }
  }

  @step.logState
  sayICouldntFindYouInPDSAndGoManual(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmm, it looks like I wasn't able to find you in the NHS Database...",
      nextStep: this.sayAnotherWay
    }
  }

  @step.logState
  sayAnotherWay(_d: IStepData<State>): IStepResult {
    return {
      body: "Let me see if I can find your GP in an other way",
      nextStep: this.askSelectGPFromUserPostcode
    }
  }

  @step.logState
  askConfirmDetails(d: IStepData<State>): IStepResult {
    const name = d.state.name //
      ? `<b>Name</b>: ${this.getFullName(d.state)}`
      : undefined
    const birthday = d.state.birthday
      ? `<b>Date of birth</b>: ${moment(d.state.birthday).format("DD MMMM YYYY")}`
      : undefined
    const postcode = d.state.userPostcode?.postcode
      ? `<b>Postcode</b>: ${d.state.userPostcode?.postcode}`
      : undefined
    const details = [name, birthday, postcode].filter(Boolean).join("\n")
    return {
      body: `The information you've provided me with are:\n${details}`,
      nextStep: this.promptConfirmDetails
    }
  }

  @step.logState
  promptConfirmDetails(d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("promptConfirmDetails"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Change name", value: "name", fullWidth: true },
          { body: "Change date of birth", value: "dob", fullWidth: true },
          { body: "Change postcode", value: "postcode", fullWidth: true },
          d.state.allowSkipPDS
            ? { body: "Try another way", value: "skip", fullWidth: true }
            : undefined,
          !d.state.explanationCount
            ? { body: "Help me figure out what's wrong", value: "explain", fullWidth: true }
            : undefined
        ].filter(Boolean) as ISelectable[]
      },
      nextStep: this.handleConfirmDetails
    }
  }

  @step.logState
  handleConfirmDetails(
    d: IStepData<State, "name" | "dob" | "postcode" | "explain" | "skip">
  ): IStepResult {
    if (d.response === "name") return { nextStep: this.askNameAgain }
    if (d.response === "dob") return { nextStep: this.askBirthdayAgain }
    if (d.response === "postcode") return { nextStep: this.askPostcodeAgain }
    if (d.response === "skip") return { body: "Okay", nextStep: this.sayAnotherWay }
    return { nextStep: this.sayExplanation }
  }

  @step.logState
  askNameAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your full name",
      prompt: {
        id: this.getPromptId("askNameAgain"),
        type: "name",
        dataPointsName: "showPromptForFullName"
      },
      nextStep: this.handleNameAgain
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (d: IStepData<State, IName>, script: EligibilityCheckWithPDSScript<State>) => {
      d.state.name = d.response
      const username = script.getFullName(d.state)
      d.state.username = username
      script.rootStore.applicationStore.setUsername(username)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getInput: (d: IStepData<State, IName>) => {
      const { firstName, lastName, middleNames } = d.response
      return `${firstName}${middleNames ? ` ${middleNames}` : ""} ${lastName}`
    },
    getNextStep: (s: EligibilityCheckWithPDSScript<State>) => s.askNameAgain
  })
  handleNameAgain(_d: IStepData<State>): IStepResult {
    return { nextStep: this.fetchSpineData }
  }

  @step.logState
  askBirthdayAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your date of birth",
      nextStep: this.showPromptForBirthdayAgain
    }
  }

  @step.logState
  showPromptForBirthdayAgain(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForBirthdayAgain"),
        trackResponse: true,
        type: "date"
      },
      nextStep: this.handleBirthdayAgain
    }
  }

  @step.logState
  handleBirthdayAgain(d: IStepData<State>): IStepResult {
    try {
      const date = moment(d.response)
      invariant(date, "I'm sorry that's not a valid date. Please enter your date of birth")
      invariant(
        date.isValid(),
        "I'm sorry that's not a valid date. Please enter your date of birth"
      )
      invariant(
        date.isBefore(moment()),
        "Hmm… I don’t think humans can time-travel. Can you try and edit your date of birth?"
      )
      invariant(
        date.isAfter(moment("1899-12-31")),
        "Hmm… I don’t think humans live that long. Can you try and edit your date of birth?"
      )
      d.state.birthday = date.toDate().getTime()
      this.setPeople({ age: moment().diff(date, "years") })
    } catch (e) {
      this.logException(e, "handleBirthdayAgain")
      return {
        body: e.message,
        nextStep: this.showPromptForBirthdayAgain
      }
    }
    return { nextStep: this.fetchSpineData }
  }

  @step.logState
  askPostcodeAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type your postcode below",
      prompt: {
        id: this.getPromptId("askPostcodeAgain"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostcodeAgain
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.checkInputForCrisis({
    getNextStep: (s: EligibilityCheckWithPDSScript) => s.askPostcodeAgain
  })
  async handlePostcodeAgain(d: IStepData<State>): Promise<IStepResult> {
    d.state.postcodeEntered = d.response || d.state.retryPostcode
    d.state.retryPostcodeTimes ??= 0
    d.state.retryPostcode = d.state.postcodeEntered

    const [postcode, postcodeStatus] = await getPostCodeDetails(d.response || d.state.retryPostcode)

    if (postcodeStatus === PostcodeStatus.Success) {
      d.state.userPostcode = postcode
      return { nextStep: this.fetchSpineData }
    }

    if (postcodeStatus === PostcodeStatus.NoInternetConnection) {
      return { nextStep: this.askRetryForOfflinePostcodeAgain }
    }
    const isInvalidPostcode = postcodeStatus === PostcodeStatus.InvalidPostcode
    const isNotFoundPostcode = postcodeStatus === PostcodeStatus.PostcodeNotFound
    if (isInvalidPostcode || isNotFoundPostcode) {
      const body = isInvalidPostcode
        ? "Hmmm, this doesn't seem to be a valid UK postcode"
        : "Hmmm, unfortunately I can't find this postcode"
      return {
        body,
        nextStep: this.askTypeItCorrectlyForPostcodeAgain
      }
    }
    if (postcodeStatus === PostcodeStatus.RequestFailed && d.state.retryPostcodeTimes < 3) {
      // 📎 check commend about retryPostcode at the top of this method
      d.state.retryPostcodeTimes = d.state.retryPostcodeTimes + 1
      return { nextStep: this.askRetryForPostcodeAgain }
    }

    d.state.retryPostcodeTimes = 0
    return {
      body: [
        "Oh dear, for some reason I can't find anything using your postcode. Sorry about that.",
        "Don't worry if your postcode is correct, I can help you find your GP another way"
      ],
      nextStep: this.askDoYouKnowThePostCodeOfGP
    }
  }

  @step.logState
  askRetryForPostcodeAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like something went wrong while looking up your postcode",
      prompt: {
        id: this.getPromptId("askRetryForPostcodeAgain"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Try again", value: false },
          { body: "Oops, let me re-type the postcode", value: true }
        ],
        isUndoAble: false
      },
      nextStep: this.handleRetryForPostcodeAgain
    }
  }

  @step.logState
  askRetryForOfflinePostcodeAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryConnection"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.handleRetryForPostcodeAgain
    }
  }

  @step.logStateAndResponse
  handleRetryForPostcodeAgain(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.track(TrackingEvents.RE_ENTER_POSTCODE)
      return { nextStep: this.askPostcodeAgain }
    }
    this.track(TrackingEvents.TRY_AGAIN_POSTCODE)
    return { nextStep: this.handlePostcodeAgain }
  }

  @step.logState
  askTypeItCorrectlyForPostcodeAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you do me a favour and double check you typed it in correctly?",
      prompt: {
        id: this.getPromptId("askTypeItCorrectlyForPostcodeAgain"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "It's correct", value: true },
          { body: "Oops, let me re-type it", value: false }
        ]
      },
      nextStep: this.handleTypeItCorrectlyForPostcodeAgain
    }
  }

  @step.logStateAndResponse
  async handleTypeItCorrectlyForPostcodeAgain(d: IStepData<State, boolean>): Promise<IStepResult> {
    if (d.response) {
      this.track(TrackingEvents.INVALID_POSTCODE, { postcode: d.state.postcodeEntered })
      return {
        body: [
          "Oh dear, for some reason I couldn't find anything using your postcode. Sorry about that.",
          "Don't worry if your postcode is correct, I can help you find your GP another way"
        ],
        nextStep: this.askDoYouKnowThePostCodeOfGP
      }
    }
    d.state.retryPostcode = undefined
    const name = this.getName(d.state)
    return { body: `No worries ${name}`, nextStep: this.askPostcodeAgain }
  }

  @step.logState
  sayExplanation(d: IStepData<State>): IStepResult {
    d.state.explanationCount ??= 0
    d.state.explanationCount++
    const organisationName = this.rootStore.configStore.organisationName
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers ?? ""
    return {
      body: [
        "The reason as to why you can't be found in the NHS database is because the information you have provided is different from what what you are registered with at your GP",
        "Common reasons for confusion are:\n" +
          "\n" +
          "1. You are married, but you are still registered with your GP under your maiden name\n" +
          "2. You have recently moved, but you haven't updated your GP with your new address",
        `If this has helped, you can go ahead and edit some of your information. Alternatively, you can try to phone ${organisationName} on ${organisationPhoneNumbers} and they will be able to help you`
      ],
      nextStep: this.promptConfirmDetails
    }
  }

  @step
  async selectIAPTServiceByGP(d: IStepData<State>): Promise<IStepResult> {
    return await this.selectIAPTServiceByODSGP(d)
  }

  /** Generic Handlers */

  async onPostcodeOfUserSuccessful(state: State): Promise<IStepResult> {
    return { nextStep: this.sayIntroToSpineSearch }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  extractPDSAddress(a?: Exclude<IResponsePDSPatientResponse["address"], undefined>[0]) {
    if (!a?.lines.length) return {}
    const [premises, street, locality, city, county] = a.lines as Array<string | undefined>
    const address = [premises, street, locality].filter(Boolean).join(", ")
    return {
      address,
      city: city ?? locality ?? county,
      county,
      postcode: a.postcode
    }
  }
}
