/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import invariant from "../../../utils/invariant"
import { ALCOHOL_FREQUENCIES, ALCOHOL_QUANTITIES, VHGTitles } from "../../../models/Constants"
import {
  ALCOHOL_QUANTITIES as AQ,
  ALCOHOL_FREQUENCIES as AF,
  ETHNICITY_MAYDEN_VHG,
  ARMED_FORCES_MAYDEN_VHG,
  PERINATAL_MAYDEN_VHG,
  GenderBirthAssigned
} from "@limbic/types"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import type {
  DISABILITY_MAYDEN_VHG,
  GENDER_MAYDEN_VHG,
  LIMBIC_IMPACT_LEVEL,
  LTC_MAYDEN_VHG,
  ReferralPayloadMaydenVHG,
  SEXUALITY_MAYDEN_VHG
} from "@limbic/types"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  ltcs,
  perinatalStatuses,
  sameGenderAsBirth,
  sexualities
} from "../../../config/referralForms/vhg-main"

interface State extends SelfReferralIAPTScriptState {
  whatDoYouExpectFromTherapy?: string
  mainIssue?: string
  preferredTitle?: VHGTitles
}

export type SelfReferralVHGScriptState = State

export class SelfReferralVHGScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralVHGScript"

  /** Script State */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0 })
  sayIntro(d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: `I'm now going to add you to ${iaptName} as a new referral`,
      nextStep: this.askWannaDoSelfReferral
    }
  }

  @step
  askWannaDoSelfReferral(_d: IStepData<State>): IStepResult {
    return {
      body: "There are just a few more details I need from you",
      prompt: {
        id: this.getPromptId("askWannaDoSelfReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Let's do it" }, { body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.startSelfReferralPart1
    }
  }

  @step
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askMainIssue }
  }

  @step.logState
  askMainIssue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `So ${name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you)`,
      prompt: {
        id: this.getPromptId("askMainIssue"),
        trackResponse: true,
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssue
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralVHGScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
  })
  handleMainIssue(d: IStepData<State, string>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thank you for sharing ${name}, you've come to the right place`,
      nextStep: this.askWhatDoYouExpectFromTherapy
    }
  }

  @step.logState
  askWhatDoYouExpectFromTherapy(_d: IStepData<State>): IStepResult {
    return {
      body: "And can you tell me what you're hoping to get out of therapy?",
      prompt: {
        id: this.getPromptId("askWhatDoYouExpectFromTherapy"),
        trackResponse: true,
        type: "text",
        forceValue: true,
        dataPointsName: "askWhatDoYouExpectFromTherapy"
      },
      nextStep: this.handleWhatDoYouExpectFromTherapy
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralVHGScript) => {
    d.state.whatDoYouExpectFromTherapy = d.response
    script.referralStore.setCustomField<State>("whatDoYouExpectFromTherapy", d.response)
  })
  handleWhatDoYouExpectFromTherapy(_d: IStepData<State, string>): IStepResult {
    return {
      body: ["Thanks, that's helpful to understand", "Now then..."],
      nextStep: this.askPhoneNumber
    }
  }

  @step.logState
  askPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "What's the best phone number to reach you on?",
      prompt: {
        id: this.getPromptId("askPhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [`Good news, ${name}`, `Your referral to ${iaptName} is nearly ready to go ✉️`],
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [
          { body: "Great", value: false },
          { body: "Good to know", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async getReferralPayload(state: State): Promise<ReferralPayloadMaydenVHG> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const treatmentExpectation = this.getCustomField("whatDoYouExpectFromTherapy")
    const problemByPatient = this.getCustomField("mainIssue")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirthAssigned(state),
      nhsNumber: state.nhsNumber,
      disability: this.getDisability(state),
      perinatal: this.getPerinatal(state),
      title: state.preferredTitle ?? state.title,
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForce(state),
      sexuality: this.getSexuality(state),
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      consentDataShare: true,
      consentDataStore: true,
      speakEnglish: true,
      gpCodeNACS: state.odsGP?.id ?? state.gp?.nacsCode,
      gpName: state.odsGP?.name ?? state.gp?.name,
      gpPractice: state.odsGP?.name ?? state.gp?.name,
      alcohol: state.alcohol,
      alcoholFrequency: this.getAlcoholFrequency(state),
      alcoholQuantity: this.getAlcoholQuantity(state),
      substances: state.substances,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      medication:
        state.substancesAreMedications && state.medicationInfo //
          ? [state.medicationInfo]
          : undefined,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      currentSupport: state.hasCurrentSupport,
      problemByPatient,
      treatmentExpectation,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords
    }
  }

  getGender(state: State): GENDER_MAYDEN_VHG {
    const gender = genders[state.gender!]
    return gender ?? "UNKNOWN"
  }

  getGenderSameAsBirthAssigned(state: State): GenderBirthAssigned {
    const genderSameAsBirthAssigned = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirthAssigned ?? "UNKNOWN"
  }

  getDisability(state: State): DISABILITY_MAYDEN_VHG {
    const disability = disabilities[state.disability!]
    return disability ?? "NONE"
  }

  getPerinatal(state: State): PERINATAL_MAYDEN_VHG {
    const perinatal = perinatalStatuses[state.perinatalStatus!]
    return perinatal ?? "NONE"
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_VHG {
    const ethnicity = ethnicities[state.ethnicity!]
    return ethnicity ?? "UNKNOWN"
  }

  getArmedForce(state: State): ARMED_FORCES_MAYDEN_VHG {
    const armedForces = exArmedForces[state.isExArmedForces!]
    return armedForces ?? "NO"
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_VHG {
    const sexuality = sexualities[state.sexuality!]
    return sexuality ?? "UNKNOWN"
  }

  getLTC(state: State): LTC_MAYDEN_VHG[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getAlcoholFrequency(state: State): typeof AF[keyof typeof AF] | undefined {
    const map = {
      [ALCOHOL_FREQUENCIES.MONTHLY]: AF.MONTHLY,
      [ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4]: AF.MONTHLY_2_TO_4,
      [ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3]: AF.WEEKLY_2_TO_3,
      [ALCOHOL_FREQUENCIES.WEEKLY_4]: AF.WEEKLY_4
    }
    return map[state.alcoholFrequency!]
  }

  getAlcoholQuantity(state: State): typeof AQ[keyof typeof AQ] | undefined {
    const map = {
      [ALCOHOL_QUANTITIES._0_2]: AQ._0_2,
      [ALCOHOL_QUANTITIES._3_4]: AQ._3_4,
      [ALCOHOL_QUANTITIES._5_6]: AQ._5_6,
      [ALCOHOL_QUANTITIES._7_9]: AQ._7_9,
      [ALCOHOL_QUANTITIES._10_PLUS]: AQ._10_PLUS
    }
    return map[state.alcoholQuantity!]
  }

  getCustomField(field: keyof State): any {
    return this.referralStore.getCustomField(field)
  }

  getCustomReferralType(state: State): string | undefined {
    if (this.clinicalStore.assessmentFinished) return "Limbic Assessment"
    return "Self Referral"
  }

  async onHandleGender(_state: State): Promise<IStepResult> {
    return { nextStep: this.askSameGenderAsBirth }
  }

  async onHandleGenderSameAsBirth(_state: State): Promise<IStepResult> {
    return { nextStep: this.askPerinatal }
  }
}

export default class SelfReferralVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralVHG
  readonly name: string = "SelfReferralVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralVHGDialogue.id, new SelfReferralVHGScript(), state, snapshot)
  }
}
