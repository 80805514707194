import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScriptState } from "./GetNameScript"
import { GetNameIAPTScript } from "./GetNameIAPTDialogue"

type State = GetNameScriptState
export type GetNameInsightWaitlistScriptState = State

export class GetNameInsightWaitlistScript extends GetNameIAPTScript {
  readonly name: string = "GetNameInsightWaitlistScript"

  /** Script Steps */

  @step
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Nice to meet you ${name}`,
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: "Nice to meet you too" }]
      },
      nextStep: this.end
    }
  }
}

export default class GetNameInsightWaitlistDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameInsightWaitlist
  readonly name: string = "GetNameInsightWaitlistDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameInsightWaitlistDialogue.id, new GetNameInsightWaitlistScript(), state, snapshot)
  }
}
