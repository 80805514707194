import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import TreatmentOptionsScript from "./TreatmentOptionsScript"
import type { TreatmentOptionsIAPTScriptState } from "./TreatmentOptionsIAPTDialogue"

type State = TreatmentOptionsIAPTScriptState

export class TreatmentOptionsHealixScript extends TreatmentOptionsScript {
  readonly name: string = "TreatmentOptionsHealixScript"
}

export default class TreatmentOptionsHealixDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsHealix
  readonly name: string = "TreatmentOptionsHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(TreatmentOptionsHealixDialogue.id, new TreatmentOptionsHealixScript(), state, snapshot)
  }
}
