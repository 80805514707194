import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

type State = PermissionsScriptState
export type PermissionsInsightScriptState = State

export class PermissionsInsightScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsInsightScript"

  /** Script Steps */
  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""
    return {
      body: [
        "You can find all the details here:\n\n" +
          `${organisationTerms}\n` +
          "Limbic [Terms of Service](https://limbic.ai/tos)\n" +
          "Limbic [Privacy Policy](https://limbic.ai/pp)\n"
      ],
      nextStep: this.promptIUnderstand
    }
  }
}

export default class PermissionsInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsInsight
  readonly name: string = "PermissionsInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsInsightDialogue.id, new PermissionsInsightScript(), state, snapshot)
  }
}
