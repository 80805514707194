/* eslint-disable @typescript-eslint/no-var-requires */
import { IAPTIDs, IIAPTService } from "../models/IIAPTService"
import { VitaMindsEmailsProduction, VitaMindsEmailsStaging } from "./emails"

const VitaMindsEmails =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? VitaMindsEmailsProduction
    : VitaMindsEmailsStaging

const KMCCGIaptEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        dover: ["angiewiggins@nhs.net"],
        faversham: ["kmccg.fcstalkingtherapies@nhs.net"],
        insight: ["admin.national@InsightHealthcare.org"],
        withYou: ["enquiries@wearewithyou.org.uk"]
      }
    : {
        dover: ["john+dover@limbic.ai", "ross+dover@limbic.ai"],
        faversham: ["john+faversham@limbic.ai", "ross+faversham@limbic.ai"],
        insight: ["john+insight-kent@limbic.ai", "ross+insight-kent@limbic.ai"],
        withYou: ["john+with-you@limbic.ai", "ross+with-you@limbic.ai"]
      }

const SABPIaptEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        riskEmails: ["duty.mindmatters@sabp.nhs.uk"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }
    : {
        riskEmails: ["andreas@limbic.ai", "john@limbic.ai"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }

export const iapts: Record<IAPTIDs, IIAPTService> = {
  [IAPTIDs.DEMO]: {
    id: IAPTIDs.DEMO,
    name: "Demo Service",
    formattedName: "Demo Service",
    postcode: "OL14 8RS",
    backendInstanceID: "DEMO_MAIN",
    phoneNumber: "+44 1234 567890",
    ageThreshold: 16,
    ageThresholdForTreatments: 18,
    emails: ["ross@limbic.ai", "john@limbic.ai"],
    riskEmails: ["ross@limbic.ai", "john@limbic.ai"],
    referralForm: require("./referralForms/demo.ts").default
  },
  [IAPTIDs.VHG_DERBY]: {
    id: IAPTIDs.VHG_DERBY,
    name: "Vita Health Group: Vitaminds Derby",
    formattedName: "Vitaminds Derby service",
    postcode: "IP33 1UZ",
    backendInstanceID: "VHG_DERBY",
    phoneNumber: "+44 3330 153496",
    ageThreshold: 16,
    ageThresholdForTreatments: 18,
    ...VitaMindsEmails["vhg-derby"],
    referralForm: require("./referralForms/derby-form.ts").default
  },
  [IAPTIDs.VHG_BB]: {
    id: IAPTIDs.VHG_BB,
    name: "Vita Health Group: Vita Minds Basildon & Brentwood",
    formattedName: "Vita Minds Basildon & Brentwood service",
    postcode: "IP33 1UZ",
    backendInstanceID: "VHG_BB",
    phoneNumber: "+44 1268 977171",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VitaMindsEmails["vhg-bb"],
    referralForm: require("./referralForms/basildon-brentwood-form.ts").default
  },
  [IAPTIDs.VHG_CALDERDALE]: {
    id: IAPTIDs.VHG_CALDERDALE,
    name: "Vita Health Group: Vitaminds Calderdale",
    formattedName: "Vitaminds Calderdale service",
    postcode: "IP33 1UZ",
    backendInstanceID: "VHG_CALDERDALE",
    phoneNumber: "+44 3330 153494",
    ageThreshold: 18,
    ageThresholdForTreatments: 18,
    ...VitaMindsEmails["vhg"],
    referralForm: require("./referralForms/calderdale-form.ts").default
  },
  [IAPTIDs.VHG_BNSSG]: {
    id: IAPTIDs.VHG_BNSSG,
    name: "Vita Health Group: Vitaminds Bristol, North Somerset & South Gloucestershire",
    formattedName: "Vitaminds Bristol, North Somerset & South Gloucestershire service",
    postcode: "BS1 1EH",
    backendInstanceID: "VHG_BNSSG",
    phoneNumber: "+44 3332 001893",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VitaMindsEmails["vhg-bnssg"],
    referralForm: require("./referralForms/bristol-form.ts").default
  },
  [IAPTIDs.VHG_KM]: {
    id: IAPTIDs.VHG_KM,
    name: "Vita Health Group: Vitaminds Kent and Medway",
    formattedName: "Vitaminds Kent and Medway service",
    postcode: "IP33 1UZ",
    backendInstanceID: "VHG_KENT",
    phoneNumber: "+44 3330 153495",
    ageThreshold: 17.5,
    ageThresholdForTreatments: 17.5,
    ...VitaMindsEmails["vhg-kent"],
    referralForm: require("./referralForms/kent-medway-form.ts").default
  },
  [IAPTIDs.VHG_LLR]: {
    id: IAPTIDs.VHG_LLR,
    name: "Vita Health Group: Vitaminds Leicester, Leicestershire & Rutland",
    formattedName: "Vitaminds Leicester, Leicestershire & Rutland service",
    postcode: "IP33 1UZ",
    backendInstanceID: "VHG_LLR",
    phoneNumber: "+44 3300 945595",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VitaMindsEmails["vhg-llr"],
    referralForm: require("./referralForms/vhg-llr.ts").default
  },
  [IAPTIDs.VHG_WEST_ESSEX]: {
    id: IAPTIDs.VHG_WEST_ESSEX,
    name: "Vita Health Group: VitaMinds West Essex",
    formattedName: "VitaMinds West Essex service",
    postcode: "CM18 7BL",
    backendInstanceID: "VHG_WEST_ESSEX",
    phoneNumber: "+44 3330 152966",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VitaMindsEmails["vhg-westessex"],
    referralForm: require("./referralForms/vhg-westessex.ts").default
  },
  [IAPTIDs.INSIGHT_KENT]: {
    id: IAPTIDs.INSIGHT_KENT,
    name: "Insight IAPT Talking Therapies (Kent)",
    formattedName: "Insight IAPT Talking Therapies (Kent) service",
    postcode: "ME13 8XZ",
    emails: KMCCGIaptEmails.insight,
    phoneNumber: "0300 555 5555",
    backendInstanceID: "INSIGHT_KENT_EAST",
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_PETTERSBOROUGH]: {
    id: IAPTIDs.INSIGHT_PETTERSBOROUGH,
    name: "Insight IAPT - Peterborough Talking Therapies",
    formattedName: "Insight IAPT - Peterborough Talking Therapies service",
    postcode: "PE1 3PB",
    phoneNumber: "0300 555 0888",
    backendInstanceID: "INSIGHT_PETERBOROUGH",
    ageThreshold: 17,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_NOTTS]: {
    id: IAPTIDs.INSIGHT_NOTTS,
    name: "Insight IAPT - Nottinghamshire",
    formattedName: "Insight IAPT - Nottinghamshire service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_NOTTINGHAM_GREATER",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_BASSETLAW]: {
    id: IAPTIDs.INSIGHT_BASSETLAW,
    name: "Insight IAPT Talking Therapies (Bassetlaw)",
    formattedName: "Insight IAPT Talking Therapies (Bassetlaw) service",
    postcode: "S80 2PU",
    phoneNumber: "0300 300 0033",
    backendInstanceID: "INSIGHT_BASSETLAW",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_DERBY]: {
    id: IAPTIDs.INSIGHT_DERBY,
    name: "Derbyshire Talking Therapies",
    formattedName: "Derbyshire Talking Therapies service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_DERBY",
    ageThreshold: 18,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_WIRRAL]: {
    id: IAPTIDs.INSIGHT_WIRRAL,
    name: "Talking Together Wirral",
    formattedName: "Talking Together Wirral service",
    postcode: "CH41 1EL",
    backendInstanceID: "INSIGHT_WIRRAL",
    phoneNumber: "0151 649 1859",
    ageThreshold: 16,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_MEDWAY]: {
    id: IAPTIDs.INSIGHT_MEDWAY,
    name: "Medway Talking Therapies",
    formattedName: "Medway Talking Therapies service",
    backendInstanceID: "INSIGHT_MEDWAY",
    postcode: "ME4 6SL",
    emails: ["john+with-you@limbic.ai", "ross+with-you@limbic.ai"],
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_KENT_DEMO]: {
    id: IAPTIDs.INSIGHT_KENT,
    name: "Insight IAPT Talking Therapies (Kent)",
    formattedName: "Insight IAPT Talking Therapies (Kent) service",
    postcode: "ME13 8XZ",
    phoneNumber: "0300 555 5555",
    backendInstanceID: "INSIGHT_KENT_EAST",
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_PETTERSBOROUGH_DEMO]: {
    id: IAPTIDs.INSIGHT_PETTERSBOROUGH,
    name: "Insight IAPT - Peterborough Talking Therapies",
    formattedName: "Insight IAPT - Peterborough Talking Therapies service",
    postcode: "PE1 3PB",
    phoneNumber: "0300 555 0888",
    backendInstanceID: "INSIGHT_PETERBOROUGH",
    ageThreshold: 17,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_NOTTS_DEMO]: {
    id: IAPTIDs.INSIGHT_NOTTS,
    name: "Insight IAPT - Nottinghamshire",
    formattedName: "Insight IAPT - Nottinghamshire service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_NOTTINGHAM_GREATER",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_BASSETLAW_DEMO]: {
    id: IAPTIDs.INSIGHT_BASSETLAW,
    name: "Insight IAPT Talking Therapies (Bassetlaw)",
    formattedName: "Insight IAPT Talking Therapies (Bassetlaw) service",
    postcode: "S80 2PU",
    phoneNumber: "0300 300 0033",
    backendInstanceID: "INSIGHT_BASSETLAW",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_DERBY_DEMO]: {
    id: IAPTIDs.INSIGHT_DERBY,
    name: "Derbyshire Talking Therapies",
    formattedName: "Derbyshire Talking Therapies service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_DERBY",
    ageThreshold: 18,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_WIRRAL_DEMO]: {
    id: IAPTIDs.INSIGHT_WIRRAL,
    name: "Talking Together Wirral",
    formattedName: "Talking Together Wirral service",
    postcode: "CH41 1EL",
    backendInstanceID: "INSIGHT_WIRRAL",
    phoneNumber: "0151 649 1859",
    ageThreshold: 16,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_MEDWAY_DEMO]: {
    id: IAPTIDs.INSIGHT_MEDWAY,
    name: "Medway Talking Therapies",
    formattedName: "Medway Talking Therapies service",
    backendInstanceID: "INSIGHT_MEDWAY",
    postcode: "ME4 6SL",
    emails: ["john+with-you@limbic.ai", "ross+with-you@limbic.ai"],
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.TRENT_NOTTS]: {
    id: IAPTIDs.TRENT_NOTTS,
    name: "Trent PTS - Ropewalk",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_NOTTS",
    postcode: "NG1 5DU",
    phoneNumber: "+44 1158 965950",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.TRENT_DERBY_WOODLANDS]: {
    id: IAPTIDs.TRENT_DERBY_WOODLANDS,
    name: "Trent PTS - Woodlands Lodge",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_DERBY",
    postcode: "DE22 3NL",
    phoneNumber: "+44 1332 265659",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.TRENT_DERBY_SALTERGATE]: {
    id: IAPTIDs.TRENT_DERBY_SALTERGATE,
    name: "Trent PTS - Saltergate",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_DERBY",
    postcode: "S40 1JS",
    phoneNumber: "+44 1246 385752",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.TRENT_NOTTS_DEMO]: {
    id: IAPTIDs.TRENT_NOTTS_DEMO,
    name: "Trent PTS - Ropewalk",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_NOTTS",
    postcode: "NG1 5DU",
    phoneNumber: "+44 1158 965950",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.TRENT_DERBY_WOODLANDS_DEMO]: {
    id: IAPTIDs.TRENT_DERBY_WOODLANDS_DEMO,
    name: "Trent PTS - Woodlands Lodge",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_DERBY",
    postcode: "DE22 3NL",
    phoneNumber: "+44 1332 265659",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.TRENT_DERBY_SALTERGATE_DEMO]: {
    id: IAPTIDs.TRENT_DERBY_SALTERGATE_DEMO,
    name: "Trent PTS - Saltergate",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_E2E_DEMO",
    postcode: "S40 1JS",
    phoneNumber: "+44 1246 385752",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.DOVER_KM]: {
    id: IAPTIDs.DOVER_KM,
    name: "Dover Counselling Centre",
    formattedName: "Dover Counselling Centre service",
    postcode: "CT16 2ES",
    emails: KMCCGIaptEmails.dover
  },
  [IAPTIDs.FAVERSHAM_KM]: {
    id: IAPTIDs.FAVERSHAM_KM,
    name: "Faversham Counselling Service",
    formattedName: "Faversham Counselling service",
    postcode: "ME13 8NX",
    emails: KMCCGIaptEmails.faversham
  },
  [IAPTIDs.WITH_YOU_KM]: {
    id: IAPTIDs.WITH_YOU_KM,
    name: "With You",
    formattedName: "We Are With You service",
    postcode: "TN23 7SG",
    emails: KMCCGIaptEmails.withYou
  },
  [IAPTIDs.MIND_MATTERS_SAB]: {
    id: IAPTIDs.MIND_MATTERS_SAB,
    name: "Mind Matters Surrey NHS",
    formattedName: "Mind Matters Surrey NHS service",
    postcode: "KT16 9FG",
    backendInstanceID: "SABP_MAIN",
    phoneNumber: "+44 3003 305450",
    ageThreshold: 17,
    ageThresholdForTreatments: 17,
    riskEmails: SABPIaptEmails.riskEmails,
    riskEmailsCC: SABPIaptEmails.riskEmailsCC,
    riskEmailsBCC: SABPIaptEmails.riskEmailsBCC,
    referralForm: require("./referralForms/sabp.ts").default
  },
  [IAPTIDs.STEPS2CHANGE_STAMFORD]: {
    id: IAPTIDs.STEPS2CHANGE_STAMFORD,
    backendInstanceID: "LINCS_STAMFORD",
    name: "Steps2Change",
    clinicName: "steps2change-stamford",
    formattedName: "Steps2Change service",
    postcode: "PE9 1UN",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.STEPS2CHANGE_SPALDING]: {
    id: IAPTIDs.STEPS2CHANGE_SPALDING,
    backendInstanceID: "LINCS_SPALDING",
    name: "Steps2Change",
    clinicName: "steps2change-spalding",
    formattedName: "Steps2Change service",
    postcode: "PE11 1TW",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.STEPS2CHANGE_GRANTHAM]: {
    id: IAPTIDs.STEPS2CHANGE_GRANTHAM,
    backendInstanceID: "LINCS_GRANTHAM",
    name: "Steps2Change",
    clinicName: "steps2change-grantham",
    formattedName: "Steps2Change service",
    postcode: "NG31 9DF",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.STEPS2CHANGE_BOSTON]: {
    id: IAPTIDs.STEPS2CHANGE_BOSTON,
    backendInstanceID: "LINCS_BOSTON",
    name: "Steps2Change",
    clinicName: "steps2change-boston",
    formattedName: "Steps2Change service",
    postcode: "PE21 8EG",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.STEPS2CHANGE_SLEAFORD]: {
    id: IAPTIDs.STEPS2CHANGE_SLEAFORD,
    backendInstanceID: "LINCS_SLEAFORD",
    name: "Steps2Change",
    clinicName: "steps2change-sleaford",
    formattedName: "Steps2Change service",
    postcode: "NG34 8GG",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.STEPS2CHANGE_SKEGNESS]: {
    id: IAPTIDs.STEPS2CHANGE_SKEGNESS,
    backendInstanceID: "LINCS_SKEGNESS",
    name: "Steps2Change",
    clinicName: "steps2change-skegness",
    formattedName: "Steps2Change service",
    postcode: "PE25 2JA",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.STEPS2CHANGE_LINCOLN]: {
    id: IAPTIDs.STEPS2CHANGE_LINCOLN,
    backendInstanceID: "LINCS_LINCOLN",
    name: "Steps2Change",
    clinicName: "steps2change-lincoln",
    formattedName: "Steps2Change service",
    postcode: "LN2 4WA",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.STEPS2CHANGE_LOUTH]: {
    id: IAPTIDs.STEPS2CHANGE_LOUTH,
    backendInstanceID: "LINCS_LOUTH",
    name: "Steps2Change",
    clinicName: "steps2change-louth",
    formattedName: "Steps2Change service",
    postcode: "LN11 0LF",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.STEPS2CHANGE_GAINSBOROUGH]: {
    id: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
    backendInstanceID: "LINCS_GAINSBOROUGH",
    name: "Steps2Change",
    clinicName: "steps2change-gainsborough",
    formattedName: "Steps2Change service",
    postcode: "DN21 2DZ",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.NOTTINGHAMSHIRE_NOTTS_CC]: {
    id: IAPTIDs.NOTTINGHAMSHIRE_NOTTS_CC,
    name: "Let's Talk-Wellbeing",
    backendInstanceID: "NOTTS_MAIN",
    formattedName: "Let's Talk-Wellbeing service",
    postcode: "NG3 6AA",
    phoneNumber: "+44 3003 002200",
    ageThreshold: 18,
    ageThresholdForTreatments: 18,
    referralForm: require("./referralForms/nottinghamshire.ts").default
  },
  [IAPTIDs.NOTTINGHAMSHIRE_NOTTS]: {
    id: IAPTIDs.NOTTINGHAMSHIRE_NOTTS,
    name: "Let's Talk-Wellbeing",
    backendInstanceID: "NOTTS_MAIN",
    formattedName: "Let's Talk-Wellbeing service",
    postcode: "NG3 6AA",
    phoneNumber: "+44 3003 002200",
    ageThreshold: 18,
    ageThresholdForTreatments: 18,
    referralForm: require("./referralForms/nottinghamshire.ts").default
  }
}
