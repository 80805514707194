import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  TreatmentOptionsIAPTScript,
  TreatmentOptionsIAPTScriptState
} from "./TreatmentOptionsIAPTDialogue"
import type { ITreatmentOption } from "../../../models/IClinicalPath"
import { InsightTreatmentOptions, TrackingEvents } from "../../../models/Constants"
import type { IStep, IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import ISelectable from "../../../models/ISelectable"
import formatUnicorn from "../../../utils/formatUnicorn"
import {
  getInsightAppointments,
  reserveAppointment
} from "../../../backend/api/insightAppointments"
import { IAppointmentStatus } from "../../../models/IAppointment"
import { submitInsightReferral } from "../../../backend/api/submitInsightReferral"

const ENABLE_APPOINTMENTS = true

interface State extends TreatmentOptionsIAPTScriptState {
  appointmentID?: string
  appointment?: string
  tempAppointment?: string
  retryBookAppointment?: number
  retryReserveAppointment?: number
  requiresInterpreter?: boolean
}

export class TreatmentOptionsInsightScript extends TreatmentOptionsIAPTScript {
  readonly name: string = "TreatmentOptionsInsightScript"

  /** Script Steps */
  @step.logState
  suggestCurrentTreatmentOption(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const serviceName = this.rootStore.configStore.serviceName
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const treatmentOption = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    const isWebinar = treatmentOption?.name === "webinar"
    const ctx = { name, organisationName, iaptName }
    const explainer = (treatmentOption.explainer || []).map(i => formatUnicorn(i, ctx))
    const introText =
      "Based on your answers to the assessment questions, we feel that the best place to start your psychological journey"
    const text = isWebinar
      ? `${introText} with ${serviceName} is by attending a ${treatmentOption.formattedName}`
      : `${introText} is either using ${serviceName}'s ${treatmentOption.formattedName} platform or attending some 1:1 sessions with one of ${serviceName}'s' 'Psychological Wellbeing Practitioners'`

    // NOTE: If not allowed treatments it will never reach this part of the code
    // but I believe its good to have a safety net
    const isAllowedTreatments =
      d.state.php9q9Score! === 0 || (d.state.php9q9Score === 1 && d.state.canKeepSelfSafe)
    const goToBookAppointment = ENABLE_APPOINTMENTS && isAllowedTreatments && d.state.patientId

    const choices: ISelectable[] = isWebinar
      ? [{ body: "Ok", value: true }]
      : [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]

    if (treatmentOption?.explainMore?.text.length)
      choices.push({ body: "Can you explain more?", value: "explain" })

    if (isWebinar) {
      return {
        body: [
          text,
          ...explainer,
          goToBookAppointment
            ? undefined
            : `One of ${serviceName}'s practitioners will call you to discuss this. The ${serviceName} team will be in touch to organise a time and date for this call`
        ],
        prompt: {
          id: this.getPromptId(`suggestCurrentTreatmentOption-${treatmentOption.name}`),
          trackResponse: true,
          type: "inlinePicker",
          choices
        },
        nextStep: this.handleCurrentTreatmentOption
      }
    }

    return {
      body: [
        text,
        ...explainer,
        "Digital therapy is conducted using computer learning and is completed in a person's own time without the need for fixed appointments. You are supported to work through the computer learning by a named practitioner. Would you be interested in digital therapy?"
      ],
      prompt: {
        id: this.getPromptId(`suggestCurrentTreatmentOption-${treatmentOption.name}`),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.handleCurrentTreatmentOption
    }
  }

  @step.logState
  @step.startTyping
  @step.delay(3)
  async sayAcceptedTreatmentOption(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const current = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    const isWebinar = current.name === "webinar"
    this.rootStore.clinicalStore.acceptTreatment(current)
    this.setTreatmentAccepted(d.state, current, true)
    this.setTreatment(d.state, current)
    this.updateReferralType(d.state)
    this.referralStore.addClinicalNote(
      `Assigned Treatment: ${
        isWebinar ? InsightTreatmentOptions.WEBINAR : InsightTreatmentOptions.DIGITAL_THERAPY
      }`
    )
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const ctx = { name, organisationName, iaptName }
    const acceptedResponse = (current.acceptedResponse || []).map(i => formatUnicorn(i, ctx))

    // NOTE: If not allowed treatments it will never reach this part of the code
    // but I believe its good to have a safety net
    const isAllowedTreatments =
      d.state.php9q9Score! === 0 || (d.state.php9q9Score === 1 && d.state.canKeepSelfSafe)
    const goToBookAppointment = ENABLE_APPOINTMENTS && isAllowedTreatments
    if (goToBookAppointment) {
      this.referralStore.setIdleSubmissionActive(false)
      const isDemo = this.referralStore.instanceID === "INSIGHT_E2E_DEMO"
      const [isSubmitSuccessful] = await submitInsightReferral(isDemo, d.state.patientId || "")
      if (isSubmitSuccessful) {
        return {
          body: [!isWebinar ? "Ok, great" : undefined],
          nextStep: this.bookAppointment
        }
      }
      // if the submission failed, then reactivate the idle submission
      this.referralStore.setIdleSubmissionActive(true)
    }
    return {
      body: [
        !isWebinar ? `Great - it's really good you're open to trying this, ${name}` : undefined,
        ...acceptedResponse
      ],
      nextStep: this.end
    }
  }

  @step.logState
  @step.startTyping
  @step.delay(3)
  async sayDeclinedTreatmentOption(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const current = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    this.rootStore.clinicalStore.declineTreatment(current)

    // INSIGHT has requested if the user rejects digital therapy to book 1:1 therapy
    // Index 2 is the self help therapy
    if (currentIndex === 1) {
      const alternativeTreatment = this.rootStore.clinicalStore.treatmentOptions[2]
      this.rootStore.clinicalStore.acceptTreatment(alternativeTreatment)
      this.setTreatmentAccepted(d.state, alternativeTreatment, true)
      this.setTreatment(d.state, alternativeTreatment)
      this.referralStore.addClinicalNote(`Assigned Treatment: ${InsightTreatmentOptions.GSH}`)
    } else {
      this.setTreatmentAccepted(d.state, current, false)
    }
    this.updateReferralType(d.state)
    const serviceName = this.rootStore.configStore.serviceName
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const ctx = { name, organisationName, iaptName }
    const declinedResponse = (current.declinedResponse || []).map(i => formatUnicorn(i, ctx))

    // NOTE: If not allowed treatments it will never reach this part of the code
    // but I believe its good to have a safety net
    const isAllowedTreatments =
      d.state.php9q9Score! === 0 || (d.state.php9q9Score === 1 && d.state.canKeepSelfSafe)
    const goToBookAppointment = ENABLE_APPOINTMENTS && isAllowedTreatments

    if (goToBookAppointment) {
      const isDemo = this.referralStore.instanceID === "INSIGHT_E2E_DEMO"
      const [isSubmitSuccessful] = await submitInsightReferral(isDemo, d.state.patientId || "")
      if (isSubmitSuccessful) {
        this.referralStore.setIdleSubmissionActive(false)
        return {
          body: [
            `That's okay ${name}`,
            `One of ${serviceName}'s practitioners will speak to you to discuss the most appropriate treatment option for you.`
          ],
          nextStep: this.bookAppointment
        }
      }
      // if the submission failed, then reactivate the idle submission
      this.referralStore.setIdleSubmissionActive(false)
    }
    return {
      body: [`That's okay ${name}`, ...declinedResponse],
      nextStep: this.end
    }
  }

  @step.logState
  async bookAppointment(d: IStepData<State>): Promise<IStepResult> {
    if (d.state.retryReserveAppointment === undefined) d.state.retryReserveAppointment = 0
    const isDemo = this.referralStore.instanceID === "INSIGHT_E2E_DEMO"
    const [appointments, appointmentsStatus] = await getInsightAppointments(
      isDemo,
      d.state.patientId || ""
    )
    const serviceName = this.rootStore.configStore.serviceName

    if (appointmentsStatus === IAppointmentStatus.NoInternetConnection) {
      return { nextStep: this.sayNoInternetConnectionGetAppointments }
    }

    if (appointmentsStatus === IAppointmentStatus.RequestFailed) {
      return {
        nextStep: this.saySomethingWentWrongRetrievingAppointments
      }
    }

    if (appointments?.length === 0 && appointmentsStatus === IAppointmentStatus.Success) {
      return {
        body: `One of ${serviceName}'s practitioners will call you to discuss this. The ${serviceName} team will be in touch to organise a time and date for this call`,
        nextStep: this.end
      }
    }

    this.track(TrackingEvents.APPOINTMENTS_PROVIDED)

    return {
      body: "Please choose a date and time that works best for you to have a 15 minute call with one of our team",
      prompt: {
        id: this.getPromptId("bookAppointment"),
        type: "appointment",
        appointments: appointments || [],
        isUndoAble: true
      },
      nextStep: this.confirmAppointment
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.tempAppointment = d.response
  })
  async confirmAppointment(d: IStepData<State, string>): Promise<IStepResult> {
    return {
      body: [
        "You have selected the following date and time",
        d.response.split("_")[1],
        "Is this correct?"
      ],
      prompt: {
        id: this.getPromptId("confirmAppointment"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No, let me change it", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleAppointment
    }
  }

  @step.logState
  async handleAppointment(d: IStepData<State, string>): Promise<IStepResult> {
    if (d.response) {
      const [_id, appointment] = d.state.tempAppointment?.split("_") || []
      d.state.appointmentID = _id
      const isDemo = this.referralStore.instanceID === "INSIGHT_E2E_DEMO"
      const [reservedAppointment, reservedAppointmentStatus] = await reserveAppointment(
        isDemo,
        d.state.patientId || "",
        d.state.appointmentID || _id || ""
      )

      if (reservedAppointmentStatus === IAppointmentStatus.NoInternetConnection) {
        return { nextStep: this.sayNoInternetConnectionReserveAppointment }
      }

      if (reservedAppointment) {
        d.state.appointment = appointment
        this.track(TrackingEvents.APPOINTMENT_BOOKED)
        this.referralStore.addClinicalNote(`Appointment: ${appointment}`)
        return { nextStep: this.end }
      }
      if (!reservedAppointment || reservedAppointmentStatus === IAppointmentStatus.RequestFailed) {
        this.track(TrackingEvents.APPOINTMENT_BOOKING_FAILED)
        d.state.tempAppointment = undefined
        if (d.state.retryReserveAppointment === undefined) d.state.retryReserveAppointment = 0
        d.state.retryReserveAppointment = d.state.retryReserveAppointment + 1
        if (d.state.retryReserveAppointment > 2) {
          return { nextStep: this.sayAppointmentBookingFailed }
        }
      }
      return {
        body: ["Hmmm... something went wrong while booking your appointment", "Please try again"],
        nextStep: this.bookAppointment
      }
    }
    d.state.tempAppointment = undefined
    return { nextStep: this.bookAppointment }
  }

  @step.logState
  sayAppointmentBookingFailed(d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    const name = this.getName(d.state)
    return {
      body: [
        `Sorry ${name}, we're encountering a persistent issue when trying to confirm your appointment booking`,
        `Your referral has been submitted successfully, so we'll ask one of the ${serviceName} team to give you a call to organise your appointment`
      ],
      nextStep: this.end
    }
  }

  @step.logState
  saySomethingWentWrongRetrievingAppointments(d: IStepData<State>): IStepResult {
    if (d.state.retryBookAppointment === undefined) d.state.retryBookAppointment = 0
    d.state.retryBookAppointment = d.state.retryBookAppointment + 1

    if (d.state.retryBookAppointment === 2) {
      const serviceName = this.rootStore.configStore.serviceName
      const name = this.getName(d.state)
      return {
        body: [
          `Sorry ${name}, we're encountering a persistent issue when trying to load the available appointments`,
          `One of ${serviceName}'s practitioners will call you to discuss this. The ${serviceName} team will be in touch to organise a time and date for this call`
        ],
        nextStep: this.end
      }
    }
    return {
      body: [
        "Hmmm... something went wrong while loading the available appointments",
        "Please try again"
      ],
      prompt: {
        id: this.getPromptId("saySomethingWentWrongRetrievingAppointments"),
        type: "inlinePicker",
        choices: [{ body: "Try again " }]
      },
      nextStep: this.bookAppointment
    }
  }

  @step.logState
  sayNoInternetConnectionGetAppointments(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionGetAppointments"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.bookAppointment
    }
  }

  @step.logState
  sayNoInternetConnectionReserveAppointment(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionGetAppointments"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.handleAppointment
    }
  }

  @step.logState
  @step.startTyping
  async bookAppointmentBelowCaseness(d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setIdleSubmissionActive(false)
    const isDemo = this.referralStore.instanceID === "INSIGHT_E2E_DEMO"
    const [isSubmitSuccessful] = await submitInsightReferral(isDemo, d.state.patientId || "")
    if (isSubmitSuccessful) {
      const serviceName = this.rootStore.configStore.serviceName
      return {
        body: `As a next step, one of ${serviceName}'s practitioners will speak to you to discuss this`,
        nextStep: this.bookAppointment
      }
    }
    // if the submission failed, then reactivate the idle submission
    this.referralStore.setIdleSubmissionActive(true)
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  getIsValidTreatmentOption(state: State, treatmentOption?: ITreatmentOption): boolean {
    const isValid = super.getIsValidTreatmentOption(state, treatmentOption)
    const isNotAllowedTreatments =
      (state.php9q9Score! === 1 && !state.canKeepSelfSafe) || state.php9q9Score! >= 2
    const isAtRisk = this.clinicalStore.isRisk
    if (isAtRisk) return false
    if (isNotAllowedTreatments) return false
    if (!isValid) return false
    if (treatmentOption?.name === "webinar") {
      const isWebinarEligible = state.email && state.canSendEmail && !state.requiresInterpreter
      return !!isWebinarEligible
    }
    return true
  }

  getBelowCasenessStep(state: State): IStep<State> | undefined {
    if (ENABLE_APPOINTMENTS && !this.clinicalStore.isRisk) {
      return this.bookAppointmentBelowCaseness
    }
    return this.end
  }
}

export default class TreatmentOptionsInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsInsight
  readonly name: string = "TreatmentOptionsInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(TreatmentOptionsInsightDialogue.id, new TreatmentOptionsInsightScript(), state, snapshot)
  }
}
