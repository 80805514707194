/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Mixpanel from "mixpanel-browser"
import moment from "moment"
import { TrackingEvents } from "../models/Constants"
import invariant from "./invariant"
import type ITracker from "../models/Tracker/ITracker"

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
const isProd = process.env.NODE_ENV === "production"

if (isProd) console.log("Mixpanel available")

const CONFIG = {
  debug: isDev,
  persistence: "localStorage",
  persistence_name: "Limbic",
  ip: false,
  api_host: "https://limbic-mxp-prx.herokuapp.com"
}

// prettier-ignore
const generateID = () => (+`${Date.now()}${Math.random() * Date.now()}`).toString(36).replace(".", "")

export default class Tracker implements ITracker {
  static isReady = false
  static _apiKey: string
  static _instance: Tracker

  /** Static Methods */

  static getInstance(): Tracker {
    invariant(this.isReady, "Can't get Tracker instance before setting it up")
    if (!this._instance) {
      this._instance = new Tracker()
    }
    return this._instance
  }

  static setAPIKey(apiKey: string): void {
    invariant(apiKey, `Tracker needs an _apiKey property. [${apiKey}]`)
    this._apiKey = apiKey
  }

  static init(): boolean {
    let isReset = false
    if (isDev) {
      this.isReady = true
      return isReset
    }
    if (isProd) {
      let sessionId = sessionStorage.getItem("Tracker:Session")
      if (!sessionId) {
        // prettier-ignore
        // eslint-disable-next-line no-empty
        try { Mixpanel.reset() } catch {} // sanity check
        localStorage.removeItem(`mp_${CONFIG.persistence_name}`)
        sessionId = generateID()
        sessionStorage.setItem("Tracker:Session", sessionId)
        isReset = true
      }
      Mixpanel?.init?.(this.apiKey, CONFIG)
      this.isReady = true
      this.getInstance().identify(sessionId)
      this.getInstance().register({ sessionId })
      return isReset
    }
    return isReset
  }

  static setup(userData?: Record<string, any>): void {
    const startDate = moment().format("DD/MM/YYYY hh:mm")
    const trackerReset = this.init()

    if (trackerReset) {
      this.getInstance().track(TrackingEvents.CHAT_BOT_LAUNCH)
      this.getInstance().timeEvent(TrackingEvents.ASSESSMENT_REACHED)
      this.getInstance().timeEvent(TrackingEvents.TREATMENT_DETERMINED)
      this.getInstance().timeEvent(TrackingEvents.CONVERSATION_END)
      this.getInstance().timeEvent(TrackingEvents.ELIGIBILITY_DETERMINED)
      this.getInstance().timeEvent(TrackingEvents.SELF_REFERRAL_SUBMITTED)
      this.getInstance().setPeople({ startDate })
    } else {
      this.getInstance().setPeople({ lastStartDate: startDate })
    }
    this.getInstance().setPeople(userData)
  }

  /** Static Getters / Setters */

  static get apiKey(): string {
    invariant(this._apiKey, `Tracker needs an _apiKey property. [${this._apiKey}]`)
    return this._apiKey
  }

  /** Methods */

  identify(id: string): void {
    if (!id) return
    if (isDev) return
    if (isProd) {
      Mixpanel?.identify?.(id)
    }
  }

  register(data: any): void {
    if (!data) return
    if (isDev) return
    if (isProd) {
      Mixpanel?.register?.(data)
    }
  }

  unregister(data?: any): void {
    if (!data) return
    if (isDev) return
    if (isProd) {
      Mixpanel?.unregister?.(data)
    }
  }

  timeEvent(event?: string): void {
    if (!event) return
    if (isDev) return
    if (isProd) {
      Mixpanel?.time_event(event)
    }
  }

  setPeople(data?: any): void {
    if (!data) return
    if (isDev) return
    if (isProd) {
      Mixpanel?.people?.set?.(data)
    }
  }

  incrementPeople(event?: string): void {
    if (!event) return
    if (isDev) return
    if (isProd) {
      Mixpanel?.people?.increment?.(event)
    }
  }

  track(event?: string, data?: any): void {
    if (!event) return
    if (isDev) return
    if (isProd) {
      Mixpanel?.track?.(event, data)
    }
  }
}
