interface Data {
  message?: string
  description?: string
}

export default function errorMessage(response: Response, data?: Data): string {
  return `${response.status} ${
    response.statusText || data?.message || data?.description || "undefined"
  }`
}
