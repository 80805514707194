import "core-js/es"
import "core-js/features/string/repeat"
import "core-js/features/string/match-all"
import "raf/polyfill"
import "react-app-polyfill/ie9"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "mobx-react-lite/batchingForReactDom"
import Smoothscroll from "smoothscroll-polyfill"

Smoothscroll.polyfill()

// eslint-disable-next-line no-extend-native
Object.defineProperty(RegExp.prototype, "toJSON", {
  value: RegExp.prototype.toString
})

// eslint-disable-next-line no-extend-native
Object.defineProperty(RegExp, "fromJSON", {
  value: regExpFromJSON
})

function regExpFromJSON(value?: string): RegExp {
  const split = value?.split("/")
  if (!(value?.length && split?.length)) return new RegExp("")
  const source = split.slice(1, -1).join("/")
  const flags = split.length > 2 ? split[split.length - 1] : undefined
  return new RegExp(source, flags)
}

console.log("-- Added polyfills --")
