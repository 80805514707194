import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import AssessmentIAPTScript from "./AssessmentIAPTScript"
import { AssessmentClinicalNotes } from "../../../models/Constants"
import { ReferralType } from "../../../models/Constants"
import type { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"

type State = AssessmentIAPTScriptState
export type AssessmentSABPScriptState = State

export class AssessmentSABPScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentSABPScript"

  /** Generic Handlers */

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addMostAffectingPrimaryClinicalNote(): void {}

  getClinicalNotesMap(): Record<AssessmentClinicalNotes, string> {
    // prettier-ignore
    return {
      [AssessmentClinicalNotes.BC_Depression]: "Scored above caseness for low mood and anxiety & selected low mood as their primary problem",
      [AssessmentClinicalNotes.BC_Anxiety]: "Scored above caseness for low mood and anxiety & selected anxiety as their primary problem",
      [AssessmentClinicalNotes.NonSpecificPhobia]: "Anxiety relates to NOTHING SPECIFIC",
      [AssessmentClinicalNotes.SpecificPhobia]: "Anxiety relates to SOMETHING SPECIFIC",
      [AssessmentClinicalNotes.AnxietySpeedYes]: "Anxiety DOES come on quickly",
      [AssessmentClinicalNotes.AnxietySpeedNo]: "Anxiety DOES NOT come on quickly",
      [AssessmentClinicalNotes.AnxietyPeakYes]: "Anxiety symptoms DO peak within 10' (i.e. they feel panic)",
      [AssessmentClinicalNotes.AnxietyPeakNo]: "Anxiety symptoms DO NOT peak within 10' (i.e. they do not feel panic)",
      [AssessmentClinicalNotes.AnxietySensationsYes]: "Anxiety DOES involve physical sensations (e.g. sweating, trembling, etc)",
      [AssessmentClinicalNotes.AnxietySensationsNo]: "Anxiety DOES NOT involve physical sensations (e.g. sweating, trembling, etc)",
      [AssessmentClinicalNotes.WorryYes]: "DOES worry about a variety of things",
      [AssessmentClinicalNotes.WorryNo]: "DOES NOT worry about a variety of things",
      [AssessmentClinicalNotes.HealthAnxiety]: "Worries about their HEALTH/ILLNESS",
      [AssessmentClinicalNotes.OCDAnxiety]: "Experiences INTRUSIVE/UNWANTED THOUGHTS/IMAGES",
      [AssessmentClinicalNotes.PTSDAnxiety]: "Has distress over PAST TRAUMATIC EXPERIENCE(S)"
    }
  }

  getCustomReferralType(state: State): string | undefined {
    const currentMHTreatment = this.referralStore.getCustomField("currentMHTreatment")
    const hasCurrentMHTreatment = !!currentMHTreatment?.length
    if (hasCurrentMHTreatment) return "Extended Assessment (User seeing other MH professionals)"
  }

  getReferralTypeForRisk(_state: State): string | undefined {
    if (this.clinicalStore.isHighRisk) return ReferralType.RISK_PATIENT
    if (this.clinicalStore.isModerateRisk) return ReferralType.RISK_PATIENT
  }
}

export default class AssessmentSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentSABP
  readonly name: string = "AssessmentSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentSABPDialogue.id, new AssessmentSABPScript(), state, snapshot)
  }
}
