import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { TrackingEvents } from "../../../models/Constants"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import isEmail from "validator/lib/isEmail"
import isValidPhoneNumber, { isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"

interface State extends AssessmentPitchIAPTScriptState {
  mainIssue?: string
}
export type AssessmentPitchHealixState = State

export class AssessmentPitchHealixScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchHealixScript"

  /** Script Steps */
  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    return {
      nextStep: this.sayIntro
    }
  }

  @step.logState
  sayIntro(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    return {
      body: "I've been designed by therapists to give you a free digital mental health assessment",
      prompt: {
        id: this.getPromptId("sayIntro"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: true },
          { body: "Tell me more", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleIntro
    }
  }

  @step.logState
  async handleIntro(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      body: d.response ? undefined : "Certainly",
      nextStep: this.sayWeCanSpeedUpReferral
    }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental illness",
        "I'll then share the results with you and help you find the best support options available",
        "Sounds ok?"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "Help me decide", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWeCanSpeedUpReferral
    }
  }

  @step.logState
  async handleWeCanSpeedUpReferral(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    if (d.response) {
      return { body: `Good to hear it, ${name}`, nextStep: this.askPhoneNumber }
    }
    return { nextStep: this.explainMore }
  }

  @step.logState
  explainMore(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "Of course. Mental wellbeing is a complicated topic. There are no quick fixes",
        "But trust me when I say that this is the first step towards a solution",
        "You're at the beginning of an evidence-based pathway that has been shown to help people with a range of common mental health issues",
        `I think this is worth doing now that you're here, ${name}`
      ],
      prompt: {
        id: this.getPromptId("explainMore"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Ok" }, { body: "Go on then" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleExplainMore
    }
  }

  @step.logState
  handleExplainMore(d: IStepData<State>): IStepResult {
    return {
      body: "👍",
      nextStep: this.askPhoneNumber
    }
  }

  @step.logState
  askPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: ["So firstly, what's the best phone number to reach you on?"],
      prompt: {
        id: this.getPromptId("askPhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step
  sayPleaseTypePhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type your phone number",
      prompt: {
        id: this.getPromptId("sayPleaseTypePhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step
  returnToAskPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "So...",
      nextStep: this.sayPleaseTypePhoneNumber
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: AssessmentPitchHealixScript) => s.returnToAskPhoneNumber
  })
  async handlePhoneNumber(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      this.track(TrackingEvents.INVALID_PHONE_NUMBER)
      return {
        body: "Sorry this is not a valid phone number. Let's try again",
        nextStep: this.sayPleaseTypePhoneNumber
      }
    }
    d.state.phoneNumber = d.response
    return {
      body: "Thanks",
      nextStep: this.askCanIContactYouOnPhoneNumber
    }
  }

  @step.logState
  askCanIContactYouOnPhoneNumber(d: IStepData<State>): IStepResult {
    const isMobilePhone = isValidMobilePhone(d.state.phoneNumber!)
    return {
      body: "And do I have permission to message you or leave a voicemail on that number?",
      prompt: {
        id: this.getPromptId("askCanIContactYouOnPhoneNumber"),
        trackResponse: true,
        type: "checkbox",
        options: [
          isMobilePhone && {
            body: "You may send me text messages",
            key: "canSendTextMessagesToPhoneNumber"
          },
          {
            body: "You may leave voicemail messages",
            key: "canLeaveVoicemailToPhoneNumber"
          }
        ].filter(Boolean) as any
      },
      nextStep: this.handleCanIContactYouOnPhoneNumber
    }
  }

  @step.logStateAndResponse
  async handleCanIContactYouOnPhoneNumber(
    d: IStepData<
      State,
      {
        canSendTextMessagesToPhoneNumber?: boolean
        canLeaveVoicemailToPhoneNumber: boolean
      }
    >
  ): Promise<IStepResult> {
    const canSendTextMessagesToPhoneNumber = d.response.canSendTextMessagesToPhoneNumber
    const canLeaveVoicemailToPhoneNumber = d.response.canLeaveVoicemailToPhoneNumber
    d.state.canSendTextMessagesToPhoneNumber = canSendTextMessagesToPhoneNumber
    d.state.canLeaveVoicemailToPhoneNumber = canLeaveVoicemailToPhoneNumber
    const data = { canSendTextMessagesToPhoneNumber, canLeaveVoicemailToPhoneNumber }
    this.setPeople(data)
    this.track(TrackingEvents.PHONE_PERMISSIONS, data)
    return { nextStep: this.askDoYouWantToShareEmail }
  }

  @step.logState
  askDoYouWantToShareEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "It's optional, but you can also share your email with me if you like to be contacted this way?",
      prompt: {
        id: this.getPromptId("askDoYouWantToShareEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes, I'd like to be contacted by email", value: true },
          { body: "No, just the phone number is fine", value: false }
        ]
      },
      nextStep: this.handleDoYouWantToShareEmail
    }
  }

  @step.logStateAndResponse
  async handleDoYouWantToShareEmail(d: IStepData<State, boolean>): Promise<IStepResult> {
    this.setPeople({ wantsToShareEmail: d.response })
    this.track(TrackingEvents.DO_YOU_HAVE_EMAIL, { body: d.response ? "Yes" : "No" })
    d.state.canSendEmail = d.response
    return {
      nextStep: d.response //
        ? this.askEmail
        : this.askMainIssue
    }
  }

  @step.logState
  askEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type your email address",
      prompt: { id: this.getPromptId("askEmail"), type: "email" },
      nextStep: this.handleEmail
    }
  }

  @step.logStateAndResponse
  async handleEmail(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isEmail(d.response)
    if (!isValid) {
      return {
        body: "Sorry this is not a valid email address. Let's try again",
        nextStep: this.askEmail
      }
    }
    d.state.email = d.response
    return { nextStep: this.askMainIssue }
  }

  @step.logState
  askMainIssue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        d.state.canSendEmail ? "Brilliant" : "Okay",
        `So ${name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you)`
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssue
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: AssessmentPitchHealixScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
  })
  @step.checkInputForCrisis({ getNextStep: (s: AssessmentPitchHealixScript) => s.end })
  handleMainIssue(d: IStepData<State, string>): IStepResult {
    const name = this.getName(d.state)

    return {
      body: `Thank you for sharing ${name}, you've come to the right place`,
      nextStep: this.end
    }
  }
}

export default class AssessmentPitchHealixDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchHealix
  readonly name: string = "AssessmentPitchHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchHealixDialogue.id, new AssessmentPitchHealixScript(), state, snapshot)
  }
}
