import Trackable from "./Trackable"
import { LoggableType } from "./Logger/LoggableType"
import invariant from "../utils/invariant"

export default abstract class Persistable extends Trackable {
  static _storage: Storage
  _storage?: Storage

  /** Static Methods */

  static setStorage(storage: Storage): void {
    invariant(storage, `Persistable needs a _storage property. [${storage}]`)
    this._storage = storage
  }

  /** Static Getters / Setters */

  static get storage(): Storage {
    invariant(this._storage, `Persistable needs a _storage property. [${this._storage}]`)
    return this._storage
  }

  setStorage(storage: Storage): void {
    invariant(storage, `Persistable needs a _storage property. [${storage}]`)
    invariant(
      process.env.NODE_ENV === "test",
      "non-static setStorage of Persistable is only for testing"
    )
    this._storage = storage
  }

  /** Optional Abstract Methods */

  hydrateValueOfKey?(key: string, jsonValue: unknown): any

  /** Methods */

  persist<Key extends keyof this, V extends this[Key]>(key: Key, data: V): void {
    const fullKey = `${this.name}:${key}`
    try {
      invariant(key, `You need to pass a key to persist data. [${key}]`)
      if (data == null) {
        this.storage.removeItem(fullKey)
      } else {
        const json = JSON.stringify(data)
        this.storage.setItem(fullKey, json)
      }
    } catch (e) {
      this.logBreadcrumb(`persist ${fullKey} failed`, { fullKey, key, data })
      this.logException(e, "persist")
    }
  }

  hydrate<Key extends keyof this, V extends this[Key]>(key: Key): V | undefined {
    const fullKey = `${this.name}:${key}`
    try {
      invariant(key, `You need to pass a key to hydrate data. [${key}]`)
      const json = this.storage.getItem(fullKey)
      if (json) {
        return (
          JSON.parse(json, (key: string, value: any) => {
            if (this.hydrateValueOfKey) {
              return this.hydrateValueOfKey(key, value) ?? value
            }
            return value
          }) ?? undefined
        )
      }
    } catch (e) {
      this.logBreadcrumb(`hydrate ${fullKey} failed`, { fullKey, key })
      this.logException(e, "hydrate")
    }
  }

  /** Getters / Setters */

  get storage(): Storage {
    if (this._storage) return this._storage
    return (this.constructor as any).storage
  }
}

Persistable.setLogType(LoggableType.Persistence)
