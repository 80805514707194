import { DialogueIDs } from "../../conversation/DialogueIDs"
import dialoguesRegistry, { IDialoguesRegistry } from "../../conversation/dialoguesRegistry"
import Persistable from "../../models/Persistable"
import type { DiscussionSteps } from "../../models/Constants"

export default class DiscussionStore extends Persistable {
  readonly name: string = "DiscussionStore"
  started: boolean
  stepsOrder!: DiscussionSteps[]
  dialoguesMap!: Partial<Record<DiscussionSteps, DialogueIDs>>
  currentStepIndex: number

  constructor() {
    super()
    this.started = this.hydrate("started") ?? false
    this.stepsOrder = this.hydrate("stepsOrder") ?? []
    this.dialoguesMap = this.hydrate("dialoguesMap") ?? {}
    this.currentStepIndex = this.hydrate("currentStepIndex") ?? 0
  }

  setStarted(started: boolean): void {
    this.started = started
    this.persist("started", started)
  }

  setDiscussionStepsOrder(stepsOrder: DiscussionSteps[]): void {
    this.stepsOrder = stepsOrder
    this.persist("stepsOrder", stepsOrder)
  }

  setDialoguesMap(map: Partial<Record<DiscussionSteps, DialogueIDs>>): void {
    this.dialoguesMap = map
    this.persist("dialoguesMap", map)
  }

  setCurrentStepIndex(index: number): void {
    this.currentStepIndex = index
    this.persist("currentStepIndex", index)
  }

  getDialogueClass<Key extends keyof IDialoguesRegistry>(
    step: DiscussionSteps
  ): IDialoguesRegistry[Key] | undefined {
    const id = this.dialoguesMap?.[step] as Key | undefined
    if (id) return dialoguesRegistry.get(id)
  }

  /** Getters / Setters */

  get currentStep(): DiscussionSteps {
    return this.stepsOrder[this.currentStepIndex]
  }
}
