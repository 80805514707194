import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState
export type RiskPathwayInsightScriptState = State

export class RiskPathwayInsightScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayInsightScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 3 weeks time)?",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 3 weeks time)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    // PHQ9Q9 >= 2 and Yes to keeping safe
    if (d.response && d.state.php9q9Score! >= 2) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelModerate(d.state, RiskLevelReason.PHQ9_Q9_2_PLUS)
    }
    // PHQ9Q9 >= 2 and No to keeping safe
    if (!d.response && d.state.php9q9Score! >= 2) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_CANT_KEEP_SAFE_2_PLUS)
    }
    // PHQ9Q9 === 1 and answer NO, cannot keep self safe
    if (!d.response && d.state.php9q9Score! === 1) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_CANT_KEEP_SAFE_1)
    }

    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "However, this is not an emergency response service",
        "There are alternative services that are better placed to support you",
        "If you need urgent, life threatening medical help please call [999](tel:999)",
        "Alternatively, follow this [link](https://www.insightiapt.org/need-urgent-help/) to find out about other services, including your local mental health crisis team that will be able to support you",
        `One of ${serviceName}'s clinical team will contact you within one working day to check that you have referred yourself in to one of the crisis support services`
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  getKeepingSafeEmailSubject(state: State): string {
    return "Keeping yourself safe"
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
    <html xmlns='http://www.w3.org/1999/xhtml' lang=''>
      <head>
        <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Keeping yourself safe - Insight IAPT</title>
        ${this.getEmailHTMLStyle()}
        <!--[if IEMobile 7]>
          <style type="text/css">
            /* Targeting Windows Mobile */
          </style>
        <![endif]-->
        <!--[if gte mso 9]>
          <style>
            /* Target Outlook 2007 and 2010 */
          </style>
        <![endif]-->
      </head>
    
      <body>
        <table cellpadding='0' cellspacing='0' border='0' id='backgroundTable'>
          <tr>
            <td>
              <table cellpadding='0' cellspacing='0' border='0' id='contentTable'>
                <tr>
                  <td>
                    <img
                      class='image_fix'
                      style='max-width: 550px; display: block; margin-left: auto; margin-right: auto;'
                      src='https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/logo-keeping-safe.png'
                      alt='${title}'
                      title='${title}'
                      width='x'
                      height='x'
                    />
                    <br /><br />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h1 style='color: #dc186d !important;'>${title}</h1>
                    <p>You have made a self-referral today to Insight IAPT service. In one of your answers you said you were experiencing some thoughts of self-harm or taking your own life.</p>
                    <p>As you are waiting to access our service, if you feel you may act on suicidal thoughts, cannot keep yourself or are a risk to others around you, please call 999 or look for your local crisis team contact details by following the link below:</p>
                    <p>https://www.insightiapt.org/need-urgent-help/</p>
                    <p>The Insight IAPT Team</p>                 
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </body>
    </html>`
  }
}

export default class RiskPathwayInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayInsight
  readonly name: string = "RiskPathwayInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayInsightDialogue.id, new RiskPathwayInsightScript(), state, snapshot)
  }
}
