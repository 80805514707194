import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import IPostcode, { PostcodeStatus } from "../../models/IPostcode"
import delay from "../../utils/delay"
import isOnline from "is-online"

const BASE_URL = "https://api.postcodes.io/postcodes"
const TOTAL_RETRIES = 3

const headers = new Headers()
headers.set("Content-Type", "application/json")

export async function getPostCodeDetails(
  postCode?: string
): Promise<[IPostcode | undefined, PostcodeStatus]> {
  const [postcode, error] = await get(`${BASE_URL}/${postCode}`)
  return [postcode ? serverPostcodeToIPostcode(postcode) : undefined, error]
}

export async function getCCGId(postCode?: string): Promise<string | undefined> {
  try {
    const details = await getPostCodeDetails(postCode)
    return details?.[0]?.ccgId
  } catch (e) {
    Logger.getInstance().exception(e, "getCCGId")
  }
}

export async function get(url: string, retry = 0): Promise<[any, PostcodeStatus]> {
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) {
      throw new NetworkError("502", "no internet connection")
    }
    const result = await fetch(url, { headers })
    const data = await result.json()
    if (data?.error) {
      throw new NetworkError(data?.status, data?.error)
    }
    return [data.result, PostcodeStatus.Success]
  } catch (e) {
    const isInvalidPostcode = e.message.match(/invalid/i) && e.message.match(/postcode/i)
    // Note: the following needs to be as specific as possible because it seems it returns an error "Resource not found"
    // Which was probably giving the wrong error i.e. that Postcode was not found because it was picking up
    // the "not found" from the "Resource not found"
    const isNotFoundPostcode =
      e.message.toLowerCase().match(/postcode/i) &&
      e.message.match(/not/i) &&
      e.message.match(/found/i)
    const invalidOrNotFoundPostcodeStatus = isInvalidPostcode
      ? PostcodeStatus.InvalidPostcode
      : PostcodeStatus.PostcodeNotFound
    const errorType =
      isInvalidPostcode || isNotFoundPostcode
        ? invalidOrNotFoundPostcodeStatus
        : PostcodeStatus.RequestFailed
    if (
      errorType !== PostcodeStatus.InvalidPostcode &&
      errorType !== PostcodeStatus.PostcodeNotFound &&
      retry < TOTAL_RETRIES
    ) {
      Logger.getInstance().exception(e, "get postcode failed - getPostCodeDetails Retry")
      await delay(2 * retry || 1.5)
      return await get(url, retry + 1)
    } else if (e.code === "502") {
      Logger.getInstance().exception(e, PostcodeStatus.NoInternetConnection)
      return [undefined, PostcodeStatus.NoInternetConnection]
    } else {
      Logger.getInstance().exception(e, errorType)
      return [undefined, errorType]
    }
  }
}

interface IServerPostcode {
  postcode: string
  longitude: number
  latitude: number
  ccg: string
  codes: {
    ccg_id: string
  }
}

function serverPostcodeToIPostcode(serverPostcode: IServerPostcode): IPostcode {
  return {
    postcode: serverPostcode.postcode,
    longitude: serverPostcode.longitude,
    latitude: serverPostcode.latitude,
    ccg: serverPostcode.ccg,
    ccgId: serverPostcode.codes.ccg_id
  }
}
