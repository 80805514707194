import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState
export type RiskPathwayCCGScriptState = State

export class RiskPathwayCCGScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayCCGScript"

  /** Script Steps */

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    const isHighRisk = this.clinicalStore.isHighRisk
    if (!isHighRisk && d.state.php9q9Score! >= 1) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_ABOVE_ZERO)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step
  sayRiskExplanation(d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(1)
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, it sounds like you have been experiencing symptoms of depression, including thoughts of hurting yourself, or of being better off dead`,
        "It is normal for people to have thoughts of this nature at times when we feel down",
        "It may be nothing to worry about",
        "However, your safety is my number one priority. And <b>this is not an emergency response service</b>"
      ],
      nextStep: this.sayInstructions
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: this.sayRiskExplanation }
  }

  getClosingMessage(state: State): StepResultBodyType[] | undefined {
    const body = [] as string[]
    if (state.keepingSafeSent) {
      body.push(
        "I've also sent you some useful material over email. " +
          "If you don't see this in your inbox, maybe check your spam folder"
      )
    }
    body.push("Ok, let's carry on with the mental health check in")
    return body
  }

  getReferralSubmittedIndicatorHTML(): undefined {
    // we never send the referral to the service
    // so on reason to add the indicator
    return undefined
  }
}

export default class RiskPathwayCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayCCG
  readonly name: string = "RiskPathwayCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayCCGDialogue.id, new RiskPathwayCCGScript(), state, snapshot)
  }
}
