import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { EligibilityCheckIAPTScript } from "./EligibilityCheckIAPTDialogue"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IGPService } from "../../../models/IGPService"
import getIAPTById from "../../../utils/getIAPTById"
import { IAPTIDs } from "../../../models/IIAPTService"

// In order for this to be compatible with the spine flow
// we need to convert these into ODS compatible GPs so that
// they are saved in state.odsGP so that the iapt check won't
// try to fetch it from the ODS (we don't want to do that because
// these have custom nacsCodes so it will fail on most of them)
// and then use the iaptPerGPMap to specify which iapt to refer to
// from which GP
export const gpServices: IGPService[] = [
  {
    id: "0",
    pimsCode: "C81001a",
    nacsCode: "C81001a",
    name: "The Springs Health Centre",
    formattedName: "The Springs Health Centre (Recreation Close, Clowne)",
    postcode: "S43 4PL",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "1",
    pimsCode: "C81003",
    nacsCode: "C81003",
    name: "Sett Valley Medical Centre",
    formattedName: "Sett Valley Medical Centre (Hyde Bank Road, New Mills, Hig)",
    postcode: "SK22 4BP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "2",
    pimsCode: "C81004",
    nacsCode: "C81004",
    name: "Ivy Grove Surgery",
    formattedName: "Ivy Grove Surgery (Steeple Drive)",
    postcode: "DE5 3TH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "3",
    pimsCode: "C81013",
    nacsCode: "C81013",
    name: "Baslow Health Centre",
    formattedName: "Baslow Health Centre (Church Lane, Baslow)",
    postcode: "DE45 1SP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "4",
    pimsCode: "C81014",
    nacsCode: "C81014",
    name: "Derwent Valley Medical Practice",
    formattedName: "Derwent Valley Medical Practice (20 St Marks Road)",
    postcode: "DE21 6AT",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "5",
    pimsCode: "C81016",
    nacsCode: "C81016",
    name: "Bakewell Medical Centre",
    formattedName: "Bakewell Medical Centre (C/O Tideswell Surgery, Parke Road, Tideswell)",
    postcode: "SK17 8NS",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "6",
    pimsCode: "C81017",
    nacsCode: "C81017",
    name: "Arthur Medical Centre",
    formattedName: "Arthur Medical Centre (Four Lane Ends, Main Street, Horsle)",
    postcode: "DE7 6AX",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "7",
    pimsCode: "C81020",
    nacsCode: "C81020",
    name: "Newhall Surgery",
    formattedName: "Newhall Surgery (46-48 High Street, Newhall)",
    postcode: "DE11 0HS",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "8",
    pimsCode: "C81026",
    nacsCode: "C81026",
    name: "Adam House Medical Centre",
    formattedName: "Adam House Medical Centre (85 - 91 Derby Road)",
    postcode: "NG10 5HZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "9",
    pimsCode: "C81032",
    nacsCode: "C81032",
    name: "Swadlincote Surgery",
    formattedName: "Swadlincote Surgery (Darklands Road)",
    postcode: "DE11 0PP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "10",
    pimsCode: "C81038",
    nacsCode: "C81038",
    name: "Whitemoor Medical Centre",
    formattedName: "Whitemoor Medical Centre (Whitemoor Lane)",
    postcode: "DE56 0JB",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "11",
    pimsCode: "C81039",
    nacsCode: "C81039",
    name: "Eyam Surgery",
    formattedName: "Eyam Surgery (Church Street, Eyam, Hop)",
    postcode: "S32 5QH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "12",
    pimsCode: "C81040",
    nacsCode: "C81040",
    name: "Park Lane Surgery",
    formattedName: "Park Lane Surgery (2 Park Lane)",
    postcode: "DE22 2DS",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "13",
    pimsCode: "C81042",
    nacsCode: "C81042",
    name: "Mickleover Medical Centre",
    formattedName: "Mickleover Medical Centre (Vicarage Road, Mickleover)",
    postcode: "DE3 0HA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "14",
    pimsCode: "C81046",
    nacsCode: "C81046",
    name: "West Hallam Medical Centre",
    formattedName: "West Hallam Medical Centre (The Village, West Hallam )",
    postcode: "DE7 6GR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "15",
    pimsCode: "C81048",
    nacsCode: "C81048",
    name: "Appletree Medical Practice",
    formattedName: "Appletree Medical Practice (47a Town Street, Duffield)",
    postcode: "DE56 4GG",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "16",
    pimsCode: "C81050",
    nacsCode: "C81050",
    name: "The Village Surgery",
    formattedName: "The Village Surgery (108 Victoria Road, Pinxton)",
    postcode: "NG16 6NH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "17",
    pimsCode: "C81052",
    nacsCode: "C81052",
    name: "Brooklyn Medical Practice",
    formattedName: "Brooklyn Medical Practice (65 Mansfield Road)",
    postcode: "DE75 7AL",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "18",
    pimsCode: "C81055",
    nacsCode: "C81055",
    name: "North Wingfield Medical Centre",
    formattedName: "North Wingfield Medical Centre (Chesterfield Road, North Wingfield)",
    postcode: "S42 5ND",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "19",
    pimsCode: "C81057",
    nacsCode: "C81057",
    name: "Willington Surgery",
    formattedName: "Willington Surgery (Kingfisher Lane, Willington)",
    postcode: "DE65 6QT",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "20",
    pimsCode: "C81058",
    nacsCode: "C81058",
    name: "The Brimington Surgery",
    formattedName: "The Brimington Surgery (Church Street, Brimington)",
    postcode: "S43 1JG",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "21",
    pimsCode: "C81060",
    nacsCode: "C81060",
    name: "Woodville Surgery",
    formattedName: "Woodville Surgery (71 Burton Road, Woodville)",
    postcode: "DE11 7JE",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "22",
    pimsCode: "C81061",
    nacsCode: "C81061",
    name: "Littlewick Medical Centre",
    formattedName: "Littlewick Medical Centre (42 Nottingham Road)",
    postcode: "DE7 5PR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "23",
    pimsCode: "C81062",
    nacsCode: "C81062",
    name: "Hannage Brook Medical Centre",
    formattedName: "Hannage Brook Medical Centre (Hannage Way, Water Lane)",
    postcode: "DE4 4JG",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "24",
    pimsCode: "C81064",
    nacsCode: "C81064",
    name: "Park Farm Medical Centre",
    formattedName: "Park Farm Medical Centre (3 Park Farm Centre, Allestree)",
    postcode: "DE22 2QN",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "25",
    pimsCode: "C81065",
    nacsCode: "C81065",
    name: "Buxton Medical Practice",
    formattedName: "Buxton Medical Practice (2 Temple Road)",
    postcode: "SK17 9BZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "26",
    pimsCode: "C81066",
    nacsCode: "C81066",
    name: "Overdale Medical Practice",
    formattedName: "Overdale Medical Practice ( 207 Victoria Avenue, Borrowash)",
    postcode: "DE72 3HG",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "27",
    pimsCode: "C81067",
    nacsCode: "C81067",
    name: "Chatsworth Road Medical Centre",
    formattedName: "Chatsworth Road Medical Centre (Storrs Road, Brampton)",
    postcode: "S40 3PY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "28",
    pimsCode: "C81070",
    nacsCode: "C81070",
    name: "Oakhill Medical Practice",
    formattedName: "Oakhill Medical Practice (Oakhill Road)",
    postcode: "S18 2EJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "29",
    pimsCode: "C81073",
    nacsCode: "C81073",
    name: "Macklin Street Surgery",
    formattedName: "Macklin Street Surgery (90 Macklin Street)",
    postcode: "DE1 1JX",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "30",
    pimsCode: "C81074",
    nacsCode: "C81074",
    name: "Elmwood Medical Centre",
    formattedName: "Elmwood Medical Centre (7 Burlington Road)",
    postcode: "SK17 9AY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "31",
    pimsCode: "C81080",
    nacsCode: "C81080",
    name: "Goyt Valley Medical Practice",
    formattedName: "Goyt Valley Medical Practice (Chapel Street, Whaley Bridge, Hig)",
    postcode: "SK23 7SR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "32",
    pimsCode: "C81082",
    nacsCode: "C81082",
    name: "Hartington Surgery",
    formattedName: "Hartington Surgery (Dig Street, Hartington)",
    postcode: "SK17 0AQ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "33",
    pimsCode: "C81092",
    nacsCode: "C81092",
    name: "Evelyn Medical Centre",
    formattedName: "Evelyn Medical Centre (Marsh Avenue)",
    postcode: "S33 6RJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "34",
    pimsCode: "C81097",
    nacsCode: "C81097",
    name: "College Street Medical Practice",
    formattedName: "College Street Medical Practice (86 College Street, Long Eaton)",
    postcode: "NG10 4NP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "35",
    pimsCode: "C81101",
    nacsCode: "C81101",
    name: "Lime Grove Medical Centre",
    formattedName: "Lime Grove Medical Centre (Lime Grove Walk)",
    postcode: "DE4 3FD",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "36",
    pimsCode: "C81110",
    nacsCode: "C81110",
    name: "Wellbrook Medical Centre",
    formattedName: "Wellbrook Medical Centre (Welland Road)",
    postcode: "DE65 5GZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "37",
    pimsCode: "C81113",
    nacsCode: "C81113",
    name: "Mickleover Surgery",
    formattedName: "Mickleover Surgery (10 Cavendish Way)",
    postcode: "DE3 9BJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "38",
    pimsCode: "C81115",
    nacsCode: "C81115",
    name: "Gladstone House Surgery",
    formattedName: "Gladstone House Surgery (Gladstone Street West)",
    postcode: "DE7 5QS",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "39",
    pimsCode: "C81616",
    nacsCode: "C81616",
    name: "Peartree Medical Centre",
    formattedName: "Peartree Medical Centre (159 Peartree Road)",
    postcode: "DE23 8NQ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "40",
    pimsCode: "C81634",
    nacsCode: "C81634",
    name: "Arden House Medical Practice",
    formattedName: "Arden House Medical Practice (Sett Close, New Mills, Hig)",
    postcode: "SK22 4AQ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "41",
    pimsCode: "C81638",
    nacsCode: "C81638",
    name: "Castle Street Medical Centre",
    formattedName: "Castle Street Medical Centre (Castle Street)",
    postcode: "S44 6PP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "42",
    pimsCode: "C81647",
    nacsCode: "C81647",
    name: "St Lawrence Road Surgery",
    formattedName: "St Lawrence Road Surgery (17 - 19 St Lawrence Road, North Wingfield)",
    postcode: "S42 5LH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "43",
    pimsCode: "C81652",
    nacsCode: "C81652",
    name: "Derwent Medical Centre",
    formattedName: "Derwent Medical Centre (26 North Street)",
    postcode: "DE1 3AZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "44",
    pimsCode: "C84011",
    nacsCode: "C84011",
    name: "Elmswood Surgery",
    formattedName: "Elmswood Surgery (Sherwood Health Centre, Elmswood Gardens, Sherwood)",
    postcode: "NG5 4AD",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "45",
    pimsCode: "C84025",
    nacsCode: "C84025",
    name: "East Bridgford Medical Centre",
    formattedName: "East Bridgford Medical Centre (2 Butt Lane, East Bridgford)",
    postcode: "NG13 8NY",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "46",
    pimsCode: "C84026",
    nacsCode: "C84026",
    name: "Stenhouse Medical Centre",
    formattedName: "Stenhouse Medical Centre (66 Furlong Street)",
    postcode: "NG5 7BP",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "47",
    pimsCode: "C84032",
    nacsCode: "C84032",
    name: "Eastwood Primary Care Centre",
    formattedName: "Eastwood Primary Care Centre (11b Church Street, Eastwood)",
    postcode: "NG16 3BS",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "48",
    pimsCode: "C84033",
    nacsCode: "C84033",
    name: "Westdale Lane Surgery",
    formattedName: "Westdale Lane Surgery (20-22 Westdale Lane, Gedling)",
    postcode: "NG4 3JA",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "49",
    pimsCode: "C84034",
    nacsCode: "C84034",
    name: "Churchfields Medical Practice",
    formattedName:
      "Churchfields Medical Practice (Old Basford Health Centre, 1 Bailey Street, Old Basford)",
    postcode: "NG6 0HD",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "50",
    pimsCode: "C84039",
    nacsCode: "C84039",
    name: "Derby Road Health Centre",
    formattedName: "Derby Road Health Centre (336 Derby Road, Lenton)",
    postcode: "NG7 2DW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "51",
    pimsCode: "C84043",
    nacsCode: "C84043",
    name: "Leen View Surgery",
    formattedName: "Leen View Surgery (Bulwell Riverside Health Centre, Main Street, Bulwell)",
    postcode: "NG6 8QJ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "52",
    pimsCode: "C84044",
    nacsCode: "C84044",
    name: "Deer Park Family Medical Practice",
    formattedName: "Deer Park Family Medical Practice (Wollaton Vale Health Centre, Wollaton Vale)",
    postcode: "NG8 2GR",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "53",
    pimsCode: "C84053",
    nacsCode: "C84053",
    name: "Torkard Hill Medical Centre",
    formattedName: "Torkard Hill Medical Centre (Farleys Lane)",
    postcode: "NG15 6DY",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "54",
    pimsCode: "C84055",
    nacsCode: "C84055",
    name: "Highcroft Surgery",
    formattedName: "Highcroft Surgery (Arnold Health Centre, High Street, Arnold)",
    postcode: "NG5 7BQ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "55",
    pimsCode: "C84060",
    nacsCode: "C84060",
    name: "Rivergreen Medical Centre",
    formattedName: "Rivergreen Medical Centre (106 Southchurch Drive, Clifton)",
    postcode: "NG11 8AD",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "56",
    pimsCode: "C84078",
    nacsCode: "C84078",
    name: "Hucknall Road Medical Centre",
    formattedName: "Hucknall Road Medical Centre (Off Kibworth close, Heathfield)",
    postcode: "NG5 1NA",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "57",
    pimsCode: "C84091",
    nacsCode: "C84091",
    name: "Aspley Medical Centre",
    formattedName: "Aspley Medical Centre (509 Aspley Lane, Aspley)",
    postcode: "NG8 5RU",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "58",
    pimsCode: "C84105",
    nacsCode: "C84105",
    name: "The Fairfields Practice",
    formattedName: "The Fairfields Practice (Mary Potter Centre, Gregory Boulevard, Hyson Green)",
    postcode: "NG7 5HY",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "59",
    pimsCode: "C84112",
    nacsCode: "C84112",
    name: "Bramcote Surgery",
    formattedName: "Bramcote Surgery (2a Hanley Avenue)",
    postcode: "NG9 3HF",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "60",
    pimsCode: "C84115",
    nacsCode: "C84115",
    name: "Plains View Surgery",
    formattedName: "Plains View Surgery (57 Plains Road)",
    postcode: "NG3 5LB",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "61",
    pimsCode: "C84116",
    nacsCode: "C84116",
    name: "Melbourne Park Medical Centre",
    formattedName: "Melbourne Park Medical Centre (Melbourne Park, Aspley)",
    postcode: "NG8 5HL",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "62",
    pimsCode: "C84131",
    nacsCode: "C84131",
    name: "Newthorpe Medical Practice",
    formattedName: "Newthorpe Medical Practice (Harvest Road, Eastwood)",
    postcode: "NG16 3HU",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "63",
    pimsCode: "C84605",
    nacsCode: "C84605",
    name: "Castle Healthcare Practice",
    formattedName:
      "Castle Healthcare Practice, Embankment Primary Care Centre, 50-60 Wilford Lane, West Bridgford, Nottingham",
    postcode: "NG2 7SD",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "64",
    pimsCode: "C84619",
    nacsCode: "C84619",
    name: "Tudor House Medical Practice",
    formattedName: "Tudor House Medical Practice (138 Edwards Lane, Sherwood)",
    postcode: "NG5 3HU",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "65",
    pimsCode: "C84624",
    nacsCode: "C84624",
    name: "Hama Medical Centre",
    formattedName: "Hama Medical Centre (11 Nottingham Road, Kimberley)",
    postcode: "NG16 2NB",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "66",
    pimsCode: "C84628",
    nacsCode: "C84628",
    name: "Sherwood Rise Medical Centre",
    formattedName: "Sherwood Rise Medical Centre (31 Nottingham Road, Sherwood Rise)",
    postcode: "NG7 7AD",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "67",
    pimsCode: "C84667",
    nacsCode: "C84667",
    name: "Giltbrook Surgery",
    formattedName: "Giltbrook Surgery (492 Nottingham Road, Giltbrook)",
    postcode: "NG16 2GE",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "68",
    pimsCode: "C84682",
    nacsCode: "C84682",
    name: "Sherrington Park Medical Practice",
    formattedName: "Sherrington Park Medical Practice (402 Mansfield Road, Sherwood)",
    postcode: "NG5 2EJ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "69",
    pimsCode: "C84693",
    nacsCode: "C84693",
    name: "Bakersfield Medical Centre",
    formattedName: "Bakersfield Medical Centre (141 Oakdale Road, Bakersfield)",
    postcode: "NG3 7EJ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "70",
    pimsCode: "C84695",
    nacsCode: "C84695",
    name: "The Alice Medical Centre",
    formattedName: "The Alice Medical Centre (1 Carnwood Road, Bestwood Estate)",
    postcode: "NG5 5HW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "71",
    pimsCode: "C84696",
    nacsCode: "C84696",
    name: "West Oak Surgery",
    formattedName: "West Oak Surgery (319 Westdale Lane, Mapperley)",
    postcode: "NG3 6EW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "72",
    pimsCode: "C84704",
    nacsCode: "C84704",
    name: "Beechdale Surgery",
    formattedName: "Beechdale Surgery (439 Beechdale Road, Aspley)",
    postcode: "NG8 3LF",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "73",
    pimsCode: "C84705",
    nacsCode: "C84705",
    name: "Hickings Lane Medical Centre",
    formattedName: "Hickings Lane Medical Centre (120 Ryecroft Street)",
    postcode: "NG9 8PN",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "74",
    pimsCode: "Y02847A",
    nacsCode: "Y02847A",
    name: "Parliament Street Medical Centre",
    formattedName: "Parliament Street Medical Centre (79A Upper Parliament Street, Nottingham)",
    postcode: "NG1 6LD",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "75",
    pimsCode: "Y03124",
    nacsCode: "Y03124",
    name: "Grange Farm Medical Centre",
    formattedName: "Grange Farm Medical Centre (17a Tremayne Road, Bilborough)",
    postcode: "NG8 4HQ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "76",
    pimsCode: "Y05733",
    nacsCode: "Y05733",
    name: "Parkfields Surgery",
    formattedName: "Parkfields Surgery (1217 London Road, Alvaston)",
    postcode: "DE24 8QJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "77",
    pimsCode: "Y06356",
    nacsCode: "Y06356",
    name: "Bilborough Medical Centre",
    formattedName: "Bilborough Medical Centre (48 Bracebridge Drive, Bilborough)",
    postcode: "NG8 4PN",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "78",
    pimsCode: "Y06443",
    nacsCode: "Y06443",
    name: "Whyburn Medical Practice",
    formattedName: "Whyburn Medical Practice (Curtis Street, Hucknall)",
    postcode: "NG15 7JE",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "79",
    pimsCode: "Y06507",
    nacsCode: "Y06507",
    name: "Peacock Healthcare",
    formattedName: "Peacock Healthcare (428 Carlton Hill)",
    postcode: "NG4 1HQ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "80",
    pimsCode: "Y06792",
    nacsCode: "Y06792",
    name: "Broad Oak Medical Practice",
    formattedName: "Broad Oak Medical Practice (Strelley Health Centre, 116 Strelley Road)",
    postcode: "NG8 6LN",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "81",
    pimsCode: "C81002",
    nacsCode: "C81002",
    name: "Moss Valley Medical Practice",
    formattedName: "Moss Valley Medical Practice (Gosber Road, Eckington)",
    postcode: "S21 4BZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "82",
    pimsCode: "C81002a",
    nacsCode: "C81002a",
    name: "Gosforth Valley Medical Practice",
    formattedName: "Gosforth Valley Medical Practice (Gorsey Brigg, Off Pentland Road, Dronfiel)",
    postcode: "S18 8UE",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "83",
    pimsCode: "C81003A",
    nacsCode: "C81003A",
    name: "Old Bank Surgery",
    formattedName: "Old Bank Surgery (Market Street, Hayfield, Hig)",
    postcode: "SK22 4EP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "84",
    pimsCode: "C81005",
    nacsCode: "C81005",
    name: "Jessop Medical Practice Greenhill Lane",
    formattedName: "Jessop Medical Practice Greenhill Lane (Leabrooks, Alfreton)",
    postcode: "DE55 1LU",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "85",
    pimsCode: "C81005B",
    nacsCode: "C81005B",
    name: "Jessop Medical Practice",
    formattedName:
      "Jessop Medical Practice (C/O Greenhill Primary Care Centre, Greenhill Lane, Leabrooks, Alfreton)",
    postcode: "DE55 1LU",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "86",
    pimsCode: "C81006",
    nacsCode: "C81006",
    name: "Horizon Healthcare",
    formattedName: "Horizon Healthcare (2-5 Burton Road)",
    postcode: "DE1 1TH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "87",
    pimsCode: "C81006A",
    nacsCode: "C81006A",
    name: "Horizon Healthcare",
    formattedName: "Horizon Healthcare (5 Tuffnell Gardens, Mackworth)",
    postcode: "DE22 4DY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "88",
    pimsCode: "C81009",
    nacsCode: "C81009",
    name: "Wilson Street Surgery",
    formattedName: "Wilson Street Surgery (11 Wilson Street)",
    postcode: "DE1 1PG",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "89",
    pimsCode: "C81009A",
    nacsCode: "C81009A",
    name: "Taddington Road Surgery",
    formattedName: "Taddington Road Surgery (Taddington Road, Chaddesden)",
    postcode: "DE21 4JU",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "90",
    pimsCode: "C81010",
    nacsCode: "C81010",
    name: "Moir Medical Centre",
    formattedName: "Moir Medical Centre (Regent Street, Lon)",
    postcode: "NG10 1QQ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "91",
    pimsCode: "C81010A",
    nacsCode: "C81010A",
    name: "Sawley Medical Centre",
    formattedName: "Sawley Medical Centre (60 Draycott Road, Sawley, Lon)",
    postcode: "NG10 3FR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "92",
    pimsCode: "C81012",
    nacsCode: "C81012",
    name: "Wheatbridge Surgery",
    formattedName: "Wheatbridge Surgery (30 Wheatbridge Road)",
    postcode: "S40 2AB",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "93",
    pimsCode: "C81014A",
    nacsCode: "C81014A",
    name: "Derwent Valley Medical Practice",
    formattedName: "Derwent Valley Medical Practice (81 Sitwell Street, Spondon)",
    postcode: "DE21 7TH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "94",
    pimsCode: "C81015",
    nacsCode: "C81015",
    name: "Newbold Surgery",
    formattedName: "Newbold Surgery (3 Windermere Road, Newbold)",
    postcode: "S41 8DU",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "95",
    pimsCode: "C81021",
    nacsCode: "C81021",
    name: "Old Station Surgery",
    formattedName: "Old Station Surgery (Heanor Road, Ilkeston)",
    postcode: "DE7 8ES",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "96",
    pimsCode: "C81022",
    nacsCode: "C81022",
    name: "Dr Webb and Partners",
    formattedName: "Dr Webb and Partners (Ilkeston Health Centre, South Street)",
    postcode: "DE7 5PZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "97",
    pimsCode: "C81023",
    nacsCode: "C81023",
    name: "Aitune Practice",
    formattedName: "Aitune Practice (Long Eaton Health Centre, Midland Street, Lon)",
    postcode: "NG10 1RY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "98",
    pimsCode: "C81025",
    nacsCode: "C81025",
    name: "Dronfield Medical Practice",
    formattedName: "Dronfield Medical Practice (Dronfield Medical Centre, High Street)",
    postcode: "S18 1PY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "99",
    pimsCode: "C81026A",
    nacsCode: "C81026A",
    name: "Hillside Medical Centre",
    formattedName: "Hillside Medical Centre (162 Nottingham Road, Stapleford)",
    postcode: "NG9 8AR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "100",
    pimsCode: "C81027",
    nacsCode: "C81027",
    name: "Somercotes Medical Centre",
    formattedName: "Somercotes Medical Centre (22 Nottingham Road)",
    postcode: "DE55 4JJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "101",
    pimsCode: "C81028",
    nacsCode: "C81028",
    name: "Imperial Road Surgery",
    formattedName: "Imperial Road Surgery (8 Imperial Road)",
    postcode: "DE4 3NL",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "102",
    pimsCode: "C81029",
    nacsCode: "C81029",
    name: "Staffa Health",
    formattedName: "Staffa Health (189 Birkinstyle Lane, Stonebroom)",
    postcode: "DE55 6LD",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "103",
    pimsCode: "C81029A",
    nacsCode: "C81029A",
    name: "Staffa Health",
    formattedName: "Staffa Health (19a Heath Road, Holmewood)",
    postcode: "S42 5RB",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "104",
    pimsCode: "C81029B",
    nacsCode: "C81029B",
    name: "Staffa Health",
    formattedName: "Staffa Health (3 Waverley Street, Tibself)",
    postcode: "DE55 5PS",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "105",
    pimsCode: "C81029C",
    nacsCode: "C81029C",
    name: "Staffa Health",
    formattedName: "Staffa Health (Willow Close, Pilsley)",
    postcode: "S45 8JA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "106",
    pimsCode: "C81030",
    nacsCode: "C81030",
    name: "Darley Dale Medical Centre",
    formattedName: "Darley Dale Medical Centre (Dale Road South, Darley Dale)",
    postcode: "DE4 2EY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "107",
    pimsCode: "C81030B",
    nacsCode: "C81030B",
    name: "Youlgreave Surgery",
    formattedName: "Youlgreave Surgery (Alport Road, Youlgreave)",
    postcode: "DE45 1WN",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "108",
    pimsCode: "C81031",
    nacsCode: "C81031",
    name: "The Park Surgery",
    formattedName: "The Park Surgery (60 Ilkeston Road)",
    postcode: "DE75 7DX",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "109",
    pimsCode: "C81033",
    nacsCode: "C81033",
    name: "Shires Health Care",
    formattedName: "Shires Health Care (18 Main Street)",
    postcode: "NG20 8DG",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "110",
    pimsCode: "C81034",
    nacsCode: "C81034",
    name: "Stewart Medical Centre",
    formattedName: "Stewart Medical Centre (15 Hartington Road)",
    postcode: "SK17 6JP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "111",
    pimsCode: "C81035",
    nacsCode: "C81035",
    name: "Village Community Medical Centre",
    formattedName: "Village Community Medical Centre (Browning Street)",
    postcode: "DE23 8AL",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "112",
    pimsCode: "C81036",
    nacsCode: "C81036",
    name: "Friar Gate Surgery",
    formattedName: "Friar Gate Surgery (Agard Street)",
    postcode: "DE1 1DZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "113",
    pimsCode: "C81037",
    nacsCode: "C81037",
    name: "Ashbourne Medical Practice",
    formattedName: "Ashbourne Medical Practice (Clifton Road)",
    postcode: "DE6 1DR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "114",
    pimsCode: "C81041",
    nacsCode: "C81041",
    name: "Glapwell Surgery",
    formattedName: "Glapwell Surgery (The Green, Glapwell)",
    postcode: "S44 6DF",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "115",
    pimsCode: "C81041A",
    nacsCode: "C81041A",
    name: "Welbeck Road Health Centre",
    formattedName: "Welbeck Road Health Centre (1B Welbeck Road, Bolsover, Chesterfield)",
    postcode: "S44 6DF",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "116",
    pimsCode: "C81044",
    nacsCode: "C81044",
    name: "Whittington Moor Surgery",
    formattedName: "Whittington Moor Surgery (Scarsdale Road, Whittington Moor)",
    postcode: "S41 8NA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "117",
    pimsCode: "C81045",
    nacsCode: "C81045",
    name: "Ashgate Medical Practice",
    formattedName: "Ashgate Medical Practice (Ashgate Manor)",
    postcode: "S40 4AA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "118",
    pimsCode: "C81047",
    nacsCode: "C81047",
    name: "Alvaston Medical Centre",
    formattedName: "Alvaston Medical Centre (14 Boulton Lane, Alvaston)",
    postcode: "DE24 0GE",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "119",
    pimsCode: "C81049",
    nacsCode: "C81049",
    name: "Kelvingrove Medical Centre",
    formattedName: "Kelvingrove Medical Centre (28 Hands Road)",
    postcode: "DE75 7HA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "120",
    pimsCode: "C81051",
    nacsCode: "C81051",
    name: "The Park Medical Practice",
    formattedName: "The Park Medical Practice (Maine Drive, Chaddesden)",
    postcode: "DE21 6LA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "121",
    pimsCode: "C81051A",
    nacsCode: "C81051A",
    name: "The Park Medical Practice",
    formattedName: "The Park Medical Practice (Borrowash Surgery, 46 Derby Road, Borrowash)",
    postcode: "DE72 3HA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "122",
    pimsCode: "C81051B",
    nacsCode: "C81051B",
    name: "The Park Medical Practice",
    formattedName: "The Park Medical Practice (University of Derby, Kedleston Road)",
    postcode: "DE22 1GB",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "123",
    pimsCode: "C81053",
    nacsCode: "C81053",
    name: "Parkside Surgery",
    formattedName: "Parkside Surgery (Alfreton PCC, Church Street)",
    postcode: "DE55 7AH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "124",
    pimsCode: "C81054",
    nacsCode: "C81054",
    name: "Hollybrook Medical Centre",
    formattedName: "Hollybrook Medical Centre (Hollybrook Way, Heatherton Village)",
    postcode: "DE23 3TX",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "125",
    pimsCode: "C81056",
    nacsCode: "C81056",
    name: "Tupton Surgery",
    formattedName: "Tupton Surgery (Queen Victoria Road, Tupton)",
    postcode: "S42 6ED",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "126",
    pimsCode: "C81056A",
    nacsCode: "C81056A",
    name: "Clay Cross Medical Centre",
    formattedName: "Clay Cross Medical Centre (Bridge Street, Clay Cross)",
    postcode: "S45 9NG",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "127",
    pimsCode: "C81059",
    nacsCode: "C81059",
    name: "Ripley Medical Centre",
    formattedName: "Ripley Medical Centre (Derby Road, Ripley)",
    postcode: "DE5 3HR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "128",
    pimsCode: "C81063",
    nacsCode: "C81063",
    name: "Thornbrook Surgery",
    formattedName: "Thornbrook Surgery (Thornbrook Road, Chapel-en-le-Frith, Hig)",
    postcode: "SK23 0RH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "129",
    pimsCode: "C81064A",
    nacsCode: "C81064A",
    name: "Vernon Street Surgery",
    formattedName: "Vernon Street Surgery (10 Vernon Street)",
    postcode: "DE1 1FR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "130",
    pimsCode: "C81066A",
    nacsCode: "C81066A",
    name: "Overdale Medical Practice",
    formattedName: "Overdale Medical Practice (1 Bridge Field, Breaston)",
    postcode: "DE72 3DS",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "131",
    pimsCode: "C81068",
    nacsCode: "C81068",
    name: "Chapel Street Medical Centre",
    formattedName: "Chapel Street Medical Centre (10 Chapel Street, Spondon)",
    postcode: "DE21 7RJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "132",
    pimsCode: "C81068A",
    nacsCode: "C81068A",
    name: "Mayfield Road Medical Centre",
    formattedName:
      "Mayfield Road Medical Centre (C/O Chapel Street Medical Centre, 10 Chapel Street, Spondon)",
    postcode: "DE21 7RJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "133",
    pimsCode: "C81069",
    nacsCode: "C81069",
    name: "Riversdale Surgery",
    formattedName: "Riversdale Surgery (59 Bridge Street)",
    postcode: "DE56 1AY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "134",
    pimsCode: "C81071",
    nacsCode: "C81071",
    name: "Osmaston Surgery",
    formattedName: "Osmaston Surgery (212 Osmaston Road)",
    postcode: "DE23 8JX",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "135",
    pimsCode: "C81072",
    nacsCode: "C81072",
    name: "Lister House",
    formattedName: "Lister House (207 St Thomas Road)",
    postcode: "DE23 8RJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "136",
    pimsCode: "C81072A",
    nacsCode: "C81072A",
    name: "Oakwood Medical Centre",
    formattedName: "Oakwood Medical Centre (Danebridge Crescent, Oakwood)",
    postcode: "DE21 2HT",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "137",
    pimsCode: "C81075",
    nacsCode: "C81075",
    name: "Brailsford Medical Centre",
    formattedName: "Brailsford Medical Centre (The Green)",
    postcode: "DE6 3BX",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "138",
    pimsCode: "C81075A",
    nacsCode: "C81075A",
    name: "Hulland Ward Medical Practice",
    formattedName: "Hulland Ward Medical Practice (Main Road, Hullaand Ward)",
    postcode: "DE6 3EA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "139",
    pimsCode: "C81080A",
    nacsCode: "C81080A",
    name: "Goyt Valley Medical Practice",
    formattedName: "Goyt Valley Medical Practice (Eccles Road, Chapel-en-le-Frith, Hig)",
    postcode: "SK23 9EQ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "140",
    pimsCode: "C81083",
    nacsCode: "C81083",
    name: "Golden Brook Practice",
    formattedName: "Golden Brook Practice (Long Eaton Health Centre, Midland Street, Lon)",
    postcode: "NG10 1RY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "141",
    pimsCode: "C81084",
    nacsCode: "C81084",
    name: "Avenue House Surgery",
    formattedName: "Avenue House Surgery (109 Saltergate)",
    postcode: "S40 1LE",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "142",
    pimsCode: "C81086",
    nacsCode: "C81086",
    name: "The Surgery",
    formattedName: "The Surgery (Clifton Road)",
    postcode: "DE6 1RR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "143",
    pimsCode: "C81087",
    nacsCode: "C81087",
    name: "Haven Medical Centre",
    formattedName: "Haven Medical Centre (690 Osmaston Road)",
    postcode: "DE24 8GT",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "144",
    pimsCode: "C81089",
    nacsCode: "C81089",
    name: "Stubley Medical Centre",
    formattedName: "Stubley Medical Centre (7 Stubley Drive)",
    postcode: "S18 8QY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "145",
    pimsCode: "C81091",
    nacsCode: "C81091",
    name: "Killamarsh Medical Practice",
    formattedName: "Killamarsh Medical Practice (209 Sheffield Road, Killamarsh)",
    postcode: "S21 1DX",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "146",
    pimsCode: "C81094",
    nacsCode: "C81094",
    name: "Holloway Surgery",
    formattedName: "Holloway Surgery (Mill Lane, Holloway)",
    postcode: "DE4 5AQ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "147",
    pimsCode: "C81094A",
    nacsCode: "C81094A",
    name: "Crich Medical Practice",
    formattedName: "Crich Medical Practice (Oakwell Drive, Crich)",
    postcode: "DE4 5BP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "148",
    pimsCode: "C81094B",
    nacsCode: "C81094B",
    name: "South Wingfield Surgery",
    formattedName: "South Wingfield Surgery (Inns Lane, South Wingfield)",
    postcode: "DE55 7LW",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "149",
    pimsCode: "C81095",
    nacsCode: "C81095",
    name: "Emmett Carr Surgery",
    formattedName: "Emmett Carr Surgery (Abbey Place)",
    postcode: "S21 3TY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "150",
    pimsCode: "C81095A",
    nacsCode: "C81095A",
    name: "Eckington Health Centre",
    formattedName: "Eckington Health Centre (Gosber Road, Eckington)",
    postcode: "S21 4BZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "151",
    pimsCode: "C81099",
    nacsCode: "C81099",
    name: "Limes Medical Centre",
    formattedName: "Limes Medical Centre (Limes Avenue)",
    postcode: "DE55 7DW",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "152",
    pimsCode: "C81604",
    nacsCode: "C81604",
    name: "Eden Surgery",
    formattedName: "Eden Surgery (Cavendish Road)",
    postcode: "DE7 5AN",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "153",
    pimsCode: "C81611",
    nacsCode: "C81611",
    name: "Ashover Medical Centre",
    formattedName: "Ashover Medical Centre (Milken Lane, Ashover)",
    postcode: "S45 0BA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "154",
    pimsCode: "C81642",
    nacsCode: "C81642",
    name: "Park View Medical",
    formattedName: "Park View Medical (Cranflett Way, Long Eaton)",
    postcode: "NG10 3RJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "155",
    pimsCode: "C81649",
    nacsCode: "C81649",
    name: "Calow + Brimington Practice",
    formattedName:
      "Calow + Brimington Practice (C/O Calow & Brimington Practice, 12 Rose Avenue, Calow)",
    postcode: "S44 5TH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "156",
    pimsCode: "C81653",
    nacsCode: "C81653",
    name: "Brook Medical Centre",
    formattedName: "Brook Medical Centre (183 Kedleston Road)",
    postcode: "DE22 1FT",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "157",
    pimsCode: "C81655",
    nacsCode: "C81655",
    name: "The Friendly Family Surgery",
    formattedName: "The Friendly Family Surgery (Welbeck Road)",
    postcode: "S44 6DE",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "158",
    pimsCode: "C81658",
    nacsCode: "C81658",
    name: "Wingerworth Medical Centre",
    formattedName:
      "Wingerworth Medical Centre (Allendale Road, Wingerworth, Chesterfield, Derbyshire)",
    postcode: "S42 6PX",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "159",
    pimsCode: "C81661",
    nacsCode: "C81661",
    name: "Blackwell Medical Practice",
    formattedName: "Blackwell Medical Practice (6 Gloves Lane, Blackwell)",
    postcode: "DE55 5JJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "160",
    pimsCode: "C81662",
    nacsCode: "C81662",
    name: "Barlborough Medical Practice",
    formattedName: "Barlborough Medical Practice (7 Worsop Road, Barlborough)",
    postcode: "S43 4TY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "161",
    pimsCode: "C82040",
    nacsCode: "C82040",
    name: "Orchard Surgery",
    formattedName: "Orchard Surgery (The Dragwell, Kegworth)",
    postcode: "DE74 2EL",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "162",
    pimsCode: "C82040A",
    nacsCode: "C82040A",
    name: "Village Hall Surgery",
    formattedName: "Village Hall Surgery (Nottingham Road, Gotham)",
    postcode: "NG11 0HE",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "163",
    pimsCode: "C84004",
    nacsCode: "C84004",
    name: "St Albans Medical Centre",
    formattedName: "St Albans Medical Centre (Hucknall Lane)",
    postcode: "NG6 8AQ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "164",
    pimsCode: "C84005A",
    nacsCode: "C84005A",
    name: "Village Health Group",
    formattedName: "Village Health Group (Shaw Street, Ruddington)",
    postcode: "NG11 6HF",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "165",
    pimsCode: "C84005B",
    nacsCode: "C84005B",
    name: "Village Health Group",
    formattedName: "Village Health Group (45 Orchard Close, Sutton Bonington)",
    postcode: "LE12 5NF",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "166",
    pimsCode: "C84010",
    nacsCode: "C84010",
    name: "Trentside Medical Group",
    formattedName:
      "Trentside Medical Group (Netherfield Medical Centre, Knight Street, Netherfield)",
    postcode: "NG4 2FN",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "167",
    pimsCode: "C84017",
    nacsCode: "C84017",
    name: "Cotgrave Surgery",
    formattedName: "Cotgrave Surgery (Candleby Lane, Cotgrave)",
    postcode: "NG12 3JG",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "168",
    pimsCode: "C84018",
    nacsCode: "C84018",
    name: "Family Medical Centre",
    formattedName: "Family Medical Centre (171 Carlton Road)",
    postcode: "NG3 2FW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "169",
    pimsCode: "C84023",
    nacsCode: "C84023",
    name: "Cripps Health Centre",
    formattedName: "Cripps Health Centre (University Park)",
    postcode: "NG7 2QW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "170",
    pimsCode: "C84028",
    nacsCode: "C84028",
    name: "Ruddington Medical Centre",
    formattedName: "Ruddington Medical Centre (Church Street, Ruddington)",
    postcode: "NG11 6HD",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "171",
    pimsCode: "C84030",
    nacsCode: "C84030",
    name: "Oaks Medical Centre",
    formattedName: "Oaks Medical Centre (18-20 Villa Street)",
    postcode: "NG9 2NY",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "172",
    pimsCode: "C84042",
    nacsCode: "C84042",
    name: "Saxon Cross Surgery",
    formattedName: "Saxon Cross Surgery (Church Street)",
    postcode: "NG9 8DA",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "173",
    pimsCode: "C84046",
    nacsCode: "C84046",
    name: "Clifton Medical Practice",
    formattedName: "Clifton Medical Practice (Clifton Cornerstone, Southchurch Drive, Clifton)",
    postcode: "NG11 8EW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "174",
    pimsCode: "C84047",
    nacsCode: "C84047",
    name: "Calverton Practice",
    formattedName: "Calverton Practice (2a St Wilfrid's Square, Calverton)",
    postcode: "NG14 6FP",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "175",
    pimsCode: "C84064",
    nacsCode: "C84064",
    name: "Parkside Medical Practice",
    formattedName: "Parkside Medical Practice (Bulwell Riverside Centre, Main Street, Bulwell)",
    postcode: "NG6 8QJ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "176",
    pimsCode: "C84065",
    nacsCode: "C84065",
    name: "Abbey Medical Centre",
    formattedName: "Abbey Medical Centre (63 Central Avenue, Beeston)",
    postcode: "NG9 2QP",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "177",
    pimsCode: "C84066",
    nacsCode: "C84066",
    name: "Daybrook Medical Centre",
    formattedName: "Daybrook Medical Centre (Salop Street, Daybrook)",
    postcode: "NG5 6HP",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "178",
    pimsCode: "C84072",
    nacsCode: "C84072",
    name: "Wellspring Surgery, St Ann`s Valley Centre",
    formattedName: "Wellspring Surgery, St Ann`s Valley Centre (2 Livingstone Road, St Ann`s)",
    postcode: "NG3 3GG",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "179",
    pimsCode: "C84080",
    nacsCode: "C84080",
    name: "Manor Surgery",
    formattedName: "Manor Surgery (Middle Street, Beeston)",
    postcode: "NG9 1GA",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "180",
    pimsCode: "C84081",
    nacsCode: "C84081",
    name: "John Ryle Medical Centre",
    formattedName: "John Ryle Medical Centre (Clifton Cornerstone, Southchurch Drive, Clifton)",
    postcode: "NG11 8EW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "181",
    pimsCode: "C84085",
    nacsCode: "C84085",
    name: "Victoria and Mapperley Practice",
    formattedName: "Victoria and Mapperley Practice (Glasshouse Street)",
    postcode: "NG1 3LW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "182",
    pimsCode: "C84085A",
    nacsCode: "C84085A",
    name: "Mapperley Surgery",
    formattedName: "Mapperley Surgery (858 Woodborough Road, Mapperley)",
    postcode: "NG3 5QQ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "183",
    pimsCode: "C84086",
    nacsCode: "C84086",
    name: "St Georges Medical Centre",
    formattedName: "St Georges Medical Centre (93 Musters Road, West Bridgford)",
    postcode: "NG2 7PG",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "184",
    pimsCode: "C84090",
    nacsCode: "C84090",
    name: "Musters Medical Practice",
    formattedName: "Musters Medical Practice (50-60 Wilford Lane, Wes)",
    postcode: "NG2 7SD",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "185",
    pimsCode: "C84095",
    nacsCode: "C84095",
    name: "Oakenhall Medical Practice",
    formattedName: "Oakenhall Medical Practice (Bolsover Street)",
    postcode: "NG15 7UA",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "186",
    pimsCode: "C84103",
    nacsCode: "C84103",
    name: "The Forest Practice",
    formattedName: "The Forest Practice (Mary Potter Centre, Gregory Boulevard, Hyson Green)",
    postcode: "NG7 5HY",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "187",
    pimsCode: "C84107",
    nacsCode: "C84107",
    name: "Linden Medical Group",
    formattedName: "Linden Medical Group (Stapleford Care Centre, Church Street, Stapleford)",
    postcode: "NG9 8DB",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "188",
    pimsCode: "C84117",
    nacsCode: "C84117",
    name: "Radford Medical Practice",
    formattedName: "Radford Medical Practice (Radford Health Centre, Ilkeston Road, Radford)",
    postcode: "NG7 3GW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "189",
    pimsCode: "C84117A",
    nacsCode: "C84117A",
    name: "Student Health Centre",
    formattedName:
      "Student Health Centre (Radford Medical Practice, Nottingham Trent University, Sandy Halls of Residence, Peel Street, Nottingham)",
    postcode: "NG1 4FW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "190",
    pimsCode: "C84120",
    nacsCode: "C84120",
    name: "Valley Surgery",
    formattedName: "Valley Surgery (81 Bramcote Lane)",
    postcode: "NG9 4ET",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "191",
    pimsCode: "C84120A",
    nacsCode: "C84120A",
    name: "Chilwell Meadows Surgery",
    formattedName: "Chilwell Meadows Surgery (Ranson Road, Chilwell)",
    postcode: "NG9 6DX",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "192",
    pimsCode: "C84122",
    nacsCode: "C84122",
    name: "Wollaton Park Medical Centre",
    formattedName: "Wollaton Park Medical Centre (12 Harrow Road, Wollaton Park)",
    postcode: "NG8 1FG",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "193",
    pimsCode: "C84129",
    nacsCode: "C84129",
    name: "Rise Park Surgery",
    formattedName: "Rise Park Surgery (Off Revelstoke Way, Rise Park)",
    postcode: "NG5 5EB",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "194",
    pimsCode: "C84138",
    nacsCode: "C84138",
    name: "Springfield Medical Centre",
    formattedName: "Springfield Medical Centre (301 Main Street, Bulwell)",
    postcode: "NG6 8ED",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "195",
    pimsCode: "C84150",
    nacsCode: "C84150",
    name: "Unity Surgery",
    formattedName: "Unity Surgery (318 Westdale Lane, Mapperley)",
    postcode: "NG3 6EU",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "196",
    pimsCode: "C84151",
    nacsCode: "C84151",
    name: "The Medical Centre",
    formattedName: "The Medical Centre (2a Zulu Road, Basford)",
    postcode: "NG7 7DS",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "197",
    pimsCode: "C84621",
    nacsCode: "C84621",
    name: "West Brigford Medical Centre",
    formattedName: "West Brigford Medical Centre (97 Musters Road, Wes)",
    postcode: "NG2 7PX",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "198",
    pimsCode: "C84646",
    nacsCode: "C84646",
    name: "Ivy Medical Group",
    formattedName: "Ivy Medical Group (Lambley Lane Surgery, 6 Lambley Lane, Burto)",
    postcode: "NG14 5BG",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "199",
    pimsCode: "C84664",
    nacsCode: "C84664",
    name: "Welbeck Surgery",
    formattedName: "Welbeck Surgery (481 - 491 Mansfield Road, Sherwood)",
    postcode: "NG5 2JJ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "200",
    pimsCode: "C84676",
    nacsCode: "C84676",
    name: "Greenfields Medical Practice",
    formattedName: "Greenfields Medical Practice (Greenfields Medical Centre, 12 Terrace Street)",
    postcode: "NG7 6ER",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "201",
    pimsCode: "C84683",
    nacsCode: "C84683",
    name: "Windmill Practice",
    formattedName: "Windmill Practice (Sneinton Health Centre, Beaumont Street, Sneinton)",
    postcode: "NG2 4PJ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "202",
    pimsCode: "C84691",
    nacsCode: "C84691",
    name: "The High Green Medical Practice",
    formattedName:
      "The High Green Medical Practice (Mary Potter Centre, Gregory Boulevard, Hyson Green)",
    postcode: "NG7 5HY",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "203",
    pimsCode: "C84694",
    nacsCode: "C84694",
    name: "Limetree Surgery",
    formattedName: "Limetree Surgery (1 Limetree Avenue, Cinderhill)",
    postcode: "NG8 6AB",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "204",
    pimsCode: "C84703",
    nacsCode: "C84703",
    name: "Gamston Medical Centre",
    formattedName: "Gamston Medical Centre (Gamston District Centre, Gamston)",
    postcode: "NG2 6PS",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "205",
    pimsCode: "C84714A",
    nacsCode: "C84714A",
    name: "Sunrise Medical Practice",
    formattedName:
      "Sunrise Medical Practice (Nottingham Trent University, Clifton Campus, Clifton Lane)",
    postcode: "NG11 8NS",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "206",
    pimsCode: "C84717",
    nacsCode: "C84717",
    name: "Riverlyn Medical Centre",
    formattedName: "Riverlyn Medical Centre (Station Road, Bulwell)",
    postcode: "NG6 9AA",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "207",
    pimsCode: "Y00026",
    nacsCode: "Y00026",
    name: "The OM Surgery",
    formattedName: "The OM Surgery (112 Watnall Road, Hucknall)",
    postcode: "NG15 7JP",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "208",
    pimsCode: "Y01812",
    nacsCode: "Y01812",
    name: "Heartwood Medical Centre",
    formattedName: "Heartwood Medical Centre (Swadlincote Health Centre, Civic Way)",
    postcode: "DE11 0AE",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "209",
    pimsCode: "Y02442",
    nacsCode: "Y02442",
    name: "St Thomas Road Surgery",
    formattedName: "St Thomas Road Surgery (207 St Thomas Road)",
    postcode: "DE23 8RJ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "210",
    pimsCode: "Y04977",
    nacsCode: "Y04977",
    name: "Creswell Medical Centre",
    formattedName: "Creswell Medical Centre (Welbeck Street, Creswell)",
    postcode: "S80 4HA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "211",
    pimsCode: "Y04977A",
    nacsCode: "Y04977A",
    name: "Langwith Medical Centre",
    formattedName: "Langwith Medical Centre (206 Main Street, Langwith, Mansfield)",
    postcode: "NG20 9HD",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "212",
    pimsCode: "Y05286",
    nacsCode: "Y05286",
    name: "Lister House at Chellaston",
    formattedName: "Lister House at Chellaston (Fellow Lands Way, Chellaston)",
    postcode: "DE73 6SW",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "213",
    pimsCode: "Y05286A",
    nacsCode: "Y05286A",
    name: "Lister House at Coleman Street",
    formattedName: "Lister House at Coleman Street (Coleman Street, Allenton)",
    postcode: "DE24 8NH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "214",
    pimsCode: "Y05622",
    nacsCode: "Y05622",
    name: "Southglade Health Centre",
    formattedName: "Southglade Health Centre (Southglade Road)",
    postcode: "NG5 5GU",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "215",
    pimsCode: "C81007",
    nacsCode: "C81007",
    name: "Vernon Street Medical Ctr",
    formattedName: "Vernon Street Medical Ctr (13 Vernon Street)",
    postcode: "DE1 1FW",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "216",
    pimsCode: "C81021B",
    nacsCode: "C81021B",
    name: "Kirk Hallam Surgery",
    formattedName: "Kirk Hallam Surgery (Queen Elizabeth Way, Kir)",
    postcode: "DE7 4NU",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "217",
    pimsCode: "C81030A",
    nacsCode: "C81030A",
    name: "Winster Surgery",
    formattedName: "Winster Surgery (Leacroft Road, Winster)",
    postcode: "DE4 2DL",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "218",
    pimsCode: "C81045A",
    nacsCode: "C81045A",
    name: "Holme Hall Medical Centre",
    formattedName: "Holme Hall Medical Centre (Wardgate Way, Holmehall)",
    postcode: "S40 4SL",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "219",
    pimsCode: "C81096",
    nacsCode: "C81096",
    name: "Crags Health Care",
    formattedName: "Crags Health Care (Cresswell Surgery, 174 Elmton Road)",
    postcode: "S80 4DY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "220",
    pimsCode: "C81096A",
    nacsCode: "C81096A",
    name: "Crags Health Care Whitwell Surgery",
    formattedName: "Crags Health Care Whitwell Surgery (The Health Centre, The Square)",
    postcode: "S80 4QR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "221",
    pimsCode: "C81108",
    nacsCode: "C81108",
    name: "Melbourne and Chellaston Medical Centre",
    formattedName: "Melbourne and Chellaston Medical Centre (Penn Lane, Melbourne)",
    postcode: "DE73 8EF",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "222",
    pimsCode: "C81649A",
    nacsCode: "C81649A",
    name: "Calow & Brimington Practice",
    formattedName: "Calow & Brimington Practice (12 Rose Avenue, Calow)",
    postcode: "S44 5TH",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "223",
    pimsCode: "C84004A",
    nacsCode: "C84004A",
    name: "Nirmala Medical Centre",
    formattedName: "Nirmala Medical Centre (112 Pedmore Valley, Bestwood Park)",
    postcode: "NG5 5NN",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "224",
    pimsCode: "C84005",
    nacsCode: "C84005",
    name: "Village Health Group",
    formattedName: "Village Health Group (Gotham Road, East Leake)",
    postcode: "LE12 6JG",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "225",
    pimsCode: "C84017A",
    nacsCode: "C84017A",
    name: "Bingham Medical Centre",
    formattedName: "Bingham Medical Centre (Newgate Street)",
    postcode: "NG13 8FD",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "226",
    pimsCode: "C84063",
    nacsCode: "C84063",
    name: "Greendale Primary Care Centre",
    formattedName: "Greendale Primary Care Centre (249 Sneinton Dale, Sneinton)",
    postcode: "NG3 7DQ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "227",
    pimsCode: "C84084",
    nacsCode: "C84084",
    name: "Radcliffe on Trent Healthcare",
    formattedName: "Radcliffe on Trent Healthcare (Main Road, Radcliffe on Trent)",
    postcode: "NG12 2GD",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "228",
    pimsCode: "C84136",
    nacsCode: "C84136",
    name: "St Luke`s Surgery",
    formattedName: "St Luke`s Surgery (Radford Health Centre, Ilkeston Road, Radford)",
    postcode: "NG7 3GW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "229",
    pimsCode: "C84613",
    nacsCode: "C84613",
    name: "Jubilee Park Medical Partnership",
    formattedName:
      "Jubilee Park Medical Partnership, Lowdham Medical Centre, Francklin Road, Lowdham",
    postcode: "NG14 7BG",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "230",
    pimsCode: "Y04995",
    nacsCode: "Y04995",
    name: "Rectory Road Medical Centre",
    formattedName: "Rectory Road Medical Centre (Rectory Road)",
    postcode: "S43 3UZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "231",
    pimsCode: "Y04995A",
    nacsCode: "Y04995A",
    name: "Grange Family Health Centre",
    formattedName: "Grange Family Health Centre (Stubbing Road)",
    postcode: "S40 2HP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "232",
    pimsCode: "Y04995B",
    nacsCode: "Y04995B",
    name: "Inkersall Family Health Centre",
    formattedName: "Inkersall Family Health Centre (Attlee Road, Inkersall)",
    postcode: "S43 3HB",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "233",
    pimsCode: "C81006B",
    nacsCode: "C81006B",
    name: "Horizon Healthcare",
    formattedName: "Horizon Healthcare (31 Humbleton Drive, Mackworth)",
    postcode: "DE22 4AU",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "234",
    pimsCode: "C81007A",
    nacsCode: "C81007A",
    name: "The Lanes Medical Centre",
    formattedName: "The Lanes Medical Centre (147 Normanton Lane, Littleover)",
    postcode: "DE23 6LF",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "235",
    pimsCode: "C81008a",
    nacsCode: "C81008a",
    name: "Grassmoor Surgery",
    formattedName: "Grassmoor Surgery (186 North Wingfield Road, Grassmoor)",
    postcode: "S42 5ED",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "236",
    pimsCode: "C81008b",
    nacsCode: "C81008b",
    name: "Royal Primary Care Clay Cross & Grassmore",
    formattedName: "Royal Primary Care Clay Cross & Grassmore (Eldon Street, Clay Cross)",
    postcode: "S45 9NR",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "237",
    pimsCode: "C81016A",
    nacsCode: "C81016A",
    name: "Tideswell Surgery",
    formattedName: "Tideswell Surgery (Parke Road, Tideswell)",
    postcode: "SK17 8NS",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "238",
    pimsCode: "C81021C",
    nacsCode: "C81021C",
    name: "Cotmanhay Surgery",
    formattedName: "Cotmanhay Surgery (1 Skeavingtons Lane)",
    postcode: "DE7 8WG",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "239",
    pimsCode: "C81033b",
    nacsCode: "C81033b",
    name: "Shires Health Care Church Warsop",
    formattedName: "Shires Health Care Church Warsop (Bishops Walk, Churc)",
    postcode: "NG20 0SN",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "240",
    pimsCode: "C81045b",
    nacsCode: "C81045b",
    name: "Whittington Medical Centre",
    formattedName: "Whittington Medical Centre (High Street, Old Whittington)",
    postcode: "S41 9JZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "241",
    pimsCode: "C81047D",
    nacsCode: "C81047D",
    name: "Aston Surgery",
    formattedName: "Aston Surgery (8 Bell Avenue, Aston o)",
    postcode: "DE72 2BE",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "242",
    pimsCode: "C81050A",
    nacsCode: "C81050A",
    name: "South Normanton Surgery",
    formattedName: "South Normanton Surgery (The Hub, shiners Way, South Normanton)",
    postcode: "De55 2AA",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "243",
    pimsCode: "C81051C",
    nacsCode: "C81051C",
    name: "The Park Medical Practice",
    formattedName: "The Park Medical Practice (Oakwood Surgery, 380 Bishops Drive, Oakwood)",
    postcode: "DE21 2DF",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "244",
    pimsCode: "C81054A",
    nacsCode: "C81054A",
    name: "Sinfin Health Centre",
    formattedName: "Sinfin Health Centre (Arleston Lane, Sinfin)",
    postcode: "DE24 3DS",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "245",
    pimsCode: "C81073A",
    nacsCode: "C81073A",
    name: "Park Farm Surgery",
    formattedName: "Park Farm Surgery (Park Farm Drive, Allestree)",
    postcode: "DE22 2RP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "246",
    pimsCode: "C81084a",
    nacsCode: "C81084a",
    name: "Hasland Medical Centre",
    formattedName: "Hasland Medical Centre (1 Jepson Road, Hasland)",
    postcode: "S41 0NZ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "247",
    pimsCode: "C81084b",
    nacsCode: "C81084b",
    name: "Hasland Surgery",
    formattedName: "Hasland Surgery (82 St Phillips Drive, Hasland)",
    postcode: "S41 0RG",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "248",
    pimsCode: "C81087A",
    nacsCode: "C81087A",
    name: "Haven Medical Centre",
    formattedName: "Haven Medical Centre (Keldholme Lane, Alvaston)",
    postcode: "DE24 0RY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "249",
    pimsCode: "C81092A",
    nacsCode: "C81092A",
    name: "Hathersage Surgery",
    formattedName: "Hathersage Surgery (5a Hathersage Park, Station Road, Hathersage)",
    postcode: "S32 1DP",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "250",
    pimsCode: "C81108b",
    nacsCode: "C81108b",
    name: "Melbourne & Chellaston Medical Practice",
    formattedName: "Melbourne & Chellaston Medical Practice (Rowallen Way, Chellaston)",
    postcode: "DE73 5BG",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "251",
    pimsCode: "C81114A",
    nacsCode: "C81114A",
    name: "Gresleydale Healthcare Centre",
    formattedName: "Gresleydale Healthcare Centre (Glamorgan Way, Church Gresley)",
    postcode: "DE11 9JT",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "252",
    pimsCode: "C81118A",
    nacsCode: "C81118A",
    name: "Derby Lane Medical Centre",
    formattedName: "Derby Lane Medical Centre (1 Hastings Street)",
    postcode: "DE23 6QQ",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "253",
    pimsCode: "C81662a",
    nacsCode: "C81662a",
    name: "Renishaw Medical Practice",
    formattedName: "Renishaw Medical Practice (c/o Emmett Carr Surgery, Abbey Place)",
    postcode: "S21 3TY",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "254",
    pimsCode: "C84005D",
    nacsCode: "C84005D",
    name: "Village Health Group",
    formattedName: "Village Health Group (Bunny Lane, Keyworth)",
    postcode: "NG12 5JU",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "255",
    pimsCode: "C84017B",
    nacsCode: "C84017B",
    name: "Cropwell Bishop Surgery",
    formattedName: "Cropwell Bishop Surgery (Fern Road, Cropwell Bishop)",
    postcode: "NG12 3BU",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "256",
    pimsCode: "C84032a",
    nacsCode: "C84032a",
    name: "Eastwood Primary Care Centre",
    formattedName: "Eastwood Primary Care Centre (Church Walk, Eastwood)",
    postcode: "NG16 3BH",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "257",
    pimsCode: "C84092a",
    nacsCode: "C84092a",
    name: "Bridgeway Practice",
    formattedName: "Bridgeway Practice (1 Bridgeway Centre, The Meadows)",
    postcode: "NG2 2JG",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "258",
    pimsCode: "C84096",
    nacsCode: "C84096",
    name: "Radford Medical Practice",
    formattedName: "Radford Medical Practice (Radford Health Centre, Ilkeston Road, Radford)",
    postcode: "NG7 3GW",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "259",
    pimsCode: "C84144a",
    nacsCode: "C84144a",
    name: "Meadows Health Centre",
    formattedName: "Meadows Health Centre (1 Bridgeway Centre, The Meadows)",
    postcode: "NG2 2JG",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "260",
    pimsCode: "C84613A",
    nacsCode: "C84613A",
    name: "Jubilee Park Medical Partnership",
    formattedName: "Jubilee Park Medical Partnership (61 Burton Road, Carlton)",
    postcode: "NG4 3DQ",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "261",
    pimsCode: "C84646b",
    nacsCode: "C84646b",
    name: "Ivy Medical Group",
    formattedName:
      "Ivy Medical Group (Apple Tree Medical Practice, 4 Wheatshelf Court, Burton Joyce)",
    postcode: "NG14 5EA",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "262",
    pimsCode: "C84646C",
    nacsCode: "C84646C",
    name: "Ivy Medical Group",
    formattedName: "Ivy Medical Group (Lowdham Medical Centre, Francklin Road, Lowdham)",
    postcode: "NG14 7BG",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  },
  {
    id: "263",
    pimsCode: "Y06356A",
    nacsCode: "Y06356A",
    name: "Assarts Farm Medical Centre",
    formattedName: "Assarts Farm Medical Centre (Upminster Drive, Nuthall)",
    postcode: "NG16 1PT",
    ccg: {
      id: "10880421",
      name: "NHS Nottingham And Nottinghamshire CCG",
      code: "52R"
    },
    isCustom: true
  }
]

type State = EligibilityCheckIAPTState

export class EligibilityCheckTrentPTSScript extends EligibilityCheckIAPTScript {
  readonly name: string = "EligibilityCheckTrentPTSScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.saySelectYourGP }
  }

  @step.logState
  saySelectYourGP(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, in order to find the right health service for you, I first need to locate your registered GP`,
        "Please select your GP from the list",
        "(You can also type to filter them by name)"
      ],
      prompt: {
        id: this.getPromptId("saySelectYourGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          ...gpServices.map(gp => ({ body: gp.formattedName, value: gp })),
          { body: "I don't know", value: null, backgroundColor: "#da4b4b" }
        ],
        searchable: true,
        textPrompt: {
          placeholder: "Type to search"
        }
      },
      nextStep: this.handleSelectYourGP
    }
  }

  @step.logState
  handleSelectYourGP(d: IStepData<State, IGPService | null>): IStepResult {
    this.setGP(d.state, d.response || undefined)
    if (!d.state.gp) return { nextStep: this.sayItsImportantToFindGP }

    const isDerby = d.state.gp?.ccg.id === "10398209"
    if (isDerby) {
      const iapt = getIAPTById(IAPTIDs.TRENT_DERBY_WOODLANDS)
      this.setIAPT(d.state, iapt)
    }

    const isNotts = d.state.gp?.ccg.id === "10880421"
    if (isNotts) {
      const iapt = getIAPTById(IAPTIDs.TRENT_NOTTS)
      this.setIAPT(d.state, iapt)
    }
    return { nextStep: this.checkUnderAgedForIAPT }
  }

  @step.logState
  sayItsImportantToFindGP(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "It's important that we identify your GP in order to find the right mental health service for you"
      ],
      nextStep: this.sayWithoutGPICannotReferYou
    }
  }

  @step.logState
  sayWeDontSupportYourGP(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: `Unfortunately, ${iaptName} doesn't support your GP`,
      nextStep: this.closeWithCallIntoService
    }
  }

  /** Generic Handlers */

  async onIneligibleGPForIAPTFound(state: State): Promise<IStepResult<State> | void> {
    this.setEligibility(state, false)
    this.setSignpostToManualSelfReferral(state, false)
    return { nextStep: this.sayWeDontSupportYourGP }
  }
}

export default class EligibilityCheckTrentPTSDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckTrentPTS
  readonly name: string = "EligibilityCheckTrentPTSDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckTrentPTSDialogue.id,
      new EligibilityCheckTrentPTSScript(),
      state,
      snapshot
    )
  }
}
