import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import AssessmentScript, { AssessmentScriptState } from "./AssessmentScript"
import { DialogueIDs } from "../../DialogueIDs"
import { DiscussionSteps } from "../../../models/Constants"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"

type State = AssessmentScriptState

export class AssessmentDemoConferenceScript extends AssessmentScript {
  readonly name: string = "AssessmentDemoConferenceScript"

  /** Generic Handlers */

  async onFinishAssessment(state: State): Promise<IStepResult> {
    const TreatmentOptionsDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.TreatmentOptions
    )
    const nextDialogue = TreatmentOptionsDialogue
      ? new TreatmentOptionsDialogue({ ...state })
      : undefined
    return { nextDialogue, nextStep: this.end }
  }
}

export default class AssessmentDemoConferenceDialogue extends AdHocDialogue<
  State,
  AssessmentDemoConferenceScript
> {
  static id = DialogueIDs.AssessmentDemoConference
  readonly name: string = "AssessmentDemoConferenceDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      AssessmentDemoConferenceDialogue.id,
      new AssessmentDemoConferenceScript(),
      state,
      snapshot
    )
  }
}
