import Loggable from "../../models/Loggable"
import Script from "./Script"
import uuidv4 from "./utils/uuidv4"
import requiredParam from "../../utils/requiredParam"
import invariant from "../../utils/invariant"
import { LoggableType } from "../../models/Logger/LoggableType"
import type IMessage from "./models/IMessage"
import type { IStep, IStepData, IStepResult, IStepRewindData } from "./models/IStep"

export type UserResponse = unknown | any

export interface IDialogueSnapshot<State = any> {
  uuid: string
  identifier: string
  state: State
  lastResponse?: UserResponse
  previousStepName?: string
  currentStepName?: string
  nextStepName?: string
}

export default abstract class Dialogue<State = any> extends Loggable {
  readonly identifier: string
  readonly uuid: string
  readonly type: string = "Generic"
  readonly script: Script<State>
  protected lastResponse?: UserResponse
  protected previousStep?: IStep<State>
  protected nextStep?: IStep<State>
  currentStep?: IStep<State>
  state: State

  constructor(
    id: string,
    script: Script<State>,
    state: State = {} as State,
    snapshot?: IDialogueSnapshot<State>
  ) {
    super()
    this.uuid = uuidv4()
    this.identifier = id
    this.script = script
    this.state = state

    if (snapshot) {
      this.uuid = snapshot.uuid || this.uuid
      this.lastResponse = snapshot.lastResponse
      this.previousStep = snapshot.previousStepName ? script[snapshot.previousStepName] : undefined
      this.currentStep = snapshot.currentStepName ? script[snapshot.currentStepName] : undefined
      this.nextStep = snapshot.nextStepName ? script[snapshot.nextStepName] : undefined
      this.state = snapshot.state
    }
  }

  async init(): Promise<void> {
    await this.onStart()
  }

  async onStart(): Promise<void> {
    this.logBreadcrumb("onStart")
    await this.runStep(this.script.start, { response: undefined, state: this.state })
  }

  onResume(props: { lastMessage?: IMessage; lastDialogue?: Dialogue }): void {
    try {
      const { prompt, _meta, id, ...lastMessage } = (props?.lastMessage || {}) as any
      const { lastDialogue } = props
      this.logBreadcrumb("onResume", { lastMessage, lastDialogue, currentDialogue: this })
    } catch {
      this.logBreadcrumb("onResume")
    }
    const previousDialogue = props.lastDialogue
    const previousStepName = this.previousStep?.stepName
    const currentStep = this.currentStep
    const response = this.lastResponse
    const state = this.state
    if (!currentStep) {
      // If the dialogue doesn't have a currentStep yet, it
      // means that it was never started, so we just start it
      void this.runStep(this.script.start, { response, state, previousDialogue })
      return
    }

    if (props?.lastMessage?.author === "bot") {
      if (props?.lastMessage?.prompt && props?.lastMessage?._meta?.dialogueUUID === this.uuid) {
        // If the last message has a prompt and belongs to this
        // dialogue then we don't need to take any action. The
        // dialogue is hydrated, so it has access to the next step
        // and thus when the user answers that prompt, the dialogue
        // flow will continue as normal.
        return
      }
    }

    // If the last message is not a prompt for the user to
    // answer and resume the dialogue, then we just run the
    // dialogue's currentStep with the last response received.
    void this.runStep(currentStep, { response, state, previousStepName, previousDialogue })
  }

  onRemove?(nextDialogue?: Dialogue): void

  onInterrupt(subjectChanged: boolean): void {
    this.logBreadcrumb("onInterrupt", { subjectChanged })
  }

  rewind(rewindData: IStepRewindData<State>): void {
    this.logBreadcrumb("rewind", { rewindData })
    if (rewindData.currentStepName) {
      this.currentStep = this.script[rewindData.currentStepName]
    }
    if (rewindData.nextStepName) {
      this.nextStep = this.script[rewindData.nextStepName]
    }
  }

  onReceiveResponse(response?: UserResponse): void {
    if (this.nextStep) {
      void this.runStep(this.nextStep, {
        response,
        state: this.state,
        previousStepName: this.currentStep?.stepName
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onStepStart(): void | Promise<void> {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onStepEnd(_result: IStepResult<State>, _isFinished: boolean): void | Promise<void> {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFinish(_state: State): void | Promise<void> {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onError(_error: Error): void {}

  protected async runStep(step: IStep<State>, data: IStepData<State>): Promise<void> {
    try {
      invariant(data, "runStep called without data")
      const { response, state = requiredParam("state"), previousStepName, previousDialogue } = data
      await this.onStepStart()
      this.previousStep = data.previousStepName ? this.script[data.previousStepName] : undefined
      this.currentStep = step
      this.lastResponse = response

      const stepResult: IStepResult = await step.call(this.script, {
        response,
        state,
        previousStepName,
        previousDialogue
      })
      const { prompt, nextStep, nextDialogue } = stepResult
      this.nextStep = nextStep

      const isFinished = this.nextStep == null
      const isUndoAble = prompt && prompt.isUndoAble !== false
      if (isUndoAble && nextStep) {
        stepResult.rewindData = {
          currentStepName: step.stepName,
          nextStepName: nextStep.stepName
        }
      }

      await this.onStepEnd(stepResult, isFinished)

      if (!prompt) {
        // If we don't have a prompt next, then we
        // know that the next step does not care
        // about user input. So we can just set the
        // current step to that so that the next
        // resume will run the next step directly.
        this.previousStep = step
        this.currentStep = this.nextStep
        this.lastResponse = undefined
      }

      // Go to the next step immediately if there is
      // a next step and no prompt and no next dialogue
      if (this.nextStep && !prompt && !nextDialogue) {
        await this.runStep(this.nextStep, {
          response: undefined,
          state: this.state,
          previousStepName: step.stepName
        })
      }
    } catch (e) {
      this.logException(e, "runStep")
      this.onError(e)
    }
  }

  // noinspection JSUnusedLocalSymbols
  private toJSON() {
    const { script, state, ...dialogue } = this
    return dialogue
  }

  /** Getters / Setters */

  get snapshot(): IDialogueSnapshot<State> | undefined {
    return {
      uuid: this.uuid,
      identifier: this.identifier,
      state: this.state,
      lastResponse: this.lastResponse,
      previousStepName: this.previousStep?.stepName,
      currentStepName: this.currentStep?.stepName,
      nextStepName: this.nextStep?.stepName
    }
  }
}

Dialogue.setLogType(LoggableType.Dialogue)
