import { backendEnv } from "../../config/config"
import invariant from "../../utils/invariant"
import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import delay from "../../utils/delay"
import { IDataPoints } from "@limbic/types"

interface Response {
  id: string
  signupCode: string
}

const TOTAL_RETRIES = 2

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/v1/data/send",
  development: "https://access-v2-staging.limbic.ai/v1/data/send",
  production: "https://access-v2.limbic.ai/v1/data/send"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const DATAPOINTS_URL = endpoints[backendEnv] || endpoints.production
const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export default async function postDataPoints(
  id: { patientID?: string; signupCode?: string }, // Always one OR the other
  age: number,
  dataPoints: IDataPoints[],
  retry = 0
): Promise<Response | undefined> {
  invariant(id, "Cannot post data without an id")
  let body = ""
  try {
    body = JSON.stringify({ ...id, age: Math.round(age), data: dataPoints })
    const result = await fetch(DATAPOINTS_URL, { body, method, headers })
    const json = await result.json()

    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "postDataPoints failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`postDataPoints body for retry ${retry}`, body)
      Logger.getInstance().message("postDataPoints retry")
      await delay(1)
      return await postDataPoints(id, age, dataPoints, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
