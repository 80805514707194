import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState

export class CrisisVHGScript extends CrisisScript {
  readonly name: string = "CrisisVHGScript"

  /** Generic Handlers */

  async onHandleSayInstructions(state: State): Promise<IStepResult> {
    return { nextStep: this.goToGoodbye }
  }

  getCustomReferralType(state: State): string | undefined {
    if (this.clinicalStore.assessmentFinished) return "Limbic Assessment"
    return "Self Referral"
  }
}

export default class CrisisVHGDialogue extends AdHocDialogue<State, CrisisVHGScript> {
  static id = DialogueIDs.CrisisVHG
  readonly name: string = "CrisisVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisVHGDialogue.id, new CrisisVHGScript(), state, snapshot)
  }
}
