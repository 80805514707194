import React, { useMemo } from "react"
import { observer } from "mobx-react"
import { Typography } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import classNames from "classnames"
import "./ChatHeader.scss"
import { useApplicationStore, useConfigStore } from "../../contexts/RootStoreContext"
import SettingsMenu from "../SettingsMenu/SettingsMenu"
import Tracker from "../../../utils/Tracker"
import InactivityIndicator from "../InactivityIndicator/InactivityIndicator"
import SOSButton from "../SOSButton/SOSButton"

export interface Props {
  onClose: () => void
}

function ChatHeader(props: Props): JSX.Element {
  const config = useConfigStore()
  const enableSOS = config.enableSOS
  const app = useApplicationStore()
  const progressBar = useMemo(() => {
    if (app.totalProgressBars <= 0) {
      return null
    }
    const b = Array(app.totalProgressBars).fill(null)
    const bars = b.map((_, idx) => <div key={idx} className="lb-header-progress-bar-item" />)
    const activeBars = b.map((_, idx) => {
      const active = idx + 1 < app.currentProgressBar
      const current = idx + 1 === app.currentProgressBar
      const css = classNames("lb-header-progress-bar-item", { active })
      if (current) {
        const c = app.currentProgress
        return (
          <React.Fragment key={idx}>
            <div className="lb-header-progress-bar-item active current" style={{ flex: c }} />
            <div className="lb-header-progress-bar-item spacer" style={{ flex: 1 - c }} />
          </React.Fragment>
        )
      }
      return <div key={idx} className={css} />
    })
    return (
      <div className="lb-header-progress-bar">
        <div className="lb-header-progress-bar-row">{bars}</div>
        <div className="lb-header-progress-bar-row">{activeBars}</div>
      </div>
    )
  }, [app.totalProgressBars, app.currentProgress, app.currentProgressBar])

  const handleCloseBot = () => {
    Tracker.getInstance().track("Bot button closed")
    props.onClose?.()
  }

  return (
    <>
      <header className="lb-header-container">
        <div className="lb-header-main">
          <img src={config.logo} className="lb-header-img" alt="" />
          <div className="lb-header-title">
            <Typography style={{ fontSize: "16px" }}>{config.title}</Typography>
          </div>
          {enableSOS && <SOSButton />}
          <div aria-label="Settings" className="lb-header-button lb-header-button-small">
            <SettingsMenu faqLink={config.faqLink} />
          </div>
          {!config.fullscreen && (
            <button
              aria-label="Close Limbic Self Referral"
              aria-describedby="Close Limbic Self Referral"
              name="Close Limbic Self Referral"
              className="lb-header-button"
              onClick={handleCloseBot}
            >
              <Close />
            </button>
          )}
        </div>
        {progressBar}
      </header>
      <InactivityIndicator />
    </>
  )
}

export default observer(ChatHeader)
