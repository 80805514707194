import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import TreatmentOptionsScript from "./TreatmentOptionsScript"
import type { TreatmentOptionsIAPTScriptState } from "./TreatmentOptionsIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends TreatmentOptionsIAPTScriptState {
  assessmentPreference?: "telephone" | "digital"
}

export type TreatmentOptionsVitalityScriptState = State

export class TreatmentOptionsVitalityScript extends TreatmentOptionsScript {
  readonly name: string = "TreatmentOptionsVitalityScript"

  @step.logState
  @step.setState<State>({ currentTreatmentOptionIndex: 0, totalTreatmentsOffered: 0 })
  start(d: IStepData<State>): IStepResult<State> {
    if (d.state.assessmentPreference === "telephone") {
      return { nextStep: this.end }
    }
    return { nextStep: this.checkClinicalPath }
  }
}
export default class TreatmentOptionsVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsVitality
  readonly name: string = "TreatmentOptionsVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      TreatmentOptionsVitalityDialogue.id,
      new TreatmentOptionsVitalityScript(),
      state,
      snapshot
    )
  }
}
