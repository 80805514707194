import { ClinicalFlags, ClinicalGroups, ProblemCategories } from "../../../../models/Constants"
import type { IClinicalPath } from "../../../../models/IClinicalPath"

const primaryCombos: ProblemCategories[][] = [
  [ProblemCategories.M_GADWorry, ProblemCategories.OCD],
  [ProblemCategories.S_GADWorry, ProblemCategories.OCD],
  [ProblemCategories.M_GADWorry, ProblemCategories.SpecificPhobia],
  [ProblemCategories.S_GADWorry, ProblemCategories.SpecificPhobia],
  [ProblemCategories.M_GADWorry, ProblemCategories.SpecificPhobiaNBI],
  [ProblemCategories.S_GADWorry, ProblemCategories.SpecificPhobiaNBI],
  [ProblemCategories.M_GADWorry, ProblemCategories.HealthAnxiety],
  [ProblemCategories.S_GADWorry, ProblemCategories.HealthAnxiety],
  [ProblemCategories.M_GADWorry, ProblemCategories.Panic],
  [ProblemCategories.S_GADWorry, ProblemCategories.Panic]
]

const flagCombos: ClinicalFlags[][] = [
  [],
  [ClinicalFlags.MS_FunctionalImpairment],
  [ClinicalFlags.S_FunctionalImpairment]
]

const paths: Omit<IClinicalPath, "id">[] = []

primaryCombos.forEach(primaryProblems =>
  flagCombos.forEach(flags =>
    paths.push({
      matcher: { primaryProblems, flags },
      enableSecondary: false,
      clinicalGroup: ClinicalGroups.ComplexComorbid,
      suggestTreatments: false,
      defaultReferralType: "Short Assessment",
      treatments: [],
      declinedTreatmentsReferralType:
        "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
    })
  )
)

const lincolnshireCustomClinicalPaths: IClinicalPath[] = paths.map((path, index) => ({
  ...path,
  id: `lincolnshire-${index + 1}`
}))

export { lincolnshireCustomClinicalPaths }
