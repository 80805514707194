import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScriptState } from "./GetNameScript"
import { GetNameIAPTScript } from "./GetNameIAPTDialogue"
import { VHGTitles } from "../../../models/Constants"

interface State extends GetNameScriptState {
  preferredTitle?: VHGTitles
}

export type GetNameVHGScriptState = State

export class GetNameVHGScript extends GetNameIAPTScript {
  readonly name: string = "GetNameVHGScript"

  /** Script Steps */

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Nice to meet you ${name}`,
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: "Nice to meet you too" }]
      },
      nextStep: this.askHowToBeAddressed
    }
  }

  @step.logState
  askHowToBeAddressed(d: IStepData<State>): IStepResult {
    const titles = this.getTitles()
    if (!titles?.length) {
      this.logBreadcrumb("TITLES NOT FOUND", d.state, { titles })
      this.logMessage("TITLES NOT FOUND")
      return { nextStep: this.end }
    }

    return {
      body: "How would you liked to be addressed?",
      prompt: {
        id: this.getPromptId("askHowToBeAddressed"),
        trackResponse: true,
        type: "inlinePicker",
        choices: titles.map(g => ({ body: g, value: g }))
      },
      nextStep: this.handleHowToBeAddressed
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, VHGTitles>, script: GetNameVHGScript) => {
    d.state.preferredTitle = d.response
  })
  handleHowToBeAddressed(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  getTitles(): VHGTitles[] {
    return ["Mr", "Mrs", "Miss", "Ms", "Mx", "Dr", "Rev", "Prof"]
  }
}

export default class GetNameVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameVHG
  readonly name: string = "GetNameVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameVHGDialogue.id, new GetNameVHGScript(), state, snapshot)
  }
}
