import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IntroductionScript, IntroductionScriptState } from "./IntroductionScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = IntroductionScriptState
export type IntroductionDemoConferenceScriptState = State

export class IntroductionDemoConferenceScript extends IntroductionScript {
  readonly name: string = "IntroductionDemoConferenceScript"

  /** Script Steps */

  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Hi I’m Limbic!",
        "I help patients access psychological support 💛",
        "I’ve also helped release over 10,000 hours of clinician time"
      ],
      prompt: {
        id: this.getPromptId("greet"),
        type: "inlinePicker",
        choices: [{ body: "I'd love a demo" }]
      },
      nextStep: this.handleGreet
    }
  }

  @step.logState
  handleGreet(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I’m really happy to show you how it works",
        "Picture this: you’re a patient hoping to get a referral..."
      ],
      prompt: {
        id: this.getPromptId("handleGreet"),
        type: "inlinePicker",
        choices: [{ body: "Start 👉" }]
      },
      nextStep: this.end
    }
  }

  /** Abstract overrides */

  askCanIHelp(_d: IStepData<State>): IStepResult {
    return {}
  }
}

export default class IntroductionDemoConferenceDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionDemoConference
  readonly name: string = "IntroductionDemoConferenceDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      IntroductionDemoConferenceDialogue.id,
      new IntroductionDemoConferenceScript(),
      state,
      snapshot
    )
  }
}
