import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PermissionsScript } from "./PermissionsScript"
import type { PermissionsScriptState } from "./PermissionsScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = PermissionsScriptState
export type PermissionsIAPTScriptState = State

export class PermissionsIAPTScript extends PermissionsScript {
  readonly name: string = "PermissionsIAPTScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I know mental health can be a sensitive subject",
        "I just want to reassure you that everything we discuss is confidential and all your information is stored safely"
      ],
      nextStep: this.showTOSLinks
    }
  }

  @step.logState
  handleIUnderstand(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thanks ${name}! I think we're ready to get started`,
      nextStep: this.end
    }
  }
}

export default class PermissionsIAPTDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsIAPT
  readonly name: string = "PermissionsIAPTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsIAPTDialogue.id, new PermissionsIAPTScript(), state, snapshot)
  }
}
