import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"

type State = RiskPathwayScriptState

export class RiskPathwayDemoScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayDemoScript"
}

export default class RiskPathwayDemoDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayDemo
  readonly name: string = "RiskPathwayDemoDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayDemoDialogue.id, new RiskPathwayDemoScript(), state, snapshot)
  }
}
