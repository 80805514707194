import type Script from "../../Script"
import type { ScriptState } from "../../Script"
import type {
  IStep,
  IStepData,
  IStepResult,
  StepDecorator,
  StepDescriptor
} from "../../models/IStep"

interface Props {
  state?: boolean
  response?: boolean
}

const defaults = {
  state: false,
  response: false
}

function decorate<State extends ScriptState, S extends Script<State> = any>(
  step: IStep<State>,
  stepName: keyof S,
  props?: Props
): IStep<State> {
  const includeState = props?.state ?? defaults.state
  const includeResponse = props?.response ?? defaults.response
  const decorated = async function (this: S, d: IStepData<State>): Promise<IStepResult> {
    const stateData = includeState ? d?.state : undefined
    const extra = includeResponse ? { response: d?.response } : undefined
    this.logBreadcrumb(`${stepName}`, stateData, extra)
    this.trackStep?.(`${stepName}`)
    const result = await step.call(this, d)
    if (result.prompt) {
      result.prompt = this.getPrompt(d!.state, result.prompt)
    }
    return result
  }
  Object.defineProperty(decorated, "name", { value: stepName, writable: false })
  Object.defineProperty(decorated, "stepName", { value: stepName, writable: false })
  return decorated
}

// Dont get intimidated by this weird situation here.
// This is just function overloading where you explicitly
// describe the various signatures this function can be
// called with
export function main<State extends ScriptState = any, S extends Script<State> = any>(
  target: S,
  key: keyof S,
  desc: StepDescriptor
): StepDescriptor
export function main<State extends ScriptState = any, S extends Script<State> = any>(
  props: Props
): StepDecorator<State, S>
export function main<State extends ScriptState = any, S extends Script<State> = any>():
  | StepDecorator<State, S>
  | StepDescriptor {
  // eslint-disable-next-line prefer-rest-params
  const props: Props = arguments.length === 1 ? arguments[0] : defaults
  const state = props.state ?? defaults.state
  const response = props.response ?? defaults.response
  function decorator(target: S, key: keyof S, desc: StepDescriptor): StepDescriptor {
    const org = desc.value!
    desc.value = decorate(org, key, { state, response })
    return desc
  }

  if (arguments.length === 1) {
    return decorator
  }
  // eslint-disable-next-line prefer-rest-params
  return decorator(arguments[0], arguments[1], arguments[2])
}

main.decorate = decorate
main.logState = main({ state: true })
main.logStateAndResponse = main({ state: true, response: true })
