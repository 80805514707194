import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

// NOTE: List is here:
// https://docs.google.com/spreadsheets/d/1xYGkV6f9azMnx_087sI0EKpJO66AV5Kc/edit#gid=120188721

const iaptGPsMapLincolnshireBoston: IAPTGPMap = {
  C83614: IAPTIDs.STEPS2CHANGE_BOSTON,
  C83049: IAPTIDs.STEPS2CHANGE_BOSTON,
  C83055: IAPTIDs.STEPS2CHANGE_BOSTON,
  C83083: IAPTIDs.STEPS2CHANGE_BOSTON,
  C83059: IAPTIDs.STEPS2CHANGE_BOSTON,
  C83057: IAPTIDs.STEPS2CHANGE_BOSTON,
  C83004: IAPTIDs.STEPS2CHANGE_BOSTON,
  C83010: IAPTIDs.STEPS2CHANGE_BOSTON,
  C83015: IAPTIDs.STEPS2CHANGE_BOSTON,
  C83060: IAPTIDs.STEPS2CHANGE_BOSTON,
  C83047: IAPTIDs.STEPS2CHANGE_BOSTON,
  A91031: IAPTIDs.STEPS2CHANGE_BOSTON
}

const iaptGPsMapLincolnshireGainsborough: IAPTGPMap = {
  C83018: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
  C83037: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
  C83052: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
  C83033: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
  C83641: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
  C83074: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
  C83633: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
  C83044: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
  C83038: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH,
  B81099: IAPTIDs.STEPS2CHANGE_GAINSBOROUGH
}

const iaptGPsMapLincolnshireGrantham: IAPTGPMap = {
  C83008: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C83075: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C83080: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C83048: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C83024: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C83040: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C83053: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C83067: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C83653: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C83628: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C83649: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C82123: IAPTIDs.STEPS2CHANGE_GRANTHAM,
  C82076: IAPTIDs.STEPS2CHANGE_GRANTHAM
}

const iaptGPsMapLincolnshireLincoln: IAPTGPMap = {
  C83635: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83051: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83001: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83002: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83009: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83014: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83637: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83025: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83029: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83031: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83041: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83046: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83058: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83062: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83071: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83072: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83073: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83078: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83016: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83079: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83082: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83611: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83626: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83656: IAPTIDs.STEPS2CHANGE_LINCOLN,
  C83652: IAPTIDs.STEPS2CHANGE_LINCOLN
}

const iaptGPsMapLincolnshireLouth: IAPTGPMap = {
  C83652: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83032: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83043: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83064: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83056: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83042: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83006: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83061: IAPTIDs.STEPS2CHANGE_LOUTH, // Lancaster & Holten-Le-Clay Surgery
  C83027: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83085: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83605: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83613: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83634: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83635: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83643: IAPTIDs.STEPS2CHANGE_LOUTH,
  C83650: IAPTIDs.STEPS2CHANGE_LOUTH
}

const iaptGPsMapLincolnshireSkegness: IAPTGPMap = {
  C83005: IAPTIDs.STEPS2CHANGE_SKEGNESS,
  C83019: IAPTIDs.STEPS2CHANGE_SKEGNESS,
  C83045: IAPTIDs.STEPS2CHANGE_SKEGNESS
}

const iaptGPsMapLincolnshireSleaford: IAPTGPMap = {
  C83013: IAPTIDs.STEPS2CHANGE_SLEAFORD,
  C83020: IAPTIDs.STEPS2CHANGE_SLEAFORD,
  C83023: IAPTIDs.STEPS2CHANGE_SLEAFORD,
  C83030: IAPTIDs.STEPS2CHANGE_SLEAFORD,
  C83011: IAPTIDs.STEPS2CHANGE_SLEAFORD,
  Y01652: IAPTIDs.STEPS2CHANGE_SLEAFORD,
  A91021: IAPTIDs.STEPS2CHANGE_SLEAFORD
}

const iaptGPsMapLincolnshireSpalding: IAPTGPMap = {
  C83028: IAPTIDs.STEPS2CHANGE_SPALDING,
  C83003: IAPTIDs.STEPS2CHANGE_SPALDING,
  C83036: IAPTIDs.STEPS2CHANGE_SPALDING,
  C83022: IAPTIDs.STEPS2CHANGE_SPALDING,
  C83039: IAPTIDs.STEPS2CHANGE_SPALDING,
  C83065: IAPTIDs.STEPS2CHANGE_SPALDING,
  C83617: IAPTIDs.STEPS2CHANGE_SPALDING,
  C83631: IAPTIDs.STEPS2CHANGE_SPALDING,
  C83063: IAPTIDs.STEPS2CHANGE_SPALDING,
  C83063006: IAPTIDs.STEPS2CHANGE_SPALDING
}

const iaptGPsMapLincolnshireStamford: IAPTGPMap = {
  C83054: IAPTIDs.STEPS2CHANGE_STAMFORD,
  C83035: IAPTIDs.STEPS2CHANGE_STAMFORD,
  C83026: IAPTIDs.STEPS2CHANGE_STAMFORD,
  C83017: IAPTIDs.STEPS2CHANGE_STAMFORD,
  C83007: IAPTIDs.STEPS2CHANGE_STAMFORD,
  C83026002: IAPTIDs.STEPS2CHANGE_STAMFORD
}

export const iaptGPMapLincolnshire: IAPTGPMap = {
  ...iaptGPsMapLincolnshireBoston,
  ...iaptGPsMapLincolnshireGainsborough,
  ...iaptGPsMapLincolnshireGrantham,
  ...iaptGPsMapLincolnshireLincoln,
  ...iaptGPsMapLincolnshireLouth,
  ...iaptGPsMapLincolnshireSkegness,
  ...iaptGPsMapLincolnshireSleaford,
  ...iaptGPsMapLincolnshireSpalding,
  ...iaptGPsMapLincolnshireStamford
}
