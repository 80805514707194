import {
  ARMED_FORCES_PCMIS,
  DISABILITY_PCMIS,
  ETHNICITY_PCMIS,
  GenderBirthAssigned,
  GENDER_PCMIS,
  LTC_PCMIS,
  PERINATAL_PCMIS,
  RELIGION_PCMIS,
  SEXUALITY_PCMIS
} from "@limbic/types"

export const ltcs: Record<string, LTC_PCMIS | undefined> = {
  Asthma: "ASTHMA",
  Cancer: "CANCER",
  "Chronic Kidney Disease": "KIDNEY",
  "Chronic Muscular Skeletal": "CMS",
  COPD: "COPD",
  "Chronic Pain": "CHRONIC_PAIN",
  "Coronary Heart Disease (CHD)": "CHD",
  Dementia: "DEMENTIA",
  "Diabetes - Type 1": "IDDM",
  "Diabetes - Type 2": "DIABETES",
  "Digestive Tract Conditions": "DIGESTIVE",
  Epilepsy: "EPILEPSY",
  Fibromyalgia: "CHRONIC_PAIN",
  "Heart Failure": "HEART_FAILURE",
  Hypertension: "HYPERTENSION",
  "Medically Unexplained Conditions": "UNKNOWN",
  "Multiple Sclerosis": "MS",
  "Parkinson's": "PARKINSON",
  "Skin Condition including Eczema": "SKIN",
  "Stroke and Transient Ischaemic Attack": "STIA"
}

export const sexualities: Record<string, SEXUALITY_PCMIS> = {
  Heterosexual: "HETEROSEXUAL",
  "Female homosexual": "HOMOSEXUAL",
  "Male homosexual": "HOMOSEXUAL",
  Bisexual: "BISEXUAL",
  "Sexually attracted to neither male nor female sex": "UNKNOWN",
  "Not stated (Person asked but declined to provide a response)": "NOT_ANSWERED",
  Unknown: "UNKNOWN",
  "Person asked and does not know or is not sure": "NOT_ANSWERED"
}

export const genders: Record<string, GENDER_PCMIS> = {
  "Male (including trans man)": "MALE",
  "Female (including trans woman)": "FEMALE",
  "Non-binary": "NON_BINARY",
  Other: "NOT_LISTED",
  "I don't want to say": "NOT_ANSWERED"
}

export const sameGenderAsBirth: Record<string, GenderBirthAssigned> = {
  Yes: "YES",
  No: "NO",
  "I don't know": "UNKNOWN",
  "I don't want to say": "NOT_ANSWERED"
}

export const ethnicities: Record<string, ETHNICITY_PCMIS> = {
  "White - British": "WHITE_BRITISH",
  "White - Irish": "WHITE_IRISH",
  "White - Any other White background": "WHITE_OTHER",
  "Mixed - White and Black Caribbean": "MIXED_WHITE_BLACK",
  "Mixed - White and Black African": "MIXED_WHITE_BLACK",
  "Mixed - White and Asian": "MIXED_WHITE_ASIAN",
  "Mixed - Any other mixed background": "MIXED_OTHER",
  "Asian or Asian British - Indian": "ASIAN_INDIAN",
  "Asian or Asian British - Pakistani": "ASIAN_PAKISTANI",
  "Asian or Asian British - Bangladeshi": "ASIAN_BANGLADESHI",
  "Asian or Asian British - Any other Asian background": "ASIAN_OTHER",
  "Black or Black British - Caribbean": "BLACK_CARIBBEAN",
  "Black or Black British - African": "BLACK_AFRICAN",
  "Black or Black British - Any other Black background": "BLACK_OTHER",
  "Other Ethnic Groups - Chinese": "CHINESE",
  "Other Ethnic Groups - Any other ethnic group": "OTHER",
  "Not Stated - Not Stated": "NOT_ANSWERED",
  "Not known - Not known": "UNKNOWN"
}

export const exArmedForces: Record<string, ARMED_FORCES_PCMIS> = {
  "Yes - ex services": "YES",
  "Yes - currently serving": "YES",
  "Dependant on ex-serving member": "DEPENDENT",
  No: "NO",
  "No - dependant of ex-serving member of British Armed Forces": "NO",
  "Not stated (Person asked but declined to provide a response)": "NOT_ANSWERED"
}

export const disabilities: Record<string, DISABILITY_PCMIS> = {
  "Behaviour and Emotional": "BEHAVIOUR",
  Hearing: "HEARING",
  "Manual Dexterity": "MANUAL",
  "Memory or ability to concentrate, learn or understand (Learning Disability)": "LEARNING",
  "Mobility and Gross Motor": "MOTOR",
  "Perception of Physical Danger": "PERCEPTION",
  "Personal, Self Care and Continence": "SELF_CARE",
  "Progressive Conditions and Physical Health (such as HIV, cancer, multiple sclerosis, fits, etc)":
    "PROGRESSIVE",
  Sight: "SIGHT",
  Speech: "SPEECH",
  Other: "NOT_LISTED",
  "Do not wish to say": "NOT_ANSWERED"
}

export const religions: Record<string, RELIGION_PCMIS> = {
  "(None)": "NONE",
  "Not stated": "NOT_ANSWERED",
  Bahai: "BAHAI",
  Buddhist: "BUDDHIST",
  Christian: "CHRISTIAN",
  Hindu: "HINDU",
  Jain: "JAIN",
  Jewish: "JEWISH",
  Muslim: "MUSLIM",
  Pagan: "PAGAN",
  Sikh: "SIKH",
  Zoroastrian: "ZOROASTRIAN",
  "Patient Religion Unknown": "UNKNOWN"
}

export const perinatalStatuses: Record<string, PERINATAL_PCMIS> = {
  "None apply": "NONE",
  Pregnant: "PREGNANT",
  "Child under 1": "CHILD_UNDER_1",
  "Pregnancy in the last year": "PREGNANCY_WITHIN_1_YEAR",
  "Pregnant and Child under 1": "PREGNANT_AND_CHILD_UNDER_1",
  "Pregnancy in the last year and Child under 1": "PREGNANCY_WITHIN_1_YEAR_AND_CHILD_UNDER_1",
  "New Father - Child born within last 12 months": "NEW_FATHER"
}

const form = {
  form_url:
    "https://www.iaptportal.co.uk/ServiceUser/SelfReferralForm.aspx?sd=5c72796f-c6ab-4521-91b1-8676655c1e87",
  filled_by: "API",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  sameGenderAsBirth: Object.keys(sameGenderAsBirth),
  ethnicities: Object.keys(ethnicities),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs),
  religions: Object.keys(religions)
}

export default form
