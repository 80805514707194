import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

type State = PermissionsScriptState
export type PermissionsSABPScriptState = State

export class PermissionsSABPScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsSABPScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I know mental health can be a sensitive subject",
        "I just want to reassure you that everything you discuss is stored safely and confidentially however there may be limits to this, please read the terms and conditions before you proceed."
      ],
      nextStep: this.showTOSLinks
    }
  }

  @step.logState
  handleIUnderstand(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thanks ${name}! I think we're ready to get started`,
      nextStep: this.end
    }
  }
}

export default class PermissionsSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsSABP
  readonly name: string = "PermissionsSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsSABPDialogue.id, new PermissionsSABPScript(), state, snapshot)
  }
}
