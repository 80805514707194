import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IntroductionScript, IntroductionScriptState } from "./IntroductionScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = IntroductionScriptState

export class IntroductionGPScript extends IntroductionScript {
  readonly name: string = "IntroductionGPScript"

  /** Script Steps */

  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const serviceName = this.rootStore.configStore.serviceName
    const organisationName = this.rootStore.configStore.organisationName
    let explainer = `I'm a friendly robot assistant to help you access psychological support via ${serviceName}`
    if (serviceName !== organisationName) {
      explainer = `${explainer} to ${organisationName} who are the local provider for mental health services`
    }
    return {
      body: ["Hi there!", "I’m Limbic", explainer],
      nextStep: directReferral ? this.end : this.askCanIHelp
    }
  }

  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    return {
      body: "Can I help?",
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes, I'm looking for mental health support", value: true },
          { body: "No, I'm just browsing", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }
}

export default class IntroductionGPDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionGP
  readonly name: string = "IntroductionGPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionGPDialogue.id, new IntroductionGPScript(), state, snapshot)
  }
}
