import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import delay from "../../utils/delay"
import isOnline from "is-online"
import invariant from "../../utils/invariant"
import { backendEnv } from "../../config/config"
import { EligibilityReason, VitalityEligibilityStatus } from "../../models/IVitalityEligibility"

const TOTAL_RETRIES = 3

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/v1/vitality/eligibility/check",
  development: "https://access-v2-staging.limbic.ai/v1/vitality/eligibility/check",
  production: "https://access-v2.limbic.ai/v1/vitality/eligibility/check"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const BASE_URL = endpoints[backendEnv] || endpoints.production
const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export async function checkEligibilityVitality(
  entityNumber: number,
  retry = 0
): Promise<
  [{ eligible: boolean; reason: EligibilityReason } | undefined, VitalityEligibilityStatus]
> {
  let body = ""
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) {
      return [undefined, VitalityEligibilityStatus.NoInternetConnection]
    }
    body = JSON.stringify({
      instanceID: "VITALITY_MAIN",
      memberNumber: entityNumber
    })
    const result = await fetch(`${BASE_URL}`, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return [data, VitalityEligibilityStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "checkEligibilityVitality fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`checkEligibilityVitality body for retry ${retry}`, body)
      Logger.getInstance().message("checkEligibilityVitality retry")
      await delay(1)
      return await checkEligibilityVitality(entityNumber, retry + 1)
    }
    return [undefined, VitalityEligibilityStatus.RequestFailed]
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
