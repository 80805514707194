import {
  ARMED_FORCES_MAYDEN_NOTTS,
  DISABILITY_MAYDEN_NOTTS,
  ETHNICITY_MAYDEN_NOTTS,
  GENDER_MAYDEN_NOTTS,
  LANGUAGE_MAYDEN_NOTTS,
  LTC_MAYDEN_NOTTS,
  PERINATAL_MAYDEN_NOTTS,
  RELIGION_MAYDEN_NOTTS,
  SEXUALITY_MAYDEN_NOTTS,
  GenderBirthAssigned
} from "@limbic/types"

export const perinatalStatuses: Record<string, PERINATAL_MAYDEN_NOTTS> = {
  "None apply": "NONE",
  "Family with child under 1": "CHILD_UNDER_1",
  "Family with children under 1": "CHILDREN_UNDER_1",
  "Family with child under 2": "CHILD_UNDER_2",
  "Family with children under 2": "CHILDREN_UNDER_2",
  "Pregnant partner": "PREGNANT_PARTNER",
  Pregnant: "PREGNANT",
  "Pregnancy within the last 2 years": "PREGNANCY_WITHIN_2_YEARS"
}

export const sexualities: Record<string, SEXUALITY_MAYDEN_NOTTS> = {
  Heterosexual: "HETEROSEXUAL",
  "Lesbian or Gay": "HOMOSEXUAL",
  Bisexual: "BISEXUAL",
  Other: "NOT_LISTED",
  "Not known": "UNKNOWN",
  "Not stated": "NOT_ANSWERED"
}

export const genders: Record<string, GENDER_MAYDEN_NOTTS> = {
  "Male (including trans man)": "MALE",
  "Female (including trans woman)": "FEMALE",
  "Non-binary": "NON_BINARY",
  Other: "UNKNOWN",
  "I don't want to say": "NOT_ANSWERED"
}

export const ethnicities: Record<string, ETHNICITY_MAYDEN_NOTTS> = {
  "White - British": "WHITE_BRITISH",
  "White - Irish": "WHITE_IRISH",
  "White - Any other White background": "WHITE_OTHER",
  "Mixed - White and Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "Mixed - White and Black African": "BLACK_BRITISH_AFRICAN",
  "Mixed - White and Asian": "MIXED_WHITE_ASIAN",
  "Mixed - Any other mixed background": "MIXED_OTHER",
  "Asian or Asian British - Indian": "ASIAN_BRITISH_INDIAN",
  "Asian or Asian British - Pakistani": "ASIAN_BRITISH_PAKISTANI",
  "Asian or Asian British - Bangladeshi": "ASIAN_BRITISH_BANGLADESHI",
  "Asian or Asian British - Any other Asian background": "ASIAN_BRITISH_OTHER",
  "Black or Black British - Caribbean": "BLACK_BRITISH_CARIBBEAN",
  "Black or Black British - African": "BLACK_BRITISH_AFRICAN",
  "Black or Black British - Any other Black background": "BLACK_BRITISH_OTHER",
  "Other Ethnic Groups - Chinese": "CHINESE",
  "Other Ethnic Groups - Any other ethnic group": "OTHER",
  "Not stated": "NOT_ANSWERED",
  "Not known": "UNKNOWN"
}

export const religions: Record<string, RELIGION_MAYDEN_NOTTS> = {
  None: "NONE",
  "Decline to disclose": "NOT_ANSWERED",
  "Atheist/Agnostic": "AGNOSTIC",
  Christian: "CHRISTIAN",
  Buddhist: "BUDDHIST",
  Hindu: "HINDU",
  Jain: "JAIN",
  Jewish: "JEWISH",
  Muslim: "MUSLIM",
  "Other protestant": "PROTESTANT",
  "Other Christian": "CHRISTIAN",
  "Orthodox Christian": "CHRISTIAN_ORTHODOX",
  "Orthodox Jewish": "JEWISH_ORTHODOX",
  Rastafarian: "RASTAFARIAN",
  "Roman Catholic": "ROMAN_CATHOLIC",
  "Shi'ite Muslim": "SHIITE_MUSLIM",
  Sikh: "SIKH",
  "Sunni Muslim": "SUNNI_MUSLIM",
  Zoroastrian: "ZOROASTRIAN",
  Other: "OTHER"
}

export const languages: Record<string, LANGUAGE_MAYDEN_NOTTS> = {
  English: "ENGLISH",
  "Akan (Ashanti)": "AKAN",
  Albanian: "ALBANIAN",
  Amharic: "AMHARIC",
  Arabic: "ARABIC",
  "Bengali & Sylheti": "BENGALI",
  "Brawa & Somali": "BRAWA",
  "British Sign Language": "BRITISH_SIGN_LANGUAGE",
  Cantonese: "CANTONESE",
  "Cantonese & Vietnamese": "CANTONESE",
  Creole: "CREOLE",
  Dutch: "DUTCH",
  Ethiopian: "ETHIOPIAN",
  "Farsi (Persian)": "PERSIAN_FARSI",
  Finnish: "FINNISH",
  Flemish: "FLEMISH",
  French: "FRENCH",
  "French Creole": "FRENCH_CREOLE",
  Gaelic: "GAELIC",
  German: "GERMAN",
  Greek: "GREEK",
  Gujerati: "GUJARATI",
  Hakka: "HAKKA",
  Hausa: "HAUSA",
  Hebrew: "HEBREW",
  Hindi: "HINDI",
  "Igbo (Ibo)": "IGBO",
  Italian: "ITALIAN",
  Japanese: "JAPANESE",
  Korean: "KOREAN",
  Kurdish: "KURDISH",
  Kutchi: "KUTCHI",
  Latvian: "LATVIAN",
  Lingala: "LINGALA",
  Lithuanian: "LITHUANIAN",
  Luganda: "LUGANDA",
  "Makaton (Sign Language)": "MAKATON_SIGN_LANGUAGE",
  Malayalam: "MALAYALAM",
  Mandarin: "MANDARIN",
  Norwegian: "NORWEGIAN",
  "Pashto (Pashtoo)": "PASHTO",
  Patois: "PATOIS",
  Polish: "POLISH",
  Portuguese: "PORTUGUESE",
  Punjabi: "PUNJABI",
  Russian: "RUSSIAN",
  "Serbian/Croatian": "SERBIAN",
  Sinhala: "SINHALA",
  Slovak: "SLOVAK",
  Somali: "SOMALI",
  Spanish: "SPANISH",
  Swahili: "SWAHILI",
  Swedish: "SWEDISH",
  Sylheti: "SYLHETI",
  "Tagalog (Filipino)": "TAGALOG_FILIPINO",
  Tamil: "TAMIL",
  Thai: "THAI",
  Tigrinya: "TIGRINYA",
  Turkish: "TURKISH",
  Urdu: "URDU",
  Vietnamese: "VIETNAMESE",
  Welsh: "WELSH",
  Yoruba: "YORUBA",
  Other: "NOT_LISTED"
}

export const exArmedForces: Record<string, ARMED_FORCES_MAYDEN_NOTTS> = {
  "Yes - ex-services": "YES_EX",
  "Dependant of a ex-serving member": "DEPENDENT",
  No: "NO",
  Unknown: "UNKNOWN",
  "Not stated (Person asked but declined to provide a response)": "NOT_ANSWERED"
}

export const disabilities: Record<string, DISABILITY_MAYDEN_NOTTS> = {
  "No disability": "NONE",
  "Behavioural & Emotional": "BEHAVIOUR",
  Hearing: "HEARING",
  "Manual Dexterity": "MANUAL",
  "Memory or ability to concentrate, learn or understand (Learning Disability)": "LEARNING",
  "Mobility & Gross Motor": "MOTOR",
  "Perception of Physical Danger": "PERCEPTION",
  "Personal, Self Care & Continence": "SELF_CARE",
  "Progessive Conditions & Physical Health (such as HIV, Cancer, Multiple Sclerosis, Fits etc.)":
    "PROGRESSIVE",
  Sight: "SIGHT",
  Speech: "SPEECH",
  Other: "NOT_LISTED",
  "Do not wish to say": "NOT_ANSWERED"
}

export const ltcs: Record<string, LTC_MAYDEN_NOTTS> = {
  "Acquired brain injury": "BRAIN",
  "Acquired Immune Deficiency Syndrome": "AIDS",
  Arthritis: "ARTHRITIS",
  Asthma: "ASTHMA",
  Cancer: "CANCER",
  "Cerebal Palsy": "CEREBRAL_PALSY",
  "Chronic Fatigue Syndrome": "FATIGUE",
  "Chronic Kidney Disease": "KIDNEY",
  "Chronic Muscular Skeletal": "MUSCULOSKELETAL",
  "Chronic Obstructive Pulmonary Disease": "COPD",
  "Chronic Pain, including Fibromyalgia": "CHRONIC_PAIN",
  "Coronary Heart Disease": "CHD",
  Dementia: "DEMENTIA",
  Diabetes: "DIABETES",
  "Digestive tract conditions": "DIGESTIVE",
  Epilepsy: "EPILEPSY",
  "Heart Disease": "HEART_DISEASE",
  "Heart Failure": "HEART_FAILURE",
  HIV: "HIV",
  Hypertension: "HYPERTENSION",
  "Insulin Dependent Diabetes": "DIABETES_TYPE1",
  "Irritable Bowel Syndrome": "IBS",
  "Medically Unexplained Symptoms": "MEDICALLY_UNEXPLAINED",
  "Multiple Sclerosis": "MS",
  "Musculoskeletal Disorder (MSK)": "MSK",
  "Non-Insulin Dependent Diabetes": "DIABETES_TYPE2",
  "Other Respiratory Disease": "RESPIRATORY",
  "Parkinsons Disease": "PARKINSON",
  "Post Covid-19 Syndrome (Long Covid)": "POST_COVID",
  "Skin condition including Eczema": "SKIN",
  "Stroke or Transient Ischaemic Attack": "STIA"
}

export const sameGenderAsBirth: Record<string, GenderBirthAssigned> = {
  Yes: "YES",
  No: "NO",
  "I don't know": "UNKNOWN",
  "I don't want to say": "NOT_ANSWERED"
}

const form = {
  form_url: "https://gateway.mayden.co.uk/referral-v2/1012c873-cdb4-40de-b68d-6d7526697792",
  filled_by: "form",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  sameGenderAsBirth: Object.keys(sameGenderAsBirth),
  ethnicities: Object.keys(ethnicities),
  religions: Object.keys(religions),
  languages: Object.keys(languages),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs)
}

export default form
