import { ICCGServiceCOnfig } from "../models/IAppLaunchConfig"

export const ccgServices: Record<string, ICCGServiceCOnfig> = {
  KentMedway: {
    id: "10880436",
    code: "91Q",
    name: "Kent & Medway Mental Health and Wellbeing Hub",
    formattedName: "Kent & Medway Mental Health and Wellbeing Hub",
    ccgArea: "Kent And Medway CCG"
  }
}
