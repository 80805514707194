import { isEqual, sortBy } from "lodash"
import { IClinicalPath, IUserClinicalPathDescriptor } from "../models/IClinicalPath"

export default function getClinicalPath(
  defaults: IClinicalPath[] = [],
  custom: IClinicalPath[] = [],
  input: IUserClinicalPathDescriptor
): IClinicalPath | undefined {
  const customWithSecondary = custom.filter(p => p.enableSecondary)
  const customWithoutSecondary = custom.filter(p => !p.enableSecondary)
  const allPaths = [...customWithSecondary, ...customWithoutSecondary, ...defaults]
  const path = allPaths.find(p => isMatchingPath(input, p))
  return path
}

export function getAllMatchingPaths(
  clinicalPaths: IClinicalPath[],
  input: IUserClinicalPathDescriptor
): IClinicalPath[] {
  return clinicalPaths.filter(p => isMatchingPath(input, p))
}

export function isMatchingPath(input: IUserClinicalPathDescriptor, path: IClinicalPath): boolean {
  if (!path.matcher) return false
  const iPrimaries = sortBy(input.primaryProblems)
  const pPrimaries = sortBy(path.matcher.primaryProblems)
  const iSecondaries = sortBy(input.secondaryProblems)
  const pSecondaries = sortBy(path.matcher.secondaryProblems)
  const iFlags = sortBy(input.flags)
  const pFlags = sortBy(path.matcher.flags)

  // if primaries don't match
  if (!isEqual(iPrimaries, pPrimaries)) return false
  // if flags don't match
  if (!isEqual(iFlags, pFlags)) return false
  // if secondaries are enabled and don't match
  // noinspection RedundantIfStatementJS
  if (path.enableSecondary && !isEqual(iSecondaries, pSecondaries)) return false
  return true
}
