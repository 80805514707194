import moment from "moment"
import Logger from "./Logger"
import isWeekend from "./isWeekend"
import { DivisionTypes, isHoliday } from "../backend/api/getHolidaysFeed"

export default async function getNextWorkingDay(
  date: Date | moment.Moment = new Date(),
  division: DivisionTypes = DivisionTypes.EnglandWales
): Promise<string> {
  const nextDay = await _getNextWorkingDayRecursive(date, division)
  const datePlusOne = moment(date).add(1, "day")
  if (isSameDate(nextDay, datePlusOne)) {
    return `within the next 24 hours`
  }
  const diff = getDifferenceInDays(nextDay, date)
  if (diff > 6) {
    return `on ${moment(nextDay).format("dddd DD MMMM")}`
  }
  return `on ${moment(nextDay).format("dddd")}`
}

async function _getNextWorkingDayRecursive(
  date: Date | moment.Moment,
  division: DivisionTypes
): Promise<Date> {
  const nextDay = getNextDay(date)
  try {
    if (isWeekend(nextDay)) {
      return await _getNextWorkingDayRecursive(nextDay, division)
    }
    if (await isHoliday(division, nextDay)) {
      return await _getNextWorkingDayRecursive(nextDay, division)
    }
  } catch (e) {
    Logger.getInstance().exception(e, "_getNextWorkingDayRecursive failed")
  }
  return nextDay
}

function getNextDay(date: Date | moment.Moment): Date {
  return moment(date).add(1, "day").toDate()
}

function isSameDate(a: Date | moment.Moment, b: Date | moment.Moment): boolean {
  const f = "dddd DD MMMM YYYY"
  return moment(a).format(f) === moment(b).format(f)
}

function getDifferenceInDays(a: Date | moment.Moment, b: Date | moment.Moment): number {
  return Math.round(moment(a).diff(moment(b), "days", true))
}
