import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import TreatmentOptionsScript, { TreatmentOptionsScriptState } from "./TreatmentOptionsScript"

type State = TreatmentOptionsScriptState
export type TreatmentOptionsIAPTScriptState = State

export class TreatmentOptionsIAPTScript extends TreatmentOptionsScript {
  readonly name: string = "TreatmentOptionsIAPTScript"
}

export default class TreatmentOptionsIAPTDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsIAPT
  readonly name: string = "TreatmentOptionsIAPTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(TreatmentOptionsIAPTDialogue.id, new TreatmentOptionsIAPTScript(), state, snapshot)
  }
}
