import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"
import { EligibilityCheckIAPTScript } from "./EligibilityCheckIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { checkEligibilityVitality } from "../../../backend/api/vitalityEligibility"
import invariant from "../../../utils/invariant"
import { EligibilityReason, VitalityEligibilityStatus } from "../../../models/IVitalityEligibility"
import Logger from "../../../utils/Logger"
import { TrackingEvents } from "../../../models/Constants"

interface State extends EligibilityCheckIAPTState {
  eligibilityReason?: EligibilityReason
  retryRequest?: number
  howDistressed?: number
}

export type EligibilityCheckVitalityScriptState = State

export class EligibilityCheckVitalityScript extends EligibilityCheckIAPTScript {
  readonly name: string = "EligibilityCheckVitalityScript"

  /** Script Steps */
  @step.logState
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.checkVitalityEligibility }
  }

  @step
  async checkVitalityEligibility(d: IStepData<State>): Promise<IStepResult> {
    d.state.retryRequest ??= 0
    try {
      const entityNumber = this.referralStore.getCustomField("entityNumber")
      invariant(entityNumber, "No entityNumber has been provided")
      const [eligibility, status] = await checkEligibilityVitality(Number(entityNumber))
      if (status === VitalityEligibilityStatus.NoInternetConnection) {
        d.state.retryRequest = 0
        return { nextStep: this.sayNoInternetConnection }
      }

      // TODO: Check different failing scenarios thoroughly
      if (status === VitalityEligibilityStatus.RequestFailed && d.state.retryRequest < 3) {
        this.track(TrackingEvents.ASK_RETRY_CONNECTION_VITALITY_ELIGIBILITY_CHECK)
        return { nextStep: this.saySomethingWentWrongWithEligibilityCheck }
      }

      if (status === VitalityEligibilityStatus.RequestFailed && d.state.retryRequest === 3) {
        this.track(TrackingEvents.ASK_RETRY_REQUEST_FAILED_VITALITY_ELIGIBILITY_CHECK)
        return { nextStep: this.sayThereIsSomethingWrong }
      }
      d.state.eligibilityReason = eligibility?.reason
      this.setEligibility(d.state, !!eligibility?.eligible)
      return { nextStep: this.handleEligibilityCheck }
    } catch (error) {
      Logger.getInstance().exception(error, "entityNumber does not exist")
      this.track(TrackingEvents.MEMBER_ID_NOT_PROVIDED)
      return {
        nextStep: this.somethingWentWrongWithMemberID
      }
    }
  }

  @step.logState
  handleEligibilityCheck(d: IStepData<State>): IStepResult {
    if (d.state.isEligible) {
      return { nextStep: this.end }
    }
    this.logBreadcrumb("handleEligibilityCheck", { ...d.state })
    this.logMessage("handleEligibilityCheck")
    if (d.state.eligibilityReason === EligibilityReason.QuotaExceeded) {
      return { nextStep: this.sayQuotaExceeded }
    }
    return { nextStep: this.sayYouAreNotEligible }
  }

  @step.logState
  sayYouAreNotEligible(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "Hmmm",
        `I'm really sorry, ${name}, but it doesn't look like you have access to this service right now`,
        "You’ll need to speak to Vitality to discuss accessing mental health services",
        `Please contact their customer support team on 0345 0703040`,
        "Or you can email them at specialised.care@vitality.co.uk"
      ],
      prompt: {
        id: this.getPromptId("sayYouAreNotEligible"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayQuotaExceeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Okay ${name} it looks like you have access to mental health services with Vitality`,
        "However, I work best when I can connect you with a therapist right away",
        "I've just checked and there are no therapists available at IPRS Health",
        "Sorry about that",
        "Fortunately, Vitality have some other options that can help you get support sooner"
      ],
      prompt: {
        id: this.getPromptId("sayQuotaExceeded"),
        type: "inlinePicker",
        choices: [{ body: "Show me 👉" }]
      },
      nextStep: this.showOtherOptions
    }
  }

  @step.logState
  showOtherOptions(_d: IStepData<State>): IStepResult {
    return {
      body:
        "You should be eligible to access the following talking therapy services:\n" +
        "👉 [Nuffield Health](https://www.nuffieldhealth.com/mental-health-support)\n" +
        "👉 [My Online Therapy](https://myonlinetherapy.com)",
      prompt: {
        id: this.getPromptId("showOtherOptions"),
        type: "inlinePicker",
        choices: [
          {
            body: "Take me back to Vitality 👋",
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://www.vitality.co.uk")
              a.setAttribute("target", "_blank")
              a.click()
            }
          }
        ]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayNoInternetConnection(_d: IStepData<State>): IStepResult {
    return {
      body: ["Hmmm... it seems that you are not connected to the internet", "Please try again"],
      prompt: {
        id: this.getPromptId("sayNoInternetConnection"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }]
      },
      nextStep: this.checkVitalityEligibility
    }
  }

  @step.logState
  saySomethingWentWrongWithEligibilityCheck(d: IStepData<State>): IStepResult {
    d.state.retryRequest = d.state.retryRequest! + 1
    return {
      body: [
        "Hmmm, it looks like something went wrong with the eligibility check",
        "Please try again"
      ],
      prompt: {
        id: this.getPromptId("saySomethingWentWrongWithEligibilityCheck"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }]
      },
      nextStep: this.checkVitalityEligibility
    }
  }

  @step.logState
  somethingWentWrongWithMemberID(d: IStepData<State>): IStepResult {
    const organisationGenericPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    const name = this.getName(d.state)

    return {
      body: [
        `Sorry ${name}, something seems to have gone wrong while looking up your coverage`,
        `Please give the service a call at: ${organisationGenericPhoneNumber}`
      ],
      prompt: {
        id: this.getPromptId("somethingWentWrongWithMemberID"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayThereIsSomethingWrong(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationGenericPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    return {
      body: [
        `Sorry ${name}, it looks like there's something wrong`,
        `Please give the service a call at: ${organisationGenericPhoneNumber}`
      ],
      prompt: {
        id: this.getPromptId("sayThereIsSomethingWrong"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.goToGoodbye
    }
  }
}

/** istanbul ignore next */
export default class EligibilityCheckVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckVitality
  readonly name: string = "EligibilityCheckVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckVitalityDialogue.id,
      new EligibilityCheckVitalityScript(),
      state,
      snapshot
    )
  }
}
