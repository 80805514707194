import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import BaseScript from "../../BaseScript"
import {
  DiscussionSteps,
  ProblemCategories,
  RiskLevelReason,
  TrackingEvents
} from "../../../models/Constants"
import { DialogueIDs } from "../../DialogueIDs"
import type { SurveyScriptState } from "../../createSurveyDialogue"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { RiskPathwayScriptState } from "../../dialogues/riskPathway/RiskPathwayScript"
import type { IPersistableSurveyResponse, ISurveyResponse } from "../../../models/ISurvey"

type State = SurveyScriptState
export type PHQ9ScriptState = State

export class PHQ9Script extends BaseScript<State> {
  readonly name: string = "PHQ9Script"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult<State> {
    this.timeEvent(`${this.name} Finished`)
    return { nextStep: this.step1 }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step
  step1(_d: IStepData<State>): IStepResult {
    const q = "1. Little interest or pleasure in doing things."
    return {
      body: [
        "In the past two weeks, how often have you been bothered by the following problems?",
        q
      ],
      prompt: {
        id: this.getPromptId("step1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Not at all", 0),
          getQuestionChoice(q, "Several days", 1),
          getQuestionChoice(q, "More than half the days", 2),
          getQuestionChoice(q, "Nearly every day", 3)
        ],
        dataPointsName: "PHQ9 - step1"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleStep1
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("1", "phq9Responses")
  handleStep1(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.11)
    return { nextStep: this.step2 }
  }

  @step
  step2(_d: IStepData<State>): IStepResult {
    const q = "2. Feeling down, depressed, or hopeless."
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step2"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Not at all", 0),
          getQuestionChoice(q, "Several days", 1),
          getQuestionChoice(q, "More than half the days", 2),
          getQuestionChoice(q, "Nearly every day", 3)
        ],
        dataPointsName: "PHQ9 - step2"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleStep2
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("2", "phq9Responses")
  handleStep2(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.22)
    return { nextStep: this.step3 }
  }

  @step
  step3(_d: IStepData<State>): IStepResult {
    const q = "3. Trouble falling/staying asleep, sleeping too much."
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step3"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Not at all", 0),
          getQuestionChoice(q, "Several days", 1),
          getQuestionChoice(q, "More than half the days", 2),
          getQuestionChoice(q, "Nearly every day", 3)
        ],
        dataPointsName: "PHQ9 - step3"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleStep3
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("3", "phq9Responses")
  handleStep3(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.33)
    return { nextStep: this.step4 }
  }

  @step
  step4(_d: IStepData<State>): IStepResult {
    const q = "4. Feeling tired or having little energy."
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step4"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Not at all", 0),
          getQuestionChoice(q, "Several days", 1),
          getQuestionChoice(q, "More than half the days", 2),
          getQuestionChoice(q, "Nearly every day", 3)
        ],
        dataPointsName: "PHQ9 - step4"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleStep4
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("4", "phq9Responses")
  handleStep4(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.44)
    return { nextStep: this.step5 }
  }

  @step
  step5(_d: IStepData<State>): IStepResult {
    const q = "5. Poor appetite or overeating."
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step5"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Not at all", 0),
          getQuestionChoice(q, "Several days", 1),
          getQuestionChoice(q, "More than half the days", 2),
          getQuestionChoice(q, "Nearly every day", 3)
        ],
        dataPointsName: "PHQ9 - step5"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleStep5
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("5", "phq9Responses")
  handleStep5(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.55)
    return { nextStep: this.step6 }
  }

  @step
  step6(_d: IStepData<State>): IStepResult {
    const q =
      "6. Feeling bad about yourself or that you are a failure or have let yourself or your family down."
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step6"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Not at all", 0),
          getQuestionChoice(q, "Several days", 1),
          getQuestionChoice(q, "More than half the days", 2),
          getQuestionChoice(q, "Nearly every day", 3)
        ],
        dataPointsName: "PHQ9 - step6"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleStep6
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("6", "phq9Responses")
  handleStep6(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.66)
    return { nextStep: this.step7 }
  }

  @step
  step7(_d: IStepData<State>): IStepResult {
    const q =
      "7. Trouble concentrating on things, such as reading the newspaper or watching television."
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step7"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Not at all", 0),
          getQuestionChoice(q, "Several days", 1),
          getQuestionChoice(q, "More than half the days", 2),
          getQuestionChoice(q, "Nearly every day", 3)
        ],
        dataPointsName: "PHQ9 - step7"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleStep7
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("7", "phq9Responses")
  handleStep7(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.77)
    return { nextStep: this.step8 }
  }

  @step
  step8(_d: IStepData<State>): IStepResult {
    const q =
      "8. Moving or speaking so slowly that other people could have noticed. Or the opposite; being so fidgety or restless that you have been moving around a lot more than usual."
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step8"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Not at all", 0),
          getQuestionChoice(q, "Several days", 1),
          getQuestionChoice(q, "More than half the days", 2),
          getQuestionChoice(q, "Nearly every day", 3)
        ],
        dataPointsName: "PHQ9 - step8"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleStep8
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("8", "phq9Responses")
  handleStep8(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.step9 }
  }

  @step
  step9(_d: IStepData<State>): IStepResult {
    const q = "9. Thoughts that you would be better off dead or of hurting yourself in some way."
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step9"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Not at all", 0),
          getQuestionChoice(q, "Several days", 1),
          getQuestionChoice(q, "More than half the days", 2),
          getQuestionChoice(q, "Nearly every day", 3)
        ],
        dataPointsName: "PHQ9 - step9"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleStep9
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("9", "phq9Responses")
  async handleStep9(d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    d.state.php9q9Score = d.response.points
    this.track(TrackingEvents.PHQ9_Q9, { body: d.response.answer })
    return { nextStep: this.handlePHQ9Score }
  }

  @step.logState
  async handlePHQ9Score(d: IStepData<State>): Promise<IStepResult> {
    const total = this.getPHQ9Total(d.state)!
    if (total >= 10 && total <= 14) {
      this.rootStore.clinicalStore.addPrimaryProblem(ProblemCategories.M_Depression)
    }
    if (total >= 15 && total <= 19) {
      this.rootStore.clinicalStore.addPrimaryProblem(ProblemCategories.MS_Depression)
    }
    if (total >= 20) {
      this.rootStore.clinicalStore.addPrimaryProblem(ProblemCategories.S_Depression)
    }
    if (d.state.php9q9Score! >= 1) {
      const result = await this.onHandleRiskQ9Score(d.state)
      if (result) return result
    }
    const name = this.getName(d.state)
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { body: `Thanks for sharing ${name}`, nextStep: this.end }
  }

  @step.logState
  goToRiskPathway(d: IStepData<State>): IStepResult {
    const RiskPathwayDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.RiskPathway)
    const nextDialogue = RiskPathwayDialogue //
      ? new RiskPathwayDialogue({ ...d.state })
      : undefined
    return {
      nextDialogue,
      nextStep: this.handleRiskPathway
    }
  }

  @step.logState
  handleRiskPathway(d: IStepData<State, undefined, RiskPathwayScriptState>): IStepResult {
    this.updateState(d.state, d.previousDialogue?.state)
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onHandleRiskQ9Score(state: State): Promise<IStepResult | void> {
    this.setCrisisDetected(state)
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    const isRisk = this.clinicalStore.isRisk
    if (!isRisk && state.php9q9Score === 3) {
      this.setRiskLevelModerate(state, RiskLevelReason.PHQ9_Q9)
    }
    return { nextStep: this.goToRiskPathway }
  }

  saveResponse<T extends IPersistableSurveyResponse>(item: T, state: State): void {
    super.saveResponse(item, state, "phq9Responses")
  }
}

export default class PHQ9Dialogue extends Dialogue<State> {
  static id = DialogueIDs.PHQ9
  readonly name: string = "survey:PHQ9 Dialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PHQ9Dialogue.id, new PHQ9Script(), state, snapshot)
  }
}

function getQuestionChoice(title: string, answer: string, points: number) {
  return {
    body: answer,
    value: {
      title,
      answer,
      points
    }
  }
}
