import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { PermissionsIAPTScriptState } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = PermissionsIAPTScriptState
export type PermissionsVitalityScriptState = State

export class PermissionsVitalityScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsVitalityScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Now I know mental health can be a sensitive subject",
        "I just want to reassure you that everything we discuss is confidential and all your information is stored safely"
      ],
      nextStep: this.showTOSLinks
    }
  }

  @step.logState
  promptIUnderstand(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("privacyLinks"),
        type: "inlinePicker",
        choices: [{ body: "I understand, let's continue" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleIUnderstand
    }
  }
}

export default class PermissionsVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsVitality
  readonly name: string = "PermissionsVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsVitalityDialogue.id, new PermissionsVitalityScript(), state, snapshot)
  }
}
