import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type IName from "../../../models/IName"

type State = BaseScriptState
export type GetNameScriptState = State

export abstract class GetNameScript extends BaseScript<State> {
  onCheckFullName?(state: State): Promise<IStepResult>

  /** Script Steps */

  abstract step1(d: IStepData<State>): IStepResult

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.step1 }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(this.name)
    return super.end(d)
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    return {
      body: "What's your full name? ✏️",
      nextStep: this.showPromptForFullName
    }
  }

  @step
  sayPleaseGiveFullName(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your full name",
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  showPromptForFullName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForFullName"),
        type: "name",
        dataPointsName: "showPromptForFullName"
      },
      nextStep: this.handleFullName
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, IName>, script: GetNameScript) => {
    d.state.name = d.response
    const username = script.getFullName(d.state)
    d.state.username = username
    script.rootStore.applicationStore.setUsername(username)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getInput: (d: IStepData<State, IName>) => {
      const { firstName, lastName, middleNames } = d.response
      return `${firstName}${middleNames ? ` ${middleNames}` : ""} ${lastName}`
    },
    getNextStep: (s: GetNameScript) => s.sayPleaseGiveFullName
  })
  async handleFullName(_d: IStepData<State, IName>): Promise<IStepResult> {
    return { nextStep: this.checkFullName }
  }

  @step.logState
  async checkFullName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.username || d.state.username.trim() === "") {
      return { nextStep: this.sayPleaseGiveFullName }
    }
    const result = await this.onCheckFullName?.(d.state)
    if (result) return result
    return { nextStep: this.sayNiceToMeetYou }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Nice to meet you ${name}`,
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: "Nice to meet you too" }]
      },
      nextStep: this.end
    }
  }
}
