import { REFERRAL_STATUS } from "@limbic/types"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import AssessmentIAPTScript from "./AssessmentIAPTScript"
import type { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentIAPTScriptState

export class AssessmentDemoScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentDemoScript"

  /** Generic Handlers */

  async onFinishAssessment(state: State): Promise<IStepResult> {
    const referral = await this.referralStore.getReferral()
    // basically only send the email if the
    // referral has not been successfully submitted
    if (referral && referral.status !== REFERRAL_STATUS.SUCCESS) {
      if (!this.clinicalStore.isRisk) {
        const emails = state.iapt?.emails || []
        // noinspection ES6MissingAwait - ⚠️ this is done on purpose as a FnF call
        void this.submitEmail(state, emails)
      }
    }
    return { nextStep: this.end }
  }
}

export default class AssessmentDemoDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentDemo
  readonly name: string = "AssessmentDemoDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentDemoDialogue.id, new AssessmentDemoScript(), state, snapshot)
  }
}
