import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import dialoguesRegistry from "../../dialoguesRegistry"
import { enumToArray } from "../../../utils/array"
import { JobCategory } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { PostcodeStatus } from "../../../models/IPostcode"
import { getPostCodeDetails } from "../../../backend/api/postcodes"
import { TrackingEvents } from "../../../models/Constants"

interface State extends BaseScriptState {
  customUserPostcode?: string
  isCustomPostcode?: boolean
  jobCategory?: JobCategory
  retryPostcode?: string
  retryTimes?: number
}
export type EligibilityCheckCCGState = State

export class EligibilityCheckCCGScript extends BaseScript<State> {
  readonly name: string = "EligibilityCheckCCGScript"

  /** Script Steps */

  @step
  start(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Okay ${name}, let me just make sure you're in the right place`,
      nextStep: this.askAreYouNHSStaffInTheCCG
    }
  }

  @step.logState
  askAreYouNHSStaffInTheCCG(_d: IStepData<State>): IStepResult {
    const ccgService = this.rootStore.configStore.ccgService
    return {
      body: `Are you an NHS or social care staff member working in the ${ccgService?.ccgArea} area?`,
      prompt: {
        id: this.getPromptId("askAreYouNHSStaffInTheCCG"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleAreYouNHSStaffInTheCCG
    }
  }

  @step.logState
  handleAreYouNHSStaffInTheCCG(d: IStepData<State, boolean>): IStepResult {
    this.setEligibility(d.state, d.response)
    if (!d.response) {
      return { nextStep: this.goToSuitableServicesSignpost }
    }
    return { body: "Great!", nextStep: this.askJobCategory }
  }

  @step.logState
  askJobCategory(_d: IStepData<State>): IStepResult {
    const jobCategories = enumToArray(JobCategory)
    return {
      body: "And which of these job categories best describes your role?",
      prompt: {
        id: this.getPromptId("askJobCategory"),
        trackResponse: true,
        type: "inlinePicker",
        choices: jobCategories.map(c => ({ body: c, value: c }))
      },
      nextStep: this.handleJobCategory
    }
  }

  @step.logState
  handleJobCategory(d: IStepData<State, JobCategory>): IStepResult {
    d.state.jobCategory = d.response
    this.referralStore.setCustomField<State>("jobCategory", d.response)
    this.setPeople({ jobCategory: d.response })
    this.track(d.response)
    return {
      nextStep: this.askPostCodeOfUser
    }
  }

  @step.logState
  askPostCodeOfUser(_d: IStepData<State>): IStepResult {
    return {
      body: "And lastly, what's your postcode?",
      prompt: {
        id: this.getPromptId("askPostCodeOfUser"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostCodeOfUser
    }
  }

  @step
  returnToAkPostCodeOfUser(_d: IStepData<State>): IStepResult {
    return {
      body: "So what's your postcode?",
      nextStep: this.askPostCodeOfUser
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.checkInputForCrisis({
    getNextStep: (s: EligibilityCheckCCGScript) => s.returnToAkPostCodeOfUser
  })
  async handlePostCodeOfUser(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.postcodeEntered = d.response || d.state.retryPostcode
    if (d.state.retryTimes === undefined) d.state.retryTimes = 0
    d.state.retryPostcode = d.state.postcodeEntered

    const [postcode, postcodeStatus] = await getPostCodeDetails(d.response || d.state.retryPostcode)

    if (postcodeStatus === PostcodeStatus.NoInternetConnection) {
      return { nextStep: this.askRetryInternetConnection }
    }
    if (postcodeStatus === PostcodeStatus.Success) {
      d.state.userPostcode = postcode
      return {
        body: "Fantastic! You're in the right place 👌",
        nextStep: this.end
      }
    }
    const isInvalidPostcode = postcodeStatus === PostcodeStatus.InvalidPostcode
    const isNotFoundPostcode = postcodeStatus === PostcodeStatus.PostcodeNotFound
    if (isInvalidPostcode || isNotFoundPostcode) {
      d.state.customUserPostcode = d.response.toUpperCase()
      const body = isInvalidPostcode
        ? "Hmmm, this doesn't seem to be a valid UK postcode"
        : "Hmmm, unfortunately I can't find this postcode"
      return {
        body,
        nextStep: this.askDidYouTypeThePostCodeCorrectly
      }
    }
    if (postcodeStatus === PostcodeStatus.RequestFailed && d.state.retryTimes < 3) {
      d.state.retryTimes = d.state.retryTimes + 1
      return { nextStep: this.askPostcodeRetry }
    }

    d.state.retryTimes = 0
    return {
      body: ["Oh dear, for some reason I can't find details for your postcode", "Sorry about that"],
      nextStep: this.end
    }
  }

  @step.logState
  askRetryInternetConnection(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryConnection"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again", value: false }],
        isUndoAble: false
      },
      nextStep: this.handleAskPostcodeRetry
    }
  }

  @step.logStateAndResponse
  handleAskPostcodeRetry(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.track(TrackingEvents.RE_ENTER_POSTCODE)
      return { nextStep: this.askPostCodeOfUser }
    }
    this.track(TrackingEvents.TRY_AGAIN_POSTCODE)
    return { nextStep: this.handlePostCodeOfUser }
  }

  @step.logState
  askDidYouTypeThePostCodeCorrectly(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you do me a favour and double check you typed it in correctly?",
      prompt: {
        id: this.getPromptId("askDidYouTypeThePostCodeCorrectly"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "It's correct", value: true },
          { body: "Oops, let me re-type it", value: false }
        ]
      },
      nextStep: this.handleDidYouTypeThePostCodeCorrectly
    }
  }

  @step.logStateAndResponse
  handleDidYouTypeThePostCodeCorrectly(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      d.state.isCustomPostcode = true
      this.track(TrackingEvents.CUSTOM_POSTCODE, { postcode: d.state.customUserPostcode })
      return {
        body: [
          "No problem - I'll just check those details...",
          "Fantastic! You're in the right place 👌"
        ],
        nextStep: this.end
      }
    }
    d.state.retryPostcode = undefined
    const name = this.getName(d.state)
    return { body: `No worries ${name}`, nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  askPostcodeRetry(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like something went wrong while looking up your postcode",
      prompt: {
        id: this.getPromptId("askPostcodeRetry"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Try again", value: false },
          { body: "Oops, let me re-type the postcode", value: true }
        ],
        isUndoAble: false
      },
      nextStep: this.handleAskPostcodeRetry
    }
  }

  @step.logState
  goToSuitableServicesSignpost(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const SuitableServicesSignpostCCGDialogue = dialoguesRegistry.get(DialogueIDs.SuitableServicesSignpostCCG)
    return {
      nextDialogue: new SuitableServicesSignpostCCGDialogue({ ...d.state }),
      nextStep: this.end
    }
  }
}

export default class EligibilityCheckCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckCCG
  readonly name: string = "EligibilityCheckCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckCCGDialogue.id, new EligibilityCheckCCGScript(), state, snapshot)
  }
}
