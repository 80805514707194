import { sample } from "lodash"
import { step } from "../../../backend/chatbot/decorators/step"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import { TrackingEvents } from "../../../models/Constants"
import getQRCodeURL from "../../../utils/getQRCodeURL"
import getNextWorkingDay from "../../../utils/getNextWorkingDay"
import { StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface WindowWithLimbicNameSpace extends Window {
  DEEP_LINK: string
}

declare let window: WindowWithLimbicNameSpace

window.DEEP_LINK = String(process.env.REACT_APP_DEEP_LINK ?? "enabled")

const deepLinkEnabled = window.DEEP_LINK !== "disabled"

interface State extends BaseScriptState {
  isIdleSubmitted?: boolean
}

export type GoodbyeScriptState = State

export default abstract class GoodbyeScript extends BaseScript<State> {
  /** Optional Abstract Generic Handlers */

  getCustomRecapMessage?(state: State): Promise<StepResultBodyType[] | undefined>

  onHandleRecapMessage?(
    state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void>

  /** Script Names */

  @step.logState
  async start(d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setIdleSubmissionActive(false)
    this.updateReferralType(d.state)
    const body: StepResultBodyType[] = []
    const introMessage = await this.getIntroMessage(d.state)
    introMessage && body.push(introMessage)
    const customRecap = await this.getCustomRecapMessage?.(d.state)
    customRecap?.length && body.push(...customRecap)
    const recapMessage = await this.getRecapMessage(d.state)
    recapMessage && body.push(recapMessage)

    const result = await this.onHandleRecapMessage?.(d.state, body, recapMessage)
    if (result) return result

    if (!body.length) {
      return { nextStep: this.askWhereDidYouHearAboutUs }
    }

    const choices: any[] = [{ body: "Okay" }, recapMessage && { body: "I understand" }] //
      .filter(Boolean)

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askWhereDidYouHearAboutUs
    }
  }

  @step.logState
  askWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    if (this.clinicalStore.isCrisis) {
      this.track(TrackingEvents.SKIP_WHERE_DID_YOU_HEAR)
      return { nextStep: this.sayIHopeIHelped }
    }
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: `Before we close, where did you hear about ${serviceName}?`,
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleWhereDidYouHearAboutUs
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    d.state.whereDidYouHearAboutService = d.response
    // TODO: this 👇 needs to be just one property instead
    //        of this mess. We have to do this because we have
    //        a different property name for each service sadly
    // NOTE: Insight doesn't use this step because they ask it
    //       earlier in the GetName dialogue and we send it in
    //       the SelfReferral dialogue
    void script.referralStore.updateReferral({
      whereHeardAboutUs: d.response, // Insight, Trent
      whereHeard: d.response, // VHG
      whereHeardDetails: d.response, // Notts
      referralSource: "Other - please specify", // SABP
      referralSourceDetails: d.response // SABP
    })
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: GoodbyeScript) => s.askFeedback
  })
  handleWhereDidYouHearAboutUs(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askFeedback }
  }

  @step.logState
  sayIHopeIHelped(_d: IStepData<State>): IStepResult {
    return {
      body: "I hope I've been able to help you today",
      nextStep: this.sayGoodBye
    }
  }

  @step.logState
  askFeedback(_d: IStepData<State>): IStepResult {
    if (this.clinicalStore.isCrisis) {
      this.track(TrackingEvents.SKIP_FEEDBACK_CRISIS)
      return { nextStep: this.sayIHopeIHelped }
    }
    return {
      body: "I hope I've been able to help you today",
      prompt: {
        id: this.getPromptId("askFeedback"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes thanks - you've helped me", value: "yes" },
          { body: "I'd have preferred a bit more help", value: "more" },
          { body: "No - you haven't helped me", value: "no" }
        ]
      },
      nextStep: this.handleFeedback
    }
  }

  @step
  handleFeedback(d: IStepData<State, "yes" | "more" | "no">): IStepResult {
    if (d.response === "no") {
      d.state.isHelpful = "No"
      return { nextStep: this.saySorryToHearThat }
    }
    if (d.response === "more") {
      d.state.isHelpful = "Needed more"
      return { nextStep: this.sayUnderstood }
    }
    d.state.isHelpful = "Yes"
    return { nextStep: this.sayImGlad }
  }

  @step
  saySorryToHearThat(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Sorry to hear that. How could I improve?",
        "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
      ],
      prompt: {
        id: this.getPromptId("howCanIImprove (Not Helpful)"),
        trackResponse: true,
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleSorryToHearThat
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    const suggestion = d.response
    script.track(TrackingEvents.IMPROVEMENT_SUGGESTION, { isHelpful: "No", suggestion })
    d.state.improvementSuggestion = suggestion
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: GoodbyeScript) => s.sayGoodBye
  })
  handleSorryToHearThat(_d: IStepData<State, string>): IStepResult {
    return {
      body: "Noted - I'm always learning so hopefully I can do better next time",
      nextStep: this.sayGoodBye
    }
  }

  @step
  sayUnderstood(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Understood. How could I improve?",
        "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
      ],
      prompt: {
        id: this.getPromptId("howCanIImprove (Needed More)"),
        trackResponse: true,
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleUnderstood
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    const suggestion = d.response
    script.track(TrackingEvents.IMPROVEMENT_SUGGESTION, { isHelpful: "Needed More", suggestion })
    d.state.improvementSuggestion = suggestion
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: GoodbyeScript) => s.sayGoodBye
  })
  handleUnderstood(_d: IStepData<State, string>): IStepResult {
    return {
      body: "Noted - I'm always learning so hopefully I can do better next time",
      nextStep: this.sayGoodBye
    }
  }

  @step
  sayImGlad(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm really glad to hear that",
        "What was the main benefit I was able to bring you?",
        "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
      ],
      prompt: {
        id: this.getPromptId("howCanIImprove (Helpful)"),
        trackResponse: true,
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleImGlad
    }
  }

  @step.logState
  returnToSayImGlad(_d: IStepData<State>): IStepResult {
    return {
      body: "So you said I was able to help you 😊",
      nextStep: this.sayImGlad
    }
  }

  @step
  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeScript) => {
    const suggestion = d.response
    script.track(TrackingEvents.IMPROVEMENT_SUGGESTION, { isHelpful: "Yes", suggestion })
    d.state.improvementSuggestion = suggestion
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: GoodbyeScript) => s.sayGoodBye
  })
  handleImGlad(_d: IStepData<State, string>): IStepResult {
    return {
      body: "Noted - I'm always learning so hopefully I can do even better next time",
      nextStep: this.sayGoodBye
    }
  }

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    void this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()

    return {
      body: "Well done for taking this important step towards better mental health",
      nextStep: this.sayDynamicLink
    }
  }

  @step.logState
  sayDynamicLink(d: IStepData<State>): IStepResult {
    if (!deepLinkEnabled || !d.state.dynamicLink) {
      return { nextStep: this.sayFavoriteQuotes }
    }
    const qrCodeURL = getQRCodeURL(d.state.dynamicLink)
    return {
      body: [
        "I'm looking forward to going on this journey with you",
        "We can continue our conversations in my mobile app",
        `If you're already on your mobile device, just click [here](${d.state.dynamicLink}) to download it`,
        "If you're on a desktop computer, you can scan the QR code below",
        {
          type: "imageAttachment",
          image: qrCodeURL
        }
      ],
      prompt: {
        id: this.getPromptId("sayDynamicLink"),
        type: "inlinePicker",
        choices: [{ body: "Done", value: true }]
      },
      nextStep: this.sayFavoriteQuotes
    }
  }

  @step.logState
  sayFavoriteQuotes(d: IStepData<State, true | undefined>): IStepResult {
    const name = this.getName(d.state)
    const great = d.response ? ["Great"] : []
    return {
      body: [
        ...great,
        "I'll leave you with one of my favourite quotes...",
        {
          type: "imageAttachment",
          image: sample([
            "https://inspirational-quotes.s3.eu-west-2.amazonaws.com/Inspirational_quote_1.png",
            "https://inspirational-quotes.s3.eu-west-2.amazonaws.com/Inspirational_quote_2.png",
            "https://inspirational-quotes.s3.eu-west-2.amazonaws.com/Inspirational_quote_4.png",
            "https://inspirational-quotes.s3.eu-west-2.amazonaws.com/Inspirational_quote_5.png",
            "https://inspirational-quotes.s3.eu-west-2.amazonaws.com/Inspirational_quote_7.png",
            "https://inspirational-quotes.s3.eu-west-2.amazonaws.com/Inspirational_quote_9.png",
            "https://inspirational-quotes.s3.eu-west-2.amazonaws.com/Inspirational_quote_10.png"
          ])!
        },
        `Goodbye ${name} 👋`
      ],
      nextStep: this.end
    }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.CONVERSATION_END)
    return super.end(d)
  }

  /** Generic Handler */

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    return isCrisis
      ? `Thank you for sharing this information with me ${name}`
      : `Well, it's been a pleasure getting to know you ${name}`
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return state.isIdleSubmitted //
        ? `It looks like there hasn’t been any activity for some time so I've referred you to ${iaptName}`
        : `I've referred you to ${iaptName}`
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && state.needsAssessmentCall) {
      return "Someone from our admin team will be in touch in the next 3 working days to book you in for an assessment"
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      const nextWorkingDay = await getNextWorkingDay()
      return `Because you identified as being in crisis, someone from our duty of care team will call you ${nextWorkingDay}. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger.`
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      const nextWorkingDay = await getNextWorkingDay()
      return `Because you identified as being in crisis, someone from our duty of care team will call you ${nextWorkingDay}. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger.`
    }
  }

  async getTreatmentMessage(_state: State): Promise<string | void> {
    const treatment = this.clinicalStore.getAcceptedTreatment()
    if (treatment) {
      return `You have been offered ${treatment.formattedName} and someone from our team will be in touch in the next 3 working days to book you in for your first session`
    }
  }

  async getRecapMessage(state: State): Promise<string | void> {
    const recaps: string[] = []
    const referredYouMessage = await this.getReferredYouMessage(state)
    referredYouMessage && recaps.push(referredYouMessage)

    const adminWillCallMessage = await this.getAdminWillCallMessage(state)
    adminWillCallMessage && recaps.push(adminWillCallMessage)

    const highRiskContactMessage = await this.getHighRiskContactMessage(state)
    highRiskContactMessage && recaps.push(highRiskContactMessage)

    const moderateRiskContactMessage = await this.getModerateRiskContactMessage(state)
    moderateRiskContactMessage && recaps.push(moderateRiskContactMessage)

    const treatmentMessage = await this.getTreatmentMessage(state)
    treatmentMessage && recaps.push(treatmentMessage)

    const formattedRecap = recaps.length > 1 ? recaps.map((i, idx) => `${idx + 1}. ${i}`) : recaps
    const recapString = formattedRecap.join("\n")

    if (recapString?.length) {
      return `Just to recap:\n\n${recapString}`
    }
  }
}
