import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import getNextWorkingDay from "../../../utils/getNextWorkingDay"
import type { GoodbyeScriptState } from "./GoodbyeScript"

type State = GoodbyeScriptState

export class GoodbyeVHGScript extends GoodbyeScript {
  readonly name: string = "GoodbyeVHGScript"

  /** Generic Handlers */

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    const treatment = this.clinicalStore.getAcceptedTreatment()
    if (state.referralSubmitted && !this.clinicalStore.isRisk && !treatment) {
      return "A member of the Vitaminds team will contact you in the next 3 working days"
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      const nextWorkingDay = await getNextWorkingDay()
      return `A member of the Vitaminds team will contact you ${nextWorkingDay}`
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      const nextWorkingDay = await getNextWorkingDay()
      return `A member of the Vitaminds team will contact you ${nextWorkingDay}`
    }
  }

  async getTreatmentMessage(_state: State): Promise<string | void> {
    const treatment = this.clinicalStore.getAcceptedTreatment()
    if (treatment) {
      return "A member of the Vitaminds team will contact you in the next 3 working days"
    }
  }

  getCustomReferralType(state: State): string | undefined {
    if (this.clinicalStore.assessmentFinished) return "Limbic Assessment"
    return "Self Referral"
  }
}

export default class GoodbyeVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeVHG
  readonly name: string = "GoodbyeVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeVHGDialogue.id, new GoodbyeVHGScript(), state, snapshot)
  }
}
