export default function isValidPhoneNumber(phoneNumber: string): boolean {
  return isValidMobilePhone(phoneNumber) || isValidLandlineNumber(phoneNumber)
}

export function isValidMobilePhone(phoneNumber: string): boolean {
  return /^07\d{9}$/.test(phoneNumber)
}

export function isValidLandlineNumber(phoneNumber: string): boolean {
  return /^\d{11}$/.test(phoneNumber)
}
