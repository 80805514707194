import React, { useCallback, useRef, useState } from "react"
import classnames from "classnames"
import { useConfigStore } from "../../contexts/RootStoreContext"
import TextInput from "./TextInput"
import InlineButton from "../InlineButton/InlineButton"
import "./NameInput.scss"
import type IName from "../../../models/IName"
import type { IDataPoints } from "@limbic/types"
import type ISelectable from "../../../models/ISelectable"

const submitButton = { body: "Submit" } as ISelectable

interface IProps {
  onSubmit?: (name: IName, dataPoints?: IDataPoints) => void
}

export default function NameInput(props: IProps): JSX.Element {
  const config = useConfigStore()
  const [firstName, setFirstName] = useState<string>()
  const [middleName, setMiddleName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [fError, setFError] = useState<string | undefined>()
  const [lError, setLError] = useState<string | undefined>()
  const dirtyFields = useRef({ firstName: false, lastName: false })

  const changeFirstName = useCallback((text?: string) => {
    const isDirty = dirtyFields.current.firstName
    dirtyFields.current.firstName = true
    if (text?.trim().replace("*", "")) setFError(undefined)
    else if (isDirty) setFError("First Name can't be empty")
    setFirstName(text)
  }, [])

  const changeLastName = useCallback((text?: string) => {
    const isDirty = dirtyFields.current.lastName
    dirtyFields.current.lastName = true
    if (text?.trim().replace("*", "")) setLError(undefined)
    else if (isDirty) setLError("Last Name can't be empty")
    setLastName(text)
  }, [])

  const onSubmit = useCallback(() => {
    const name = {
      firstName: firstName?.trim().replace("*", ""),
      middleNames: middleName?.trim().replace("*", ""),
      lastName: lastName?.trim().replace("*", "")
    }
    // sanity check
    if (!name.firstName || !name.lastName) return
    props.onSubmit?.(name as IName)
  }, [firstName, lastName, middleName, props])

  const active = firstName?.length && lastName?.length
  const submitButtonCSS = classnames("lb-user-input-name-submit-button", { active })
  return (
    <div className="lb-user-input-name-container">
      <ErrorInfo error={fError} />
      <TextInput
        borderless
        hasError={!!fError}
        placeholder={"First Name *"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoComplete={"name"}
        onChangeText={changeFirstName}
        onSubmit={onSubmit}
        showOnSubmitButton={false}
      />
      <TextInput
        borderless
        autoFocus={false}
        placeholder={"Middle Name(s)"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoComplete={"additional-name"}
        onChangeText={setMiddleName}
        onSubmit={onSubmit}
        showOnSubmitButton={false}
      />
      <ErrorInfo error={lError} />
      <TextInput
        borderless
        autoFocus={false}
        hasError={!!lError}
        placeholder={"Last Name *"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoComplete={"family-name"}
        onChangeText={changeLastName}
        onSubmit={onSubmit}
        showOnSubmitButton={false}
      />
      <InlineButton //
        fullWidth
        disabled={!active}
        style={{ backgroundColor: config.userMessageBackground }}
        btn={submitButton}
        onSelect={onSubmit}
        buttonClassName={submitButtonCSS}
      />
    </div>
  )
}

interface IErrorInfoProps {
  error?: string
}

function ErrorInfo(props: IErrorInfoProps) {
  const { error } = props
  if (!error) return null
  return (
    <div className="lb-user-input-name-error-container">
      <span className="lb-user-input-name-error">{error}</span>
    </div>
  )
}
