import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import AssessmentIAPTScript, { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import moment from "moment"

interface State extends AssessmentIAPTScriptState {
  mainIssue?: string
  assessmentPreference?: "telephone" | "digital"
}

export type AssessmentVitalityScriptState = State

export class AssessmentVitalityScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentVitalityScript"

  /** Script Steps */
  @step.logState
  start(d: IStepData<State>): IStepResult {
    if (d.state.assessmentPreference === "telephone") {
      return { nextStep: this.end }
    }
    this.rootStore.applicationStore.setTotalProgressBars(4)
    this.rootStore.applicationStore.setCurrentProgressBar(1)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return {
      nextStep: this.askMainIssue
    }
  }

  @step.logState
  askMainIssue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `So ${name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you)`,
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssue
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: AssessmentVitalityScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
    script.referralStore.updateReferral({ problemInOwnWords: d.response })
  })
  @step.checkInputForCrisis({ getNextStep: (s: AssessmentVitalityScript) => s.goToPHQ9 })
  handleMainIssue(d: IStepData<State, string>): IStepResult {
    const name = this.getName(d.state)

    return {
      body: `Thank you for sharing ${name}, you've come to the right place`,
      nextStep: this.sayLetsGetStarted
    }
  }

  sayLetsGetStarted(_d: IStepData<State>): IStepResult {
    return {
      body: "I am now going to ask you a set of 9 questions designed to measure common symptoms of low mood",
      nextStep: this.goToPHQ9
    }
  }

  /** Generic Handlers */

  // TODO: Check if we need to keep Employment questions (and remove if we do)
  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }

  getPersonalInfoHTML(state: State): string {
    // prettier-ignore
    return ([] as Array<string | false | undefined>)
      .concat(
        `<b>Name:</b> ${state.username}<br/>`,
        !!state.birthday && `<b>Date of Birth:</b> ${moment(state.birthday).format("DD/MM/YYYY")}<br/>`,
        `<b>Phone number:</b> ${state.phoneNumber || "N/A"}<br/>`,
      )
      .filter(Boolean)
      .join("\n")
  }

  getClinicalNotesHTML(_state: State): string {
    return ""
  }

  getQuestionnairesInfoHTML(_state: State): string {
    return ""
  }
}

export default class AssessmentVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentVitality
  readonly name: string = "AssessmentVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentVitalityDialogue.id, new AssessmentVitalityScript(), state, snapshot)
  }
}
