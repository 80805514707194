import moment from "moment"
import isEmail from "validator/lib/isEmail"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import AssessmentScript, { AssessmentScriptState } from "./AssessmentScript"
import { step } from "../../../backend/chatbot/decorators/step"
import isValidPhoneNumber, { isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import { TrackingEvents } from "../../../models/Constants"
import invariant from "../../../utils/invariant"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { ICheckBoxPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = AssessmentScriptState
export type AssessmentCCGScriptState = State

export class AssessmentCCGScript extends AssessmentScript {
  readonly name: string = "AssessmentCCGScript"

  /** Script Steps */

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    return { nextStep: this.askPhoneNumber }
  }

  @step.logState
  askPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "So firstly, in case we get disconnected, what's the best phone number to reach you on?",
      prompt: {
        id: this.getPromptId("askPhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step
  sayPleaseTypePhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type your phone number",
      prompt: {
        id: this.getPromptId("sayPleaseTypePhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step
  returnToAskPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "So...",
      nextStep: this.sayPleaseTypePhoneNumber
    }
  }

  @step.logState
  @step.checkInputForCrisis({ getNextStep: (s: AssessmentCCGScript) => s.returnToAskPhoneNumber })
  handlePhoneNumber(d: IStepData<State, string>): IStepResult {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      this.track(TrackingEvents.INVALID_PHONE_NUMBER)
      return {
        body: "Sorry this is not a valid phone number. Let's try again",
        nextStep: this.sayPleaseTypePhoneNumber
      }
    }
    d.state.phoneNumber = d.response
    return {
      body: "Thanks",
      nextStep: this.askCanIContactYouOnPhoneNumber
    }
  }

  @step.logState
  askCanIContactYouOnPhoneNumber(d: IStepData<State>): IStepResult {
    const isMobilePhone = isValidMobilePhone(d.state.phoneNumber!)
    return {
      body: "And is it ok to send you text messages or leave a voicemail if I can't get through?",
      prompt: {
        id: this.getPromptId("askCanIContactYouOnPhoneNumber"),
        trackResponse: true,
        type: "checkbox",
        options: [
          isMobilePhone && {
            body: "You may send me text messages",
            key: "canSendTextMessagesToPhoneNumber"
          },
          {
            body: "You may leave voicemail messages",
            key: "canLeaveVoicemailToPhoneNumber"
          }
        ].filter(Boolean)
      } as ICheckBoxPrompt,
      nextStep: this.handleCanIContactYouOnPhoneNumber
    }
  }

  @step.logStateAndResponse
  handleCanIContactYouOnPhoneNumber(
    d: IStepData<
      State,
      {
        canSendTextMessagesToPhoneNumber?: boolean
        canLeaveVoicemailToPhoneNumber: boolean
      }
    >
  ): IStepResult {
    const canSendTextMessagesToPhoneNumber = d.response.canSendTextMessagesToPhoneNumber
    const canLeaveVoicemailToPhoneNumber = d.response.canLeaveVoicemailToPhoneNumber
    d.state.canSendTextMessagesToPhoneNumber = canSendTextMessagesToPhoneNumber
    d.state.canLeaveVoicemailToPhoneNumber = canLeaveVoicemailToPhoneNumber
    const data = { canSendTextMessagesToPhoneNumber, canLeaveVoicemailToPhoneNumber }
    this.setPeople(data)
    this.track(TrackingEvents.PHONE_PERMISSIONS, data)
    return { nextStep: this.askDoYouHaveEmail }
  }

  @step.logState
  askDoYouHaveEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "It's optional, but you can also share your email with me if you like to be contacted this way?",
      prompt: {
        id: this.getPromptId("askDoYouHaveEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes, I'd like to be contacted by email", value: true },
          { body: "No, just the phone number is fine", value: false }
        ]
      },
      nextStep: this.handleDoYouHaveAnEmail
    }
  }

  @step.logStateAndResponse
  handleDoYouHaveAnEmail(d: IStepData<State, boolean>): IStepResult {
    this.setPeople({ hasEmail: d.response })
    this.track(TrackingEvents.DO_YOU_HAVE_EMAIL, { body: d.response ? "Yes" : "No" })
    if (d.response) {
      d.state.canSendEmail = true
      return { nextStep: this.askEmail }
    }
    d.state.canSendEmail = false
    return { nextStep: this.askBirthday }
  }

  @step.logState
  askEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type your email address",
      prompt: { id: this.getPromptId("askEmail"), type: "email" },
      nextStep: this.handleEmail
    }
  }

  @step.logStateAndResponse
  handleEmail(d: IStepData<State, string>): IStepResult {
    const isValid = isEmail(d.response)
    if (!isValid) {
      return {
        body: "Sorry this is not a valid email address. Let's try again",
        nextStep: this.askEmail
      }
    }
    d.state.email = d.response
    return { nextStep: this.askBirthday }
  }

  @step.logState
  askBirthday(_d: IStepData<State>): IStepResult {
    return {
      body: ["Okay, and just for my records...", "What's your date of birth?"],
      prompt: {
        id: this.getPromptId("askBirthday"),
        trackResponse: true,
        type: "date"
      },
      nextStep: this.handleBirthday
    }
  }

  @step.logState
  sayPleaseGiveABirthday(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your date of birth",
      prompt: {
        id: this.getPromptId("sayPleaseGiveABirthday"),
        trackResponse: true,
        type: "date"
      },
      nextStep: this.handleBirthday
    }
  }

  @step
  handleBirthday(d: IStepData<State, number>): IStepResult {
    try {
      const date = moment(d.response)
      invariant(date, "Date object is falsy")
      invariant(date.isValid(), "Date is not valid")
      invariant(date.isBefore(moment()), "Birth date cannot be in the future")
      invariant(date.isAfter(moment("1899-12-31")), "Birth date cannot be before 1900")
      const birthday = date.toDate().getTime()
      d.state.birthday = birthday
      this.referralStore.setCustomField<State>("birthday", birthday)
      const age = moment().diff(date, "years")
      this.setPeople({ age })
      this.track(String(age))
    } catch (e) {
      this.logException(e, "handleBirthday")
      return {
        body: "I'm sorry that's not a valid date",
        nextStep: this.sayPleaseGiveABirthday
      }
    }
    return {
      body: "Thanks for sharing",
      nextStep: this.askGender // WHICH STEP?
    }
  }

  @step
  askGender(_d: IStepData<State>): IStepResult {
    const genders = this.getGenders()
    return {
      body: "Which gender do you identify as?",
      prompt: {
        id: this.getPromptId("askGender"),
        trackResponse: true,
        type: "inlinePicker",
        choices: genders.map(g => ({ body: g, value: g })),
        dataPointsName: "askGender"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleGender
    }
  }

  @step.logStateAndResponse
  async handleGender(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.gender = d.response
    this.referralStore.setCustomField<State>("gender", d.response)
    this.setPeople({ gender: d.response })
    this.track(d.response)
    return { nextStep: this.askEthnicity }
  }

  @step.logState
  askEthnicity(_d: IStepData<State>): IStepResult {
    const ethnicities = this.getEthnicities()

    return {
      body: "Can you tell me which ethnicity you identify as?",
      prompt: {
        id: this.getPromptId("askEthnicity"),
        trackResponse: true,
        type: "inlinePicker",
        choices: ethnicities.map(e => ({ body: e, value: e })),
        dataPointsName: "askEthnicity"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleEthnicity
    }
  }

  @step.logStateAndResponse
  async handleEthnicity(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.ethnicity = d.response
    this.referralStore.setCustomField<State>("ethnicity", d.response)
    this.setPeople({ ethnicity: d.response })
    this.track(d.response)
    return { nextStep: this.askSexuality }
  }

  @step.logState
  askSexuality(_d: IStepData<State>): IStepResult {
    const sexualities = this.getSexualities()

    return {
      body: "How would you describe your sexuality?",
      prompt: {
        id: this.getPromptId("askSexuality"),
        trackResponse: true,
        type: "inlinePicker",
        choices: sexualities.map(g => ({ body: this.getChoiceBody(g), value: g })),
        dataPointsName: "askSexuality"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleSexuality
    }
  }

  @step.logStateAndResponse
  async handleSexuality(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.sexuality = d.response
    this.referralStore.setCustomField<State>("sexuality", d.response)
    this.setPeople({ sexuality: d.response })
    this.track(d.response)
    return { nextStep: this.startAssessment }
  }

  @step.logState
  startAssessment(_d: IStepData<State>): IStepResult {
    return { body: "Okay", nextStep: this.sayLetsGetStarted }
  }

  /** Generic Handlers */

  async onFinishAssessment(state: State): Promise<IStepResult> {
    const wellbeingHubEmails = this.rootStore.configStore.wellbeingHubEmails ?? []
    // noinspection ES6MissingAwait - ⚠️ this is done on purpose as a FnF call
    void this.submitEmail(state, wellbeingHubEmails)
    const clinicalPath = this.clinicalStore.clinicalPath
    const clinicalGroup = this.clinicalStore.clinicalPath?.clinicalGroup
    this.track(clinicalPath?.defaultReferralType)
    this.track(clinicalGroup)
    return { nextStep: this.end }
  }

  getChoiceBody(text: string): string {
    return (
      {
        "Not stated (Person asked but declined to provide a response)": "Prefer not to say",
        "Person asked and does not know or is not sure": "Not sure",
        "Unknown (Person asked and does not know or isn't sure)": "Not sure",
        "Patient Religion Unknown": "Not sure",
        "(None)": "None"
      }[text] || text
    )
  }

  getEthnicities(): string[] {
    return [
      "White - British",
      "White - Irish",
      "White - Any other White background",
      "Mixed - White and Black Caribbean",
      "Mixed - White and Black African",
      "Mixed - White and Asian",
      "Mixed - Any other mixed background",
      "Asian or Asian British - Indian",
      "Asian or Asian British - Pakistani",
      "Asian or Asian British - Bangladeshi",
      "Asian or Asian British - Any other Asian background",
      "Black or Black British - Caribbean",
      "Black or Black British - African",
      "Black or Black British - Any other Black background",
      "Other Ethnic Groups - Chinese",
      "Other Ethnic Groups - Any other ethnic group",
      "Not Stated - Not Stated",
      "Not known - Not known"
    ]
  }

  getGenders(): string[] {
    return ["Male", "Female", "Not known", "Not specified"]
  }

  getSexualities(): string[] {
    return [
      "Heterosexual",
      "Female homosexual",
      "Male homosexual",
      "Bisexual",
      "Sexually attracted to neither male nor female sex",
      "Not stated (Person asked but declined to provide a response)",
      "Unknown",
      "Person asked and does not know or is not sure"
    ]
  }

  getReferralSubmittedIndicatorHTML(): undefined {
    // we never send the referral to the service
    // so on reason to add the indicator
    return undefined
  }
}

/* istanbul ignore next */
export default class AssessmentCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentCCG
  readonly name: string = "AssessmentCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentCCGDialogue.id, new AssessmentCCGScript(), state, snapshot)
  }
}
