import React from "react"
import moment from "moment"
import "./DateInput.scss"
import TextInput from "./TextInput"

interface Props {
  placeholder?: string
  onSubmit: (body: string, value: number) => void
}

export default function DateInput(props: Props): JSX.Element {
  const { placeholder, onSubmit } = props
  const [date, setDate] = React.useState<string | undefined>("")
  const [error, setError] = React.useState<string | undefined>()

  const updateDate = React.useCallback((text?: string) => {
    setDate(text)
    setError(undefined)
  }, [])

  const submit = React.useCallback(() => {
    if (!date) {
      setError("Input can't be empty")
      return
    }
    const formattedDate = date?.match?.(/(\d+)/gi)?.join?.("/") || ""
    const rawFormattedDate = formattedDate.replace(/[^a-zA-Z0-9]/g, "")
    const d = moment(formattedDate, "DD/MM/YYYY")
    if (rawFormattedDate.length !== 8) {
      setError("Please use DD/MM/YYYY (eg. 01/01/2001)")
      return
    }
    if (!d.isValid()) {
      setError("This is not a valid date")
      return
    }
    onSubmit?.(d.format("DD MMM YYYY"), d.toDate().getTime())
  }, [date, onSubmit])
  return (
    <>
      {!!error && (
        <div className="lb-date-input-error-container">
          <span className="lb-date-input-error">{error}</span>
        </div>
      )}
      <TextInput
        forceValue
        borderless
        value={date}
        placeholder={placeholder || "DD-MM-YYYY"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoCapitalize={"none"}
        autoComplete={"birthday"}
        onChangeText={updateDate}
        onSubmit={submit}
      />
    </>
  )
}
