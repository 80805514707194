import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState

export class CrisisCCGScript extends CrisisScript {
  readonly name: string = "CrisisCCGScript"

  /** Script Steps */

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "However, this is not an emergency response service",
        `I'll make sure to notify ${organisationName} of this in the referral`,
        "Ok, let's carry on with the mental health check in"
      ],
      nextStep: this.end
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getReferralSubmittedIndicatorHTML(): undefined {
    // we never send the referral to the service
    // so on reason to add the indicator
    return undefined
  }
}

export default class CrisisCCGDialogue extends AdHocDialogue<State, CrisisCCGScript> {
  static id = DialogueIDs.CrisisCCG
  readonly name: string = "CrisisCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisCCGDialogue.id, new CrisisCCGScript(), state, snapshot)
  }
}
