import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameIAPTScript } from "./GetNameIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { GetNameScriptState } from "./GetNameScript"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = GetNameScriptState
export type GetNameHealixScriptState = State

export class GetNameHealixScript extends GetNameIAPTScript {
  readonly name: string = "GetNameHealixScript"

  /** Script Steps */

  @step
  step1(d: IStepData<State>): IStepResult {
    const hasName = d.state.name && d.state.username
    const { directReferral } = this.rootStore.configStore
    // NOTE: I am not sure the following makes any sense
    // const body = directReferral
    //   ? "I'm now going to create a patient record for you"
    //   : "Ok, I'm just going to create a patient record for you"
    const prompt = directReferral
      ? ({
          type: "inlinePicker",
          choices: [{ body: "Okay" }]
        } as IInlinePickerSingleSelectPrompt)
      : undefined
    return {
      // body,
      prompt,
      nextStep: hasName ? this.end : this.askFullName
    }
  }
}

export default class GetNameHealixDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameHealix
  readonly name: string = "GetNameHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameHealixDialogue.id, new GetNameHealixScript(), state, snapshot)
  }
}
