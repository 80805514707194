import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = BaseScriptState
export type PermissionsScriptState = State

export abstract class PermissionsScript extends BaseScript<State> {
  /** Script Steps */

  abstract step1(d: IStepData<State>): IStepResult
  abstract handleIUnderstand(d: IStepData<State>): IStepResult

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""
    return {
      body: [
        "You can find all the details here:\n\n" +
          "Limbic [Terms of Service](https://limbic.ai/tos)\n" +
          "Limbic [Privacy Policy](https://limbic.ai/pp)\n" +
          organisationTerms
      ],
      nextStep: this.promptIUnderstand
    }
  }

  @step.logState
  promptIUnderstand(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("privacyLinks"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleIUnderstand
    }
  }
}
