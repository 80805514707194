import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { EligibilityCheckIAPTScript } from "./EligibilityCheckIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import getIAPTById from "../../../utils/getIAPTById"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = EligibilityCheckIAPTState
export type EligibilityCheckGPState = State

export class EligibilityCheckGPScript extends EligibilityCheckIAPTScript {
  readonly name: string = "EligibilityCheckGPScript"

  /** Script Steps */

  @step.logState
  checkAgeThresholds(d: IStepData<State>): IStepResult {
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    if (!eligibleIAPTs.length) {
      this.setUnderAged(d.state, true)
      return { nextStep: this.checkEligibility } // basically skipping checking for under aged again
    }
    return { nextStep: this.askAreYouRegisteredWithCurrentGP }
  }

  @step.logState
  askAreYouRegisteredWithCurrentGP(_d: IStepData<State>): IStepResult {
    const gpService = this.rootStore.configStore.gpService
    const gpServiceName = gpService?.formattedName ?? gpService?.name
    if (!gpService?.formattedName) {
      return { nextStep: this.askYourAreaOrGPPostCode }
    }
    return {
      body: `Are you registered with ${gpServiceName}?`,
      prompt: {
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleAreYouRegisteredWithCurrentGP
    }
  }

  @step.logState
  handleAreYouRegisteredWithCurrentGP(d: IStepData<State, boolean>): IStepResult {
    const gpService = this.rootStore.configStore.gpService
    const eligibleIAPT = getIAPTById(gpService?.eligibleIAPT)
    if (gpService?.name && d.response && eligibleIAPT) {
      d.state.gpPostcode = gpService.postcode
      this.setGP(d.state, { ...gpService, postcode: gpService.postcode.postcode })
      this.setIAPT(d.state, eligibleIAPT)
      this.setIAPTSuggestions(d.state, [])
      return { nextStep: this.checkUnderAgedForIAPT }
    }
    return { nextStep: this.askYourAreaOrGPPostCode }
  }
}

export default class EligibilityCheckGPDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckGP
  readonly name: string = "EligibilityCheckGPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckGPDialogue.id, new EligibilityCheckGPScript(), state, snapshot)
  }
}
