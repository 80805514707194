import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { KeepingSafeLeafletViaStatus, RiskLevelReason } from "../../../models/Constants"
import getNextWorkingDay from "../../../utils/getNextWorkingDay"
import isValidPhoneNumber from "../../../utils/isvalidPhoneNumber"
import sendEmail from "../../../backend/api/sendEmail"
import sendRiskEmail from "../../../backend/api/sendRiskEmail"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"
import type { ISurveyResponse } from "../../../models/ISurvey"
import type {
  IInlinePickerSingleSelectPrompt,
  ISliderPrompt
} from "../../../backend/chatbot/models/IPrompt"
import { SendEmailStatus } from "../../../models/ISendEmail"

const shouldSendKeepingSafe = process.env.REACT_APP_SHOULD_SEND_KEEPING_SAFE === "enabled"

interface State extends BaseScriptState {
  keepingSafeSent?: boolean
}

export type RiskPathwayScriptState = State

export abstract class RiskPathwayScript extends BaseScript<State> {
  /** Optional Abstract Generic Handlers */

  onSaveRiskLevelAndReferralType?(state: State): Promise<IStepResult>
  getClosingMessage?(state: State): StepResultBodyType[] | undefined
  getKeepingSafeEmailSubject?(state: State): string
  getKeepingSafeAttachmentUrls?(state: State): string[]

  /** Script Steps */

  async start(d: IStepData<State>): Promise<IStepResult<State>> {
    await this.sendKeepingSafeEmail(d.state)
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askHaveYouMadePlans }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step
  askHaveYouMadePlans(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you made plans to end your life?",
      prompt: {
        id: this.getPromptId("Q9 - madePlansToEndLife"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "Q9 - madePlansToEndLife"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleHaveYouMadePlans
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "10",
    "Have you made plans to end your life?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleHaveYouMadePlans(d: IStepData<State, boolean>): IStepResult {
    const isRisk = this.clinicalStore.isRisk
    if (!isRisk && d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelModerate(d.state, RiskLevelReason.PHQ9_Q9_PLANS)
    }
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.askAnyPreparations }
  }

  @step
  askAnyPreparations(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you made any actual preparations to end your life?",
      prompt: {
        id: this.getPromptId("Q9 - preparations"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "Q9 - preparations"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleAnyPreparations
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "11",
    "Have you made any preparations to end your life?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleAnyPreparations(d: IStepData<State, boolean>): IStepResult {
    const isHighRisk = this.clinicalStore.isHighRisk
    if (!isHighRisk && d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_PREPARATIONS)
    }
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.askWillYouAct }
  }

  @step
  askWillYouAct(_d: IStepData<State>): IStepResult {
    return {
      body: "How likely is it that you will act on these thoughts or plans to end your life?",
      prompt: {
        id: this.getPromptId("Q9 - willYouAct"),
        trackResponse: true,
        type: "slider",
        title: "How likely is it that you will act on these thoughts or plans to end your life?",
        min: 1,
        max: 8,
        labels: {
          1: "Never",
          4: "Somewhat",
          8: "Certainly"
        },
        dataPointsName: "Q9 - willYouAct"
      } as ISliderPrompt,
      nextStep: this.handleWillYouAct
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("12", "riskPathwayResponses")
  handleWillYouAct(d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(1)
    const isRisk = this.clinicalStore.isRisk
    if (!isRisk && [3, 4, 5].includes(d.response.points!)) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelModerate(d.state, RiskLevelReason.PHQ9_Q9_WILL_U_ACT_SOMEWHAT)
    }
    const isHighRisk = this.clinicalStore.isHighRisk
    if (!isHighRisk && d.response.points! > 5) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_WILL_U_ACT_HIGHLY)
    }
    if (d.response.points! >= 3) {
      return { nextStep: this.askWhatStopsYou }
    }
    return { nextStep: this.askAreYouInRiskFromSomeoneElse }
  }

  @step.logState
  askWhatStopsYou(_d: IStepData<State>): IStepResult {
    return {
      body: "And what stops you from acting on these feelings?",
      prompt: {
        type: "text",
        id: this.getPromptId("askWhatStopsYou"),
        forceValue: true,
        dataPointsName: "askWhatStopsYou"
      },
      nextStep: this.handleWhatStopsYou
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "13",
    "And what stops you from acting on these feelings?",
    "riskPathwayResponses"
  )
  handleWhatStopsYou(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askAreYouInRiskFromSomeoneElse }
  }

  @step.logState
  askAreYouInRiskFromSomeoneElse(_d: IStepData<State>): IStepResult {
    return {
      body: "Finally, do you feel that you are at risk from anyone else?",
      prompt: {
        type: "inlinePicker",
        id: this.getPromptId("askAreYouInRiskFromSomeoneElse"),
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "Q9 - askAreYouInRiskFromSomeoneElse"
      },
      nextStep: this.handleAreYouInRiskFromSomeoneElse
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "14",
    "Do you feel that you are at risk from anyone else?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleAreYouInRiskFromSomeoneElse(d: IStepData<State, boolean>): IStepResult {
    const isHighRisk = this.clinicalStore.isHighRisk
    if (!isHighRisk && d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_RISK_FROM_SOMEONE_ELSE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async saveRiskLevelAndReferralType(d: IStepData<State>): Promise<IStepResult> {
    const riskLevel = this.clinicalStore.riskLevel
    const riskLevelReason = this.clinicalStore.riskLevelReason
    const canKeepSelfSafe = d.state.canKeepSelfSafe ?? true
    void this.referralStore.updateReferral({ riskLevel, riskLevelReason, canKeepSelfSafe })
    this.updateReferralType(d.state)

    const result = await this.onSaveRiskLevelAndReferralType?.(d.state)
    if (result) return result
    return { nextStep: this.sayThanksForSharing }
  }

  @step.logState
  sayThanksForSharing(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const body = [`Thanks for sharing, ${name}`, "I've made a note of that"]
    if (d.state.keepingSafeSent) {
      body.push(
        "I've also sent you some useful material over email. " +
          "If you don't see this in your inbox, maybe check your spam folder"
      )
    }
    const isRisk = this.getIsRisk(d.state)
    return {
      body,
      prompt: {
        type: "inlinePicker",
        choices: [{ body: "Thanks" }, { body: "Okay" }],
        isUndoAble: false
      } as IInlinePickerSingleSelectPrompt,
      nextStep: isRisk ? this.sayRiskExplanation : this.end
    }
  }

  @step
  sayRiskExplanation(d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(1)
    return {
      body: [
        "It sounds like you have been experiencing symptoms of depression, including thoughts of hurting yourself, or of being better off dead",
        "It is normal for people to have thoughts of this nature at times when we feel down",
        "It may be nothing to worry about",
        "However, your safety is my number one priority. And this is not an emergency response service.",
        "I'm therefore going to ask someone from our clinical team to ring you to discuss this matter with you directly"
      ],
      nextStep: d.state.phoneNumber ? this.theyllCallYou : this.askPhoneNumber
    }
  }

  @step
  async theyllCallYou(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.phoneNumber) {
      return { nextStep: this.askPhoneNumber }
    }

    const nextWorkingDay = await getNextWorkingDay()
    return {
      body: `They'll call you ${nextWorkingDay} on the number you provided earlier (${d.state.phoneNumber})`,
      prompt: {
        id: this.getPromptId("theyllCallYou"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: true },
          { body: "I understand", value: true },
          { body: "Change number", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleTheyllCallYou
    }
  }

  @step.logStateAndResponse
  handleTheyllCallYou(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.informRiskToService }
    }
    return { nextStep: this.askPhoneNumber }
  }

  @step.logState
  askPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "What's the best phone number to call you on?",
      prompt: {
        id: this.getPromptId("askPhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step.logState
  async handlePhoneNumber(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      return {
        body: "Sorry this is not a valid phone number. Let's try again",
        nextStep: this.askPhoneNumber
      }
    }
    d.state.phoneNumber = d.response
    const nextWorkingDay = await getNextWorkingDay()
    return {
      body: [
        "Thanks",
        `Someone from our clinical team will call you ${nextWorkingDay} on (${d.state.phoneNumber})`
      ],
      nextStep: this.informRiskToService
    }
  }

  @step.logState
  async informRiskToService(d: IStepData<State>): Promise<IStepResult> {
    await this.sendRiskEmailToService(d.state)
    return { nextStep: this.sayInstructions }
  }

  @step
  async sayInstructions(_d: IStepData<State>): Promise<IStepResult> {
    return {
      body: [
        "In the meantime, if you feel that you or someone you know is in danger, please call 999 immediately",
        "Further support is also provided by the Samaritans, available anytime by calling: 116 123"
      ],
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "I understand" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleInstructions
    }
  }

  @step.logState
  handleInstructions(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999 and The Samaritans")
    return { nextStep: this.closeDialogue }
  }

  @step.logState
  closeDialogue(d: IStepData<State>): IStepResult {
    const body = this.getClosingMessage?.(d.state)
    return { body, nextStep: this.end }
  }

  /** Generic Handlers */

  getIsRisk(_state: State): boolean {
    // this handler looks unnecessary, but it's here
    // in case a service needs to overwrite it
    return this.clinicalStore.isRisk
  }

  async sendKeepingSafeEmail(state: State): Promise<void> {
    try {
      if (shouldSendKeepingSafe && !state.email) {
        state.keepingSafeSent = false
        await this.referralStore.updateReferral({
          keepingSafeLeafletStatus: KeepingSafeLeafletViaStatus.NO_RECIPIENT
        })
      }
      if (shouldSendKeepingSafe && state.email) {
        const logo = this.rootStore.configStore.keepingSafeLogo
        const title = this.rootStore.configStore.keepingSafeTitle
        const subject = this.getKeepingSafeEmailSubject?.(state)
        const keepingSafeEmail = this.createKeepingSafeLeafletEmail(title, logo)
        const attachmentUrls = this.getKeepingSafeAttachmentUrls?.(state) || undefined
        const organisationName = this.rootStore.configStore.organisationName
        const response: SendEmailStatus = await sendEmail(
          {
            // TODO: New email endpoints always need a subject
            // Adding a fallback here just in case 👇
            subject: subject ?? "Limbic | Therapy Assistant",
            to: [state.email],
            text: keepingSafeEmail,
            attachmentUrls
          },
          organisationName
        )
        const hasKeepingSafeBeenSent = response === SendEmailStatus.Success
        state.keepingSafeSent = hasKeepingSafeBeenSent
        const keepingSafeLeafletStatus = hasKeepingSafeBeenSent
          ? KeepingSafeLeafletViaStatus.SENT
          : KeepingSafeLeafletViaStatus.FAILED
        await this.referralStore.updateReferral({
          keepingSafeLeafletVia: "EMAIL",
          keepingSafeLeafletStatus
        })
      }
    } catch (e) {
      this.logException(e, "sendKeepingSafe")
    }
  }

  async sendRiskEmailToService(state: State): Promise<void> {
    try {
      if (!this.clinicalStore.isRisk) return
      const emails = state.iapt?.riskEmails || []
      const ccEmails = state.iapt?.riskEmailsCC || []
      const bccEmails = state.iapt?.riskEmailsBCC || []
      if (emails?.length) {
        const text = this.createReferralEmail(state, true)
        await sendRiskEmail({
          to: emails,
          cc: ccEmails.length ? ccEmails : undefined, // This needs to be undefined in case of empty array because mailgun is stupid
          bcc: bccEmails.length ? bccEmails : undefined, // This needs to be undefined in case of empty array because mailgun is stupid
          text
        })
      }
    } catch (e) {
      this.logException(e, "sendRiskEmailToService")
    }
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang='en'>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Limbic Therapy Assistant | Keeping Safe</title>
    <style type="text/css">
      #outlook a {padding: 0;}

      body {
        width: 100% !important;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        margin: 0;
        padding: 0;
        font-family: sans-serif;
      }

      .ExternalClass {width: 100%;}
      .ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div {line-height: 100%;}

      #backgroundTable {
        margin: 0;
        padding: 0;
        width: 100% !important;
        line-height: 100% !important;
      }

      #contentTable {
        margin: 64px auto;
        max-width: 800px !important;
        line-height: 100% !important;
      }

      img {
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
      }

      a img {border: none;}
      .image_fix {display: block;}
      p {margin: 1em 0;}

      h1,h2,h3,h4,h5,h6 {color: black !important;}
      h1 a,h2 a,h3 a,h4 a,h5 a,h6 a {color: blue !important;}
      h1 a:active,h2 a:active,h3 a:active,h4 a:active,h5 a:active,h6 a:active {color: red !important;}
      h1 a:visited,h2 a:visited,h3 a:visited,h4 a:visited,h5 a:visited,h6 a:visited {color: purple !important;}

      table td {
        padding-left: 24px;
        padding-right: 24px;
        border-collapse: collapse;
      }

      table {
        border-collapse: collapse;
        mso-table-lspace: 0;
        mso-table-rspace: 0;
      }

      a {color: orange;}
      h1,h2,h3,a {color: #4AA4CB !important;}
      h1 {text-align: center;}
      .red {color: red;}
      
      .blue {
        color: #4AA4CB !important;
        font-weight: bold;
      }

      .big {
        font-size: 21px;
        font-weight: bold;
      }

    </style>
    <!--[if IEMobile 7]>
	<style type="text/css">
		/* Targeting Windows Mobile */
	</style>
	<![endif]-->
    <!--[if gte mso 9]>
	<style>
		/* Target Outlook 2007 and 2010 */
	</style>
	<![endif]-->
  </head>

  <body>
    <table cellpadding="0" cellspacing="0" border="0" id="backgroundTable">
      <tr>
        <td>
          <table cellpadding="0" cellspacing="0" border="0" id="contentTable">
            <tr>
              <td><img class="image_fix" src="${logo}" alt="${title}" title="${title}" width="x" height="x" /><br /><br /></td>
            </tr>
            <tr>
              <td>
                <h1>Keeping Safe</h1>
                It is common for people experiencing emotional distress to have thoughts of harming themselves or that they would be better off dead.<br /><br />
                If you are feeling concerned about your safety, please do speak with your practitioner, course facilitator or make an appointment with your GP.
                You will be asked to complete a questionnaire (PHQ9) prior to assessment and during treatment. If you have scored a 2 or 3 on question 9 of the questionnaire, this may indicate that you need more support.<br /><br />
                We have a duty of care to keep people safe. This is a duty we share with patients and GPs. To share this duty, it is important that we keep GPs informed. Therefore, we inform GPs when patients score highly on PHQ9.
              </td>
            </tr>
            <tr>
              <td>
                <h3>Remember suicide is preventable. You are not alone and can get help now.</h3>
                <h4>"Suicide is not chosen; it happens when pain exceeds resources for coping with pain."</h4>
                You are not a bad person, weak, or flawed because you feel suicidal, experience suicidal thoughts or thoughts of being better off dead.
                It doesn't even mean that you really want to die – it only means that you have more pain than you can cope with right now.
              </td>
            </tr>
            <tr>
              <td>
                <h3>How can I help myself?</h3>
                If you are feeling suicidal don't try to cope alone. Sometimes problems seem impossible to manage or the mental anguish is unbearable.<br /><br />
                <span class="blue">1.</span>&nbsp;&nbsp;&nbsp;You have made the first step by telling your doctor or a health professional. They will have discussed treatments and options with you. If you have been given antidepressants, remember that they take two weeks to start working, and then gradually.<br /><br />
                <span class="blue">2.</span>&nbsp;&nbsp;&nbsp;Please use any self-help information your practitioner has provided you with.<br /><br />
                <span class="blue">3.</span>&nbsp;&nbsp;&nbsp;Try and tell your friends and family, who will support you by spending time with you. Talking to a family member or a friend or a colleague can bring huge relief.<br /><br />
                <span class="blue">4.</span>&nbsp;&nbsp;&nbsp;Try to avoid long periods of time on your own, especially if you just sit and dwell on things.<br /><br />
                <span class="blue">5.</span>&nbsp;&nbsp;&nbsp;Plan your day and set small, easy to achieve tasks. This will keep you occupied and give a sense of achievement.<br /><br />
                <span class="blue">6.</span>&nbsp;&nbsp;&nbsp;You must try and eat, at best little and often and try to drink up to two litres of water each day.<br /><br />
                <span class="blue">7.</span>&nbsp;&nbsp;&nbsp;Avoid alcohol and non-prescription drugs.<br /><br />
                <span class="blue">8.</span>&nbsp;&nbsp;&nbsp;Get someone to help you clear out old medicines and anything harmful when you find yourself dwelling on this.<br /><br />
                <span class="blue">9.</span>&nbsp;&nbsp;&nbsp;Try to distract yourself by phoning a friend, going out, reading a magazine, etc.<br /><br />
                <span class="blue">10.</span>&nbsp;&nbsp;Exercise can make you feel better, at least 30 mins a day.<br /><br />
                <span class="blue">11.</span>&nbsp;&nbsp;Just try and be kind to yourself. It will pass, don’t be afraid of how you feel, try and be brave and keep safe.<br /><br />
              </td>
            </tr>
            <tr>
              <td>
                <h3>When it feels really bad, or when you find things building up RING:</h3>
                <b>Samaritans</b> To speak to a Samaritan volunteer anytime day or night call <b>116 123</b>. Calls on this number are automatically sent to the nearest free line, which could be your local branch or another.<br />
                <a href="www.samaritans.org">www.samaritans.org</a><br /><br />
                <b>Breathing Space</b> on <b>0800 83 85 87</b> between 6pm and 2am on weekdays (Monday to Thursday) and 24 hours at weekends (6pm Friday to 6am Monday). Remember your call is confidential and free.<br />
                <a href="www.breathingspacescotland.co.uk">www.breathingspacescotland.co.uk</a><br /><br />
                Campaign Against Living Miserably (CALM) For young men who are feeling unhappy. As well as the website,<br />
                CALM also has a helpline <b>0800 58 58 58</b><br /><br />
                Get Connected: 0800 8084994 line open 1pm – 11pm daily (help line for young people up to age of 25yrs).
                <a href="www.getconnected.org.uk">www.getconnected.org.uk</a><br /><br />
                <b>Papyrus</b> prevention of young suicide. Hope line UK: <b>0800 068 41 41</b>.<br /><br />
              </td>
            </tr>
            <tr>
              <td>
                <h3>Are feelings of suicide common?</h3>
                Most people at some point in their lives will have a suicidal thought but for the majority this will be a fleeting or at least short-lived experience.
              </td>
            </tr>
            <tr>
              <td>
                <h3>When do suicidal thoughts become problematic?</h3>
                Thoughts of suicide should always be taken seriously but if these thoughts are persistent, occur frequently, are strong and for the individual there appears to be no alternative, immediate action should be taken to get support and help.<br /><br /><br /><br />
              </td>
            </tr>
            <tr>
              <td>
                <span class="big red">Contact emergency services</span> – are you at high risk at this time of killing yourself? Do you have a plan and the means to complete suicide?<br /><br /><br /><br />
                <span class="big">If so,</span> <span class="big red">call 999 RIGHT NOW.</span><br /><br /><br /><br />
                It's okay to feel the way you are feeling now and there are people around who can help you.<br />
                When you are feeling suicidal talk to someone immediately.
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>

</html>`
  }
}
