import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

// NOTE: List is here:
// https://docs.google.com/spreadsheets/d/1ATFldEHXfScgJkJ8qxnIIe98i9DKBolI/edit#gid=1638925846

const iaptGPsMapInsightMedway: IAPTGPMap = {
  G82679: IAPTIDs.INSIGHT_MEDWAY,
  G82161: IAPTIDs.INSIGHT_MEDWAY,
  G82123: IAPTIDs.INSIGHT_MEDWAY,
  G82233: IAPTIDs.INSIGHT_MEDWAY,
  G82711: IAPTIDs.INSIGHT_MEDWAY,
  G82653: IAPTIDs.INSIGHT_MEDWAY,
  G82706: IAPTIDs.INSIGHT_MEDWAY,
  G82676: IAPTIDs.INSIGHT_MEDWAY,
  G82051: IAPTIDs.INSIGHT_MEDWAY,
  Y00449: IAPTIDs.INSIGHT_MEDWAY,
  G82203: IAPTIDs.INSIGHT_MEDWAY,
  G82775: IAPTIDs.INSIGHT_MEDWAY,
  G82600: IAPTIDs.INSIGHT_MEDWAY,
  V81998: IAPTIDs.INSIGHT_MEDWAY,
  G82198: IAPTIDs.INSIGHT_MEDWAY,
  G82100: IAPTIDs.INSIGHT_MEDWAY,
  G82108: IAPTIDs.INSIGHT_MEDWAY,
  G82139: IAPTIDs.INSIGHT_MEDWAY,
  G82180: IAPTIDs.INSIGHT_MEDWAY,
  G82737: IAPTIDs.INSIGHT_MEDWAY,
  G82708: IAPTIDs.INSIGHT_MEDWAY,
  G82763: IAPTIDs.INSIGHT_MEDWAY,
  G82719: IAPTIDs.INSIGHT_MEDWAY,
  G82721: IAPTIDs.INSIGHT_MEDWAY,
  G82741: IAPTIDs.INSIGHT_MEDWAY,
  G82635: IAPTIDs.INSIGHT_MEDWAY,
  G82704: IAPTIDs.INSIGHT_MEDWAY,
  G82162: IAPTIDs.INSIGHT_MEDWAY,
  G82129: IAPTIDs.INSIGHT_MEDWAY,
  G82744: IAPTIDs.INSIGHT_MEDWAY,
  G82753: IAPTIDs.INSIGHT_MEDWAY,
  G82109: IAPTIDs.INSIGHT_MEDWAY,
  G82622: IAPTIDs.INSIGHT_MEDWAY,
  G82095: IAPTIDs.INSIGHT_MEDWAY,
  G82820: IAPTIDs.INSIGHT_MEDWAY,
  G82762: IAPTIDs.INSIGHT_MEDWAY,
  G82106: IAPTIDs.INSIGHT_MEDWAY,
  G82113: IAPTIDs.INSIGHT_MEDWAY,
  G82154: IAPTIDs.INSIGHT_MEDWAY,
  G82697: IAPTIDs.INSIGHT_MEDWAY,
  G82077: IAPTIDs.INSIGHT_MEDWAY,
  G82184: IAPTIDs.INSIGHT_MEDWAY,
  G82226: IAPTIDs.INSIGHT_MEDWAY,
  G82014: IAPTIDs.INSIGHT_MEDWAY
}

const iaptInsightGPsMapKent: IAPTGPMap = {
  G82658: IAPTIDs.INSIGHT_KENT,
  G82080: IAPTIDs.INSIGHT_KENT,
  G82730: IAPTIDs.INSIGHT_KENT,
  G82050: IAPTIDs.INSIGHT_KENT,
  G82142: IAPTIDs.INSIGHT_KENT,
  G82186: IAPTIDs.INSIGHT_KENT,
  G82053: IAPTIDs.INSIGHT_KENT,
  G82114: IAPTIDs.INSIGHT_KENT,
  G82049: IAPTIDs.INSIGHT_KENT,
  G82087: IAPTIDs.INSIGHT_KENT,
  G82094: IAPTIDs.INSIGHT_KENT,
  V81998: IAPTIDs.INSIGHT_KENT,
  G82119: IAPTIDs.INSIGHT_KENT,
  G82071: IAPTIDs.INSIGHT_KENT,
  G82115: IAPTIDs.INSIGHT_KENT,
  G82090: IAPTIDs.INSIGHT_KENT,
  G82082: IAPTIDs.INSIGHT_KENT,
  G82060: IAPTIDs.INSIGHT_KENT,
  G82039: IAPTIDs.INSIGHT_KENT,
  GP82228: IAPTIDs.INSIGHT_KENT,
  G82063: IAPTIDs.INSIGHT_KENT,
  GP2090: IAPTIDs.INSIGHT_KENT,
  G82138: IAPTIDs.INSIGHT_KENT,
  G82140: IAPTIDs.INSIGHT_KENT,
  G82802: IAPTIDs.INSIGHT_KENT,
  G82790: IAPTIDs.INSIGHT_KENT,
  G82027: IAPTIDs.INSIGHT_KENT,
  G82228: IAPTIDs.INSIGHT_KENT,
  G82639: IAPTIDs.INSIGHT_KENT,
  G82647: IAPTIDs.INSIGHT_KENT,
  G82044: IAPTIDs.INSIGHT_KENT,
  G82809: IAPTIDs.INSIGHT_KENT,
  G82225: IAPTIDs.INSIGHT_KENT,
  GP2028: IAPTIDs.INSIGHT_KENT,
  G82780: IAPTIDs.INSIGHT_KENT,
  G82648: IAPTIDs.INSIGHT_KENT,
  G82690: IAPTIDs.INSIGHT_KENT,
  G82028: IAPTIDs.INSIGHT_KENT,
  G82032: IAPTIDs.INSIGHT_KENT,
  G82021: IAPTIDs.INSIGHT_KENT,
  G82006: IAPTIDs.INSIGHT_KENT,
  G82808: IAPTIDs.INSIGHT_KENT,
  G82185: IAPTIDs.INSIGHT_KENT,
  G82143: IAPTIDs.INSIGHT_KENT,
  G82212: IAPTIDs.INSIGHT_KENT,
  G82218: IAPTIDs.INSIGHT_KENT,
  G82048: IAPTIDs.INSIGHT_KENT,
  G82122: IAPTIDs.INSIGHT_KENT,
  G82062: IAPTIDs.INSIGHT_KENT,
  G82067: IAPTIDs.INSIGHT_KENT,
  G82073: IAPTIDs.INSIGHT_KENT,
  G82088: IAPTIDs.INSIGHT_KENT,
  G82097: IAPTIDs.INSIGHT_KENT,
  G82121: IAPTIDs.INSIGHT_KENT,
  G82128: IAPTIDs.INSIGHT_KENT,
  G82117: IAPTIDs.INSIGHT_KENT,
  G82086: IAPTIDs.INSIGHT_KENT,
  G82091: IAPTIDs.INSIGHT_KENT,
  G82111: IAPTIDs.INSIGHT_KENT,
  G82072: IAPTIDs.INSIGHT_KENT,
  G82069: IAPTIDs.INSIGHT_KENT,
  G82217: IAPTIDs.INSIGHT_KENT,
  G82038: IAPTIDs.INSIGHT_KENT,
  G82147: IAPTIDs.INSIGHT_KENT,
  G82211: IAPTIDs.INSIGHT_KENT,
  G82165: IAPTIDs.INSIGHT_KENT,
  G82160: IAPTIDs.INSIGHT_KENT,
  G82036: IAPTIDs.INSIGHT_KENT,
  G82002: IAPTIDs.INSIGHT_KENT,
  GP2038: IAPTIDs.INSIGHT_KENT,
  G82731: IAPTIDs.INSIGHT_KENT,
  G82729: IAPTIDs.INSIGHT_KENT,
  G82665: IAPTIDs.INSIGHT_KENT,
  G82007: IAPTIDs.INSIGHT_KENT,
  G82015: IAPTIDs.INSIGHT_KENT,
  G82018: IAPTIDs.INSIGHT_KENT,
  G82652: IAPTIDs.INSIGHT_KENT,
  G82696: IAPTIDs.INSIGHT_KENT,
  G82700: IAPTIDs.INSIGHT_KENT,
  G82232: IAPTIDs.INSIGHT_KENT,
  G82227: IAPTIDs.INSIGHT_KENT,
  G82684: IAPTIDs.INSIGHT_KENT,
  Y02506: IAPTIDs.INSIGHT_KENT,
  G82687: IAPTIDs.INSIGHT_KENT,
  G82682: IAPTIDs.INSIGHT_KENT,
  G82231: IAPTIDs.INSIGHT_KENT,
  G82671: IAPTIDs.INSIGHT_KENT,
  G82634: IAPTIDs.INSIGHT_KENT,
  G82702: IAPTIDs.INSIGHT_KENT,
  G82698: IAPTIDs.INSIGHT_KENT,
  G82023: IAPTIDs.INSIGHT_KENT,
  G82757: IAPTIDs.INSIGHT_KENT,
  G82693: IAPTIDs.INSIGHT_KENT,
  G82799: IAPTIDs.INSIGHT_KENT,
  G82035: IAPTIDs.INSIGHT_KENT,
  G82026: IAPTIDs.INSIGHT_KENT,
  G82057: IAPTIDs.INSIGHT_KENT,
  G82796: IAPTIDs.INSIGHT_KENT,
  G82126: IAPTIDs.INSIGHT_KENT,
  G82219: IAPTIDs.INSIGHT_KENT,
  G82064: IAPTIDs.INSIGHT_KENT,
  G82066: IAPTIDs.INSIGHT_KENT,
  G82150: IAPTIDs.INSIGHT_KENT,
  G82046: IAPTIDs.INSIGHT_KENT,
  G82052: IAPTIDs.INSIGHT_KENT,
  G82079: IAPTIDs.INSIGHT_KENT,
  G82105: IAPTIDs.INSIGHT_KENT,
  G82107: IAPTIDs.INSIGHT_KENT,
  G82666: IAPTIDs.INSIGHT_KENT,
  G82020: IAPTIDs.INSIGHT_KENT,
  G82650: IAPTIDs.INSIGHT_KENT
}

export const iaptGPMapInsight: IAPTGPMap = {
  ...iaptInsightGPsMapKent,
  ...iaptGPsMapInsightMedway
}

const iaptGPsMapInsightMedwayDEMO: IAPTGPMap = {
  G82679: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82161: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82123: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82233: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82711: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82653: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82706: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82676: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82051: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  Y00449: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82203: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82775: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82600: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  V81998: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82198: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82100: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82108: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82139: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82180: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82737: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82708: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82763: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82719: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82721: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82741: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82635: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82704: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82162: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82129: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82744: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82753: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82109: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82622: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82095: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82820: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82762: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82106: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82113: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82154: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82697: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82077: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82184: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82226: IAPTIDs.INSIGHT_MEDWAY_DEMO,
  G82014: IAPTIDs.INSIGHT_MEDWAY_DEMO
}

const iaptInsightGPsMapKentDEMO: IAPTGPMap = {
  G82658: IAPTIDs.INSIGHT_KENT_DEMO,
  G82080: IAPTIDs.INSIGHT_KENT_DEMO,
  G82730: IAPTIDs.INSIGHT_KENT_DEMO,
  G82050: IAPTIDs.INSIGHT_KENT_DEMO,
  G82142: IAPTIDs.INSIGHT_KENT_DEMO,
  G82186: IAPTIDs.INSIGHT_KENT_DEMO,
  G82053: IAPTIDs.INSIGHT_KENT_DEMO,
  G82114: IAPTIDs.INSIGHT_KENT_DEMO,
  G82049: IAPTIDs.INSIGHT_KENT_DEMO,
  G82087: IAPTIDs.INSIGHT_KENT_DEMO,
  G82094: IAPTIDs.INSIGHT_KENT_DEMO,
  V81998: IAPTIDs.INSIGHT_KENT_DEMO,
  G82119: IAPTIDs.INSIGHT_KENT_DEMO,
  G82071: IAPTIDs.INSIGHT_KENT_DEMO,
  G82115: IAPTIDs.INSIGHT_KENT_DEMO,
  G82090: IAPTIDs.INSIGHT_KENT_DEMO,
  G82082: IAPTIDs.INSIGHT_KENT_DEMO,
  G82060: IAPTIDs.INSIGHT_KENT_DEMO,
  G82039: IAPTIDs.INSIGHT_KENT_DEMO,
  GP82228: IAPTIDs.INSIGHT_KENT_DEMO,
  G82063: IAPTIDs.INSIGHT_KENT_DEMO,
  GP2090: IAPTIDs.INSIGHT_KENT_DEMO,
  G82138: IAPTIDs.INSIGHT_KENT_DEMO,
  G82140: IAPTIDs.INSIGHT_KENT_DEMO,
  G82802: IAPTIDs.INSIGHT_KENT_DEMO,
  G82790: IAPTIDs.INSIGHT_KENT_DEMO,
  G82027: IAPTIDs.INSIGHT_KENT_DEMO,
  G82228: IAPTIDs.INSIGHT_KENT_DEMO,
  G82639: IAPTIDs.INSIGHT_KENT_DEMO,
  G82647: IAPTIDs.INSIGHT_KENT_DEMO,
  G82044: IAPTIDs.INSIGHT_KENT_DEMO,
  G82809: IAPTIDs.INSIGHT_KENT_DEMO,
  G82225: IAPTIDs.INSIGHT_KENT_DEMO,
  GP2028: IAPTIDs.INSIGHT_KENT_DEMO,
  G82780: IAPTIDs.INSIGHT_KENT_DEMO,
  G82648: IAPTIDs.INSIGHT_KENT_DEMO,
  G82690: IAPTIDs.INSIGHT_KENT_DEMO,
  G82028: IAPTIDs.INSIGHT_KENT_DEMO,
  G82032: IAPTIDs.INSIGHT_KENT_DEMO,
  G82021: IAPTIDs.INSIGHT_KENT_DEMO,
  G82006: IAPTIDs.INSIGHT_KENT_DEMO,
  G82808: IAPTIDs.INSIGHT_KENT_DEMO,
  G82185: IAPTIDs.INSIGHT_KENT_DEMO,
  G82143: IAPTIDs.INSIGHT_KENT_DEMO,
  G82212: IAPTIDs.INSIGHT_KENT_DEMO,
  G82218: IAPTIDs.INSIGHT_KENT_DEMO,
  G82048: IAPTIDs.INSIGHT_KENT_DEMO,
  G82122: IAPTIDs.INSIGHT_KENT_DEMO,
  G82062: IAPTIDs.INSIGHT_KENT_DEMO,
  G82067: IAPTIDs.INSIGHT_KENT_DEMO,
  G82073: IAPTIDs.INSIGHT_KENT_DEMO,
  G82088: IAPTIDs.INSIGHT_KENT_DEMO,
  G82097: IAPTIDs.INSIGHT_KENT_DEMO,
  G82121: IAPTIDs.INSIGHT_KENT_DEMO,
  G82128: IAPTIDs.INSIGHT_KENT_DEMO,
  G82117: IAPTIDs.INSIGHT_KENT_DEMO,
  G82086: IAPTIDs.INSIGHT_KENT_DEMO,
  G82091: IAPTIDs.INSIGHT_KENT_DEMO,
  G82111: IAPTIDs.INSIGHT_KENT_DEMO,
  G82072: IAPTIDs.INSIGHT_KENT_DEMO,
  G82069: IAPTIDs.INSIGHT_KENT_DEMO,
  G82217: IAPTIDs.INSIGHT_KENT_DEMO,
  G82038: IAPTIDs.INSIGHT_KENT_DEMO,
  G82147: IAPTIDs.INSIGHT_KENT_DEMO,
  G82211: IAPTIDs.INSIGHT_KENT_DEMO,
  G82165: IAPTIDs.INSIGHT_KENT_DEMO,
  G82160: IAPTIDs.INSIGHT_KENT_DEMO,
  G82036: IAPTIDs.INSIGHT_KENT_DEMO,
  G82002: IAPTIDs.INSIGHT_KENT_DEMO,
  GP2038: IAPTIDs.INSIGHT_KENT_DEMO,
  G82731: IAPTIDs.INSIGHT_KENT_DEMO,
  G82729: IAPTIDs.INSIGHT_KENT_DEMO,
  G82665: IAPTIDs.INSIGHT_KENT_DEMO,
  G82015: IAPTIDs.INSIGHT_KENT_DEMO,
  G82018: IAPTIDs.INSIGHT_KENT_DEMO,
  G82652: IAPTIDs.INSIGHT_KENT_DEMO,
  G82696: IAPTIDs.INSIGHT_KENT_DEMO,
  G82700: IAPTIDs.INSIGHT_KENT_DEMO,
  G82232: IAPTIDs.INSIGHT_KENT_DEMO,
  G82227: IAPTIDs.INSIGHT_KENT_DEMO,
  G82007: IAPTIDs.INSIGHT_KENT_DEMO,
  G82684: IAPTIDs.INSIGHT_KENT_DEMO,
  Y02506: IAPTIDs.INSIGHT_KENT_DEMO,
  G82687: IAPTIDs.INSIGHT_KENT_DEMO,
  G82682: IAPTIDs.INSIGHT_KENT_DEMO,
  G82231: IAPTIDs.INSIGHT_KENT_DEMO,
  G82671: IAPTIDs.INSIGHT_KENT_DEMO,
  G82634: IAPTIDs.INSIGHT_KENT_DEMO,
  G82702: IAPTIDs.INSIGHT_KENT_DEMO,
  G82698: IAPTIDs.INSIGHT_KENT_DEMO,
  G82023: IAPTIDs.INSIGHT_KENT_DEMO,
  G82757: IAPTIDs.INSIGHT_KENT_DEMO,
  G82693: IAPTIDs.INSIGHT_KENT_DEMO,
  G82799: IAPTIDs.INSIGHT_KENT_DEMO,
  G82035: IAPTIDs.INSIGHT_KENT_DEMO,
  G82026: IAPTIDs.INSIGHT_KENT_DEMO,
  G82057: IAPTIDs.INSIGHT_KENT_DEMO,
  G82796: IAPTIDs.INSIGHT_KENT_DEMO,
  G82126: IAPTIDs.INSIGHT_KENT_DEMO,
  G82219: IAPTIDs.INSIGHT_KENT_DEMO,
  G82064: IAPTIDs.INSIGHT_KENT_DEMO,
  G82066: IAPTIDs.INSIGHT_KENT_DEMO,
  G82150: IAPTIDs.INSIGHT_KENT_DEMO,
  G82046: IAPTIDs.INSIGHT_KENT_DEMO,
  G82052: IAPTIDs.INSIGHT_KENT_DEMO,
  G82079: IAPTIDs.INSIGHT_KENT_DEMO,
  G82105: IAPTIDs.INSIGHT_KENT_DEMO,
  G82107: IAPTIDs.INSIGHT_KENT_DEMO,
  G82666: IAPTIDs.INSIGHT_KENT_DEMO,
  G82020: IAPTIDs.INSIGHT_KENT_DEMO,
  G82650: IAPTIDs.INSIGHT_KENT_DEMO
}

export const iaptGPMapInsightDEMO: IAPTGPMap = {
  ...iaptInsightGPsMapKentDEMO,
  ...iaptGPsMapInsightMedwayDEMO
}
