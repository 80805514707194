import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"
import type { ISurveyResponse } from "../../../models/ISurvey"
import type { ISliderPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = RiskPathwayScriptState

export class RiskPathwayVHGScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayVHGScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askHaveYouMadeASuicideAttempt }
  }

  @step.logState
  askHaveYouMadeASuicideAttempt(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you made a suicide attempt or taken any steps towards preparing for a suicide attempt (e.g. researching methods or stockpiling medication etc.) in the last two weeks?",
      prompt: {
        id: this.getPromptId("Q9 - askHaveYouMadeASuicideAttempt"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "Q9 - askHaveYouMadeASuicideAttempt"
      },
      nextStep: this.handleHaveYouMadeASuicideAttempt
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "10",
    "Have you made a suicide attempt or taken any steps towards preparing for a suicide attempt (e.g. researching methods or stockpiling medication etc.) in the last two weeks?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleHaveYouMadeASuicideAttempt(d: IStepData<State>): IStepResult {
    const isHighRisk = this.clinicalStore.isHighRisk
    if (!isHighRisk && d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_PLANS)
    }
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.askWillYouAct }
  }

  @step
  askWillYouAct(_d: IStepData<State>): IStepResult {
    return {
      body: "How likely is it that within the next week, you will act on plans to end your life?",
      prompt: {
        id: this.getPromptId("Q9 - willYouAct"),
        trackResponse: true,
        type: "slider",
        title:
          "How likely is it that within the next week, you will act on plans to end your life?",
        min: 1,
        max: 8,
        labels: {
          1: "Never",
          2: "Unlikely",
          3: "Unlikely",
          4: "Possible",
          5: "Possible",
          6: "Likely",
          7: "Likely",
          8: "Certain"
        },
        dataPointsName: "Q9 - willYouAct"
      } as ISliderPrompt,
      nextStep: this.handleWillYouAct
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("12", "riskPathwayResponses")
  handleWillYouAct(d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(1)
    const isHighRisk = this.clinicalStore.isHighRisk
    const isOverThreshold = d.response.points! >= 4
    if (!isHighRisk && isOverThreshold) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_WILL_U_ACT_HIGHLY)
      return { nextStep: this.askWhatStopsYou }
    }
    // We need the following conditional, because if its high risk already (true)
    // (which in most of the cases this will apply)
    // and response points are 4+ it still needs to ask "askWhatStopsYou"
    if (isOverThreshold) {
      return { nextStep: this.askWhatStopsYou }
    }
    return { nextStep: this.askAreYouInRiskFromSomeoneElse }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "14",
    "Do you feel that you are at risk from anyone else?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleAreYouInRiskFromSomeoneElse(d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  /** Generic Handlers */

  getClosingMessage(state: State): StepResultBodyType[] | undefined {
    return ["Ok, let's carry on with the mental health check in"]
  }

  getCustomReferralType(state: State): string | undefined {
    if (this.clinicalStore.assessmentFinished) return "Limbic Assessment"
    return "Self Referral"
  }

  getAdditionalInfoHTML(state: State): string {
    const original = super.getAdditionalInfoHTML(state)
    const isRisk = this.clinicalStore.isRisk
    const reason = this.clinicalStore.riskLevelReason
    return `
        ${original}
        <b>Duty team should call:</b> ${isRisk ? "Yes" : "No"}
        <b>Reason why duty team should call:</b> ${reason}
        `
  }
}

export default class RiskPathwayVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayVHG
  readonly name: string = "RiskPathwayVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayVHGDialogue.id, new RiskPathwayVHGScript(), state, snapshot)
  }
}
