import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionScriptState } from "./IntroductionScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Logger from "../../../utils/Logger"

interface State extends IntroductionScriptState {
  claimNumber?: string
  source?: "telephone" | "memberzone"
}

export class IntroductionVitalityScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionVitalityScript"

  /** Script Steps */

  @step.logState
  greet(d: IStepData<State>): IStepResult {
    const entityNumber = this.referralStore.getCustomField("entityNumber")
    if (!entityNumber) {
      Logger.getInstance().exception(new Error("entityNumber"), "no entity number was found")
    }
    const claimNumber = this.referralStore.getCustomField("claimNumber")
    if (claimNumber) {
      d.state.claimNumber = claimNumber
      d.state.source = "telephone"
    } else {
      d.state.claimNumber = undefined
      d.state.source = "memberzone"
    }

    // TODO: are we getting the same property names for Vitality?
    const firstName = this.referralStore.getCustomField("firstName") ?? undefined
    const middleNames = this.referralStore.getCustomField("middleNames") ?? undefined
    const lastName =
      this.referralStore.getCustomField("lastName") ??
      this.referralStore.getCustomField("lastname") ??
      undefined
    let greet = "Hi there!"
    if (firstName && lastName) {
      d.state.name = { firstName, middleNames, lastName }
      const username = this.getFullName(d.state)
      const name = this.getName(d.state)
      d.state.username = username
      this.rootStore.applicationStore.setUsername(username)
      greet = `Hi ${name}!`
    }
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        greet,
        "I’m Limbic",
        `I'm a friendly robot assistant to help you access talking therapies from ${organisationName}`
      ],
      nextStep: this.promptLetsGetStarted
    }
  }

  @step.logState
  promptLetsGetStarted(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("promptLetsGetStarted"),
        type: "inlinePicker",
        choices: [{ body: "Let's get started" }]
      },
      nextStep: this.sayLetsDoIt
    }
  }

  @step.logState
  sayLetsDoIt(_d: IStepData<State>): IStepResult {
    return {
      body: ["Let's do it!", "I'm just going to look up your details in my system"],
      nextStep: this.end
    }
  }
}

/** istanbul ignore next */
export default class IntroductionVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionVitality
  readonly name: string = "IntroductionVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionVitalityDialogue.id, new IntroductionVitalityScript(), state, snapshot)
  }
}
