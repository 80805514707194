import Dialogue, { IDialogueSnapshot } from "./Dialogue"
import Script, { ScriptState } from "./Script"

// This is a dialogue to be used when we intend to branch
// off to it temporarily and return to the current dialogue.
// For example this can be used to create dialogue with a
// very small set of related questions like postcode or
// address, so that we don't repeat the same steps for
// a lot of services. Another example are crisis dialogues
// that can be triggered temporarily at any point, and we still
// need to have access to their state after they're gone.
export default abstract class AdHocDialogue<
  State extends ScriptState,
  Sc extends Script<State>
> extends Dialogue<State> {
  constructor(
    id: string,
    script: Sc,
    state: State = {} as State,
    snapshot?: IDialogueSnapshot<State>
  ) {
    super(id, script, state, snapshot)
  }

  onRemove(nextDialogue?: Dialogue): void {
    this.logBreadcrumb("onRemove", { nextDialogue })
    const state = nextDialogue?.state
    nextDialogue?.script.updateState(state, this.state)
  }
}
