export enum VitalityEligibilityStatus {
  Success = "SUCCESS",
  RequestFailed = "REQUEST_FAILED",
  NoInternetConnection = "NO_INTERNET_CONNECTION"
}

export enum EligibilityReason {
  NoRecord = "No eligibility record found",
  NotEligible = "Not eligible",
  QuotaExceeded = "Quota exceeded"
}
