/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment"
import {
  ALCOHOL_QUANTITIES as AQ,
  ALCOHOL_FREQUENCIES as AF,
  LIMBIC_IMPACT_LEVEL,
  LTC_PCMIS,
  CIVIL_STATUS_PCMIS,
  SEXUALITY_PCMIS,
  GENDER_PCMIS,
  ETHNICITY_PCMIS,
  ARMED_FORCES_PCMIS,
  DISABILITY_PCMIS,
  RELIGION_PCMIS,
  PERINATAL_PCMIS,
  GenderBirthAssigned
} from "@limbic/types"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { fullNameRegex } from "../../../utils/fullNameRegex"
import { ALCOHOL_FREQUENCIES, ALCOHOL_QUANTITIES } from "../../../models/Constants"
import invariant from "../../../utils/invariant"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { ReferralPayloadPCMIS } from "@limbic/types"
import { TrentTitles } from "../../../models/Constants"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  ltcs,
  perinatalStatuses,
  religions,
  sameGenderAsBirth,
  sexualities
} from "../../../config/referralForms/trent-pts"

interface IRelevantInfo {
  mobilityProblems: boolean
  physicalDisability: boolean
  dementiaMemoryProblems: boolean
  wheelchairAccessRequired: boolean
  readingWritingDifficulty: boolean
  carerResponsibilities: boolean
  dependentChildren: boolean
  translatorRequired: boolean
}

interface State extends SelfReferralIAPTScriptState, Partial<IRelevantInfo> {
  primaryLanguage?: string
  nhsNumber?: string
  individualOrCouple?: "Individually" | "As a Couple"
  partnersName?: string
  partnersContact?: string
  coupleProblem?: string
  dayToDayDifficulties?: string
  problemOverTime?: string
  otherCareProfessionals?: string
  frontLineNHSWorker?: boolean
  careHomeWorker?: boolean
  maritalStatus?: string
  canShareDataWithGP?: boolean
  preferredTitle?: TrentTitles
  preferredPronouns?: string
}

export class SelfReferralTrentPTSScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralTrentPTSScript"

  /** Script Steps */

  @step
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    if (d.state.nhsNumber) return { nextStep: this.askFrontLineNHSWorker }
    return { nextStep: this.askNHSNumber }
  }

  @step.logState
  askNHSNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "What is your NHS number?",
      prompt: {
        id: this.getPromptId("askNHSNumber"),
        trackResponse: false,
        type: "inlinePicker",
        choices: [{ body: "I don't know", value: 0 }],
        textPrompt: {
          trimAllSpacesOnSubmit: true,
          trimAllSpacesOnValidation: true,
          validation: [/^\d{10}$/],
          validationExplainer: [
            "This is not a valid NHS number",
            "It needs to be a number with 10 digits"
          ],
          forceValue: true,
          dataPointsName: "askNHSNumber"
        }
      },
      nextStep: this.handleNHSNumber
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, 0 | string>) => {
    // This 👇 is important to make sure we don't add empty strings
    d.state.nhsNumber = d.response ? d.response : undefined
  })
  handleNHSNumber(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askFrontLineNHSWorker }
  }

  @step.logState
  askFrontLineNHSWorker(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you a front line NHS worker?",
      prompt: {
        id: this.getPromptId("askFrontLineNHSWorker"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askFrontLineNHSWorker"
      },
      nextStep: this.handleFrontLineNHSWorker
    }
  }

  @step.logState
  handleFrontLineNHSWorker(d: IStepData<State, boolean>): IStepResult {
    d.state.frontLineNHSWorker = d.response
    if (d.response) {
      return { nextStep: this.askCareHomeWorker }
    }
    return { nextStep: this.askPhoneNumber }
  }

  @step.logState
  askCareHomeWorker(_d: IStepData<State>): IStepResult {
    return {
      body: "And are you a care home worker?",
      prompt: {
        id: this.getPromptId("askCareHomeWorker"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCareHomeWorker"
      },
      nextStep: this.handleCareHomeWorker
    }
  }

  @step.logState
  handleCareHomeWorker(d: IStepData<State, boolean>): IStepResult {
    d.state.careHomeWorker = d.response
    return { nextStep: this.askPhoneNumber }
  }

  @step.logState
  askPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "And what's the best phone number to reach you on?",
      prompt: {
        id: this.getPromptId("askPhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step.logState
  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPrimaryLanguage }
  }

  @step.logState
  askPrimaryLanguage(_d: IStepData<State>): IStepResult {
    return {
      body: "What is your 1st language?",
      prompt: {
        id: this.getPromptId("askPrimaryLanguage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "English" }],
        textPrompt: { forceValue: true },
        dataPointsName: "askPrimaryLanguage"
      },
      nextStep: this.handlePrimaryLanguage
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.primaryLanguage = d.response
  })
  handlePrimaryLanguage(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askIndividualOrCouple }
  }

  @step.logState
  askIndividualOrCouple(_d: IStepData<State>): IStepResult {
    return {
      body: "And do you require therapy individually or as a couple?",
      prompt: {
        id: this.getPromptId("askIndividualOrCouple"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Individually", value: "Individually" },
          { body: "As a Couple", value: "As a Couple" }
        ],
        dataPointsName: "askIndividualOrCouple"
      },
      nextStep: this.handleIndividualOrCouple
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, "Individually" | "As a Couple">) => {
    d.state.individualOrCouple = d.response
  })
  handleIndividualOrCouple(d: IStepData<State, "Individually" | "As a Couple">): IStepResult {
    if (d.state.individualOrCouple === "As a Couple") {
      return { nextStep: this.askPartnersName }
    }
    return { nextStep: this.askDayToDayDifficulties }
  }

  @step.logState
  askPartnersName(_d: IStepData<State>): IStepResult {
    return {
      body: "What's your partner's full name please?",
      prompt: {
        id: this.getPromptId("askPartnersName"),
        type: "text",
        validation: [new RegExp(fullNameRegex)],
        validationExplainer: ["Please enter your partner's full name"],
        forceValue: true
      },
      nextStep: this.handlePartnersName
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.partnersName = d.response
  })
  handlePartnersName(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPartnersContact }
  }

  @step.logState
  askPartnersContact(_d: IStepData<State>): IStepResult {
    return {
      body: "And on what phone number or email can we reach out to your partner?",
      prompt: {
        id: this.getPromptId("askPartnersContact"),
        trackResponse: false,
        type: "text",
        cancelLabel: "skip",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handlePartnersContact
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.partnersContact = d.response
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralTrentPTSScript) => s.askCoupleProblem
  })
  handlePartnersContact(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCoupleProblem }
  }

  @step.logState
  askCoupleProblem(_d: IStepData<State>): IStepResult {
    return {
      body: "Please explain how your, or your partner’s, mental health problem impacts on your relationship",
      prompt: {
        id: this.getPromptId("askCoupleProblem"),
        trackResponse: true,
        type: "text",
        forceValue: true,
        dataPointsName: "askCoupleProblem"
      },
      nextStep: this.handleCoupleProblem
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.coupleProblem = d.response
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralTrentPTSScript) => s.sayPartnerNeedsToRefer
  })
  handleCoupleProblem(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.sayPartnerNeedsToRefer }
  }

  @step.logState
  sayPartnerNeedsToRefer(_d: IStepData<State>): IStepResult {
    return {
      body: "Okay, just so you know, both partners must submit a referral to receive therapy as a couple",
      prompt: {
        id: this.getPromptId("sayPartnerNeedsToRefer"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }],
        dataPointsName: "sayPartnerNeedsToRefer"
      },
      nextStep: this.askDayToDayDifficulties
    }
  }

  @step.logState
  askDayToDayDifficulties(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Briefly describe a day to day example of your difficulties",
        "(e.g your thoughts, feelings, things you ﬁnd difficult to do or have stopped doing, things you do that trouble you)"
      ],
      prompt: {
        id: this.getPromptId("askDayToDayDifficulties"),
        type: "text",
        forceValue: true,
        dataPointsName: "askDayToDayDifficulties"
      },
      nextStep: this.handleDayToDayDifficulties
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.dayToDayDifficulties = d.response
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralTrentPTSScript) => s.askProblemOverTime
  })
  handleDayToDayDifficulties(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askProblemOverTime }
  }

  @step.logState
  askProblemOverTime(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "And could you give me some info on how long have your current problems been present? Have they changed over time?",
        "(if yes, how?)"
      ],
      prompt: {
        id: this.getPromptId("askProblemOverTime"),
        type: "text",
        forceValue: true,
        dataPointsName: "askProblemOverTime"
      },
      nextStep: this.handleProblemOverTime
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.problemOverTime = d.response
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralTrentPTSScript) => s.askCurrentSupport
  })
  handleProblemOverTime(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askMaritalStatus }
  }

  @step.logState
  askMaritalStatus(_d: IStepData<State>): IStepResult {
    const options = ["Single", "Separated", "Widowed", "Married", "Divorced"]
    return {
      body: "What is your marital status?",
      prompt: {
        id: this.getPromptId("askMaritalStatus"),
        trackResponse: true,
        type: "inlinePicker",
        choices: options.map(o => ({ body: o, value: o })),
        dataPointsName: "askMaritalStatus"
      },
      nextStep: this.handleMaritalStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.maritalStatus = d.response
  })
  handleMaritalStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEthnicity }
  }

  @step.logState
  askOtherRelevantInfo(_d: IStepData<State>): IStepResult {
    return {
      body: "Please select any of the following statements that are relevant to you",
      prompt: {
        id: this.getPromptId("askOtherRelevantInfo"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          { body: "I have mobility problems", value: "mobilityProblems" },
          { body: "I have physical disability", value: "physicalDisability" },
          { body: "I have dementia/memory problems", value: "dementiaMemoryProblems" },
          { body: "I need access to a wheelchair", value: "wheelchairAccessRequired" },
          { body: "I have difficulty reading and writing", value: "readingWritingDifficulty" },
          { body: "I have carer responsibilities", value: "carerResponsibilities" },
          { body: "I have dependent children", value: "dependentChildren" },
          { body: "A translator is required", value: "translatorRequired" },
          {
            body: "None apply",
            value: "noneApply",
            selectIndividually: true,
            backgroundColor: "#EC9CC8"
          }
        ],
        dataPointsName: "askOtherRelevantInfo"
      },
      nextStep: this.handleOtherRelevantInfo
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, Array<keyof IRelevantInfo | "noneApply">>) => {
    const none = d.response.includes("noneApply")
    d.state.mobilityProblems = !none && d.response.includes("mobilityProblems")
    d.state.physicalDisability = !none && d.response.includes("physicalDisability")
    d.state.dementiaMemoryProblems = !none && d.response.includes("dementiaMemoryProblems")
    d.state.wheelchairAccessRequired = !none && d.response.includes("wheelchairAccessRequired")
    d.state.readingWritingDifficulty = !none && d.response.includes("readingWritingDifficulty")
    d.state.carerResponsibilities = !none && d.response.includes("carerResponsibilities")
    d.state.dependentChildren = !none && d.response.includes("dependentChildren")
    d.state.translatorRequired = !none && d.response.includes("translatorRequired")
  })
  handleOtherRelevantInfo(_d: IStepData<State, IRelevantInfo>): IStepResult {
    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askCurrentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Are there any other professionals involved in your care other than your GP?",
      prompt: {
        id: this.getPromptId("askCurrentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCurrentSupport"
      },
      nextStep: this.handleCurrentSupport
    }
  }

  @step.logState
  askOtherCareProfessionals(_d: IStepData<State>): IStepResult {
    const professionals = [
      "Psychiatrist",
      "Community Psychiatric Nurse",
      "Community Mental Health Team",
      "Substance Misuse Team",
      "Private or NHS Therapist / CBT Therapist or Counsellor",
      "Psychological Therapy Team"
    ]
    return {
      body: "Please select from the list which ones are involved in your care",
      prompt: {
        id: this.getPromptId("askOtherCareProfessionals"),
        type: "inlinePickerMultiSelect",
        choices: professionals.map(p => ({ body: p, value: p })),
        dataPointsName: "askOtherCareProfessionals"
      },
      nextStep: this.handleOtherCareProfessionals
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string[]>, script: SelfReferralTrentPTSScript) => {
    const response = d.response?.join(", ")
    d.state.otherCareProfessionals = response
    script.referralStore.setCustomField<State>("otherCareProfessionals", response)
  })
  handleOtherCareProfessionals(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askCanIShareWithGP }
  }

  @step.logState
  askCanIShareWithGP(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you okay to share this data with your GP?",
      prompt: {
        id: this.getPromptId("askCanIShareWithGP"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanIShareWithGP"
      },
      nextStep: this.handleCanIShareWithGP
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, s: SelfReferralTrentPTSScript) => {
    d.state.canShareDataWithGP = d.response
    s.referralStore.setCustomField<State>("canShareDataWithGP", d.response)
  })
  handleCanIShareWithGP(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.doReferralSubmission }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `You're doing great, ${name}`,
        "Congratulations on taking this important step towards better mental health!"
      ],
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: "What happens next?" }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleGender(_state: State): Promise<IStepResult> {
    return { nextStep: this.askSameGenderAsBirth }
  }

  async onHandleGenderSameAsBirth(_state: State): Promise<IStepResult> {
    return { nextStep: this.askPerinatal }
  }

  async onHandleEthnicity(state: State): Promise<IStepResult> {
    return { nextStep: this.askReligion }
  }

  async onHandleSubstances(state: State): Promise<IStepResult<State> | void> {
    if (!state.substances) return { nextStep: this.askOtherRelevantInfo }
  }

  async onHandleSubstancesInfo(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askOtherRelevantInfo }
  }

  async onHandleMedicationWithinDoseRange(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askOtherRelevantInfo }
  }

  async onHandleCurrentSupport(state: State): Promise<IStepResult<State> | void> {
    if (state.hasCurrentSupport) {
      return { nextStep: this.askOtherCareProfessionals }
    }
    return { nextStep: this.askCanIShareWithGP }
  }

  async onHandleLongTermMedicalCondition(state: State): Promise<IStepResult<State>> {
    if (state.longTermMedicalCondition?.includes("No")) {
      return { nextStep: this.askHaveYouEverCaughtCovid }
    }
    return { nextStep: this.askDoesLTCAffectMood }
  }

  async onHandleDoesLTCAffectMood(state: State): Promise<IStepResult<State>> {
    if (state.ltcAffectsMood) return { nextStep: this.askHowMuchLTCAffectsMood }
    return { nextStep: this.askHaveYouEverCaughtCovid }
  }

  async onHandleHowMuchLTCAffectsMood(state: State): Promise<IStepResult<State>> {
    if (state.ltcMoodImpact === "little") return { nextStep: this.askHaveYouEverCaughtCovid }
    return { nextStep: this.askHowWellYouManageYourLTC }
  }

  async onHandleHowWellYouManageYourLTC(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askHaveYouEverCaughtCovid }
  }

  async onHandleHaveYouEverCaughtCovid(state: State): Promise<IStepResult<State>> {
    if (state.covidStatus === "NO") {
      return { nextStep: this.askAlcohol }
    }
    return { nextStep: this.askWhenDidYouHaveCovid }
  }

  async onHandleWhenDidYouHaveCovid(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askAlcohol }
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadPCMIS> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile
    const perinatal = this.getPerinatal(state)
    const pregnant = (["PREGNANT", "PREGNANT_AND_CHILD_UNDER_1"] as PERINATAL_PCMIS[]).includes(
      perinatal
    )

    return {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      nameFirstAlias: this.getName(state),
      title: state.preferredTitle,
      pronouns: state.preferredPronouns,
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      alcohol: state.alcohol,
      alcoholFrequency: this.getAlcoholFrequency(state),
      alcoholQuantity: this.getAlcoholQuantity(state),
      substances: state.substances,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      medication:
        state.substancesAreMedications && state.medicationInfo //
          ? [state.medicationInfo]
          : undefined,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      covidStatus: state.covidStatus,
      covidDate: state.covidDate,
      primaryLanguage: state.primaryLanguage as any, // 👈 TODO: is this the correct type?
      civilStatus: this.getCivilStatus(state),
      sexuality: this.getSexuality(state),
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirthAssigned(state),
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForced(state),
      disability: this.getDisability(state),
      religion: this.getReligion(state),
      perinatal,
      pregnant,
      gpCode: state.gp?.nacsCode,
      gpDisplay: state.gp?.name,
      gpPracticeDisplay: state.gp?.name,
      nhsNumber: state.nhsNumber,
      nhsWorker: state.frontLineNHSWorker,
      careWorker: state.careHomeWorker,
      individualOrCouple: state.individualOrCouple,
      partnersName: state.partnersName,
      partnersContact: state.partnersContact,
      coupleProblem: state.coupleProblem,
      dayToDayDifficulties: state.dayToDayDifficulties,
      difficultiesOverTime: state.problemOverTime,
      mobilityProblems: state.mobilityProblems,
      physicalDisability: state.physicalDisability,
      dementiaMemoryProblems: state.dementiaMemoryProblems,
      wheelchairAccessRequired: state.wheelchairAccessRequired,
      readingWritingDifficulty: state.readingWritingDifficulty,
      carerResponsibilities: state.carerResponsibilities,
      dependentChildren: state.dependentChildren,
      interpreter: state.translatorRequired,
      otherCareProfessionals: state.otherCareProfessionals,
      canShareDataWithGP: state.canShareDataWithGP
    }
  }

  getAlcoholFrequency(state: State): typeof AF[keyof typeof AF] | undefined {
    const map = {
      [ALCOHOL_FREQUENCIES.MONTHLY]: AF.MONTHLY,
      [ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4]: AF.MONTHLY_2_TO_4,
      [ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3]: AF.WEEKLY_2_TO_3,
      [ALCOHOL_FREQUENCIES.WEEKLY_4]: AF.WEEKLY_4
    }
    return map[state.alcoholFrequency!]
  }

  getAlcoholQuantity(state: State): typeof AQ[keyof typeof AQ] | undefined {
    const map = {
      [ALCOHOL_QUANTITIES._0_2]: AQ._0_2,
      [ALCOHOL_QUANTITIES._3_4]: AQ._3_4,
      [ALCOHOL_QUANTITIES._5_6]: AQ._5_6,
      [ALCOHOL_QUANTITIES._7_9]: AQ._7_9,
      [ALCOHOL_QUANTITIES._10_PLUS]: AQ._10_PLUS
    }
    return map[state.alcoholQuantity!]
  }

  getLTC(state: State): LTC_PCMIS[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean) as LTC_PCMIS[]
    return ltc?.length ? [...new Set(ltc)] : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getCivilStatus(state: State): CIVIL_STATUS_PCMIS | undefined {
    const map: Record<string, CIVIL_STATUS_PCMIS | undefined> = {
      Single: "SINGLE",
      Married: "MARRIED",
      Divorced: "DIVORCED",
      Widowed: "WIDOWED",
      Separated: "SEPARATED"
    }
    return map[state.maritalStatus!]
  }

  getSexuality(state: State): SEXUALITY_PCMIS | undefined {
    // NOT_LISTED when the response if a free text (custom input)
    return sexualities[state.sexuality!] || "NOT_LISTED"
  }

  getGender(state: State): GENDER_PCMIS {
    return genders[state.gender!] ?? "NOT_LISTED"
  }

  getGenderSameAsBirthAssigned(state: State): GenderBirthAssigned {
    const genderSameAsBirthAssigned = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirthAssigned ?? "UNKNOWN"
  }

  getEthnicity(state: State): ETHNICITY_PCMIS {
    return ethnicities[state.ethnicity!] ?? "NOT_ANSWERED"
  }

  getArmedForced(state: State): ARMED_FORCES_PCMIS {
    return exArmedForces[state.isExArmedForces!] ?? "NOT_ANSWERED"
  }

  getDisability(state: State): DISABILITY_PCMIS | undefined {
    if (state.disabilityStatus === false) return
    if (!state.disabilityStatus || !state.disability) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }
    return disabilities[state.disability!]
  }

  getReligion(state: State): RELIGION_PCMIS {
    return religions[state.religion!] ?? "NOT_ANSWERED"
  }

  getPerinatal(state: State): PERINATAL_PCMIS {
    const perinatal = perinatalStatuses[state.perinatalStatus!]
    return perinatal ?? "NONE"
  }

  getCustomField(field: keyof State): any {
    return this.referralStore.getCustomField(field)
  }
}

export default class SelfReferralTrentPTSDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralTrentPTS
  readonly name: string = "SelfReferralTrentPTSDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralTrentPTSDialogue.id, new SelfReferralTrentPTSScript(), state, snapshot)
  }
}
