import moment from "moment"
import invariant from "../../utils/invariant"
import Loggable from "../../models/Loggable"
import delay from "../../utils/delay"
import { LoggableType } from "../../models/Logger/LoggableType"
import NetworkError from "../../models/NetworkError"
import errorMessage from "../../utils/parseErrorMessage"

const PATH = "https://www.gov.uk/bank-holidays.json"
const TOTAL_RETRIES = 2

export enum DivisionTypes {
  EnglandWales = "england-and-wales",
  Scotland = "scotland",
  NorthernIreland = "northern-ireland"
}

interface IHoliday {
  title: string
  date: string
  notes: string
  bunting: boolean
}

interface IFeedData {
  division: DivisionTypes
  events: IHoliday[]
}

class Feed extends Loggable {
  readonly name: string = "Division"

  data?: Record<DivisionTypes, IFeedData>

  async load(retry = 0): Promise<void> {
    if (!this.data) {
      try {
        const response = await fetch(PATH)
        if (!response.ok) {
          const text = await response.text()
          const message = errorMessage(response, { message: text })
          throw new NetworkError(String(response.status), message)
        }
        this.data = await response.json()
      } catch (e) {
        this.logException(e, "load")
        if (retry < TOTAL_RETRIES) {
          this.logMessage("load Retry")
          await delay(3)
          return await this.load(retry + 1)
        }
      }
    }
  }

  getDivision(division: DivisionTypes): Division {
    invariant(this.data, "no data yet, call load() first")
    invariant(this.data[division], `division [${division} is not supported`)
    return new Division(this.data[division].events)
  }
}

class Division extends Loggable {
  readonly name: string = "Feed"

  holidays: IHoliday[]

  constructor(data: IHoliday[] = []) {
    super()
    this.holidays = data
  }

  isHoliday(date: Date | moment.Moment = new Date()): boolean {
    const s = moment(date).startOf("day")
    const e = moment(date).endOf("day")
    return !!this.holidays.find(h => moment(h.date).add(1, "hour").isBetween(s, e))
  }

  getHolidays(start?: Date | moment.Moment, end?: Date | moment.Moment): IHoliday[] {
    try {
      if (!start) {
        return this.holidays
      } else if (!end) {
        const s = moment(start)
        return this.holidays.filter(h => moment(h.date).isSameOrAfter(s))
      } else {
        const s = moment(start).startOf("day")
        const e = moment(end).endOf("day")
        return this.holidays.filter(h => moment(h.date).add(1, "hour").isBetween(s, e))
      }
    } catch (e) {
      this.logException(e, "getHolidays")
    }
    return []
  }
}

Feed.setLogType(LoggableType.HTTP)
Division.setLogType(LoggableType.HTTP)

const feed = new Feed()
const feedPromise = feed.load()

export default async function getHolidaysDivision(
  division: DivisionTypes = DivisionTypes.EnglandWales
): Promise<Division> {
  await feedPromise
  return feed.getDivision(division)
}

export async function isHoliday(
  division: DivisionTypes = DivisionTypes.EnglandWales,
  date: Date | moment.Moment = new Date()
): Promise<boolean> {
  const d = await getHolidaysDivision(division)
  return d.isHoliday(date)
}
