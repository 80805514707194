import React from "react"
import IAppLaunchConfig from "../../../models/IAppLaunchConfig"
import SidebarInsight from "./SidebarInsight"
import SidebarSABP from "./SidebarSABP"
import SidebarNottinghamshire from "./SidebarNottinghamshire"
import SidebarLincolnshire from "./SidebarLincolnshire"
import SidebarVitality from "./SidebarVitality"
import { Props } from "./Sidebar"

export const sidebarsMap: Record<IAppLaunchConfig["API_KEY"], React.FC<Props>> = {
  INSIGHT_TALKING_THERAPIES_FULLSCREEN: SidebarInsight,
  SABP_FULLSCREEN: SidebarSABP,
  NOTTINGHAMSHIRE_FULLSCREEN: SidebarNottinghamshire,
  LINCOLNSHIRE_FULLSCREEN: SidebarLincolnshire,
  VITALITY: SidebarVitality
}
