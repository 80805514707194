import { step } from "../../../backend/chatbot/decorators/step"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import type { IntroductionIAPTScriptState } from "./IntroductionIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = IntroductionIAPTScriptState

export class IntroductionInsightFollowUpScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionInsightFollowUpScript"

  /* Script Steps */

  @step.logState
  greet(d: IStepData<State>): IStepResult {
    return {
      body: [
        "Hi there!",
        "Welcome to Insight IAPT",
        "I’m Limbic",
        "I'm a friendly robot assistance to help you on your next step to access our service",
        "You are having your assessment with Insight IAPT in a few days",
        "In order to provide your therapist with as much information as possible and determine your optimal treatment pathway, we need to take some details from you"
      ],
      prompt: {
        id: this.getPromptId("greet"),
        type: "inlinePicker",
        choices: [{ body: "Hi Limbic" }]
      },
      nextStep: this.end
    }
  }
}

export default class IntroductionInsightFollowUpDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionInsightFollowUp
  readonly name: string = "IntroductionInsightFollowUpDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      IntroductionInsightFollowUpDialogue.id,
      new IntroductionInsightFollowUpScript(),
      state,
      snapshot
    )
  }
}
