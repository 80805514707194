import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostLincolnshireScriptState = State

export class UnderAgeSignpostLincolnshireScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostLincolnshireScript"

  /** Script Steps */
  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const threshold = this.getIAPTServiceAgeThreshold(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, there's a special type of mental health support for people under the age of ${threshold}`,
        `${iaptName} do not currently provide this`,
        "However, you can speak to a professional about getting the right support for you by calling the Lincolnshire Here4You Line",
        "Please call them on [0800 234 6342](tel:0800 234 6342). The phone line is operated 09.30 and 16:30, Monday to Friday",
        "Also, to find the best service for you, there's a helpful website [here](https://www.nhs.uk/service-search/other-services/Mental-health-support-for-young-people/LocationSearch/1430)",
        "Or you can talk to your doctor and they will help you"
      ],
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: "okay" },
          { body: "Thanks", value: "thanks" }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleNotEligible
    }
  }
}

export default class UnderAgeSignpostDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderageSignpostLincolnshire
  readonly name: string = "UnderAgeSignpostLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostDialogue.id, new UnderAgeSignpostLincolnshireScript(), state, snapshot)
  }
}
