import { ClinicalFlags, ClinicalGroups, ProblemCategories } from "../../../../models/Constants"
import { IClinicalPath } from "../../../../models/IClinicalPath"
import treatmentsInsight from "../treatments/insight"

const insightCustomClinicalPaths: IClinicalPath[] = [
  {
    id: "insight-1",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.M_Depression],
      flags: []
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-2",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.M_Depression, ProblemCategories.M_GADWorry],
      flags: []
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-3",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.M_Depression],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia_S_FI,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-4",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.M_Depression, ProblemCategories.M_GADWorry],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia_S_FI,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-5",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.M_Depression],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia_MS_FI,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-6",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.M_Depression, ProblemCategories.M_GADWorry],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia_MS_FI,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-7",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.MS_Depression],
      flags: []
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-8",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.MS_Depression, ProblemCategories.M_GADWorry],
      flags: []
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-9",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.MS_Depression],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia_S_FI,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-10",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.MS_Depression, ProblemCategories.M_GADWorry],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia_S_FI,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-11",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.MS_Depression],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia_MS_FI,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-12",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.MS_Depression, ProblemCategories.M_GADWorry],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia_MS_FI,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-13",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.M_GADWorry],
      flags: []
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-14",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.M_GADWorry],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia_S_FI,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "insight-15",
    matcher: {
      primaryProblems: [ProblemCategories.SocialPhobia],
      secondaryProblems: [ProblemCategories.M_GADWorry],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.SocialPhobia_MS_FI,
    suggestTreatments: true,
    defaultReferralType: "Step 1 Limbic Assessment",
    treatments: treatmentsInsight,
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  }
]

export { insightCustomClinicalPaths }
