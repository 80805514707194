import { ITreatmentOption } from "../../../../models/IClinicalPath"

const treatments: ITreatmentOption[] = [
  {
    name: "webinar",
    formattedName: "wellbeing webinar",
    referralType: "Step 1 Limbic Assessment",
    age: [18],
    order: 0,
    explainMore: {
      text: [
        "Wellbeing webinars consist of 6 two hour sessions taking place over 6 consecutive weeks. Webinars are delivered by two qualified therapists and provide you with Cognitive Behavioural Therapy (CBT) tools and techniques for managing symptoms of Depression and Anxiety. The webinar is a ‘one way facing conversation’ therefore the facilitators or other participants cannot see you- making you feel more comfortable when attending. If you wish to actively participate within the sessions there is a chat function for you to use"
      ],
      // Webinar cannot be declined
      choices: [
        { body: "Ok", value: true },
        { body: "👍", value: true }
      ]
    }
  },
  {
    name: "ccbt",
    formattedName: "digital therapy",
    referralType: "Step 1 Limbic Assessment",
    age: [18],
    order: 1,
    explainMore: {
      text: [
        "SilverCloud is our evidence-based, interactive digital therapy platform. SilverCloud is based on Cognitive Behavioural Therapy (CBT) principles and can support individuals with symptoms of Depression and Anxiety across a range of online learning modules. The program provides a very flexible approach to therapy as it can be used via any smart device or laptop and you do not have fixed appointment times. Support is provided once per week from your dedicated therapist via email. Prior to using SilverCloud, one of our therapists will contact you by telephone to explain how the system works and discuss the most appropriate modules for you to complete"
      ],
      choices: [
        { body: "Sounds interesting", value: true },
        { body: "No thanks", value: false }
      ]
    },
    acceptedResponse: [
      "One of Insight IAPT's practitioners will speak to you to discuss the most appropriate treatment option for you. "
    ]
  },
  {
    name: "gsh",
    formattedName: "guided self help",
    referralType: "Step 1 Limbic Assessment",
    age: [18],
    order: 2,
    acceptedResponse: [
      "One of Insight IAPT's practitioners will speak to you to discuss the most appropriate treatment option for you. "
    ]
  }
]

export default treatments
