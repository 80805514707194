export enum DiscussionSteps {
  DateOfBirth = "dateOfBirth",
  EmailAddress = "emailAddress",
  Intro = "intro",
  GetName = "getName",
  Eligibility = "eligibility",
  PeaceOfMind = "peaceOfMind",
  Permissions = "permissions",
  SelfReferralPitch = "selfReferralPitch",
  SelfReferral = "selfReferral",
  SuitableServicesSignpost = "suitableServicesSignpost",
  UnderageSignpost = "underageSignpost",
  AssessmentPitch = "assessmentPitch",
  Assessment = "assessment",
  RiskPathway = "riskPathway",
  TreatmentOptions = "treatmentOptions",
  BookMentalHealthProfessional = "bookMentalHealthProfessional",
  WellbeingHub = "wellbeingHub",
  Goodbye = "goodbye",
  Crisis = "crisis",
  PHQ9 = "phq9",
  BookAppointment = "bookAppointment"
}

export enum ReferralType {
  LIMBIC_RISK_PATIENT = "Limbic RISK patient",
  SELF_REFERRAL = "Limbic Self-Referral",
  ASSESSMENT_CALL_REQUIRED = "Limbic Full Assessment - Assessment call required",
  HIGH_RISK_PATIENT = "HIGH RISK PATIENT",
  MODERATE_RISK_PATIENT = "MODERATE RISK PATIENT",
  RISK_PATIENT = "RISK PATIENT"
}

export enum AssessmentType {
  DEPRESSION = "depression",
  ANXIETY_WORRY = "anxiety (worry group)",
  ANXIETY_PHOBIA = "anxiety (phobia group)",
  ANXIETY_GAD = "anxiety (GAD group)",
  ANXIETY_PANIC = "anxiety (panic group)"
}

export enum AssessmentCallReason {
  LTC_NOT_MANAGED_WELL = "LTC not managed well",
  DRINKING_ALCOHOL = "Drinking alcohol to manage mood",
  USING_SUBSTANCES = "Using non-prescribed medication or substances to manage mood",
  USING_SUBSTANCES_OUTSIDE_DOSE_RANGE = "Using medication or substances outside the recommended dose range",
  CURRENT_SUPPORT = "Receiving 3rd party mental health support",
  UNDETERMINED_ELIGIBILITY = "Limbic could not determine user eligibility for the service"
}

export enum ALCOHOL_FREQUENCIES {
  MONTHLY = "Monthly or less",
  MONTHLY_2_TO_4 = "2 to 4 times a month",
  WEEKLY_2_TO_3 = "2 to 3 times a week",
  WEEKLY_4 = "4 times or more per week"
}

export enum ALCOHOL_QUANTITIES {
  _0_2 = "0 to 2",
  _3_4 = "3 to 4",
  _5_6 = "5 to 6",
  _7_9 = "7 to 9",
  _10_PLUS = "10 or more"
}

export enum RiskLevel {
  Low = "LOW",
  Moderate = "MODERATE",
  High = "HIGH"
}

export enum RiskLevelReason {
  PHQ9_Q9 = "Scored 3 on PHQ9 Q9",
  PHQ9_Q9_ABOVE_ZERO = "Scored above 0 on PHQ9 Q9",
  PHQ9_Q9_PLANS = "Has made plans to end life",
  PHQ9_Q9_PREPARATIONS = "Has made preparations to end life",
  PHQ9_Q9_WILL_U_ACT_SOMEWHAT = "Somewhat likely to act on plans to end life",
  PHQ9_Q9_WILL_U_ACT_HIGHLY = "Quite likely to act on plans to end life",
  PHQ9_Q9_RISK_FROM_SOMEONE_ELSE = "In risk from someone else",
  PHQ9_Q9_CANT_KEEP_SAFE = "User can't keep self safe",
  PHQ9_Q9_CANT_KEEP_SAFE_1 = "Scored 1 on PHQ9 Q9 and can't keep self safe",
  PHQ9_Q9_CANT_KEEP_SAFE_2_PLUS = "Scored 2+ on PHQ9 Q9 and can't keep self safe",
  PHQ9_Q9_2_PLUS = "Scored 2+ on PHQ9 Q9 and can keep self safe",
  CANNOT_KEEP_SELF_SAFE = "Scored 1+ on PHQ9 and cannot keep self safe",
  CRISIS_DETECTION = "Crisis was detected in user input"
}

export const KeepingSafeLeafletViaStatus = {
  SENT: "SENT",
  FAILED: "FAILED",
  NO_RECIPIENT: "NO_RECIPIENT"
}

export const TrackingEvents = {
  [AssessmentType.DEPRESSION]: "Assessment - Depression",
  [AssessmentType.ANXIETY_WORRY]: "Assessment - Anxiety Worry",
  [AssessmentType.ANXIETY_PHOBIA]: "Assessment - Anxiety Phobia",
  [AssessmentType.ANXIETY_GAD]: "Assessment - Anxiety GAD",
  [AssessmentType.ANXIETY_PANIC]: "Assessment - Anxiety Panic",
  ASSESSMENT_REACHED: "Assessment Reached",
  APPOINTMENT_BOOKED: "Appointment Booked",
  APPOINTMENTS_PROVIDED: "Appointments Provided to User",
  APPOINTMENT_BOOKING_FAILED: "Appointment Reservation Failed",
  APPOINTMENT_SKIPPED: "Appointment Skipped",
  TREATMENT_DETERMINED: "Treatment Determined",
  CRISIS_DETECTED: "Crisis Detected",
  CRISIS_DETECTION_CORRECT: "Crisis Detection Was Correct",
  CRISIS_DETECTION_WRONG: "Crisis Detection Was Wrong",
  IAPT_DETERMINED: "IAPT Determined",
  EXTERNAL_IAPTS_DETERMINED: "External IAPT Suggestions Determined",
  SIGNPOST_IAPTS: "Signpost to IAPTs",
  SIGNPOST_GP: "Signpost to GP",
  SIGNPOST_WITHOUT_GP_INFO: "Signpost w/o GP info",
  SIGNPOST_MANUAL_REFERRAL: "Signpost To Manual Referral",
  USER_NEEDS_TO_CALL: "User Needs To Call In",
  INVALID_POSTCODE: "Invalid Postcode",
  CUSTOM_POSTCODE: "Custom Postcode",
  ELIGIBILITY_DETERMINED: "Eligibility Determined",
  UNDO: "Undo",
  SELECT_GP_BY_POSTCODE: "Select GP by Postcode",
  SELECT_GP_BY_NAME: "Select GP by Name",
  SELECT_GP_BY_ELIGIBLE_LIST: "Select GP from eligible list",
  DO_U_KNOW_GP_NAME: "Do you know the name of your GP?",
  MEDICATION_PRESCRIBED_BOTH: "Taking medication (Prescribed / Not Prescribed)",
  MEDICATION_PRESCRIBED: "Taking medication (Prescribed)",
  MEDICATION_NOT_PRESCRIBED: "Taking medication (Not Prescribed)",
  NO_MEDICATION_PRESCRIBED: "Not taking medication (Prescribed)",
  NO_MEDICATION_NOT_PRESCRIBED: "Not taking medication (Not Prescribed)",
  SKIP_FEEDBACK_CRISIS: "Skipping Feedback (Crisis Detected)",
  SKIP_WHERE_DID_YOU_HEAR: "Skipping Where id you hear about us (Crisis Detected)",
  IMPROVEMENT_SUGGESTION: "Improvement Suggestion",
  CONVERSATION_END: "Conversation End",
  CHAT_BOT_LAUNCH: "Chat Bot Launch",
  SELF_REFERRAL_SUBMITTED: "Self Referral Data Submitted",
  SELF_REFERRAL_NOT_SUBMITTED: "Self Referral Data Not Submitted Successfully",
  BELOW_CASENESS_EMAIL: "Service notified user is below caseness",
  BOOK_MENTAL_HEALTH_PROFESSIONAL_SUBMITTED: "Book Mental Health Professional Submitted",
  BOOK_MENTAL_HEALTH_PROFESSIONAL_NOT_SUBMITTED:
    "Book Mental Health Professional Not Submitted Successfully",
  BOOKING_ABORTED: "User Has Aborted The Booking Process",
  PHQ9_Q9: "PHQ9 - Q9",
  INVALID_PHONE_NUMBER: "Invalid phone number",
  PHONE_PERMISSIONS: "Phone Permissions",
  DO_YOU_HAVE_EMAIL: "doYouHaveEmail",
  SELECT_ADDRESS: "Select Address",
  MANUAL_ADDRESS_POSTCODE_NOT_FOUND: "Postcode Not Found Enter Manual Address",
  NO_GP_FOUND: "No GP found",
  NO_ELIGIBLE_IAPT_FOUND: "No eligible IAPT found",
  NO_IAPTS_FOUND: "No IAPT found",
  NO_IAPTS_FOUND_VIA_ODS_GP: "No IAPT found via ODS GP",
  INELIGIBLE_GP_MATCHING_IAPT: "Ineligible GP with matching IAPT",
  TRY_AGAIN_POSTCODE: "Try Again Pressed",
  RE_ENTER_POSTCODE: "Something Went Wrong - Re-enter Postcode",
  ASK_RETRY_CONNECTION_GP_FROM_GP_POSTCODE: "Ask User to Retry Connection for GP from GP Postcode",
  ASK_RETRY_CONNECTION_GP_FROM_USER_POSTCODE:
    "Ask User to Retry Connection for GP from User Postcode",
  ASK_RETRY_CONNECTION_SELECT_IAPT_SERVICE_BY_GP:
    "Ask User to Retry Connection for Select IAPT Service from GP",
  ASK_RETRY_CONNECTION_VITALITY_ELIGIBILITY_CHECK:
    "Ask User to Retry Connection for Vitality Eligibility Check",
  ASK_RETRY_REQUEST_FAILED_VITALITY_ELIGIBILITY_CHECK:
    "Ask User to Retry Request Failed for Vitality Eligibility Check",
  MEMBER_ID_NOT_PROVIDED: "Member ID not Provided",
  ASK_RETRY_SOMETHING_WRONG_SELECT_GP_FROM_GP_POSTCODE:
    "Something Went Wrong Retry for Select GP from GP Postcode",
  ASK_RETRY_SOMETHING_WRONG_SELECT_GP_FROM_USER_POSTCODE:
    "Something Went Wrong Retry for Select GP from User Postcode",
  ASK_RETRY_SOMETHING_WRONG_SELECT_IAPT_SERVICE_BY_GP:
    "Something Went Wrong Retry for Select IAPT Service by GP",
  NO_ELIGIBLE_GP_KM: "No eligible GP found for K&M",
  ODS_GP_BY_NACSCODE_NOT_FOUND: "Could not find ODS GP via NACSCode",
  SPINE_SUCCESS_ON_1ST_TRY: "Successful spine search on 1st try",
  SPINE_SEARCH_RESULT: "Spine Search Result",
  MULTIPLE_ELIGIBLE_IAPTS: "Multiple Eligible IAPTs",
  RISK_EMAIL_SKIPPED: "Risk email skipped - no risk emails available",
  VITALITY_ALTERNATE_SUGGESTIONS_HELPED: "Vitality Alternative Suggestions Helped",
  VITALITY_ALTERNATE_SUGGESTIONS_DID_NOT_HELP: "Vitality Alternative Suggestions Did Not Help",
  VITALITY_CHECKIN: "Vitality Digital Triage Route",
  VITALITY_APPOINTMENT: "Vitality Appointment Route"
}

export enum ProblemCategories {
  M_Depression = "Moderate Depression",
  MS_Depression = "Moderately Severe Depression",
  S_Depression = "Severe Depression",
  SocialPhobia = "Social Phobia",
  SpecificPhobia = "Specific Phobia",
  SpecificPhobiaNBI = "Specific Phobia (type: Needle, Blood, Injury)",
  Panic = "Panic",
  M_GADWorry = "Moderate GAD/Worry",
  S_GADWorry = "Severe GAD/Worry",
  HealthAnxiety = "Health Anxiety",
  OCD = "OCD",
  PTSD = "PTSD",
  AnxietyNOS = "Anxiety NOS"
}

export enum ClinicalFlags {
  MS_FunctionalImpairment = "Moderately Severe Functional Impairment",
  S_FunctionalImpairment = "Severe Functional Impairment"
}

export enum ClinicalGroups {
  ComplexComorbid = "Complex Comorbid",
  Undetermined = "Clinical Group Undetermined",
  BelowCaseness = "Below Caseness",
  BelowCaseness_MS_FI = "Below Caseness w/ Moderately Severe Functional Impairment",
  BelowCaseness_S_FI = "Below Caseness w/ Severe Functional Impairment",
  M_Depression = "Moderate Depression",
  M_Depression_MS_FI = "Moderate Depression w/ Moderately Severe Functional Impairment",
  M_Depression_S_FI = "Moderate Depression w/ Severe Functional Impairment",
  MS_Depression = "Moderately Severe Depression",
  MS_Depression_MS_FI = "Moderately Severe Depression w/ Moderately Severe Functional Impairment",
  MS_Depression_S_FI = "Moderately Severe Depression w/ Severe Functional Impairment",
  S_Depression = "Severe Depression",
  S_Depression_MS_FI = "Severe Depression w/ Moderately Severe Functional Impairment",
  S_Depression_S_FI = "Severe Depression w/ Severe Functional Impairment",
  SocialPhobia = "Social Phobia",
  SocialPhobia_MS_FI = "Social Phobia w/ Moderately Severe Functional Impairment",
  SocialPhobia_S_FI = "Social Phobia w/ Severe Functional Impairment",
  SpecificPhobia = "Specific Phobia",
  SpecificPhobia_MS_FI = "Specific Phobia w/ Moderately Severe Functional Impairment",
  SpecificPhobia_S_FI = "Specific Phobia w/ Severe Functional Impairment",
  SpecificPhobiaNBI = "Specific Phobia (type: Needle, Blood, Injury)",
  SpecificPhobiaNBI_MS_FI = "Specific Phobia (type: Needle, Blood, Injury) w/ Moderately Severe Functional Impairment",
  SpecificPhobiaNBI_S_FI = "Specific Phobia (type: Needle, Blood, Injury) w/ Severe Functional Impairment",
  Panic = "Panic",
  Panic_MS_FI = "Panic w/ Moderately Severe Functional Impairment",
  Panic_S_FI = "Panic w/ Severe Functional Impairment",
  M_GADWorry = "Moderate GAD/Worry",
  M_GADWorry_MS_FI = "Moderate GAD/Worry w/ Moderately Severe Functional Impairment",
  M_GADWorry_S_FI = "Moderate GAD/Worry w/ Severe Functional Impairment",
  S_GADWorry = "Severe GAD/Worry",
  S_GADWorry_MS_FI = "Severe GAD/Worry w/ Moderately Severe Functional Impairment",
  S_GADWorry_S_FI = "Severe GAD/Worry w/ Severe Functional Impairment",
  HealthAnxiety = "Health Anxiety",
  HealthAnxiety_MS_FI = "Health Anxiety w/ Moderately Severe Functional Impairment",
  HealthAnxiety_S_FI = "Health Anxiety w/ Severe Functional Impairment",
  OCD = "OCD",
  OCD_MS_FI = "OCD w/ Moderately Severe Functional Impairment",
  OCD_S_FI = "OCD w/ Severe Functional Impairment",
  PTSD = "PTSD",
  PTSD_MS_FI = "PTSD w/ Moderately Severe Functional Impairment",
  PTSD_S_FI = "PTSD w/ Severe Functional Impairment",
  AnxietyNOS = "Anxiety NOS",
  AnxietyNOS_MS_FI = "Anxiety NOS w/ Moderately Severe Functional Impairment",
  AnxietyNOS_S_FI = "Anxiety NOS w/ Severe Functional Impairment"
}

export const disordersMap = {
  depression: [
    ProblemCategories.M_Depression,
    ProblemCategories.MS_Depression,
    ProblemCategories.S_Depression
  ],
  anxiety: {
    socialPhobia: [ProblemCategories.SocialPhobia],
    phobia: [ProblemCategories.SpecificPhobia, ProblemCategories.SpecificPhobiaNBI],
    panic: [ProblemCategories.Panic],
    worry: [ProblemCategories.M_GADWorry, ProblemCategories.S_GADWorry]
  },
  anxietyExtra: [
    ProblemCategories.HealthAnxiety,
    ProblemCategories.OCD,
    ProblemCategories.PTSD,
    ProblemCategories.AnxietyNOS
  ]
}

export const clinicalFlagsMap = {
  wsas: [ClinicalFlags.MS_FunctionalImpairment, ClinicalFlags.S_FunctionalImpairment]
}

export const problemCategoryUserFriendlyNames: Record<ProblemCategories, string> = {
  [ProblemCategories.M_Depression]: "Low Mood",
  [ProblemCategories.MS_Depression]: "Low Mood",
  [ProblemCategories.S_Depression]: "Low Mood",
  [ProblemCategories.SocialPhobia]: "social Phobia",
  [ProblemCategories.SpecificPhobia]: "Specific Phobia",
  [ProblemCategories.SpecificPhobiaNBI]: "Phobia related to needles, blood or injury",
  [ProblemCategories.Panic]: "Panic",
  [ProblemCategories.M_GADWorry]: "Worry",
  [ProblemCategories.S_GADWorry]: "Worry",
  [ProblemCategories.HealthAnxiety]: "Worrying about your health/illness",
  [ProblemCategories.OCD]: "Intrusive or unwanted thoughts/images",
  [ProblemCategories.PTSD]: "Distress over past traumatic experience(s)",
  [ProblemCategories.AnxietyNOS]: "Anxiety"
}

export enum JobCategory {
  NursingMidwifery = "Nursing / Midwifery",
  DrConsultant = "Doctor / Consultant",
  TherapistAssistant = "Therapist / Healthcare Assistant",
  NonClinicalSenior = "Senior Non-Clinical (e.g manager, director, lead)",
  NonClinicalOther = "Other Non-Clinical (e.g administrator, cleaner)"
}

export enum AssessmentClinicalNotes {
  BC_Depression = "BC_Depression",
  BC_Anxiety = "BC_Anxiety",
  SpecificPhobia = "SpecificPhobia",
  NonSpecificPhobia = "NonSpecificPhobia",
  AnxietySpeedYes = "AnxietySpeedYes",
  AnxietySpeedNo = "AnxietySpeedNo",
  AnxietyPeakYes = "AnxietyPeakYes",
  AnxietyPeakNo = "AnxietyPeakNo",
  AnxietySensationsYes = "AnxietySensationsYes",
  AnxietySensationsNo = "AnxietySensationsNo",
  WorryYes = "WorryYes",
  WorryNo = "WorryNo",
  HealthAnxiety = "HealthAnxiety",
  OCDAnxiety = "OCDAnxiety",
  PTSDAnxiety = "PTSDAnxiety"
}

export enum InsightTreatmentOptions {
  WEBINAR = "Wellbeing Webinar",
  DIGITAL_THERAPY = "Digital Therapy",
  GSH = "Guided Self-Help"
}

export enum Titles {
  MR = "Mr",
  MRS = "Mrs",
  MISS = "Miss",
  MS = "Ms",
  DR = "Dr",
  REV = "Rev",
  PROF = "Prof",
  MX = "Mx"
}

export type TrentTitles = "Mr" | "Mrs" | "Miss" | "Ms" | "Dr" | "Rev" | "Prof" | "Mx"
export type VHGTitles = "Mr" | "Mrs" | "Miss" | "Ms" | "Mx" | "Dr" | "Rev" | "Prof"
export type VitalityTitles = "Mr" | "Ms" | "Mrs" | "Miss" | "Mx"

export type TrentPreferredPronouns =
  | "She/her"
  | "He/him"
  | "They/them"
  | "She/they"
  | "He/they"
  | "Other"

export enum VitalityRoute {
  VITALITY_CHECKIN = "VITALITY_CHECKIN",
  VITALITY_APPOINTMENT = "VITALITY_APPOINTMENT",
  NONE = "NONE"
}
