import BaseScript, { BaseScriptState } from "../../BaseScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { submitVitalityReferral } from "../../../backend/api/submitVitalityReferral"
import { TrackingEvents } from "../../../models/Constants"
import { IAppointmentStatus } from "../../../models/IAppointment"
import {
  getVitalityAppointments,
  reserveAppointment
} from "../../../backend/api/vitalityAppointments"
import moment from "moment"

interface State extends BaseScriptState {
  retryReserveAppointment?: number
  retryBookAppointment?: number
  tempAppointment?: string
  appointment?: string
  appointmentTimestamps?: string
  assessmentPreference?: "telephone" | "digital"
  paymentURL?: string
}

export type BookAppointmentVitalityState = State

export class BookAppointmentVitalityScript extends BaseScript<State> {
  readonly name: string = "BookAppointmentVitalityScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    if (this.clinicalStore.isRisk) {
      // here 👇 we just end the flow in order to start the assessment
      // or to just move to the next discussion step
      if (d.state.assessmentPreference === "digital") return { nextStep: this.end }
      // here 👇 we skip everything because the user is
      // at Risk but also said they don't want to do the assessment
      // there isn't anything else for them to do
      return { nextStep: this.goToGoodbye }
    }
    if (d.state.assessmentPreference === "digital") return { nextStep: this.startShortAppointment }
    return { nextStep: this.sayLongAppointment }
  }

  @step.logState
  sayLongAppointment(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm going to put you in touch with a qualified mental health professional at IPRS Health",
        "This will be a 30 minute phone call to go through some questions and find the best support options for you",
        "If you'd prefer to do this in a shorter 15 minute phone call, we can go through some of the questions now"
      ],
      prompt: {
        id: this.getPromptId("sayLongAppointment"),
        type: "inlinePicker",
        choices: [
          { body: "Book in a 30 minute call", value: false },
          { body: "Let's go through more questions now", value: true }
        ]
      },
      nextStep: this.handleLongAppointment
    }
  }

  @step.logState
  handleLongAppointment(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      // just ending is enough here, because if we reached this
      // step it means the user had initially chosen "telephone"
      // as preferred assessment, which means that the self referral
      // dialogue brought us here by interrupting the main flow. This
      // means that if this dialogue ends, then it will go to the self
      // referral dialogue, and that will end as well, which will bring
      // the user to the assessment dialogue, just like we intended
      d.state.assessmentPreference = "digital"
      void this.referralStore.updateReferral({ assessmentPreference: d.state.assessmentPreference })
      return {
        body: ["No Problem!", "Let's go through these together now"],
        nextStep: this.end
      }
    }
    return { nextStep: this.triggerReferralSubmission }
  }

  @step.logState
  startShortAppointment(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm going to put you in touch with a qualified mental health professional at IPRS Health",
        "We've already covered a lot of important detail, so you and your mental health professional will be able to find the right support options in a brief 15 minute phone call"
      ],
      nextStep: this.triggerReferralSubmission
    }
  }

  @step.logState
  async triggerReferralSubmission(d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setIdleSubmissionActive(false)
    const patientId = this.referralStore.patientId!
    const [isSubmitSuccessful, paymentURL] = await submitVitalityReferral(patientId)
    if (isSubmitSuccessful) {
      d.state.paymentURL = paymentURL
      return { nextStep: this.getAvailableAppointments }
    }
    // if the submission failed, then reactivate the idle submission
    this.referralStore.setIdleSubmissionActive(true)
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  async getAvailableAppointments(d: IStepData<State>): Promise<IStepResult> {
    d.state.retryReserveAppointment ??= 0
    const patientId = this.referralStore.patientId!
    const serviceName = this.rootStore.configStore.serviceName
    const [appointments, appointmentsStatus] = await getVitalityAppointments(patientId)

    if (appointmentsStatus === IAppointmentStatus.NoInternetConnection) {
      return { nextStep: this.sayNoInternetConnectionGetAppointments }
    }

    if (appointmentsStatus === IAppointmentStatus.RequestFailed) {
      return { nextStep: this.saySomethingWentWrongRetrievingAppointments }
    }

    if (appointments?.length === 0 && IAppointmentStatus.Success) {
      return {
        body: `One of ${serviceName}'s practitioners will call you to discuss this. The ${serviceName} team will be in touch to organise a time and date for this call`,
        nextStep: this.goToGoodbye
      }
    }

    this.track(TrackingEvents.APPOINTMENTS_PROVIDED)
    const isFullScreen = this.rootStore.configStore.fullscreen
    const minutes = d.state.assessmentPreference === "telephone" ? "30" : "15"
    return {
      body: [
        "Now, let's see all the available appointment slots",
        `Please choose a date and time that works best for you to have a ${minutes} minute appointment with one of our team`
      ],
      prompt: {
        id: this.getPromptId("bookAppointment"),
        type: "appointment",
        isFullScreen,
        appointments: appointments || [],
        withUserID: false,
        hideDuration: true,
        isUndoAble: true
      },
      nextStep: this.confirmAppointment
    }
  }

  @step.logState
  sayNoInternetConnectionGetAppointments(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionGetAppointments"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.getAvailableAppointments
    }
  }

  @step.logState
  saySomethingWentWrongRetrievingAppointments(d: IStepData<State>): IStepResult {
    d.state.retryBookAppointment ??= 0
    d.state.retryBookAppointment = d.state.retryBookAppointment + 1

    if (d.state.retryBookAppointment === 2) {
      const serviceName = this.rootStore.configStore.serviceName
      const name = this.getName(d.state)
      return {
        body: [
          `Sorry ${name}, we're encountering a persistent issue when trying to load the available appointments`,
          `One of ${serviceName}'s practitioners will call you to discuss this. The ${serviceName} team will be in touch to organise a time and date for this call`
        ],
        nextStep: this.goToGoodbye
      }
    }
    return {
      body: [
        "Hmmm... something went wrong while loading the available appointments",
        "Please try again"
      ],
      prompt: {
        id: this.getPromptId("saySomethingWentWrongRetrievingAppointments"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.getAvailableAppointments
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.tempAppointment = d.response
  })
  async confirmAppointment(d: IStepData<State, string>): Promise<IStepResult> {
    const [time, date] = d.response.split("_")
    const appointmentTime = moment(time.split("&&")[0]).format("H:mm") || ""

    return {
      body: [
        "You have selected the following date and time",
        `${date} - ${appointmentTime}`,
        "Is this correct?"
      ],
      prompt: {
        id: this.getPromptId("confirmAppointment"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No, let me change it", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleAppointment
    }
  }

  @step.logState
  async handleAppointment(d: IStepData<State, boolean>): Promise<IStepResult> {
    if (d.response) {
      const patientId = this.referralStore.patientId!
      const timestamps = d.state.tempAppointment?.split("_")[0]
      d.state.appointmentTimestamps = timestamps
      this.referralStore.setCustomField("appointmentTimestamps", timestamps)

      const [timeStart, timeEnd] = timestamps!.split("&&")
      const [reservedAppointment, reservedAppointmentStatus] = await reserveAppointment(
        patientId,
        timeStart,
        timeEnd
      )

      if (reservedAppointmentStatus === IAppointmentStatus.NoInternetConnection) {
        return { nextStep: this.sayNoInternetConnectionReserveAppointment }
      }

      if (reservedAppointment) {
        d.state.appointment = timestamps
        this.referralStore.setCustomField("appointmentSet", true)
        this.track(TrackingEvents.APPOINTMENT_BOOKED)
        this.referralStore.addClinicalNote(`Appointment: ${timeStart}-${timeEnd}`)
        return { nextStep: this.goToGoodbye }
      }

      if (!reservedAppointment || reservedAppointmentStatus === IAppointmentStatus.RequestFailed) {
        this.track(TrackingEvents.APPOINTMENT_BOOKING_FAILED)
        d.state.tempAppointment = undefined
        d.state.retryReserveAppointment ??= 0
        d.state.retryReserveAppointment = d.state.retryReserveAppointment + 1
        if (d.state.retryReserveAppointment > 2) {
          return { nextStep: this.sayAppointmentBookingFailed }
        }
      }
      return {
        body: ["Hmmm... something went wrong while booking your appointment", "Please try again"],
        nextStep: this.getAvailableAppointments
      }
    }
    d.state.tempAppointment = undefined
    return { nextStep: this.getAvailableAppointments }
  }

  @step.logState
  sayNoInternetConnectionReserveAppointment(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionReserveAppointment"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.handleAppointment
    }
  }

  @step.logState
  sayAppointmentBookingFailed(d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    const name = this.getName(d.state)
    // This should never be the case (sayAppointmentBookingFailed)
    // but adding it as a safeguard 👇
    const hasAppointment = d.state.appointment
    return {
      body: [
        `Sorry ${name}, we're encountering a persistent issue when trying to confirm your appointment booking`,
        hasAppointment
          ? "Your referral has been submitted successfully"
          : `Your referral has been submitted successfully, so we'll ask one of the ${serviceName} team to give you a call to organise your appointment`
      ],
      nextStep: this.goToGoodbye
    }
  }
}

export default class BookAppointmentVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.BookAppointmentVitality
  readonly name: string = "BookAppointmentVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(BookAppointmentVitalityDialogue.id, new BookAppointmentVitalityScript(), state, snapshot)
  }
}
