import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PeaceOfMindScript } from "./PeaceOfMindScript"
import type { PeaceOfMindScriptState } from "./PeaceOfMindScript"

type State = PeaceOfMindScriptState

export class PeaceOfMindGPScript extends PeaceOfMindScript {
  readonly name: string = "PeaceOfMindGPScript"
}

export default class PeaceOfMindGPDialogue extends Dialogue<State> {
  static id = DialogueIDs.PeaceOfMindGP
  readonly name: string = "PeaceOfMindGPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PeaceOfMindGPDialogue.id, new PeaceOfMindGPScript(), state, snapshot)
  }
}
