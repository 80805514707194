import { step } from "../../../backend/chatbot/decorators/step"
import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import { ReferralType } from "../../../models/Constants"

type State = AssessmentPitchIAPTScriptState
export type AssessmentPitchSABPState = State

export class AssessmentPitchSABPScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchSABPScript"

  /** Generic Handlers */

  @step.logState
  checkForNeedToBeCalled(d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeCanSpeedUpReferral }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Well ${name}, I've actually got something more I can offer you`,
        "With some more information, I can match you up to a therapist that can treat you best"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sounds interesting 🤔", value: true },
          { body: "Okay", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWeCanSpeedUpReferral
    }
  }

  @step.logState
  askWannaDoQuestionnaires(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName

    return {
      body: [
        `Good news, ${name}`,
        "Your referral is ready to go",
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "This should take around 10 minutes",
        "And I will use the results to match you with the best therapist for your needs",
        "What do you think? Shall we go through the questions together now?"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoQuestionnaires"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sure!", value: "sure" },
          { body: "Okay... If it could help speed things up", value: "okay" },
          {
            body: `No thanks, I'll wait for ${iaptName?.split(" service")[0]} to contact me`,
            value: "no"
          }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaDoQuestionnaires
    }
  }

  getReferralTypeForRisk(_state: State): string | undefined {
    if (this.clinicalStore.isHighRisk) return ReferralType.RISK_PATIENT
    if (this.clinicalStore.isModerateRisk) return ReferralType.RISK_PATIENT
  }

  getCustomReferralType(state: State): string | undefined {
    const currentMHTreatment = this.referralStore.getCustomField("currentMHTreatment")
    const hasCurrentMHTreatment = !!currentMHTreatment?.length
    if (hasCurrentMHTreatment) return "Extended Assessment (User seeing other MH professionals)"
  }

  async onHandleWeCanSpeedUpReferral(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askWannaDoQuestionnaires }
  }
}

export default class AssessmentPitchSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchSABP
  readonly name: string = "AssessmentPitchSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchSABPDialogue.id, new AssessmentPitchSABPScript(), state, snapshot)
  }
}
