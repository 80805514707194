import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameIAPTScript } from "./GetNameIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { GetNameScriptState } from "./GetNameScript"

type State = GetNameScriptState
export type GetNameDemoPrivateProvidersScriptState = State

export class GetNameDemoPrivateProvidersScript extends GetNameIAPTScript {
  readonly name: string = "GetNameDemoPrivateProvidersScript"

  /** Script Steps */

  @step
  step1(d: IStepData<State>): IStepResult {
    const hasName = d.state.name && d.state.username
    return { nextStep: hasName ? this.end : this.askFullName }
  }
}

export default class GetNameDemoPrivateProvidersDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameDemoPrivateProviders
  readonly name: string = "GetNameDemoPrivateProvidersDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      GetNameDemoPrivateProvidersDialogue.id,
      new GetNameDemoPrivateProvidersScript(),
      state,
      snapshot
    )
  }
}
