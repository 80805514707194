import { step } from "./decorators/step"
import Trackable from "../../models/Trackable"
import { LoggableType } from "../../models/Logger/LoggableType"
import invariant from "../../utils/invariant"
import firstName from "../../utils/firstName"
import lastName from "../../utils/lastName"
import { arrayUpsert } from "../../utils/array"
import type IPrompt from "./models/IPrompt"
import type { IPersistableUserResponse } from "../../models/IUserResponse"
import type Dialogue from "./Dialogue"
import type { IStep, IStepData, IStepResult } from "./models/IStep"
import { Titles } from "../../models/Constants"

export interface ScriptState {
  username?: string
  nameTitle?: Titles
  preferredName?: string
  undoBlocked?: boolean
}

export default abstract class Script<State extends ScriptState> extends Trackable {
  /** Optional Abstract Generic Handlers */

  onHandleTriggerWords?(
    state: State,
    triggerWords: string[],
    input?: string,
    nextStep?: IStep<State>
  ): Promise<IStepResult | void>

  getCrisisDialogue?(state: State): (new (...args: any[]) => Dialogue<any>) | undefined

  /** Script Steps */

  @step.logState
  start(_data: IStepData<State>): IStepResult<State> | Promise<IStepResult<State>> {
    throw new Error("start script is not implemented")
  }

  @step
  end(_data: IStepData<State>): IStepResult<State> {
    return {}
  }

  /** Generic Handlers */

  getLoggableState(state: State): any {
    return state
  }

  logBreadcrumb(message: string, state?: State, extra?: Record<string, any>): void {
    try {
      let data = state ? this.getLoggableState(state) : undefined
      if (extra) {
        data = { ...data, ...extra }
      }
      super.logBreadcrumb(message, data)
    } catch (e) {
      console.log(`${this.name} - logBreadcrumb: ${e.message}`)
    }
  }

  trackStep(name?: string): void {
    this.track(`Entering ${this.name}.${name}`)
  }

  getName(state: State): string {
    return firstName(state.username) ?? ""
  }

  getLastName(state: State): string {
    return lastName(state.username) ?? ""
  }

  blockUndo(state: State): void {
    state.undoBlocked = true
  }

  enableUndo(state: State): void {
    state.undoBlocked = false
  }

  getPrompt(state: State, prompt: Partial<IPrompt>): IPrompt {
    invariant(state, "You need to provide the step state")
    invariant(prompt, "You need to provide the partial prompt")
    return {
      ...prompt,
      isUndoAble: prompt.isUndoAble ?? !state.undoBlocked
    } as IPrompt
  }

  normalizeAnswer(answer: unknown): string {
    return answer ? `${answer}` : ""
  }

  saveResponse<T extends IPersistableUserResponse>(item: T, state: State, key: keyof State): void {
    if (state[key] == null) {
      state[key as string] = [] as T[]
    }
    arrayUpsert(state[key as string], item, i => i.id === item.id)
  }

  updateState(state: State, newState?: Partial<State>): void {
    if (!newState) {
      return
    }
    const newKeys = Object.keys(newState)
    for (let i = 0, { length } = newKeys; i < length; i++) {
      const key = newKeys[i]
      state[key] = newState[key]
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  startTyping(): void {}
}

Script.setLogType(LoggableType.Script)
