import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  TreatmentOptionsIAPTScript,
  TreatmentOptionsIAPTScriptState
} from "./TreatmentOptionsIAPTDialogue"

type State = TreatmentOptionsIAPTScriptState

export class TreatmentOptionsTrentPTSScript extends TreatmentOptionsIAPTScript {
  readonly name: string = "TreatmentOptionsTrentPTSScript"
}

export default class TreatmentOptionsTrentPTSDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsTrentPTS
  readonly name: string = "TreatmentOptionsTrentPTSDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      TreatmentOptionsTrentPTSDialogue.id,
      new TreatmentOptionsTrentPTSScript(),
      state,
      snapshot
    )
  }
}
