import React from "react"
import { observer } from "mobx-react"
import { useMediaQuery } from "@material-ui/core"
import { useBotStore, useConfigStore, useRootStore } from "./RootStoreContext"

/** Setup */
const initialNewMessagesCount = +(sessionStorage.getItem("@Chat:newMessagesCount") || 0)
const initialOpenState = +(sessionStorage.getItem("@Chat:chatWindowOpen") ?? 0)
const hasInitialOpenState = sessionStorage.getItem("@Chat:chatWindowOpen") != null

export interface ILauncherContext {
  isOpen: boolean
  newMessagesCount: number
  onToggle: (props?: { skipIfClosed?: boolean; skipIfOpened?: boolean }) => void
}

const LauncherContext = React.createContext<ILauncherContext | null>(null)

interface Props {
  children: React.ReactNode | React.ReactNode[]
}

export const LauncherContextProvider: React.FC<Props> = observer((props: Props) => {
  const root = useRootStore()
  const config = useConfigStore()
  const botStore = useBotStore()
  const [isOpen, setIsOpen] = React.useState(!!initialOpenState)
  const [newMessagesCount, setNewMessagesCount] = React.useState(initialNewMessagesCount)
  const isMobile = useMediaQuery("(max-width: 991.98px)")
  const OPEN_DELAY = isMobile ? 20 * 1000 : 3 * 1000
  const autoOpenTriggered = React.useRef(false)

  React.useEffect(() => root.onAppLaunch(), [root])

  const changeNewMessagesCount = React.useCallback(
    (newValue: number) => {
      if (!isOpen) {
        sessionStorage.setItem("@Chat:newMessagesCount", JSON.stringify(newValue))
        setNewMessagesCount(newValue)
      }
    },
    [isOpen, setNewMessagesCount]
  )

  const onToggle = React.useCallback(
    (props?: { skipIfClosed?: boolean; skipIfOpened?: boolean }) => {
      const { skipIfClosed, skipIfOpened } = props || {}
      setIsOpen(currentIsOpen => {
        if ((skipIfOpened && currentIsOpen) || (skipIfClosed && !currentIsOpen)) {
          return currentIsOpen
        }
        if (!currentIsOpen) {
          changeNewMessagesCount(0)
        }
        return !currentIsOpen
      })
    },
    [changeNewMessagesCount]
  )

  React.useEffect(() => {
    const listener = botStore.bot?.addListener(
      "newMessage", //
      () => changeNewMessagesCount(newMessagesCount + 1)
    )
    return () => listener?.dispose?.()
  }, [isOpen, newMessagesCount, botStore.bot, changeNewMessagesCount])

  React.useEffect(() => {
    if (config.fullscreen) {
      setIsOpen(true)
      return
    }
    if (hasInitialOpenState) return
    const t = setTimeout(() => {
      if (!autoOpenTriggered.current) {
        onToggle({ skipIfOpened: true })
        autoOpenTriggered.current = true
      }
    }, OPEN_DELAY)
    return () => clearTimeout(t)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OPEN_DELAY])

  React.useEffect(() => {
    sessionStorage.setItem("@Chat:chatWindowOpen", isOpen ? "1" : "0")
  }, [isOpen])

  const value = React.useMemo(
    () => ({ isOpen, onToggle, newMessagesCount }), //
    [isOpen, onToggle, newMessagesCount]
  )

  return <LauncherContext.Provider value={value} {...props} />
})

export function useLauncherContext(): ILauncherContext {
  const ctx = React.useContext(LauncherContext)
  if (!ctx) {
    throw new Error("useLauncherContext must be used inside a LauncherContextProvider")
  }
  return ctx
}
