import { DiscussionSteps } from "../../models/Constants"

export const stepsOrder = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.Permissions,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_KM_CCG = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.BookMentalHealthProfessional,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Goodbye
]

export const stepsOrder_HEALIX = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Goodbye
]

export const stepsOrder_VITALITY = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.BookAppointment,
  DiscussionSteps.Goodbye
]

export const stepsOrder_DEMO_PRIVATE_PROVIDERS = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Goodbye
]

export const stepsOrder_Insight_Waitlist = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.Goodbye
]

export const stepsOrder_DEMO_CONFERENCE = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.SelfReferralPitch
]
