import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentPitchIAPTScriptState
export type AssessmentPitchVHGState = State
export class AssessmentPitchVHGScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchVHGScript"

  /** Script Steps */

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "And I will use the results to match you with the best therapist for your needs"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.end
    }
  }

  @step.logState
  askWannaDoQuestionnaires(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [
        "To do this, we need to go through a few more questions together",
        `Alternatively, we can finish our conversation here, and someone from ${iaptName} will be in touch over the next 3 working days to book you in for a clinical assessment (which usually takes place 2-8 weeks after booking)`,
        "What do you think? Shall we go through the questions together now?"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoQuestionnaires"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sure!", value: "sure" },
          { body: "Okay... If it could help speed things up", value: "okay" },
          {
            body: "No thanks, I'll wait for you to call me in the next 3 working days",
            value: "no"
          }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaDoQuestionnaires
    }
  }

  getCustomReferralType(state: State): string | undefined {
    if (this.clinicalStore.assessmentFinished) return "Limbic Assessment"
    return "Self Referral"
  }
}

export default class AssessmentPitchVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchVHG
  readonly name: string = "AssessmentPitchVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchVHGDialogue.id, new AssessmentPitchVHGScript(), state, snapshot)
  }
}
