import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"

type State = EligibilityCheckWithPDSScriptState

export type EligibilityCheckLincolnshireScriptState = State

export class EligibilityCheckLincolnshireScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckLincolnshireScript"

  /** Script Steps */

  /** Generic Handlers */
}

export default class EligibilityCheckLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckLincolnshire
  readonly name: string = "EligibilityCheckLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckLincolnshireDialogue.id,
      new EligibilityCheckLincolnshireScript(),
      state,
      snapshot
    )
  }
}
