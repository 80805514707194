import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"
import { sidebarsMap } from "./sidebarsMap"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  const CustomSidebar = sidebarsMap[config.key]
  if (CustomSidebar) return <CustomSidebar {...props} />

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img style={{ marginRight: 24 }} src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Refer yourself to {config.organisationName}</h3>
        </div>
        <p>
          We are your local NHS talking therapies service. It’s a free service. We are open and
          ready to support you. We offer a range of short-term psychological therapies to adults.
        </p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card full-height">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
