import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { EligibilityCheckTrentPTSScript } from "./EligibilityCheckTrentPTSDialogue"
import getIAPTById from "../../../utils/getIAPTById"
import { IAPTIDs } from "../../../models/IIAPTService"
import type { IGPService } from "../../../models/IGPService"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = EligibilityCheckIAPTState
export type EligibilityCheckTrentPTSDemoState = State
export class EligibilityCheckTrentPTSDemoScript extends EligibilityCheckTrentPTSScript {
  readonly name: string = "EligibilityCheckTrentPTSDemoScript"

  /** Script Steps */

  @step.logState
  handleSelectYourGP(d: IStepData<State, IGPService | null>): IStepResult {
    this.setGP(d.state, d.response || undefined)
    if (!d.state.gp) return { nextStep: this.sayItsImportantToFindGP }

    const isDerby = d.state.gp?.ccg.id === "10398209"
    if (isDerby) {
      const iapt = getIAPTById(IAPTIDs.TRENT_DERBY_WOODLANDS_DEMO)
      this.setIAPT(d.state, iapt)
    }

    const isNotts = d.state.gp?.ccg.id === "10880421"
    if (isNotts) {
      const iapt = getIAPTById(IAPTIDs.TRENT_NOTTS_DEMO)
      this.setIAPT(d.state, iapt)
    }
    return { nextStep: this.checkUnderAgedForIAPT }
  }
}

export default class EligibilityCheckTrentPTSDemoDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckTrentPTSDemo
  readonly name: string = "EligibilityCheckTrentPTSDemoDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckTrentPTSDemoDialogue.id,
      new EligibilityCheckTrentPTSScript(),
      state,
      snapshot
    )
  }
}
