import { IClinicalPath, IClinicalPathOverride } from "../../../models/IClinicalPath"

//prettier-ignore
export const clinicalPathsTrent: Record<IClinicalPath["id"], IClinicalPathOverride> = {
  complex_comorbid: { defaultReferralType: "Step 3 CBT (Complex Comorbid)" },
  undetermined: { defaultReferralType: "Limbic Self Referral (Undetermined)" },
  "1": { defaultReferralType: "Step 2 Trainee (Below Caseness)" },
  "2": { defaultReferralType: "Step 2 Trainee (Below Caseness)" },
  "3": { defaultReferralType: "Step 2 Trainee (Below Caseness)" },
  "4": { defaultReferralType: "Step 2 Trainee (Moderate Depression)" },
  "5": { defaultReferralType: "Step 2 (Moderate Depression, Moderate Disability)" },
  "6": { defaultReferralType: "Step 2 (Moderate Depression, Severe Disability)" },
  "7": { defaultReferralType: "Step 2 Trainee (Moderately Severe Depression)" },
  "8": { defaultReferralType: "Step 2 (Moderately Severe Depression, Moderate Disability)" },
  "9": { defaultReferralType: "Step 3 Counselling (Moderately Severe Depression, Severe Disability)" },
  "10": { defaultReferralType: "Step 3 Counselling (Severe Depression)" },
  "11": { defaultReferralType: "Step 3 Counselling (Severe Depression, Moderate Disability)" },
  "12": { defaultReferralType: "Step 3 Counselling (Severe Depression, Severe Disability)" },
  "13": { defaultReferralType: "Step 3 CBT (Social Phobia)" },
  "14": { defaultReferralType: "Step 3 CBT (Social Phobia, Moderate Disability)" },
  "15": { defaultReferralType: "Step 3 CBT (Social Phobia, Severe Disability)" },
  "16": { defaultReferralType: "Step 3 CBT (Specific Phobia)" },
  "17": { defaultReferralType: "Step 3 CBT (Specific Phobia, Moderate Disability)" },
  "18": { defaultReferralType: "Step 3 CBT (Specific Phobia, Severe Disability)" },
  "19": { defaultReferralType: "Step 3 CBT (Needle/Blood/Injury Phobia)" },
  "20": { defaultReferralType: "Step 3 CBT (Needle/Blood/Injury Phobia, Moderate Disability)" },
  "21": { defaultReferralType: "Step 3 CBT (Needle/Blood/Injury Phobia, Severe Disability)" },
  "22": { defaultReferralType: "Step 2 Trainee (Panic)" },
  "23": { defaultReferralType: "Step 2 (Panic, Moderate Disability)" },
  "24": { defaultReferralType: "Step 3 CBT (Severe Panic, High Disability)" },
  "25": { defaultReferralType: "Step 2 Trainee (Moderate GAD/Worry)" },
  "26": { defaultReferralType: "Step 2 Trainee (Moderate GAD/Worry, Moderate Disability)" },
  "27": { defaultReferralType: "Step 2 (Moderate GAD/Worry, Severe Disability)" },
  "28": { defaultReferralType: "Step 2 (Severe GAD/Worry)" },
  "29": { defaultReferralType: "Step 2 (Severe GAD/Worry, Moderate Disability)" },
  "30": { defaultReferralType: "Step 3 CBT (Severe GAD/Worry, Severe Disability)" },
  "31": { defaultReferralType: "Step 3 CBT (Health Anxiety)" },
  "32": { defaultReferralType: "Step 3 CBT (Health Anxiety, Moderate Disability)" },
  "33": { defaultReferralType: "Step 3 CBT (Health Anxiety, Severe Disability)" },
  "34": { defaultReferralType: "Step 3 CBT (OCD)" },
  "35": { defaultReferralType: "Step 3 CBT (OCD, Moderate Disability)" },
  "36": { defaultReferralType: "Step 3 CBT (OCD, Severe Disability)" },
  "37": { defaultReferralType: "Step 3 CBT (PTSD)" },
  "38": { defaultReferralType: "Step 3 CBT (PTSD, Moderate Disability)" },
  "39": { defaultReferralType: "Step 3 CBT (PTSD, Severe Disability)" },
  "40": { defaultReferralType: "Step 2 (Anxiety NOS)" },
  "41": { defaultReferralType: "Step 3 CBT (Anxiety NOS, Moderate Disability)" },
  "42": { defaultReferralType: "Step 3 CBT (Anxiety NOS, Severe Disability)" }
}
