import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  TreatmentOptionsIAPTScript,
  TreatmentOptionsIAPTScriptState
} from "./TreatmentOptionsIAPTDialogue"
import { ReferralType } from "../../../models/Constants"

type State = TreatmentOptionsIAPTScriptState

export class TreatmentOptionsSABPScript extends TreatmentOptionsIAPTScript {
  readonly name: string = "TreatmentOptionsSABPScript"

  /** Generic Handlers */

  getCustomReferralType(state: State): string | undefined {
    const currentMHTreatment = this.referralStore.getCustomField("currentMHTreatment")
    const hasCurrentMHTreatment = !!currentMHTreatment?.length
    if (hasCurrentMHTreatment) return "Extended Assessment (User seeing other MH professionals)"
  }

  getReferralTypeForRisk(_state: State): string | undefined {
    if (this.clinicalStore.isHighRisk) return ReferralType.RISK_PATIENT
    if (this.clinicalStore.isModerateRisk) return ReferralType.RISK_PATIENT
  }
}

export default class TreatmentOptionsSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsSABP
  readonly name: string = "TreatmentOptionsSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(TreatmentOptionsSABPDialogue.id, new TreatmentOptionsSABPScript(), state, snapshot)
  }
}
