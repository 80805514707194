import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import dialoguesRegistry from "../../dialoguesRegistry"
import TreatmentOptionsScript, { TreatmentOptionsScriptState } from "./TreatmentOptionsScript"
import type { IStep, IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = TreatmentOptionsScriptState

export class TreatmentOptionsCCGScript extends TreatmentOptionsScript {
  readonly name: string = "TreatmentOptionsCCGScript"

  /** Script Steps */

  @step.logState
  goToBookAProfessional(d: IStepData<State>): IStepResult {
    const BookMentalHealthProfessionalDialogue = dialoguesRegistry.get(
      DialogueIDs.BookMentalHealthProfessional
    )
    return {
      nextDialogue: new BookMentalHealthProfessionalDialogue({ ...d.state }),
      nextStep: this.end
    }
  }

  @step.logState
  goToWellBeing(d: IStepData<State>): IStepResult {
    const WellBeingDialogue = dialoguesRegistry.get(DialogueIDs.WellBeingCCG)
    return {
      nextDialogue: new WellBeingDialogue({ ...d.state }),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getBelowCasenessStep(state: State): IStep<State> | undefined {
    if (state.isBelowCaseness) {
      return this.goToWellBeing
    }
    return this.goToBookAProfessional
  }
}

export default class TreatmentOptionsCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsCCG
  readonly name: string = "TreatmentOptionsCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(TreatmentOptionsCCGDialogue.id, new TreatmentOptionsCCGScript(), state, snapshot)
  }
}
