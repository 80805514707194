import React, { useCallback, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import moment from "moment"
import { useChatStore, useReferralStore } from "../../contexts/RootStoreContext"
import delay from "../../../utils/delay"
import "./InactivityIndicator.scss"

const minutes = 1000 * 60
const IDLE_THRESHOLD = Number(process.env.REACT_APP_IDLE_THRESHOLD ?? 30 * minutes)
const IDLE_THRESHOLD_WARNING = Number(process.env.REACT_APP_IDLE_THRESHOLD_WARNING ?? 3 * minutes)

function InactivityIndicator(): JSX.Element | null {
  const referralStore = useReferralStore()
  const chatStore = useChatStore()
  const interval = useRef<NodeJS.Timeout>()
  const [message, setMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const { idleThreshold, idleThresholdWarning, increaseIdleThresholds } = useIdleThresholds(
    chatStore.lastMessage?.createdAt
  )

  const onPostponeClick = useCallback(async () => {
    setIsLoading(true)
    await delay(1.5)
    increaseIdleThresholds()
    referralStore
      .updateSyncAt()
      .catch(e => console.log(e))
      .finally(() => setIsLoading(false))
  }, [increaseIdleThresholds, referralStore])

  useEffect(() => {
    const stopTimer = () => {
      setMessage(undefined)
      clearInterval(interval.current!)
    }

    const tick = () => {
      if (referralStore.referralSubmitted) {
        stopTimer()
        return
      }

      const now = moment.utc().toDate().getTime()
      const lastMessageTime = moment.utc(chatStore.lastMessage?.createdAt).toDate().getTime()
      const lastMessageDiff = now - lastMessageTime
      const isIdle = lastMessageDiff >= idleThreshold
      const isIdleSoon = lastMessageDiff >= idleThresholdWarning
      const submissionTime = moment.utc(referralStore.submissionTime).toDate().getTime()
      const submissionDiff = submissionTime - now
      const submitted = now >= submissionTime
      const isSubmittingSoon = submissionDiff > 0 && submissionDiff <= 3 * minutes

      if (submitted || isIdle) {
        referralStore.onReferralSubmitted?.()
        return
      }

      if (!(isSubmittingSoon || isIdleSoon)) {
        setMessage(undefined)
        return
      }

      const relativeTime = isSubmittingSoon
        ? moment.utc(referralStore.submissionTime).fromNow()
        : moment.utc(lastMessageTime + idleThreshold).fromNow()

      const newMessage = `It looks like there hasn’t been any activity for some time.
      Your data will be submitted ${relativeTime}`
      setMessage(newMessage)
    }

    const startTimer = () => {
      clearInterval(interval.current!)
      interval.current = setInterval(tick, 1000)
    }

    if (referralStore.referralCreated) startTimer()
    else stopTimer()

    return () => clearInterval(interval.current!)
  }, [chatStore, idleThreshold, idleThresholdWarning, referralStore, referralStore.referralCreated])

  if (!message) return null

  return (
    <div className="lb-header-inactivity-indicator">
      {message}
      <button className="lb-header-inactivity-indicator-button" onClick={onPostponeClick}>
        {isLoading ? "Loading..." : "Postpone"}
      </button>
    </div>
  )
}

export default observer(InactivityIndicator)

function useIdleThresholds(lastMessageTime?: Date) {
  const [idleThreshold, setIdleThreshold] = useState(IDLE_THRESHOLD)
  const [idleThresholdWarning, setIdleThresholdWarning] = useState(
    IDLE_THRESHOLD - IDLE_THRESHOLD_WARNING
  )

  const increaseIdleThresholds = useCallback(() => {
    const newThreshold = idleThreshold * 2
    setIdleThreshold(newThreshold)
    setIdleThresholdWarning(newThreshold - IDLE_THRESHOLD_WARNING)
  }, [idleThreshold])

  useEffect(() => {
    setIdleThreshold(IDLE_THRESHOLD)
    setIdleThresholdWarning(IDLE_THRESHOLD - IDLE_THRESHOLD_WARNING)
  }, [lastMessageTime])

  return { idleThreshold, idleThresholdWarning, increaseIdleThresholds }
}
