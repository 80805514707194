import React from "react"
import "./InlineButton.scss"
import ISelectable from "../../../models/ISelectable"
import classNames from "classnames"
import BubbleButton from "../BubbleButton/BubbleButton"

interface Props {
  disabled?: boolean
  btn: ISelectable
  fullWidth?: boolean
  buttonClassName?: string
  className?: string
  buttonContainerClassName?: string
  style?: any
  textStyle?: any
  onSelect: (btn: ISelectable) => void
}

export default function InlineButton(props: Props): JSX.Element {
  const {
    disabled,
    btn,
    fullWidth,
    className,
    buttonClassName,
    buttonContainerClassName,
    onSelect,
    style,
    textStyle
  } = props
  const onClick = React.useCallback(() => {
    if (btn.onPress) {
      btn.onPress(btn)
      return
    }
    onSelect(btn)
  }, [btn, onSelect])
  const containerCSS = classNames("lb-inline-button-container", className, { fullWidth, disabled })
  const buttonCSS = classNames(buttonClassName)
  return (
    <button onClick={onClick} className={containerCSS}>
      <BubbleButton
        disabled={!!disabled}
        containerClassName={buttonContainerClassName}
        className={buttonCSS}
        style={style}
      >
        <span className={"lb-inline-button-text"} style={textStyle}>
          {btn.body}
        </span>
      </BubbleButton>
    </button>
  )
}
