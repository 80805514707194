import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScriptState } from "./GetNameScript"
import { GetNameIAPTScript } from "./GetNameIAPTDialogue"

type CommunicationSupport = "sight" | "hearing" | null

interface State extends GetNameScriptState {
  communicationSupport?: CommunicationSupport
  britishSignLanguage?: boolean
  useSignHealth?: boolean
}

export type GetNameInsightScriptState = State

export class GetNameInsightScript extends GetNameIAPTScript {
  readonly name: string = "GetNameInsightScript"

  /** Script Steps */

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Nice to meet you ${name}`,
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: "Nice to meet you too" }]
      },
      nextStep: this.askCommunicationSupport
    }
  }

  @step.logState
  askCommunicationSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "I’m going to check if you need any communication support",
      prompt: {
        id: this.getPromptId("askCommunicationSupport"),
        type: "inlinePicker",
        choices: [
          { body: "Sight 👀", value: "sight" },
          { body: "Hearing 👂", value: "hearing" },
          { body: "Nothing", value: null }
        ],
        isUndoAble: true
      },
      nextStep: this.handleCommunicationSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, CommunicationSupport>) => {
    d.state.communicationSupport = d.response
  })
  handleCommunicationSupport(d: IStepData<State, CommunicationSupport>): IStepResult {
    if (d.response === "sight") return { nextStep: this.goToSightSupport }
    if (d.response === "hearing") return { nextStep: this.goToHearingSupport }
    return { nextStep: this.askWhereDidYouHearAboutUs }
  }

  @step.logState
  goToSightSupport(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Got it 👍",
        "We can do your whole referral right here in chat️",
        "I’m designed to be compatible with screen readers"
      ],
      prompt: {
        id: this.getPromptId("goToSightSupport"),
        type: "inlinePicker",
        choices: [{ body: "Good to know" }],
        isUndoAble: true
      },
      nextStep: this.handleGoToSightSupport
    }
  }

  @step.logState
  handleGoToSightSupport(d: IStepData<State, CommunicationSupport>): IStepResult {
    return { nextStep: this.askWhereDidYouHearAboutUs }
  }

  @step.logState
  goToHearingSupport(_d: IStepData<State>): IStepResult {
    return {
      body: ["Got it 👍", "Would you prefer a therapist who uses British Sign Language?"],
      prompt: {
        id: this.getPromptId("goToHearingSupport"),
        type: "inlinePicker",
        choices: [
          { body: "Yes 👋", value: true },
          { body: "No thanks", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleGoToHearingSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.britishSignLanguage = d.response
  })
  handleGoToHearingSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askSignHealth }
    }
    return { nextStep: this.askWhereDidYouHearAboutUs }
  }

  @step.logState
  askSignHealth(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: [
        "If you like, I can put you through to SignHealth",
        "They specialise in mental health support for people who are Deaf",
        "Here is a link for you to check out [https://signhealth.org.uk/](https://signhealth.org.uk/)"
      ],
      prompt: {
        id: this.getPromptId("askSignHealth"),
        type: "inlinePicker",
        choices: [
          { body: "Switch to SignHealth", value: true },
          { body: `No, carry on with ${organisationName}`, value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleSignHealth
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.useSignHealth = d.response
  })
  handleSignHealth(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.goToSignHealth }
    }
    return { nextStep: this.askWhereDidYouHearAboutUs }
  }

  @step.logState
  goToSignHealth(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `It’s been great chatting to you, ${name}`,
        "I’ll take you there right now",
        "Write 'Hello Limbic' at any time if you want to carry on with me instead"
      ],
      prompt: {
        id: this.getPromptId("goToSignHealth"),
        type: "inlinePicker",
        choices: [
          {
            body: "SignHealth referral 👉",
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute(
                "href",
                "https://gateway.mayden.co.uk/referral-v2/fc684e57-b052-4fec-99ac-5c46ce74a33a"
              )
              a.setAttribute("target", "_blank")
              a.click()
            }
          }
        ],
        textPrompt: {
          placeholder: 'Type "Hello Limbic" to continue here',
          forceValue: true
        }
      },
      nextStep: this.handleSayHelloToContinue
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.useSignHealth = d.response
  })
  handleSayHelloToContinue(d: IStepData<State, boolean>): IStepResult {
    const name = this.getName(d.state)
    return { body: `Welcome back ${name}`, nextStep: this.askWhereDidYouHearAboutUs }
  }

  @step.logState
  askWhereDidYouHearAboutUs(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        d.state.useSignHealth ? "Ok, we're ready to continue!" : "Ok, we're ready!",
        `So, before we start, where did you hear about ${organisationName}?`
      ],
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "inlinePicker",
        choices: [
          { body: "GP recommendation", value: "GP recommendation" },
          { body: "Internet search", value: "Internet search" },
          { body: "Word of mouth", value: "Word of mouth" },
          { body: "Social media", value: "Social media" },
          { body: "Leaflet/poster", value: "Leaflet/poster" },
          { body: "Radio advert", value: "Radio advert" },
          { body: "Billboard", value: "Billboard" },
          { body: "Event", value: "Event" }
        ],
        textPrompt: {
          cancelLabel: "Skip",
          cancelIsEmptySubmit: true,
          forceValue: false
        },
        isUndoAble: true
      },
      nextStep: this.handleWhereDidYouHearAboutUs
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.whereDidYouHearAboutService = d.response
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: GetNameInsightScript) => s.askWhereDidYouHearAboutUs
  })
  handleWhereDidYouHearAboutUs(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }
}

export default class GetNameInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameInsight
  readonly name: string = "GetNameInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameInsightDialogue.id, new GetNameInsightScript(), state, snapshot)
  }
}
