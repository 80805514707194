import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = BaseScriptState
export type SelfReferralPitchIAPTScriptState = State

export class SelfReferralPitchIAPTScript extends BaseScript<State> {
  readonly name: string = "SelfReferralPitchIAPTScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askWannaBookIn }
  }

  @step.logState
  askWannaBookIn(_d: IStepData<State>): IStepResult {
    return {
      body: "In order to book you in, I need to ask you a few questions. Is that okay?",
      prompt: {
        id: this.getPromptId("askWannaBookIn"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Sure" }, { body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaBookIn
    }
  }

  @step.logState
  handleWannaBookIn(_d: IStepData<State, boolean>): IStepResult {
    return {
      body: "Wonderful!",
      nextStep: this.end
    }
  }
}

export default class SelfReferralPitchIAPTDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralPitchIAPT
  readonly name: string = "SelfReferralPitchIAPTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralPitchIAPTDialogue.id, new SelfReferralPitchIAPTScript(), state, snapshot)
  }
}
