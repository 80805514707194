/* eslint-disable @typescript-eslint/no-var-requires */
/* istanbul ignore file */
import { DialogueIDs } from "./DialogueIDs"
import type MainFlowDialogue from "./dialogues/MainFlowDialogue"
import type DateOfBirthDialogue from "./dialogues/ad-hoc/DateOfBirthDialogue"
import type EmailAddressDialogue from "./dialogues/ad-hoc/EmailAddressDialogue"
import type IntroductionIAPTDialogue from "./dialogues/introduction/IntroductionIAPTDialogue"
import type IntroductionGPDialogue from "./dialogues/introduction/IntroductionGPDialogue"
import type IntroductionCCGDialogue from "./dialogues/introduction/IntroductionCCGDialogue"
import type IntroductionHealixDialogue from "./dialogues/introduction/IntroductionHealixDialogue"
import type IntroductionDemoPrivateProvidersDialogue from "./dialogues/introduction/IntroductionDemoPrivateProvidersDialogue"
import type IntroductionInsightWaitlistDialogue from "./dialogues/introduction/IntroductionInsightWaitlistDialogue"
import type IntroductionInsightFollowUpDialogue from "./dialogues/introduction/IntroductionInsightFollowUpDialogue"
import type IntroductionDemoConferenceDialogue from "./dialogues/introduction/IntroductionDemoConferenceDialogue"
import type IntroductionVitalityDialogue from "./dialogues/introduction/IntroductionVitalityDialogue"
import type GetNameIAPTDialogue from "./dialogues/getName/GetNameIAPTDialogue"
import type GetNameCCGDialogue from "./dialogues/getName/GetNameCCGDialogue"
import type GetNameInsightDialogue from "./dialogues/getName/GetNameInsightDialogue"
import type GetNameLincolnshireDialogue from "./dialogues/getName/GetNameLincolnshireDialogue"
import type GetNameHealixDialogue from "./dialogues/getName/GetNameHealixDialogue"
import type GetNameNottinghamshireDialogue from "./dialogues/getName/GetNameNottinghamshireDialogue"
import type GetNameTrentPTSDialogue from "./dialogues/getName/GetNameTrentPTSDialogue"
import type GetNameDemoPrivateProvidersDialogue from "./dialogues/getName/GetNameDemoPrivateProvidersDialogue"
import type GetNameInsightWaitlistDialogue from "./dialogues/getName/GetNameInsightWaitlistDialogue"
import type GetNameInsightFollowUpDialogue from "./dialogues/getName/GetNameInsightFollowUpDialogue"
import type GetNameDemoConferenceDialogue from "./dialogues/getName/GetNameDemoConferenceDialogue"
import type GetNameSABPDialogue from "./dialogues/getName/GetNameSABPDialogue"
import type GetNameVHGDialogue from "./dialogues/getName/GetNameVHGDialogue"
import type GetNameVitalityDialogue from "./dialogues/getName/GetNameVitalityDialogue"
import type EligibilityCheckDemoDialogue from "./dialogues/eligibility/EligibilityCheckDemoDialogue"
import type EligibilityCheckIAPTDialogue from "./dialogues/eligibility/EligibilityCheckIAPTDialogue"
import type EligibilityCheckGPDialogue from "./dialogues/eligibility/EligibilityCheckGPDialogue"
import type EligibilityCheckCCGDialogue from "./dialogues/eligibility/EligibilityCheckCCGDialogue"
import type EligibilityCheckTrentPTSDialogue from "./dialogues/eligibility/EligibilityCheckTrentPTSDialogue"
import type EligibilityCheckTrentPTSDemoDialogue from "./dialogues/eligibility/EligibilityCheckTrentPTSDemoDialogue"
import type EligibilityCheckInsightDialogue from "./dialogues/eligibility/EligibilityCheckInsightDialogue"
import type EligibilityCheckLincolnshireDialogue from "./dialogues/eligibility/EligibilityCheckLincolnshireDialogue"
import type EligibilityCheckNottinghamshireDialogue from "./dialogues/eligibility/EligibilityCheckNottinghamshireDialogue"
import type EligibilityCheckSABPDialogue from "./dialogues/eligibility/EligibilityCheckSABPDialogue"
import type EligibilityCheckVHGDialogue from "./dialogues/eligibility/EligibilityCheckVHGDialogue"
import type EligibilityCheckDemoConferenceDialogue from "./dialogues/eligibility/EligibilityCheckDemoConferenceDialogue"
import type EligibilityCheckVitalityDialogue from "./dialogues/eligibility/EligibilityCheckVitalityDialogue"
import type SuitableServicesSignpostIAPTDialogue from "./dialogues/signposts/SuitableServicesSignpostIAPTDialogue"
import type SuitableServicesSignpostCCGDialogue from "./dialogues/signposts/SuitableServicesSignpostCCGDialogue"
import type SuitableServicesSignpostSABPDialogue from "./dialogues/signposts/SuitableServicesSignpostSABPDialogue"
import type UnderAgeSignpostDialogue from "./dialogues/signposts/UnderAgeSignpostDialogue"
import type UnderAgeSignpostLincolnshireDialogue from "./dialogues/signposts/UnderAgeSignpostLincolnshireDialogue"
import type PeaceOfMindIAPTDialogue from "./dialogues/peaceOfMind/PeaceOfMindIAPTDialogue"
import type PeaceOfMindGPDialogue from "./dialogues/peaceOfMind/PeaceOfMindGPDialogue"
import type PeaceOfMindCCGDialogue from "./dialogues/peaceOfMind/PeaceOfMindCCGDialogue"
import type PeaceOfMindHealixDialogue from "./dialogues/peaceOfMind/PeaceOfMindHealixDialogue"
import type PeaceOfMindDemoPrivateProvidersDialogue from "./dialogues/peaceOfMind/PeaceOfMindDemoPrivateProvidersDialogue"
import type PeaceOfMindVitalityDialogue from "./dialogues/peaceOfMind/PeaceOfMindVitalityDialogue"
import type PermissionsIAPTDialogue from "./dialogues/permissions/PermissionsIAPTDialogue"
import type PermissionsCCGDialogue from "./dialogues/permissions/PermissionsCCGDialogue"
import type PermissionsSABPDialogue from "./dialogues/permissions/PermissionsSABPDialogue"
import type PermissionsInsightDialogue from "./dialogues/permissions/PermissionsInsightDialogue"
import type PermissionsDemoPrivateProvidersDialogue from "./dialogues/permissions/PermissionsDemoPrivateProvidersDialogue"
import type PermissionsVitalityDialogue from "./dialogues/permissions/PermissionsVitalityDialogue"
import type SelfReferralPitchIAPTDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchIAPTDialogue"
import type SelfReferralPitchHealixDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchHealixDialogue"
import type SelfReferralPitchDialogueCCG from "./dialogues/selfReferralPitch/SelfReferralPitchDialogueCCG"
import type SelfReferralPitchDemoPrivateProvidersDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchDemoPrivateProvidersDialogue"
import type SelfReferralPitchDemoConferenceDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchDemoConferenceDialogue"
import type SelfReferralDemoDialogue from "./dialogues/selfReferral/SelfReferralDemoDialogue"
import type SelfReferralCCGDialogue from "./dialogues/selfReferral/SelfReferralCCGDialogue"
import type SelfReferralTrentPTSDialogue from "./dialogues/selfReferral/SelfReferralTrentPTSDialogue"
import type SelfReferralHealixDialogue from "./dialogues/selfReferral/SelfReferralHealixDialogue"
import type SelfReferralInsightDialogue from "./dialogues/selfReferral/SelfReferralInsightDialogue"
import type SelfReferralNottinghamshireDialogue from "./dialogues/selfReferral/SelfReferralNottinghamshireDialogue"
import type SelfReferralSABPDialogue from "./dialogues/selfReferral/SelfReferralSABPDialogue"
import type SelfReferralLincolnshireDialogue from "./dialogues/selfReferral/SelfReferralLincolnshireDialogue"
import type SelfReferralVHGDialogue from "./dialogues/selfReferral/SelfReferralVHGDialogue"
import type SelfReferralDemoPrivateProvidersDialogue from "./dialogues/selfReferral/SelfReferralDemoPrivateProvidersDialogue"
import type SelfReferralInsightWaitlistDialogue from "./dialogues/selfReferral/SelfReferralInsightWaitlistDialogue"
import type SelfReferralDemoConferenceDialogue from "./dialogues/selfReferral/SelfReferralDemoConferenceDialogue"
import type SelfReferralVitalityDialogue from "./dialogues/selfReferral/SelfReferralVitalityDialogue"
import type AssessmentPitchIAPTDialogue from "./dialogues/assessmentPitch/AssessmentPitchIAPTDialogue"
import type AssessmentPitchGPDialogue from "./dialogues/assessmentPitch/AssessmentPitchGPDialogue"
import type AssessmentPitchCCGDialogue from "./dialogues/assessmentPitch/AssessmentPitchCCGDialogue"
import type AssessmentPitchTrentPTSDialogue from "./dialogues/assessmentPitch/AssessmentPitchTrentPTSDialogue"
import type AssessmentPitchHealixDialogue from "./dialogues/assessmentPitch/AssessmentPitchHealixDialogue"
import type AssessmentPitchInsightDialogue from "./dialogues/assessmentPitch/AssessmentPitchInsightDialogue"
import type AssessmentPitchInsightFollowUpDialogue from "./dialogues/assessmentPitch/AssessmentPitchInsightFollowUpDialogue"
import type AssessmentPitchLincolnshireDialogue from "./dialogues/assessmentPitch/AssessmentPitchLincolnshireDialogue"
import type AssessmentPitchNottinghamshireDialogue from "./dialogues/assessmentPitch/AssessmentPitchNottinghamshireDialogue"
import type AssessmentPitchSABPDialogue from "./dialogues/assessmentPitch/AssessmentPitchSABPDialogue"
import type AssessmentPitchVHGDialogue from "./dialogues/assessmentPitch/AssessmentPitchVHGDialogue"
import type AssessmentPitchDemoPrivateProvidersDialogue from "./dialogues/assessmentPitch/AssessmentPitchDemoPrivateProvidersDialogue"
import type AssessmentPitchVitalityDialogue from "./dialogues/assessmentPitch/AssessmentPitchVitalityDialogue"
import type AssessmentDemoDialogue from "./dialogues/assessment/AssessmentDemoDialogue"
import type AssessmentCCGDialogue from "./dialogues/assessment/AssessmentCCGDialogue"
import type AssessmentSABPDialogue from "./dialogues/assessment/AssessmentSABPDialogue"
import type AssessmentHealixDialogue from "./dialogues/assessment/AssessmentHealixDialogue"
import type AssessmentInsightDialogue from "./dialogues/assessment/AssessmentInsightDialogue"
import type AssessmentNottinghamshireDialogue from "./dialogues/assessment/AssessmentNottinghamshireDialogue"
import type AssessmentVHGDialogue from "./dialogues/assessment/AssessmentVHGDialogue"
import type AssessmentTrentPTSDialogue from "./dialogues/assessment/AssessmentTrentPTSDialogue"
import type AssessmentLincolnshireDialogue from "./dialogues/assessment/AssessmentLincolnshireDialogue"
import type AssessmentDemoPrivateProvidersDialogue from "./dialogues/assessment/AssessmentDemoPrivateProvidersDialogue"
import type AssessmentDemoConferenceDialogue from "./dialogues/assessment/AssessmentDemoConferenceDialogue"
import type AssessmentVitalityDialogue from "./dialogues/assessment/AssessmentVitalityDialogue"
import type PHQ9Dialogue from "./surveys/PHQ9/PHQ9"
import type PHQ9SABPDialogue from "./surveys/PHQ9/PHQ9SABPDialogue"
import type PHQ9NottinghamshireDialogue from "./surveys/PHQ9/PHQ9NottinghamshireDialogue"
import type PHQ9VHGDialogue from "./surveys/PHQ9/PHQ9VHGDialogue"
import type PHQ9TrentPTSDialogue from "./surveys/PHQ9/PHQ9TrentPTSDialogue"
import type PHQ9VitalityDialogue from "./surveys/PHQ9/PHQ9VitalityDialogue"
import type RiskPathwayDemoDialogue from "./dialogues/riskPathway/RiskPathwayDemoDialogue"
import type RiskPathwayCCGDialogue from "./dialogues/riskPathway/RiskPathwayCCGDialogue"
import type RiskPathwayTrentPTSDialogue from "./dialogues/riskPathway/RiskPathwayTrentPTSDialogue"
import type RiskPathwayHealixDialogue from "./dialogues/riskPathway/RiskPathwayHealixDialogue"
import type RiskPathwayInsightDialogue from "./dialogues/riskPathway/RiskPathwayInsightDialogue"
import type RiskPathwayInsightFollowUpDialogue from "./dialogues/riskPathway/RiskPathwayInsightFollowUpDialogue"
import type RiskPathwayNottinghamshireDialogue from "./dialogues/riskPathway/RiskPathwayNottinghamshireDialogue"
import type RiskPathwaySABPDialogue from "./dialogues/riskPathway/RiskPathwaySABPDialogue"
import type RiskPathwayVHGDialogue from "./dialogues/riskPathway/RiskPathwayVHGDialogue"
import type RiskPathwayLincolnshireDialogue from "./dialogues/riskPathway/RiskPathwayLincolnshireDialogue"
import type RiskPathwayDemoPrivateProvidersDialogue from "./dialogues/riskPathway/RiskPathwayDemoPrivateProvidersDialogue"
import type RiskPathwayVitalityDialogue from "./dialogues/riskPathway/RiskPathwayVitalityDialogue"
import type GAD7Dialogue from "./surveys/GAD7"
import type IAPTPhobiaScaleDialogue from "./surveys/IAPTPhobiaScale"
import type IAPTEmploymentStatusDialogue from "./surveys/IAPTEmploymentStatus"
import type IAPTWorkAndSocialAdjustmentDialogue from "./surveys/IAPTWorkAndSocialAdjustment"
import type IAPTMedicationDialogue from "./surveys/IAPTMedication"
import type IAPTAccommodationDialogue from "./surveys/IAPTAccommodation"
import type TreatmentOptionsIAPTDialogue from "./dialogues/treatmentOptions/TreatmentOptionsIAPTDialogue"
import type TreatmentOptionsCCGDialogue from "./dialogues/treatmentOptions/TreatmentOptionsCCGDialogue"
import type TreatmentOptionsTrentPTSDialogue from "./dialogues/treatmentOptions/TreatmentOptionsTrentPTSDialogue"
import type TreatmentOptionsSABPDialogue from "./dialogues/treatmentOptions/TreatmentOptionsSABPDialogue"
import type TreatmentOptionsHealixDialogue from "./dialogues/treatmentOptions/TreatmentOptionsHealixDialogue"
import type TreatmentOptionsInsightDialogue from "./dialogues/treatmentOptions/TreatmentOptionsInsightDialogue"
import type TreatmentOptionsVHGDialogue from "./dialogues/treatmentOptions/TreatmentOptionsVHGDialogue"
import type TreatmentOptionsDemoPrivateProvidersDialogue from "./dialogues/treatmentOptions/TreatmentOptionsDemoPrivateProvidersDialogue"
import type TreatmentOptionsVitalityDialogue from "./dialogues/treatmentOptions/TreatmentOptionsVitalityDialogue"
import type BookMentalHealthProfessionalDialogue from "./dialogues/bookProfessional/BookMentalHealthProfessionalDialogue"
import type WellbeingCCGDialogue from "./dialogues/wellbeing/WellbeingCCGDialogue"
import type WellbeingHealixDialogue from "./dialogues/wellbeing/WellbeingHealixDialogue"
import type WellbeingDemoPrivateProvidersDialogue from "./dialogues/wellbeing/WellbeingDemoPrivateProvidersDialogue"
import type GoodbyeDemoDialogue from "./dialogues/goodbye/GoodbyeDemoDialogue"
import type GoodbyeCCGDialogue from "./dialogues/goodbye/GoodbyeCCGDialogue"
import type GoodbyeTrentPTSDialogue from "./dialogues/goodbye/GoodbyeTrentPTSDialogue"
import type GoodbyeSABPDialogue from "./dialogues/goodbye/GoodbyeSABPDialogue"
import type GoodbyeHealixDialogue from "./dialogues/goodbye/GoodbyeHealixDialogue"
import type GoodbyeInsightDialogue from "./dialogues/goodbye/GoodbyeInsightDialogue"
import type GoodbyeInsightFollowUpDialogue from "./dialogues/goodbye/GoodbyeInsightFollowUpDialogue"
import type GoodbyeNottinghamshireDialogue from "./dialogues/goodbye/GoodbyeNottinghamshireDialogue"
import type GoodbyeVHGDialogue from "./dialogues/goodbye/GoodbyeVHGDialogue"
import type GoodbyeLincolnshireDialogue from "./dialogues/goodbye/GoodbyeLincolnshireDialogue"
import type GoodbyeDemoPrivateProvidersDialogue from "./dialogues/goodbye/GoodbyeDemoPrivateProvidersDialogue"
import type GoodbyeVitalityDialogue from "./dialogues/goodbye/GoodbyeVitalityDialogue"
import type CrisisDemoDialogue from "./dialogues/crisis/CrisisDemoDialogue"
import type CrisisCCGDialogue from "./dialogues/crisis/CrisisCCGDialogue"
import type CrisisTrentPTSDialogue from "./dialogues/crisis/CrisisTrentPTSDialogue"
import type CrisisHealixDialogue from "./dialogues/crisis/CrisisHealixDialogue"
import type CrisisInsightDialogue from "./dialogues/crisis/CrisisInsightDialogue"
import type CrisisNottinghamshireDialogue from "./dialogues/crisis/CrisisNottinghamshireDialogue"
import type CrisisSABPDialogue from "./dialogues/crisis/CrisisSABPDialogue"
import type CrisisVHGDialogue from "./dialogues/crisis/CrisisVHGDialogue"
import type CrisisLincolnshireDialogue from "./dialogues/crisis/CrisisLincolnshireDialogue"
import type CrisisDemoPrivateProvidersDialogue from "./dialogues/crisis/CrisisDemoPrivateProvidersDialogue"
import type CrisisVitalityDialogue from "./dialogues/crisis/CrisisVitalityDialogue"
import type BookAppointmentVitalityDialogue from "./dialogues/bookAppointment/BookAppointmentVitalityDialogue"

/**
 * @IMPORTANT: Dialogues in this file MUST NOT be imported directly.
 *             You can import the dialogue as a type only, because
 *             you'll need to use it in the interface below 👇, but
 *             it's important to only fetch dialogues by requiring
 *             them at runtime.
 */

export interface IDialoguesRegistry {
  [DialogueIDs.MainFlow]: typeof MainFlowDialogue
  [DialogueIDs.DateOfBirth]: typeof DateOfBirthDialogue
  [DialogueIDs.EmailAddress]: typeof EmailAddressDialogue
  [DialogueIDs.IntroductionIAPT]: typeof IntroductionIAPTDialogue
  [DialogueIDs.IntroductionGP]: typeof IntroductionGPDialogue
  [DialogueIDs.IntroductionCCG]: typeof IntroductionCCGDialogue
  [DialogueIDs.IntroductionHealix]: typeof IntroductionHealixDialogue
  [DialogueIDs.IntroductionDemoPrivateProviders]: typeof IntroductionDemoPrivateProvidersDialogue
  [DialogueIDs.IntroductionInsightWaitlist]: typeof IntroductionInsightWaitlistDialogue
  [DialogueIDs.IntroductionInsightFollowUp]: typeof IntroductionInsightFollowUpDialogue
  [DialogueIDs.IntroductionDemoConference]: typeof IntroductionDemoConferenceDialogue
  [DialogueIDs.IntroductionVitality]: typeof IntroductionVitalityDialogue
  [DialogueIDs.GetNameIAPT]: typeof GetNameIAPTDialogue
  [DialogueIDs.GetNameCCG]: typeof GetNameCCGDialogue
  [DialogueIDs.GetNameInsight]: typeof GetNameInsightDialogue
  [DialogueIDs.GetNameLincolnshire]: typeof GetNameLincolnshireDialogue
  [DialogueIDs.GetNameHealix]: typeof GetNameHealixDialogue
  [DialogueIDs.GetNameNottinghamshire]: typeof GetNameNottinghamshireDialogue
  [DialogueIDs.GetNameTrentPTS]: typeof GetNameTrentPTSDialogue
  [DialogueIDs.GetNameDemoPrivateProviders]: typeof GetNameDemoPrivateProvidersDialogue
  [DialogueIDs.GetNameInsightWaitlist]: typeof GetNameInsightWaitlistDialogue
  [DialogueIDs.GetNameInsightFollowUp]: typeof GetNameInsightFollowUpDialogue
  [DialogueIDs.GetNameDemoConference]: typeof GetNameDemoConferenceDialogue
  [DialogueIDs.GetNameSABP]: typeof GetNameSABPDialogue
  [DialogueIDs.GetNameVHG]: typeof GetNameVHGDialogue
  [DialogueIDs.GetNameVitality]: typeof GetNameVitalityDialogue
  [DialogueIDs.EligibilityCheckDemo]: typeof EligibilityCheckDemoDialogue
  [DialogueIDs.EligibilityCheckIAPT]: typeof EligibilityCheckIAPTDialogue
  [DialogueIDs.EligibilityCheckGP]: typeof EligibilityCheckGPDialogue
  [DialogueIDs.EligibilityCheckCCG]: typeof EligibilityCheckCCGDialogue
  [DialogueIDs.EligibilityCheckTrentPTS]: typeof EligibilityCheckTrentPTSDialogue
  [DialogueIDs.EligibilityCheckTrentPTSDemo]: typeof EligibilityCheckTrentPTSDemoDialogue
  [DialogueIDs.EligibilityCheckInsight]: typeof EligibilityCheckInsightDialogue
  [DialogueIDs.EligibilityCheckLincolnshire]: typeof EligibilityCheckLincolnshireDialogue
  [DialogueIDs.EligibilityCheckNottinghamshire]: typeof EligibilityCheckNottinghamshireDialogue
  [DialogueIDs.EligibilityCheckSABP]: typeof EligibilityCheckSABPDialogue
  [DialogueIDs.EligibilityCheckVHG]: typeof EligibilityCheckVHGDialogue
  [DialogueIDs.EligibilityCheckDemoConference]: typeof EligibilityCheckDemoConferenceDialogue
  [DialogueIDs.EligibilityCheckVitality]: typeof EligibilityCheckVitalityDialogue
  [DialogueIDs.SuitableServicesSignpostIAPT]: typeof SuitableServicesSignpostIAPTDialogue
  [DialogueIDs.SuitableServicesSignpostCCG]: typeof SuitableServicesSignpostCCGDialogue
  [DialogueIDs.SuitableServicesSignpostSABP]: typeof SuitableServicesSignpostSABPDialogue
  [DialogueIDs.UnderageSignpost]: typeof UnderAgeSignpostDialogue
  [DialogueIDs.UnderageSignpostLincolnshire]: typeof UnderAgeSignpostLincolnshireDialogue
  [DialogueIDs.PeaceOfMindIAPT]: typeof PeaceOfMindIAPTDialogue
  [DialogueIDs.PeaceOfMindGP]: typeof PeaceOfMindGPDialogue
  [DialogueIDs.PeaceOfMindCCG]: typeof PeaceOfMindCCGDialogue
  [DialogueIDs.PeaceOfMindHealix]: typeof PeaceOfMindHealixDialogue
  [DialogueIDs.PeaceOfMindDemoPrivateProviders]: typeof PeaceOfMindDemoPrivateProvidersDialogue
  [DialogueIDs.PeaceOfMindVitality]: typeof PeaceOfMindVitalityDialogue
  [DialogueIDs.PermissionsIAPT]: typeof PermissionsIAPTDialogue
  [DialogueIDs.PermissionsCCG]: typeof PermissionsCCGDialogue
  [DialogueIDs.PermissionsSABP]: typeof PermissionsSABPDialogue
  [DialogueIDs.PermissionsInsight]: typeof PermissionsInsightDialogue
  [DialogueIDs.PermissionsDemoPrivateProviders]: typeof PermissionsDemoPrivateProvidersDialogue
  [DialogueIDs.PermissionsVitality]: typeof PermissionsVitalityDialogue
  [DialogueIDs.SelfReferralPitchIAPT]: typeof SelfReferralPitchIAPTDialogue
  [DialogueIDs.SelfReferralPitchHealix]: typeof SelfReferralPitchHealixDialogue
  [DialogueIDs.SelfReferralPitchCCG]: typeof SelfReferralPitchDialogueCCG
  [DialogueIDs.SelfReferralPitchDemoPrivateProviders]: typeof SelfReferralPitchDemoPrivateProvidersDialogue
  [DialogueIDs.SelfReferralPitchDemoConference]: typeof SelfReferralPitchDemoConferenceDialogue
  [DialogueIDs.SelfReferralDemo]: typeof SelfReferralDemoDialogue
  [DialogueIDs.SelfReferralCCG]: typeof SelfReferralCCGDialogue
  [DialogueIDs.SelfReferralTrentPTS]: typeof SelfReferralTrentPTSDialogue
  [DialogueIDs.SelfReferralHealix]: typeof SelfReferralHealixDialogue
  [DialogueIDs.SelfReferralInsight]: typeof SelfReferralInsightDialogue
  [DialogueIDs.SelfReferralNottinghamshire]: typeof SelfReferralNottinghamshireDialogue
  [DialogueIDs.SelfReferralSABP]: typeof SelfReferralSABPDialogue
  [DialogueIDs.SelfReferralLincolnshire]: typeof SelfReferralLincolnshireDialogue
  [DialogueIDs.SelfReferralVHG]: typeof SelfReferralVHGDialogue
  [DialogueIDs.SelfReferralDemoPrivateProviders]: typeof SelfReferralDemoPrivateProvidersDialogue
  [DialogueIDs.SelfReferralInsightWaitlist]: typeof SelfReferralInsightWaitlistDialogue
  [DialogueIDs.SelfReferralDemoConference]: typeof SelfReferralDemoConferenceDialogue
  [DialogueIDs.SelfReferralVitality]: typeof SelfReferralVitalityDialogue
  [DialogueIDs.AssessmentPitchIAPT]: typeof AssessmentPitchIAPTDialogue
  [DialogueIDs.AssessmentPitchGP]: typeof AssessmentPitchGPDialogue
  [DialogueIDs.AssessmentPitchCCG]: typeof AssessmentPitchCCGDialogue
  [DialogueIDs.AssessmentPitchTrentPTS]: typeof AssessmentPitchTrentPTSDialogue
  [DialogueIDs.AssessmentPitchHealix]: typeof AssessmentPitchHealixDialogue
  [DialogueIDs.AssessmentPitchInsight]: typeof AssessmentPitchInsightDialogue
  [DialogueIDs.AssessmentPitchInsightFollowUp]: typeof AssessmentPitchInsightFollowUpDialogue
  [DialogueIDs.AssessmentPitchLincolnshire]: typeof AssessmentPitchLincolnshireDialogue
  [DialogueIDs.AssessmentPitchNottinghamshire]: typeof AssessmentPitchNottinghamshireDialogue
  [DialogueIDs.AssessmentPitchSABP]: typeof AssessmentPitchSABPDialogue
  [DialogueIDs.AssessmentPitchVHG]: typeof AssessmentPitchVHGDialogue
  [DialogueIDs.AssessmentPitchDemoPrivateProviders]: typeof AssessmentPitchDemoPrivateProvidersDialogue
  [DialogueIDs.AssessmentPitchVitality]: typeof AssessmentPitchVitalityDialogue
  [DialogueIDs.AssessmentDemo]: typeof AssessmentDemoDialogue
  [DialogueIDs.AssessmentCCG]: typeof AssessmentCCGDialogue
  [DialogueIDs.AssessmentSABP]: typeof AssessmentSABPDialogue
  [DialogueIDs.AssessmentHealix]: typeof AssessmentHealixDialogue
  [DialogueIDs.AssessmentInsight]: typeof AssessmentInsightDialogue
  [DialogueIDs.AssessmentNottinghamshire]: typeof AssessmentNottinghamshireDialogue
  [DialogueIDs.AssessmentVHG]: typeof AssessmentVHGDialogue
  [DialogueIDs.AssessmentTrentPTS]: typeof AssessmentTrentPTSDialogue
  [DialogueIDs.AssessmentLincolnshire]: typeof AssessmentLincolnshireDialogue
  [DialogueIDs.AssessmentDemoPrivateProviders]: typeof AssessmentDemoPrivateProvidersDialogue
  [DialogueIDs.AssessmentDemoConference]: typeof AssessmentDemoConferenceDialogue
  [DialogueIDs.AssessmentVitality]: typeof AssessmentVitalityDialogue
  [DialogueIDs.PHQ9]: typeof PHQ9Dialogue
  [DialogueIDs.PHQ9SABP]: typeof PHQ9SABPDialogue
  [DialogueIDs.PHQ9Nottinghamshire]: typeof PHQ9NottinghamshireDialogue
  [DialogueIDs.PHQ9VHG]: typeof PHQ9VHGDialogue
  [DialogueIDs.PHQ9TrentPTS]: typeof PHQ9TrentPTSDialogue
  [DialogueIDs.PHQ9Vitality]: typeof PHQ9VitalityDialogue
  [DialogueIDs.RiskPathwayDemo]: typeof RiskPathwayDemoDialogue
  [DialogueIDs.RiskPathwayCCG]: typeof RiskPathwayCCGDialogue
  [DialogueIDs.RiskPathwayTrentPTS]: typeof RiskPathwayTrentPTSDialogue
  [DialogueIDs.RiskPathwayHealix]: typeof RiskPathwayHealixDialogue
  [DialogueIDs.RiskPathwayInsight]: typeof RiskPathwayInsightDialogue
  [DialogueIDs.RiskPathwayInsightFollowUp]: typeof RiskPathwayInsightFollowUpDialogue
  [DialogueIDs.RiskPathwayNottinghamshire]: typeof RiskPathwayNottinghamshireDialogue
  [DialogueIDs.RiskPathwaySABP]: typeof RiskPathwaySABPDialogue
  [DialogueIDs.RiskPathwayVHG]: typeof RiskPathwayVHGDialogue
  [DialogueIDs.RiskPathwayLincolnshire]: typeof RiskPathwayLincolnshireDialogue
  [DialogueIDs.RiskPathwayDemoPrivateProviders]: typeof RiskPathwayDemoPrivateProvidersDialogue
  [DialogueIDs.RiskPathwayVitality]: typeof RiskPathwayVitalityDialogue
  [DialogueIDs.GAD7]: typeof GAD7Dialogue
  [DialogueIDs.IAPTPhobiaScale]: typeof IAPTPhobiaScaleDialogue
  [DialogueIDs.IAPTEmploymentStatus]: typeof IAPTEmploymentStatusDialogue
  [DialogueIDs.IAPTWorkAndSocialAdjustment]: typeof IAPTWorkAndSocialAdjustmentDialogue
  [DialogueIDs.IAPTMedication]: typeof IAPTMedicationDialogue
  [DialogueIDs.IAPTAccommodation]: typeof IAPTAccommodationDialogue
  [DialogueIDs.TreatmentOptionsIAPT]: typeof TreatmentOptionsIAPTDialogue
  [DialogueIDs.TreatmentOptionsCCG]: typeof TreatmentOptionsCCGDialogue
  [DialogueIDs.TreatmentOptionsTrentPTS]: typeof TreatmentOptionsTrentPTSDialogue
  [DialogueIDs.TreatmentOptionsSABP]: typeof TreatmentOptionsSABPDialogue
  [DialogueIDs.TreatmentOptionsHealix]: typeof TreatmentOptionsHealixDialogue
  [DialogueIDs.TreatmentOptionsInsight]: typeof TreatmentOptionsInsightDialogue
  [DialogueIDs.TreatmentOptionsVHG]: typeof TreatmentOptionsVHGDialogue
  [DialogueIDs.TreatmentOptionsDemoPrivateProviders]: typeof TreatmentOptionsDemoPrivateProvidersDialogue
  [DialogueIDs.TreatmentOptionsVitality]: typeof TreatmentOptionsVitalityDialogue
  [DialogueIDs.BookMentalHealthProfessional]: typeof BookMentalHealthProfessionalDialogue
  [DialogueIDs.WellBeingCCG]: typeof WellbeingCCGDialogue
  [DialogueIDs.WellbeingHealix]: typeof WellbeingHealixDialogue
  [DialogueIDs.WellbeingDemoPrivateProviders]: typeof WellbeingDemoPrivateProvidersDialogue
  [DialogueIDs.GoodbyeDemo]: typeof GoodbyeDemoDialogue
  [DialogueIDs.GoodbyeCCG]: typeof GoodbyeCCGDialogue
  [DialogueIDs.GoodbyeTrentPTS]: typeof GoodbyeTrentPTSDialogue
  [DialogueIDs.GoodbyeSABP]: typeof GoodbyeSABPDialogue
  [DialogueIDs.GoodbyeHealix]: typeof GoodbyeHealixDialogue
  [DialogueIDs.GoodbyeInsight]: typeof GoodbyeInsightDialogue
  [DialogueIDs.GoodbyeInsightFollowUp]: typeof GoodbyeInsightFollowUpDialogue
  [DialogueIDs.GoodbyeNottinghamshire]: typeof GoodbyeNottinghamshireDialogue
  [DialogueIDs.GoodbyeVHG]: typeof GoodbyeVHGDialogue
  [DialogueIDs.GoodbyeLincolnshire]: typeof GoodbyeLincolnshireDialogue
  [DialogueIDs.GoodbyeDemoPrivateProviders]: typeof GoodbyeDemoPrivateProvidersDialogue
  [DialogueIDs.GoodbyeVitality]: typeof GoodbyeVitalityDialogue
  [DialogueIDs.CrisisDemo]: typeof CrisisDemoDialogue
  [DialogueIDs.CrisisCCG]: typeof CrisisCCGDialogue
  [DialogueIDs.CrisisTrentPTS]: typeof CrisisTrentPTSDialogue
  [DialogueIDs.CrisisHealix]: typeof CrisisHealixDialogue
  [DialogueIDs.CrisisInsight]: typeof CrisisInsightDialogue
  [DialogueIDs.CrisisNottinghamshire]: typeof CrisisNottinghamshireDialogue
  [DialogueIDs.CrisisSABP]: typeof CrisisSABPDialogue
  [DialogueIDs.CrisisVHG]: typeof CrisisVHGDialogue
  [DialogueIDs.CrisisLincolnshire]: typeof CrisisLincolnshireDialogue
  [DialogueIDs.CrisisDemoPrivateProviders]: typeof CrisisDemoPrivateProvidersDialogue
  [DialogueIDs.CrisisVitality]: typeof CrisisVitalityDialogue
  [DialogueIDs.BookAppointmentVitality]: typeof BookAppointmentVitalityDialogue
}

export class DialoguesRegistry {
  readonly #registry: Partial<IDialoguesRegistry>

  constructor() {
    this.#registry = {}
  }

  get<T extends keyof IDialoguesRegistry>(id: T): IDialoguesRegistry[T] {
    if (!this.#registry[id]) this.#registry[id] = DialoguesRegistry.require(id)
    return this.#registry[id] as IDialoguesRegistry[T]
  }

  /**
   * Before you WTF yourself to death looking at this, let
   * me shine some light into what happens here. This is
   * basically a replacement for inline requires or imports
   * from one dialogue to another in order to avoid require
   * cycles. Because of how the bot and the dialogues are
   * structured, each dialogue is responsible for pushing
   * the next dialogue into the dialogues stack, which means
   * a lot of dialogues have knowledge of the next dialogue
   * after them so they need to instantiate it within their
   * own steps. So if import the next dialogue right in the
   * same file, then that next dialogue might be importing
   * another dialogue which in turn could be looping back by
   * importing the first dialogue
   * ¯\_(ツ)_/¯
   */
  private static require<T extends keyof IDialoguesRegistry>(id: T): IDialoguesRegistry[T] {
    switch (id) {
      case DialogueIDs.MainFlow:
        return require("./dialogues/MainFlowDialogue").default
      case DialogueIDs.DateOfBirth:
        return require("./dialogues/ad-hoc/DateOfBirthDialogue").default
      case DialogueIDs.EmailAddress:
        return require("./dialogues/ad-hoc/EmailAddressDialogue").default
      case DialogueIDs.IntroductionIAPT:
        return require("./dialogues/introduction/IntroductionIAPTDialogue").default
      case DialogueIDs.IntroductionGP:
        return require("./dialogues/introduction/IntroductionGPDialogue").default
      case DialogueIDs.IntroductionCCG:
        return require("./dialogues/introduction/IntroductionCCGDialogue").default
      case DialogueIDs.IntroductionHealix:
        return require("./dialogues/introduction/IntroductionHealixDialogue").default
      case DialogueIDs.IntroductionDemoPrivateProviders:
        return require("./dialogues/introduction/IntroductionDemoPrivateProvidersDialogue").default
      case DialogueIDs.IntroductionInsightWaitlist:
        return require("./dialogues/introduction/IntroductionInsightWaitlistDialogue").default
      case DialogueIDs.IntroductionInsightFollowUp:
        return require("./dialogues/introduction/IntroductionInsightFollowUpDialogue").default
      case DialogueIDs.IntroductionDemoConference:
        return require("./dialogues/introduction/IntroductionDemoConferenceDialogue").default
      case DialogueIDs.IntroductionVitality:
        return require("./dialogues/introduction/IntroductionVitalityDialogue").default
      case DialogueIDs.GetNameIAPT:
        return require("./dialogues/getName/GetNameIAPTDialogue").default
      case DialogueIDs.GetNameCCG:
        return require("./dialogues/getName/GetNameCCGDialogue").default
      case DialogueIDs.GetNameInsight:
        return require("./dialogues/getName/GetNameInsightDialogue").default
      case DialogueIDs.GetNameLincolnshire:
        return require("./dialogues/getName/GetNameLincolnshireDialogue").default
      case DialogueIDs.GetNameHealix:
        return require("./dialogues/getName/GetNameHealixDialogue").default
      case DialogueIDs.GetNameNottinghamshire:
        return require("./dialogues/getName/GetNameNottinghamshireDialogue").default
      case DialogueIDs.GetNameTrentPTS:
        return require("./dialogues/getName/GetNameTrentPTSDialogue").default
      case DialogueIDs.GetNameDemoPrivateProviders:
        return require("./dialogues/getName/GetNameDemoPrivateProvidersDialogue").default
      case DialogueIDs.GetNameInsightWaitlist:
        return require("./dialogues/getName/GetNameInsightWaitlistDialogue").default
      case DialogueIDs.GetNameInsightFollowUp:
        return require("./dialogues/getName/GetNameInsightFollowUpDialogue").default
      case DialogueIDs.GetNameDemoConference:
        return require("./dialogues/getName/GetNameDemoConferenceDialogue").default
      case DialogueIDs.GetNameSABP:
        return require("./dialogues/getName/GetNameSABPDialogue").default
      case DialogueIDs.GetNameVHG:
        return require("./dialogues/getName/GetNameVHGDialogue").default
      case DialogueIDs.GetNameVitality:
        return require("./dialogues/getName/GetNameVitalityDialogue").default
      case DialogueIDs.EligibilityCheckDemo:
        return require("./dialogues/eligibility/EligibilityCheckDemoDialogue").default
      case DialogueIDs.EligibilityCheckIAPT:
        return require("./dialogues/eligibility/EligibilityCheckIAPTDialogue").default
      case DialogueIDs.EligibilityCheckGP:
        return require("./dialogues/eligibility/EligibilityCheckGPDialogue").default
      case DialogueIDs.EligibilityCheckCCG:
        return require("./dialogues/eligibility/EligibilityCheckCCGDialogue").default
      case DialogueIDs.EligibilityCheckTrentPTS:
        return require("./dialogues/eligibility/EligibilityCheckTrentPTSDialogue").default
      case DialogueIDs.EligibilityCheckTrentPTSDemo:
        return require("./dialogues/eligibility/EligibilityCheckTrentPTSDemoDialogue").default
      case DialogueIDs.EligibilityCheckInsight:
        return require("./dialogues/eligibility/EligibilityCheckInsightDialogue").default
      case DialogueIDs.EligibilityCheckLincolnshire:
        return require("./dialogues/eligibility/EligibilityCheckLincolnshireDialogue").default
      case DialogueIDs.EligibilityCheckNottinghamshire:
        return require("./dialogues/eligibility/EligibilityCheckNottinghamshireDialogue").default
      case DialogueIDs.EligibilityCheckSABP:
        return require("./dialogues/eligibility/EligibilityCheckSABPDialogue").default
      case DialogueIDs.EligibilityCheckVHG:
        return require("./dialogues/eligibility/EligibilityCheckVHGDialogue").default
      case DialogueIDs.EligibilityCheckDemoConference:
        return require("./dialogues/eligibility/EligibilityCheckDemoConferenceDialogue").default
      case DialogueIDs.EligibilityCheckVitality:
        return require("./dialogues/eligibility/EligibilityCheckVitalityDialogue").default
      case DialogueIDs.SuitableServicesSignpostIAPT:
        return require("./dialogues/signposts/SuitableServicesSignpostIAPTDialogue").default
      case DialogueIDs.SuitableServicesSignpostCCG:
        return require("./dialogues/signposts/SuitableServicesSignpostCCGDialogue").default
      case DialogueIDs.SuitableServicesSignpostSABP:
        return require("./dialogues/signposts/SuitableServicesSignpostSABPDialogue").default
      case DialogueIDs.UnderageSignpost:
        return require("./dialogues/signposts/UnderAgeSignpostDialogue").default
      case DialogueIDs.UnderageSignpostLincolnshire:
        return require("./dialogues/signposts/UnderAgeSignpostLincolnshireDialogue").default
      case DialogueIDs.PeaceOfMindIAPT:
        return require("./dialogues/peaceOfMind/PeaceOfMindIAPTDialogue").default
      case DialogueIDs.PeaceOfMindGP:
        return require("./dialogues/peaceOfMind/PeaceOfMindGPDialogue").default
      case DialogueIDs.PeaceOfMindCCG:
        return require("./dialogues/peaceOfMind/PeaceOfMindCCGDialogue").default
      case DialogueIDs.PeaceOfMindHealix:
        return require("./dialogues/peaceOfMind/PeaceOfMindHealixDialogue").default
      case DialogueIDs.PeaceOfMindDemoPrivateProviders:
        return require("./dialogues/peaceOfMind/PeaceOfMindDemoPrivateProvidersDialogue").default
      case DialogueIDs.PeaceOfMindVitality:
        return require("./dialogues/peaceOfMind/PeaceOfMindVitalityDialogue").default
      case DialogueIDs.PermissionsIAPT:
        return require("./dialogues/permissions/PermissionsIAPTDialogue").default
      case DialogueIDs.PermissionsCCG:
        return require("./dialogues/permissions/PermissionsCCGDialogue").default
      case DialogueIDs.PermissionsSABP:
        return require("./dialogues/permissions/PermissionsSABPDialogue").default
      case DialogueIDs.PermissionsInsight:
        return require("./dialogues/permissions/PermissionsInsightDialogue").default
      case DialogueIDs.PermissionsDemoPrivateProviders:
        return require("./dialogues/permissions/PermissionsDemoPrivateProvidersDialogue").default
      case DialogueIDs.PermissionsVitality:
        return require("./dialogues/permissions/PermissionsVitalityDialogue").default
      case DialogueIDs.SelfReferralPitchIAPT:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchIAPTDialogue").default
      case DialogueIDs.SelfReferralPitchHealix:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchHealixDialogue").default
      case DialogueIDs.SelfReferralPitchCCG:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchDialogueCCG").default
      case DialogueIDs.SelfReferralPitchDemoPrivateProviders:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchDemoPrivateProvidersDialogue")
          .default
      case DialogueIDs.SelfReferralPitchDemoConference:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchDemoConferenceDialogue")
          .default
      case DialogueIDs.SelfReferralDemo:
        return require("./dialogues/selfReferral/SelfReferralDemoDialogue").default
      case DialogueIDs.SelfReferralCCG:
        return require("./dialogues/selfReferral/SelfReferralCCGDialogue").default
      case DialogueIDs.SelfReferralTrentPTS:
        return require("./dialogues/selfReferral/SelfReferralTrentPTSDialogue").default
      case DialogueIDs.SelfReferralHealix:
        return require("./dialogues/selfReferral/SelfReferralHealixDialogue").default
      case DialogueIDs.SelfReferralInsight:
        return require("./dialogues/selfReferral/SelfReferralInsightDialogue").default
      case DialogueIDs.SelfReferralNottinghamshire:
        return require("./dialogues/selfReferral/SelfReferralNottinghamshireDialogue").default
      case DialogueIDs.SelfReferralSABP:
        return require("./dialogues/selfReferral/SelfReferralSABPDialogue").default
      case DialogueIDs.SelfReferralLincolnshire:
        return require("./dialogues/selfReferral/SelfReferralLincolnshireDialogue").default
      case DialogueIDs.SelfReferralVHG:
        return require("./dialogues/selfReferral/SelfReferralVHGDialogue").default
      case DialogueIDs.SelfReferralDemoPrivateProviders:
        return require("./dialogues/selfReferral/SelfReferralDemoPrivateProvidersDialogue").default
      case DialogueIDs.SelfReferralInsightWaitlist:
        return require("./dialogues/selfReferral/SelfReferralInsightWaitlistDialogue").default
      case DialogueIDs.SelfReferralDemoConference:
        return require("./dialogues/selfReferral/SelfReferralDemoConferenceDialogue").default
      case DialogueIDs.SelfReferralVitality:
        return require("./dialogues/selfReferral/SelfReferralVitalityDialogue").default
      case DialogueIDs.AssessmentPitchIAPT:
        return require("./dialogues/assessmentPitch/AssessmentPitchIAPTDialogue").default
      case DialogueIDs.AssessmentPitchGP:
        return require("./dialogues/assessmentPitch/AssessmentPitchGPDialogue").default
      case DialogueIDs.AssessmentPitchCCG:
        return require("./dialogues/assessmentPitch/AssessmentPitchCCGDialogue").default
      case DialogueIDs.AssessmentPitchTrentPTS:
        return require("./dialogues/assessmentPitch/AssessmentPitchTrentPTSDialogue").default
      case DialogueIDs.AssessmentPitchHealix:
        return require("./dialogues/assessmentPitch/AssessmentPitchHealixDialogue").default
      case DialogueIDs.AssessmentPitchInsight:
        return require("./dialogues/assessmentPitch/AssessmentPitchInsightDialogue").default
      case DialogueIDs.AssessmentPitchInsightFollowUp:
        return require("./dialogues/assessmentPitch/AssessmentPitchInsightFollowUpDialogue").default
      case DialogueIDs.AssessmentPitchLincolnshire:
        return require("./dialogues/assessmentPitch/AssessmentPitchLincolnshireDialogue").default
      case DialogueIDs.AssessmentPitchNottinghamshire:
        return require("./dialogues/assessmentPitch/AssessmentPitchNottinghamshireDialogue").default
      case DialogueIDs.AssessmentPitchSABP:
        return require("./dialogues/assessmentPitch/AssessmentPitchSABPDialogue").default
      case DialogueIDs.AssessmentPitchVHG:
        return require("./dialogues/assessmentPitch/AssessmentPitchVHGDialogue").default
      case DialogueIDs.AssessmentPitchDemoPrivateProviders:
        return require("./dialogues/assessmentPitch/AssessmentPitchDemoPrivateProvidersDialogue")
          .default
      case DialogueIDs.AssessmentPitchVitality:
        return require("./dialogues/assessmentPitch/AssessmentPitchVitalityDialogue").default
      case DialogueIDs.AssessmentDemo:
        return require("./dialogues/assessment/AssessmentDemoDialogue").default
      case DialogueIDs.AssessmentCCG:
        return require("./dialogues/assessment/AssessmentCCGDialogue").default
      case DialogueIDs.AssessmentSABP:
        return require("./dialogues/assessment/AssessmentSABPDialogue").default
      case DialogueIDs.AssessmentHealix:
        return require("./dialogues/assessment/AssessmentHealixDialogue").default
      case DialogueIDs.AssessmentInsight:
        return require("./dialogues/assessment/AssessmentInsightDialogue").default
      case DialogueIDs.AssessmentNottinghamshire:
        return require("./dialogues/assessment/AssessmentNottinghamshireDialogue").default
      case DialogueIDs.AssessmentVHG:
        return require("./dialogues/assessment/AssessmentVHGDialogue").default
      case DialogueIDs.AssessmentTrentPTS:
        return require("./dialogues/assessment/AssessmentTrentPTSDialogue").default
      case DialogueIDs.AssessmentLincolnshire:
        return require("./dialogues/assessment/AssessmentLincolnshireDialogue").default
      case DialogueIDs.AssessmentDemoPrivateProviders:
        return require("./dialogues/assessment/AssessmentDemoPrivateProvidersDialogue").default
      case DialogueIDs.AssessmentDemoConference:
        return require("./dialogues/assessment/AssessmentDemoConferenceDialogue").default
      case DialogueIDs.AssessmentVitality:
        return require("./dialogues/assessment/AssessmentVitalityDialogue").default
      case DialogueIDs.PHQ9:
        return require("./surveys/PHQ9/PHQ9").default
      case DialogueIDs.PHQ9SABP:
        return require("./surveys/PHQ9/PHQ9SABPDialogue").default
      case DialogueIDs.PHQ9Nottinghamshire:
        return require("./surveys/PHQ9/PHQ9NottinghamshireDialogue").default
      case DialogueIDs.PHQ9VHG:
        return require("./surveys/PHQ9/PHQ9VHGDialogue").default
      case DialogueIDs.PHQ9TrentPTS:
        return require("./surveys/PHQ9/PHQ9TrentPTSDialogue").default
      case DialogueIDs.PHQ9Vitality:
        return require("./surveys/PHQ9/PHQ9VitalityDialogue").default
      case DialogueIDs.RiskPathwayDemo:
        return require("./dialogues/riskPathway/RiskPathwayDemoDialogue").default
      case DialogueIDs.RiskPathwayCCG:
        return require("./dialogues/riskPathway/RiskPathwayCCGDialogue").default
      case DialogueIDs.RiskPathwayTrentPTS:
        return require("./dialogues/riskPathway/RiskPathwayTrentPTSDialogue").default
      case DialogueIDs.RiskPathwayHealix:
        return require("./dialogues/riskPathway/RiskPathwayHealixDialogue").default
      case DialogueIDs.RiskPathwayInsight:
        return require("./dialogues/riskPathway/RiskPathwayInsightDialogue").default
      case DialogueIDs.RiskPathwayInsightFollowUp:
        return require("./dialogues/riskPathway/RiskPathwayInsightFollowUpDialogue").default
      case DialogueIDs.RiskPathwayNottinghamshire:
        return require("./dialogues/riskPathway/RiskPathwayNottinghamshireDialogue").default
      case DialogueIDs.RiskPathwaySABP:
        return require("./dialogues/riskPathway/RiskPathwaySABPDialogue").default
      case DialogueIDs.RiskPathwayVHG:
        return require("./dialogues/riskPathway/RiskPathwayVHGDialogue").default
      case DialogueIDs.RiskPathwayLincolnshire:
        return require("./dialogues/riskPathway/RiskPathwayLincolnshireDialogue").default
      case DialogueIDs.RiskPathwayDemoPrivateProviders:
        return require("./dialogues/riskPathway/RiskPathwayDemoPrivateProvidersDialogue").default
      case DialogueIDs.RiskPathwayVitality:
        return require("./dialogues/riskPathway/RiskPathwayVitalityDialogue").default
      case DialogueIDs.GAD7:
        return require("./surveys/GAD7").default
      case DialogueIDs.IAPTPhobiaScale:
        return require("./surveys/IAPTPhobiaScale").default
      case DialogueIDs.IAPTEmploymentStatus:
        return require("./surveys/IAPTEmploymentStatus").default
      case DialogueIDs.IAPTWorkAndSocialAdjustment:
        return require("./surveys/IAPTWorkAndSocialAdjustment").default
      case DialogueIDs.IAPTMedication:
        return require("./surveys/IAPTMedication").default
      case DialogueIDs.IAPTAccommodation:
        return require("./surveys/IAPTAccommodation").default
      case DialogueIDs.TreatmentOptionsIAPT:
        return require("./dialogues/treatmentOptions/TreatmentOptionsIAPTDialogue").default
      case DialogueIDs.TreatmentOptionsCCG:
        return require("./dialogues/treatmentOptions/TreatmentOptionsCCGDialogue").default
      case DialogueIDs.TreatmentOptionsTrentPTS:
        return require("./dialogues/treatmentOptions/TreatmentOptionsTrentPTSDialogue").default
      case DialogueIDs.TreatmentOptionsHealix:
        return require("./dialogues/treatmentOptions/TreatmentOptionsHealixDialogue").default
      case DialogueIDs.TreatmentOptionsSABP:
        return require("./dialogues/treatmentOptions/TreatmentOptionsSABPDialogue").default
      case DialogueIDs.TreatmentOptionsInsight:
        return require("./dialogues/treatmentOptions/TreatmentOptionsInsightDialogue").default
      case DialogueIDs.TreatmentOptionsVHG:
        return require("./dialogues/treatmentOptions/TreatmentOptionsVHGDialogue").default
      case DialogueIDs.TreatmentOptionsDemoPrivateProviders:
        return require("./dialogues/treatmentOptions/TreatmentOptionsDemoPrivateProvidersDialogue")
          .default
      case DialogueIDs.TreatmentOptionsVitality:
        return require("./dialogues/treatmentOptions/TreatmentOptionsVitalityDialogue").default
      case DialogueIDs.BookMentalHealthProfessional:
        return require("./dialogues/bookProfessional/BookMentalHealthProfessionalDialogue").default
      case DialogueIDs.WellBeingCCG:
        return require("./dialogues/wellbeing/WellbeingCCGDialogue").default
      case DialogueIDs.WellbeingHealix:
        return require("./dialogues/wellbeing/WellbeingHealixDialogue").default
      case DialogueIDs.WellbeingDemoPrivateProviders:
        return require("./dialogues/wellbeing/WellbeingDemoPrivateProvidersDialogue").default
      case DialogueIDs.GoodbyeDemo:
        return require("./dialogues/goodbye/GoodbyeDemoDialogue").default
      case DialogueIDs.GoodbyeCCG:
        return require("./dialogues/goodbye/GoodbyeCCGDialogue").default
      case DialogueIDs.GoodbyeTrentPTS:
        return require("./dialogues/goodbye/GoodbyeTrentPTSDialogue").default
      case DialogueIDs.GoodbyeHealix:
        return require("./dialogues/goodbye/GoodbyeHealixDialogue").default
      case DialogueIDs.GoodbyeInsight:
        return require("./dialogues/goodbye/GoodbyeInsightDialogue").default
      case DialogueIDs.GoodbyeInsightFollowUp:
        return require("./dialogues/goodbye/GoodbyeInsightFollowUpDialogue").default
      case DialogueIDs.GoodbyeNottinghamshire:
        return require("./dialogues/goodbye/GoodbyeNottinghamshireDialogue").default
      case DialogueIDs.GoodbyeSABP:
        return require("./dialogues/goodbye/GoodbyeSABPDialogue").default
      case DialogueIDs.GoodbyeVHG:
        return require("./dialogues/goodbye/GoodbyeVHGDialogue").default
      case DialogueIDs.GoodbyeLincolnshire:
        return require("./dialogues/goodbye/GoodbyeLincolnshireDialogue").default
      case DialogueIDs.GoodbyeDemoPrivateProviders:
        return require("./dialogues/goodbye/GoodbyeDemoPrivateProvidersDialogue").default
      case DialogueIDs.GoodbyeVitality:
        return require("./dialogues/goodbye/GoodbyeVitalityDialogue").default
      case DialogueIDs.CrisisDemo:
        return require("./dialogues/crisis/CrisisDemoDialogue").default
      case DialogueIDs.CrisisCCG:
        return require("./dialogues/crisis/CrisisCCGDialogue").default
      case DialogueIDs.CrisisTrentPTS:
        return require("./dialogues/crisis/CrisisTrentPTSDialogue").default
      case DialogueIDs.CrisisInsight:
        return require("./dialogues/crisis/CrisisInsightDialogue").default
      case DialogueIDs.CrisisNottinghamshire:
        return require("./dialogues/crisis/CrisisNottinghamshireDialogue").default
      case DialogueIDs.CrisisHealix:
        return require("./dialogues/crisis/CrisisHealixDialogue").default
      case DialogueIDs.CrisisSABP:
        return require("./dialogues/crisis/CrisisSABPDialogue").default
      case DialogueIDs.CrisisVHG:
        return require("./dialogues/crisis/CrisisVHGDialogue").default
      case DialogueIDs.CrisisLincolnshire:
        return require("./dialogues/crisis/CrisisLincolnshireDialogue").default
      case DialogueIDs.CrisisDemoPrivateProviders:
        return require("./dialogues/crisis/CrisisDemoPrivateProvidersDialogue").default
      case DialogueIDs.CrisisVitality:
        return require("./dialogues/crisis/CrisisVitalityDialogue").default
      case DialogueIDs.BookAppointmentVitality:
        return require("./dialogues/bookAppointment/BookAppointmentVitalityDialogue").default
      default:
        throw new Error(`Unknown dialogue identifier: ${id}`)
    }
  }
}

export default new DialoguesRegistry()
