import React from "react"
import { useState } from "react" // New
import Tracker from "../../../utils/Tracker"
import { useConfigStore } from "../../contexts/RootStoreContext"
import { Menu, MenuItem } from "@material-ui/core"
import { Settings } from "@material-ui/icons"
import "./SettingsMenu.scss"

interface Props {
  faqLink: string
}

export default function SettingsMenu(props: Props): JSX.Element {
  const { faqLink } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const config = useConfigStore()
  const settingsMenu = document.querySelector(".settingsMenu")
  const settingsMenuBackdrop = document.querySelector(".settingsMenuBackdrop")
  const poweredByLimbic = document.querySelector(".lb-powered-by-limbic")

  const handleClick = e => {
    Tracker.getInstance().track("Settings menu opened")
    setAnchorEl(e.currentTarget)
    settingsMenu?.setAttribute("style", "display:block")
    settingsMenuBackdrop?.setAttribute("style", "display:block")
  }

  const handleFAQ = () => {
    Tracker.getInstance().track("FAQ button clicked")
    window.open(faqLink, "_blank")
  }

  //New
  const [isContrastModeOn, setContrastMode] = useState(false)
  const handleContrastMode = () => {
    Tracker.getInstance().track("Contrast Mode button clicked.")
    if (isContrastModeOn) {
      setContrastMode(false)
      Tracker.getInstance().track("Contrast has been turned off.")
      document.documentElement.style.setProperty("--primary", "")
      document.documentElement.style.setProperty("--button", "")
      document.documentElement.style.setProperty("--background", "")
      document.documentElement.style.setProperty("--primaryText", "")
      document.documentElement.style.setProperty("--placeholder", "")
      document.documentElement.style.setProperty("--lines", "")
      document.documentElement.style.setProperty("--secondary", "")
      document.documentElement.style.setProperty("--checkbox", "")
      document.documentElement.style.setProperty("--textBoxBackground", "")
      document.documentElement.style.setProperty("--scrollBarColor", "")
      document.documentElement.style.setProperty("--scrollBarBackgroundColor", "")
      document.documentElement.style.setProperty("--buttonOpacity", "")
      poweredByLimbic?.setAttribute("style", "display:flex")
    } else {
      setContrastMode(true)
      Tracker.getInstance().track("Contrast has been turned on.")
      document.documentElement.style.setProperty("--primary", "#17227A")
      document.documentElement.style.setProperty("--button", "#17227A")
      document.documentElement.style.setProperty("--background", "#FFFFFF")
      document.documentElement.style.setProperty("--primaryText", "#000")
      document.documentElement.style.setProperty("--placeholder", "#000")
      document.documentElement.style.setProperty("--lines", "#17227A")
      document.documentElement.style.setProperty("--secondary", "#f5f5f8")
      document.documentElement.style.setProperty("--checkbox", "#17227A")
      document.documentElement.style.setProperty("--textBoxBackground", "#17227A")
      document.documentElement.style.setProperty("--scrollBarColor", "#17227A")
      document.documentElement.style.setProperty("--scrollBarBackgroundColor", "#FFFFFF")
      document.documentElement.style.setProperty("--buttonOpacity", "1")
      poweredByLimbic?.setAttribute("style", "display:none")
    }
  }

  const [isBiggerTextModeOn, setBiggerTextMode] = useState(false)
  const handleBiggerTextMode = () => {
    Tracker.getInstance().track("Bigger Text Mode button clicked.")
    if (isBiggerTextModeOn) {
      setBiggerTextMode(false)
      Tracker.getInstance().track("Bigger Text has been turned off.")
      document.documentElement.style.setProperty("--lineHeight", "")
      document.documentElement.style.setProperty("--fontSize", "")
      document.documentElement.style.setProperty("--buttonfontSize", "")
    } else {
      setBiggerTextMode(true)
      Tracker.getInstance().track("Bigger Text has been turned on.")
      document.documentElement.style.setProperty("--lineHeight", "2.4rem")
      document.documentElement.style.setProperty("--fontSize", "20px")
      document.documentElement.style.setProperty("--buttonfontSize", "20px")
    }
  }

  const faqButton = document.getElementById("faqButton")
  const biggerTextButton = document.querySelector("#biggerTextButton")
  const settingsButton = document.getElementById("settingsButton")

  const handleMenuFocus = e => {
    if (e.key === "Escape") {
      settingsMenu?.setAttribute("style", "display:none")
      settingsMenuBackdrop?.setAttribute("style", "display:none")
    }
  }

  const handleClose = () => {
    settingsMenu?.setAttribute("style", "display:none")
    settingsMenuBackdrop?.setAttribute("style", "display:none")
  }

  biggerTextButton?.addEventListener("focusout", function () {
    faqButton?.focus()
  })

  settingsButton?.addEventListener("keydown", function (e) {
    if (e.key === "Tab") {
      settingsButton?.blur()
    }
  })

  // TODO: Menu here, introduces a strict mode error
  //       about the deprecated findDOMNode that the
  //       Modal inside of it uses. Debug and remove
  //       for future peace of mind
  return (
    <div onKeyDown={handleMenuFocus}>
      <button
        aria-label="Settings"
        id="settingsButton"
        className="lb-settings-button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Settings />
      </button>

      <div className="settingsMenu">
        <ul>
          {!!config.faqLink && (
            <li>
              <button aria-flowto="contrastButton" id="faqButton" onClick={handleFAQ}>
                FAQ
              </button>
            </li>
          )}
          <li>
            <button
              aria-flowto="biggerTextButton"
              id="contrastButton"
              onClick={handleContrastMode}
              className={isContrastModeOn ? "modeToggle" : ""}
            >
              Contrast Mode
            </button>
          </li>
          <li>
            <button
              aria-flowto="faqButton"
              id="biggerTextButton"
              onClick={handleBiggerTextMode}
              className={isBiggerTextModeOn ? "modeToggle" : ""}
            >
              Bigger Text
            </button>
          </li>
        </ul>
      </div>
      <div aria-label="Close" onClick={handleClose} className="settingsMenuBackdrop"></div>
      <Menu
        keepMounted
        className="settingsMenu"
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleFAQ}>FAQ</MenuItem>
      </Menu>
    </div>
  )
}
