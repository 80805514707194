import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScript, GetNameScriptState } from "./GetNameScript"

type State = GetNameScriptState
export type GetNameDemoConferenceScriptState = State

export class GetNameDemoConferenceScript extends GetNameScript {
  readonly name: string = "GetNameDemoConferenceScript"

  /** Script Steps */

  @step
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }
}

export default class GetNameDemoConferenceDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameDemoConference
  readonly name: string = "GetNameDemoConferenceDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameDemoConferenceDialogue.id, new GetNameDemoConferenceScript(), state, snapshot)
  }
}
